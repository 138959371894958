import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps, Field, initialize } from 'redux-form';
import ReduxFormInput from '../../components/shared/ReduxFormInput';
import ReduxFormCheckbox from '../../components/shared/ReduxFormCheckbox';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import { USERS_ADD_EDIT_FORM } from '../../utils/reduxFormConstants';
import { isPresent, languageOptions } from '../../utils/helper';

interface UserFormInitialValues {
  employeeId: string;
  firstName: string;
  lastName: string;
  bushpay: boolean;

  userType: number;
  license1: string;
  supervisor: number;
  isReviewingSupervisor: boolean;

  username: string;
  phone: string;
  language: string;
  isApprovingSupervisor: boolean;

  isContractor: boolean;
  isLogbookWebpageUser: boolean;
  isLogbookMobileUser: boolean;
  isJobsWebpageAccess: boolean;
  isCustomersWebpageAccess: boolean;
  isEmployeesWebpageAccess: boolean;
  isAircraftWebpageAccess: boolean;
  isFuelWebpageAccess: boolean;
  isFlightreportWebpageAccess: boolean;
  isBookingWebpageAccess: boolean;
  isBillingWebpageAccess: boolean;
  isClientWebpageAccess: boolean;
  notes: string;
}
interface UserFormProps {
  userTypes: {}[];
  supervisors: {}[];
  initialValues?: UserFormInitialValues;
  showLicenseField: boolean;
}

const UserForm = ({
  userTypes,
  supervisors,
  initialValues,
  showLicenseField
}: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPresent(initialValues)) {
      dispatch(initialize(USERS_ADD_EDIT_FORM, initialValues));
    }
  }, [initialValues, dispatch]);

  return (
    <div className="users-details-input-options">
      <div className="users-details-input-options-box">
        <div className="users-details-input-options-box-left">
          <div className="users-details-input-options-wrapper">
            <Field
              name="employeeId"
              type="text"
              component={ReduxFormInput}
              label="Employee ID"
              required
            />
            <Field
              name="firstName"
              type="text"
              component={ReduxFormInput}
              label="First Name"
              required
            />
            <Field
              name="lastName"
              type="text"
              component={ReduxFormInput}
              label="Last Name"
              required
            />
          </div>

          <div className="users-details-input-options-wrapper">
            <Field
              name="userType"
              component={ReduxFormAutocomplete}
              label="Department"
              optionsList={userTypes}
              required
            />

            {showLicenseField && (
              <Field
                name="license1"
                type="text"
                component={ReduxFormInput}
                label="License"
                required
              />
            )}

            <Field
              name="supervisor"
              component={ReduxFormAutocomplete}
              label="Supervisor"
              optionsList={supervisors}
              required
            />
          </div>

          <div className="users-details-input-options-wrapper">
            <Field
              name="username"
              type="text"
              component={ReduxFormInput}
              label="Email"
              required
            />

            <Field
              name="phone"
              type="text"
              component={ReduxFormInput}
              label="Mobile Phone"
              required
            />

            <Field
              name="language"
              component={ReduxFormAutocomplete}
              label="Language"
              optionsList={languageOptions}
              required
            />
          </div>
        </div>

        <div className="users-details-input-options-box-right">
          <div className="users-details-input-options-wrapper">
            <Field
              name="isContractor"
              component={ReduxFormCheckbox}
              label="Contractor"
            />

            <Field
              name="bushpay"
              component={ReduxFormCheckbox}
              label="Bushpay"
            />
            <Field
              name="isReviewingSupervisor"
              component={ReduxFormCheckbox}
              label="Reviewer"
            />

            <Field
              name="isApprovingSupervisor"
              component={ReduxFormCheckbox}
              label="Approver"
            />
            <Field
              name="isLogbookWebpageUser"
              component={ReduxFormCheckbox}
              label="Logbook Web Page"
            />

            <Field
              name="isLogbookMobileUser"
              component={ReduxFormCheckbox}
              label="Logbook Mobile"
            />
            <Field
              name="isJobsWebpageAccess"
              component={ReduxFormCheckbox}
              label="Jobs Webpage"
            />
            <Field
              name="isCustomersWebpageAccess"
              component={ReduxFormCheckbox}
              label="Customer Webpage"
            />
            <Field
              name="isEmployeesWebpageAccess"
              component={ReduxFormCheckbox}
              label="Employees Webpage"
            />
            <Field
              name="isAircraftWebpageAccess"
              component={ReduxFormCheckbox}
              label="Aircraft Webpage"
            />
            <Field
              name="isFuelWebpageAccess"
              component={ReduxFormCheckbox}
              label="Fuel Webpage"
            />
            <Field
              name="isFlightreportWebpageAccess"
              component={ReduxFormCheckbox}
              label="Flight Report Webpage"
            />
            <Field
              name="isBookingWebpageAccess"
              component={ReduxFormCheckbox}
              label="Booking Webpage"
            />
            <Field
              name="isBillingWebpageAccess"
              component={ReduxFormCheckbox}
              label="Billing Webpage"
            />
            <Field
              name="isClientWebpageAccess"
              component={ReduxFormCheckbox}
              label="Client Users Webpage"
            />
          </div>
        </div>
      </div>

      <div className="users-details-textarea">
        <Field
          name="notes"
          component={ReduxFormInput}
          rows={10}
          label="Notes"
          variant="outlined"
          multiline
        />
      </div>
    </div>
  );
};

export default reduxForm<InjectedFormProps, UserFormProps>({
  form: USERS_ADD_EDIT_FORM
})(UserForm);
