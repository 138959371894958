import { FuelExpenseScreenFiltersProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList } from '../../utils/helper';

const initialState: FuelExpenseScreenFiltersProps = {
  aircraftFiltersArray: initialFilterList,
  bookingFiltersArray: initialFilterList,
  vendorFiltersArray: initialFilterList,
  documentHashFiltersArray: initialFilterList,
  invoiceNumberArray: initialFilterList
};

const fuelExpenseScreenFilters = (
  state: FuelExpenseScreenFiltersProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_FUEL_EXPENSE_SCREEN_AIRCRAFT_FILTERS_SUCCESS:
      const aircraftFiltersArray = initialFilterList.concat(
        action.payload.aircraftFiltersArray
      );

      return {
        ...state,
        aircraftFiltersArray: aircraftFiltersArray
      };

    case types.FETCH_FUEL_EXPENSE_SCREEN_BOOKING_FILTERS_SUCCESS:
      const bookingFiltersArray = initialFilterList.concat(
        action.payload.bookingFiltersArray
      );

      return {
        ...state,
        bookingFiltersArray: bookingFiltersArray
      };

    case types.FETCH_FUEL_EXPENSE_SCREEN_AIRCRAFT_FILTERS_FAILURE:
    case types.FETCH_FUEL_EXPENSE_SCREEN_BOOKING_FILTERS_FAILURE:
      return {
        ...state
      };

    case types.SET_FUEL_EXPENSE_SCREEN_VENDOR_FILTERS:
      const vendorFiltersArray = initialFilterList.concat(
        action.payload.vendorFiltersArray
      );

      return {
        ...state,
        vendorFiltersArray: vendorFiltersArray
      };

    case types.SET_FUEL_EXPENSE_SCREEN_DOCUMENT_HASH_FILTERS:
      const documentHashFiltersArray = initialFilterList.concat(
        action.payload.documentHashFiltersArray
      );

      return {
        ...state,
        documentHashFiltersArray: documentHashFiltersArray
      };

    case types.SET_FUEL_EXPENSE_SCREEN_INVOICE_NUMBER_FILTERS:
      const invoiceNumberArray = initialFilterList.concat(
        action.payload.invoiceNumberArray
      );

      return {
        ...state,
        invoiceNumberArray: invoiceNumberArray
      };

    default:
      return state;
  }
};

export default fuelExpenseScreenFilters;
