export const DEPARTMENT = 'department';
export const BUSH_PAY = 'bushPay';
export const STATUS = 'status';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const COMPANY = 'company';


export const initialValues = {
  [DEPARTMENT]: -1,
  [BUSH_PAY]: 'all',
  [STATUS]: 'all',
  [FIRST_NAME]:-1,
  [LAST_NAME]:-1,
  [COMPANY]:-1,
};

export const filtersNotWorkingOptions = [
  { name: 'Filter not working', id: -2 }
];
