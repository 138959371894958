import React from 'react';
import { Field } from 'redux-form';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import { STATUS } from '../../routes/Users/helper';
import { CUSTOMERS_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { statusOptions } from '../../utils/helper';

const commonFilterPath = ['form', CUSTOMERS_FILTERS_FORM, 'values'];
export const statusFilterStatePath = commonFilterPath.concat(STATUS);

export const StatusFilterComponent = () => {
  return (
    <Field
      name={STATUS}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={statusOptions}
      required
    />
  );
};
