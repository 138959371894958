import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import * as R from 'ramda';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField
} from '@material-ui/core';
import ImageViewerModal from './ImageViewerModal';
import SharedSpinner from '../shared/SharedSpinner';
import StoreState from '../../redux/utils/interfaces';
import { getMonthStartToCurrentDateRange } from '../../utils/helper';
import { FUEL_EXPENSE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import {
  useStyles,
  columns,
  FuelExpenseRow,
  filterFuelExpenseScreenData
} from './helper';
import { fetchFuelExpenseScreenDataRequest } from '../../redux/actions/fuelExpenseScreenData';
import { useSortHook } from '../../hooks/sortHooks';
import SharedTableHeader from '../shared/SharedTableHeader';

import './style.scss';

interface FuelExpenseScreenTableProps {
  invoiceNumberValues: {};

  setInvoiceNumberValues: Function;
}

const FuelExpenseScreenTable = ({
  invoiceNumberValues,
  setInvoiceNumberValues
}: FuelExpenseScreenTableProps) => {
  const imageViewerDataInitialValue: string[] = [];
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageViewerData, setImageViewerData] = useState(
    imageViewerDataInitialValue
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    filterFormValues,
    fuelExpenseScreenData,
    isFuelExpenseScreenDataLoading
  } = useSelector((state: StoreState) => {
    return {
      filterFormValues: getFormValues(FUEL_EXPENSE_FILTERS_FORM)(state) || {},
      fuelExpenseScreenData: R.pathOr(
        [],
        ['fuelExpenseScreenData', 'fuelExpenseData'],
        state
      ),
      isFuelExpenseScreenDataLoading: R.pathOr(
        false,
        ['fuelExpenseScreenData', 'isLoading'],
        state
      )
    };
  });

  const rangeValue = R.pathOr(
    getMonthStartToCurrentDateRange(),
    ['range'],
    filterFormValues
  );
  const aircraftFilters = R.pathOr(-1, ['aircraftFilters'], filterFormValues);
  const bookingFilters = R.pathOr(-1, ['bookingFilters'], filterFormValues);
  const vendorFilters = R.pathOr(-1, ['vendorFilters'], filterFormValues);
  const pilotFilters = R.pathOr(-1, ['pilotFilters'], filterFormValues);
  const fuelTypeFilters = R.pathOr(-1, ['fuelTypeFilters'], filterFormValues);
  const paymentTypeFilters = R.pathOr(-1, ['paymentFilters'], filterFormValues);

  const flightReportFilters = R.pathOr(
    -1,
    ['flightReportFilters'],
    filterFormValues
  );
  const documentHashFilters = R.pathOr(
    -1,
    ['documentHashFilters'],
    filterFormValues
  );
  const invoiceNumberFilters = R.pathOr(
    -1,
    ['invoiceNumberFilters'],
    filterFormValues
  );

  useEffect(() => {
    dispatch(fetchFuelExpenseScreenDataRequest(rangeValue.from, rangeValue.to));
  }, [rangeValue, dispatch]);

  useEffect(() => {
    const invoiceNumberValuesLocal = {};

    fuelExpenseScreenData.forEach((row: FuelExpenseRow, index: number) => {
      invoiceNumberValuesLocal[`invoiceNumber_${row.refuelId}`] =
        row.vendorInvoiceNumber;
    });

    setInvoiceNumberValues(invoiceNumberValuesLocal);
  }, [fuelExpenseScreenData, setInvoiceNumberValues]);

  const handleInvoiceNumberChange = (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    const { name, value } = event.target;

    setInvoiceNumberValues({ ...invoiceNumberValues, [name]: value });
  };

  const filteredFuelExpenseScreenData = useMemo(
    () =>
      filterFuelExpenseScreenData(fuelExpenseScreenData, {
        aircraftFilters,
        bookingFilters,
        vendorFilters,
        documentHashFilters,
        invoiceNumberFilters,
        pilotFilters,
        flightReportFilters,
        fuelTypeFilters,
        paymentTypeFilters
      }),
    [
      fuelExpenseScreenData,
      aircraftFilters,
      bookingFilters,
      vendorFilters,
      documentHashFilters,
      invoiceNumberFilters,
      pilotFilters,
      flightReportFilters,
      fuelTypeFilters,
      paymentTypeFilters
    ]
  );

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    dataArray: filteredFuelExpenseScreenData,
    columnsData: columns,
    initialSortById: 'date'
  });

  const handelOnClickRow = (receiptUri: string[]) => {
    setImageViewerData(receiptUri);
    setIsImageViewerOpen(true);
  };

  if (isFuelExpenseScreenDataLoading) {
    return <SharedSpinner />;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          order={order}
          orderBy={orderBy}
          columns={columns}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: FuelExpenseRow, index: number) => {
            const currentInvoiceNumberName = `invoiceNumber_${row.refuelId}`;

            return (
              <TableRow
                key={index}
                className="fuel-expense-custom-body-row"
                onClick={() => handelOnClickRow(row.receiptUri)}>
                <TableCell component="th" scope="row" align={'left'}>
                  {moment.utc(row.date).format('MMM D YYYY')}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.aircraft.registration}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {`${row.booking.bookingNumber} - ${row.booking.name}`}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.flightReport}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.vendor}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.userName}
                </TableCell>
                <TableCell component="th" scope="row" align={'right'}>
                  {Number(row.quantity).toFixed(1)}
                </TableCell>
                <TableCell component="th" scope="row" align={'right'}>
                  {Number(row.amount).toFixed(2)}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.document}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.type}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.paymentMethod}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  <TextField
                    name={currentInvoiceNumberName}
                    value={invoiceNumberValues[currentInvoiceNumberName] || ''}
                    variant="outlined"
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleInvoiceNumberChange}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>

        <ImageViewerModal
          imageViewerData={imageViewerData}
          isImageViewerOpen={isImageViewerOpen}
          setIsImageViewerOpen={setIsImageViewerOpen}
        />
      </Table>
    </TableContainer>
  );
};

export default FuelExpenseScreenTable;
