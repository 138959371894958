import types from '../utils/actionTypes';

export const fetchAircraftScreenDataRequest = () => {
  return {
    type: types.FETCH_AIRCRAFT_SCREEN_DATA_REQUEST
  };
};

export const fetchAircraftScreenDataSuccess = (aircraft: []) => {
  return {
    type: types.FETCH_AIRCRAFT_SCREEN_DATA_SUCCESS,
    payload: { aircraft }
  };
};

export const fetchAircraftScreenDataFailure = () => {
  return {
    type: types.FETCH_AIRCRAFT_SCREEN_DATA_FAILURE
  };
};

export const fetchAircraftOfficialTypesRequest = () => {
  return {
    type: types.FETCH_AIRCRAFT_OFFICIAL_TYPES_REQUEST
  };
};

export const fetchAircraftOfficialTypesSuccess = (
  aircraftOfficialTypes: []
) => {
  return {
    type: types.FETCH_AIRCRAFT_OFFICIAL_TYPES_SUCCESS,
    payload: { aircraftOfficialTypes }
  };
};

export const fetchAircraftOfficialTypesFailure = () => {
  return {
    type: types.FETCH_AIRCRAFT_OFFICIAL_TYPES_FAILURE
  };
};

export const addEditAircraftDetailsRequest = (
  aircraftId: number | null,
  values: {}
) => {
  return {
    type: types.ADD_EDIT_AIRCRAFT_DETAILS_REQUEST,
    payload: { aircraftId, values }
  };
};

export const addEditAircraftDetailsSuccess = () => {
  return {
    type: types.ADD_EDIT_AIRCRAFT_DETAILS_SUCCESS
  };
};

export const addEditAircraftDetailsFailure = () => {
  return {
    type: types.ADD_EDIT_AIRCRAFT_DETAILS_FAILURE
  };
};

export const aircraftStatusChangeRequest = (
  aircraftId: number | null,
  isArchived: boolean
) => {
  return {
    type: types.UPDATE_AIRCRAFT_STATUS_REQUEST,
    payload: {
      aircraftId,
      isArchived
    }
  };
};

export const aircraftStatusChangeSuccess = () => {
  return {
    type: types.UPDATE_AIRCRAFT_STATUS_SUCCESS
  };
};

export const aircraftStatusChangeFailure = () => {
  return {
    type: types.UPDATE_AIRCRAFT_STATUS_FAILURE
  };
};
