import React from 'react';
import ImagesViewer from '../ImagesViewer';
import { isNilOrEmpty } from '../../utils/helper';
import { Grid, Paper, Typography } from '@material-ui/core';
import './imageViewerStyles.scss';
import DetailsForm from './DetailsForm';
import { LogBookRow } from '../LogBookScreenTable/helper';
interface ImageViewerProps {
  imageViewerData: string[];
  currentActiveImage:number;
   setCurrentActiveImage:React.Dispatch<React.SetStateAction<number>>;
}

const ImageViewer = ({ imageViewerData,currentActiveImage, setCurrentActiveImage }: ImageViewerProps) => {
  const noReceipts = isNilOrEmpty(imageViewerData);
  if (noReceipts) {
    return <Typography>No receipts available</Typography>;
  }

  return (
    <div>
      <ImagesViewer imagesData={imageViewerData} currentActiveImage={currentActiveImage} setCurrentActiveImage={setCurrentActiveImage}/>
    </div>
  );
};

interface ImageViewerModalProps {
  imageViewerData: string[];
  formData:LogBookRow;
  currentActiveImage: number;
  setCurrentActiveImage:React.Dispatch<React.SetStateAction<number>>;
}

const ImageViewerModal = ({
  imageViewerData,
  formData,
  currentActiveImage, setCurrentActiveImage
}: ImageViewerModalProps) => {

  return (
    <Paper className="mt-4">
      <Grid container>
        <Grid item xs={9}>
          <Paper square className="image-slider-container">
          <ImageViewer currentActiveImage={currentActiveImage} setCurrentActiveImage={setCurrentActiveImage} imageViewerData={imageViewerData} />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper square className="receipt-fields-container">
            <DetailsForm currentLogBookData={formData} />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ImageViewerModal;
