import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  AIRCRAFT_FILTERS,
  BOOK_NUMBER_FILTERS,
  PAGE_NUMBER_FILTERS,
  UPLOADED_BY_FILTERS,
  filtersNotWorkingOptions
} from '../../routes/LogBook/helper';
import { LOG_BOOK_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { LogBookRow} from './helper';
// import { LogBookRow } from './helper';

const commonFilterPath = ['form', LOG_BOOK_FILTERS_FORM, 'values'];
export const aircraftFilterStatePath = [...commonFilterPath, AIRCRAFT_FILTERS];
export const bookNumberFilterStatePath = [
  ...commonFilterPath,
  BOOK_NUMBER_FILTERS
];
export const pageNumberFilterStatePath = [
  ...commonFilterPath,
  PAGE_NUMBER_FILTERS
];
export const uploadedByFilterStatePath = [
  ...commonFilterPath,
  UPLOADED_BY_FILTERS
];

export const AircraftFilterComponent = () => {

  const {logBookScreenData} = useSelector((state: StoreState) =>({
    logBookScreenData: R.pathOr(
      filtersNotWorkingOptions,
      ['logBookScreenData', 'logBookData'],
      state
    )
  }));  
  const aircraftFiltersArray = useMemo(() => {
    let logBookData: any[] = logBookScreenData;

    const aircrafts = new Set<{ id: number | string; name: string }>();

    logBookData.forEach((data, index) => {
      aircrafts.add({ id: data.aircraft.id, name: data.aircraft.registration });
    });

    let aircraftFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    aircrafts.forEach((data, key) => {
      aircraftFiltersArray.push({id:data.id,name:data.name});
    });
    return R.uniq(aircraftFiltersArray);
  }, [logBookScreenData]);

  return (
    <Field
      name={AIRCRAFT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Aircraft"
      optionsList={R.uniq(aircraftFiltersArray)}
      required
    />
  );
};
export const BookFilterComponent = () => {
  const { logBookScreenData } = useSelector((state: StoreState) => {
    return {
      logBookScreenData: R.pathOr(
        [],
      ['logBookScreenData', 'logBookData'],
        state
      )
    };
  });
  const bookNumberFiltersArray = useMemo(() => {
    let logBookData: LogBookRow[] = logBookScreenData;

    const logbooks = new Set<string>();

    logBookData.forEach((lb, index) => {
      logbooks.add(lb.bookNumber);
    });

    let bookNumberFiltersArray: { id: string | number; name: number | string }[] = [
      { id: -1, name: 'All' }
    ];

    logbooks.forEach((lb, key) => {
      bookNumberFiltersArray.push({ id: key, name: lb });
    });
    return bookNumberFiltersArray;
  }, [logBookScreenData]);
  return (
    <Field
      name={BOOK_NUMBER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Book Number"
      optionsList={bookNumberFiltersArray}
      required
    />
  );
};
export const PageFilterComponent = () => {
  const { logBookScreenData } = useSelector((state: StoreState) => {
    return {
      logBookScreenData: R.pathOr(
        [],
      ['logBookScreenData', 'logBookData'],
        state
      )
    };
  });
  const pageNumberFiltersArray = useMemo(() => {
    let logBookData: LogBookRow[] = logBookScreenData;

    const logbooks = new Set<string>();

    logBookData.forEach((lb, index) => {
      logbooks.add(lb.pageNumber);
    });

    let pageNumberFiltersArray: { id: string | number; name: number | string }[] = [
      { id: -1, name: 'All' }
    ];

    logbooks.forEach((lb, key) => {
      pageNumberFiltersArray.push({ id: key, name: lb });
    });
    return pageNumberFiltersArray;
  }, [logBookScreenData]);
  return (
    <Field
      name={PAGE_NUMBER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Page Number"
      optionsList={pageNumberFiltersArray}
      required
    />
  );
};
export const UsersFilterComponent = () => {
  const { logBookScreenData } = useSelector((state: StoreState) => {
    return {
      logBookScreenData: R.pathOr(
        [],
      ['logBookScreenData', 'logBookData'],
        state
      )
    };
  });
  const usersFiltersArray = useMemo(() => {
    let logBookData: LogBookRow[] = logBookScreenData;

    const logbooks = new Set<{ id: number | string; name: string }>();

    logBookData.forEach((lb, index) => {
      logbooks.add({id:lb.daily.user.id,name:`${lb.daily.user.firstName} ${lb.daily.user.lastName}`});
    });

    let usersFiltersArray: { id: string | number; name: number | string }[] = [
      { id: -1, name: 'All' }
    ];

    logbooks.forEach((lb) => {
      usersFiltersArray.push({ id: lb.id, name: lb.name });
    });
    return R.uniq(usersFiltersArray);
  }, [logBookScreenData]);
  return (
    <Field
      name={UPLOADED_BY_FILTERS}
      component={ReduxFormAutocomplete}
      label="Users"
      optionsList={usersFiltersArray}
      required
    />
  );
};


