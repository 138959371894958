import { delay, put, takeLatest } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';

import { showSpinner, hideSpinner } from '../actions/spinner';

import {
  fetchCustomerFlightReportFailure,
  fetchCustomerFlightReportSuccess
} from '../actions/customerFlightReport';

export interface FetchCustomerFlightReportActionType {
  type: String;
  payload: { id: String };
}

function* fetchCustomerFlightReport(
  action: FetchCustomerFlightReportActionType
) {
  yield delay(1000);
  yield put(showSpinner());

  try {
    const { id } = action.payload;
    const response = yield api.fetchResponse(
      endpoints.CUSTOMER_FLIGHT_REPORT(id)
    );

    if (response.success) {
      yield put(fetchCustomerFlightReportSuccess(response.data));
    } else {
      yield put(fetchCustomerFlightReportFailure());
    }
  } catch (e) {
    yield put(fetchCustomerFlightReportFailure());
  }
  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_CUSTOMER_FLIGHT_REPORT_REQUEST,
    fetchCustomerFlightReport
  )
];
