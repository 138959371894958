import { put, takeLatest, select, call, delay } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchFlightReportScreenDataSuccess,
  fetchFlightReportScreenDataFailure,
  fetchFlightReportScreenDataRequest,
  updateFlightReportScreenDataSuccess,
  updateFlightReportScreenDataFailure,
  exportFlightReportScreenDataSuccess,
  exportFlightReportScreenDataFailure
} from '../actions/flightReportScreenData';
import { fetchCustomersScreenDataRequest } from '../actions/customersScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { setFlightReportStatusFilters } from '../actions/flightReportScreenFilters';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../utils/interfaces';
import { getDateString, isPresent } from '../../utils/helper';
import { filterFlightReportScreenData } from '../../components/FlightReportTable/helper';
import { fetchAndDownload } from '../utils/sagaHelpers';

interface FetchFlightReportScreenDataActionType {
  type?: String;
  payload: { startDate: Date; endDate: Date };
}

interface UpdateFlightReportScreenDataActionType {
  type: String;
  payload: any;
}

function* updateFlightReportStatus(action: any) {
  yield put(showSpinner());

  yield delay(1000);

  const { isLocked, id } = action.payload;

  try {
    const invoiceScreenDataResponse = yield api.updateData(
      isLocked ? endpoints.FLIGHT_REPORT_UNLOCK : endpoints.FLIGHT_REPORT_LOCK,
      {},
      { id },
      'POST'
    );

    if (invoiceScreenDataResponse.success) {
      yield put(updateFlightReportScreenDataSuccess());

      const {
        formValues: { range }
      } = yield select((state: StoreState) => {
        return {
          formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {}
        };
      });

      yield fetchFlightReportScreenData({
        payload: { startDate: range.from, endDate: range.to }
      });
    } else {
      yield put(updateFlightReportScreenDataFailure());
    }
  } catch (e) {
    yield put(updateFlightReportScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* exportFlightReportScreenData(action:any) {
  yield put(showSpinner());

  yield delay(1000);

  const { formValues, flightReportData } = yield select((state: StoreState) => {
    return {
      formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {},
      flightReportData: state.flightReportScreenData.flightReportData
    };
  });
  const { from, to } = formValues.range;

  const filteredData = filterFlightReportScreenData(
    flightReportData,
    formValues
  );

  try {
    if (isPresent(filteredData)) {
      const body = filteredData.map((data: {}) => {
        return R.pathOr('', ['id'], data);
      });

      const response = yield call(
        fetchAndDownload,
        `${endpoints.FLIGHT_OPS_EXPORT}?${action.payload.withData ? 'withFormFields' : ""}`,
        body,
        `Flight Reports [${from.toDateString()} - ${to.toDateString()}]`,
        true,
        'Flight Report'
      );

      if (response.success) {
        yield put(exportFlightReportScreenDataSuccess());
      } else {
        yield put(exportFlightReportScreenDataFailure());
      }
    } else {
      toast.error('No Flight Reports available to download.');
      yield put(exportFlightReportScreenDataFailure());
    }
  } catch (e) {
    yield put(exportFlightReportScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* updateFlightReportScreenData(
  action: UpdateFlightReportScreenDataActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { updateValues, isCustomerFlightReport } = action.payload;

  try {
    const invoiceScreenDataResponse = yield api.updateData(
      endpoints.FLIGHT_OPS,
      {},
      updateValues
    );

    if (invoiceScreenDataResponse.success) {
      yield put(updateFlightReportScreenDataSuccess());

      if (!isCustomerFlightReport) {
        const {
          formValues: { range }
        } = yield select((state: StoreState) => {
          return {
            formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {}
          };
        });

        yield put(fetchFlightReportScreenDataRequest(range.from, range.to));
      } else {
        yield put(fetchCustomersScreenDataRequest());
      }
    } else {
      yield put(updateFlightReportScreenDataFailure());
    }
  } catch (e) {
    yield put(updateFlightReportScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchFlightReportScreenData(
  action: FetchFlightReportScreenDataActionType
) {
  yield delay(1000);

  const { startDate, endDate } = action.payload;

  const startDateString = getDateString(new Date(startDate));
  const endDateString = getDateString(new Date(endDate));

  try {
    const response = yield api.fetchResponse(
      endpoints.FLIGHT_OPS_RANGE(startDateString, endDateString)
    );

    if (response.success) {
      yield put(fetchFlightReportScreenDataSuccess(response.data.data));

      const { status } = response.data.metaData;

      yield put(setFlightReportStatusFilters(status));
    } else {
      yield put(fetchFlightReportScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchFlightReportScreenDataFailure());
  }
}

export default [
  takeLatest(
    types.FETCH_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    fetchFlightReportScreenData
  ),
  takeLatest(
    types.UPDATE_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    updateFlightReportScreenData
  ),
  takeLatest(
    types.EXPORT_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    exportFlightReportScreenData
  ),
  takeLatest(
    types.UPDATE_FLIGHT_REPORT_STATUS_REQUEST,
    updateFlightReportStatus
  )
];
