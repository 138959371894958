import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, Field, InjectedFormProps, initialize } from 'redux-form';
import ReduxFormInput from '../shared/ReduxFormInput';
import { CUSTOMERS_ADD_EDIT_FORM } from '../../utils/reduxFormConstants';
import { isPresent } from '../../utils/helper';

interface InitialValuesProps {
  name: string;
  customerNumber: string;
  address1: string;
  address2: string;
  province: string;
  city: string;
  postalCode: string;
  phone: string;
}

interface CustomersDetailsInputProps {
  initialValues?: InitialValuesProps;
}

const CustomersDetailsInput = ({ initialValues }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPresent(initialValues)) {
      dispatch(initialize(CUSTOMERS_ADD_EDIT_FORM, initialValues));
    } else {
      dispatch(
        initialize(CUSTOMERS_ADD_EDIT_FORM, {
          name: '',
          customerNumber: '',
          address1: '',
          address2: '',
          province: '',
          city: '',
          postalCode: '',
          phone: ''
        })
      );
    }
  }, [dispatch, initialValues]);

  return (
    <Fragment>
      <div className="customer-details-input-options">
        <Field
          name="name"
          type="text"
          component={ReduxFormInput}
          label="Name"
        />
        <Field
          name="customerNumber"
          type="text"
          component={ReduxFormInput}
          label="Code"
        />
        <Field
          name="address1"
          type="text"
          component={ReduxFormInput}
          label="Street"
        />
        <Field
          name="address2"
          type="text"
          component={ReduxFormInput}
          label="Street"
        />
      </div>
      <div className="customer-details-input-options">
        <Field
          name="province"
          type="text"
          component={ReduxFormInput}
          label="Province"
        />
        <Field
          name="city"
          type="text"
          component={ReduxFormInput}
          label="City"
        />
        <Field
          name="postalCode"
          type="text"
          component={ReduxFormInput}
          label="Postal Code"
        />
        <Field
          name="phone"
          type="text"
          component={ReduxFormInput}
          label="Phone"
        />
      </div>
    </Fragment>
  );
};

export default reduxForm<InjectedFormProps, CustomersDetailsInputProps>({
  form: CUSTOMERS_ADD_EDIT_FORM
})(CustomersDetailsInput);
