import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import {
  aircraftFilterStatePath,
  employeeFilterStatePath,
  workReportFilterStatePath,
  workTypeFilterStatePath,
  workDoneFilterStatePath,
  AircraftFilterComponent,
  EmployeeFilterComponent,
  WorkReportFilterComponent,
  WorkTypeFilterComponent,
  WorkDoneFilterComponent
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

interface AircraftProps {
  id: number;
  registration: string;
}
interface WorkTypeProps {
  name: string;
}

export interface EngineerJobReviewRow {
  id: string;
  date: string;
  aircraft: AircraftProps;
  name: string;
  workReport: string;
  workType: WorkTypeProps;
  workDone: string;
  comments: string;
  hours: string;
}

export const columns: Column[] = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 120,
    align: 'center',
    sortingProps: [['date']]
  },
  {
    id: 'aircraft',
    label: 'Aircraft',
    minWidth: 90,
    align: 'center',
    sortingProps: [['aircraft', 'registration']],
    filterComponent: AircraftFilterComponent,
    filterStatePath: aircraftFilterStatePath
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 90,
    align: 'center',
    sortingProps: [['name']],
    filterComponent: EmployeeFilterComponent,
    filterStatePath: employeeFilterStatePath
  },
  {
    id: 'workReport',
    label: 'Work Report',
    minWidth: 120,
    align: 'center',
    sortingProps: [['workReport']],
    filterComponent: WorkReportFilterComponent,
    filterStatePath: workReportFilterStatePath
  },
  {
    id: 'workType',
    label: 'Work Type',
    minWidth: 120,
    align: 'center',
    sortingProps: [['workType', 'name']],
    filterComponent: WorkTypeFilterComponent,
    filterStatePath: workTypeFilterStatePath
  },
  {
    id: 'workDone',
    label: 'Work Done',
    minWidth: 120,
    align: 'center',
    sortingProps: [['workDone']],
    filterComponent: WorkDoneFilterComponent,
    filterStatePath: workDoneFilterStatePath
  },
  {
    id: 'comments',
    label: 'Comments',
    minWidth: 120,
    align: 'center',
    sortingProps: [['comments']]
  },
  {
    id: 'hours',
    label: 'Hours',
    minWidth: 120,
    align: 'center',
    sortingProps: [['hours']]
  }
];

export interface FiltersProps {
  aircraftFilters: number;
  employeeFilters: number;
  workReportFilters: number;
  workTypeFilters: number;
  workDoneFilters: number;
}

const isAllAircraftFilters = R.propEq('aircraftFilters', -1);
const isAllEmployeeFilters = R.propEq('employeeFilters', -1);
const isAllWorkReportFilters = R.propEq('workReportFilters', -1);
const isAllWorkTypeFilters = R.propEq('workTypeFilters', -1);
const isAllWorkDoneFilters = R.propEq('workDoneFilters', -1);

const isAllFiltersAppliedEvery = R.allPass([
  isAllAircraftFilters,
  isAllEmployeeFilters,
  isAllWorkReportFilters,
  isAllWorkTypeFilters,
  isAllWorkDoneFilters
]);

export const filterEngineerJobReviewData = (
  engineerJobReviewData: EngineerJobReviewRow[],
  filters: FiltersProps
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return engineerJobReviewData;
  }

  const {
    aircraftFilters,
    employeeFilters,
    workReportFilters,
    workTypeFilters,
    workDoneFilters
  } = filters;

  let filteredDataArray = engineerJobReviewData;

  if (!isAllAircraftFilters({ aircraftFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['aircraft', 'id'], obj), aircraftFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllEmployeeFilters({ employeeFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(
        R.toLower(R.pathOr('', ['name'], obj)),
        R.toLower(`${employeeFilters}`)
      );

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllWorkReportFilters({ workReportFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(
        R.toLower(R.pathOr('', ['workReport'], obj)),
        R.toLower(`${workReportFilters}`)
      );

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllWorkTypeFilters({ workTypeFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['workType', 'id'], obj), workTypeFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllWorkDoneFilters({ workDoneFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(
        R.toLower(R.pathOr('', ['workDone'], obj)),
        R.toLower(`${workDoneFilters}`)
      );

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  return filteredDataArray;
};

export const getTotals = (
  filteredInvoiceScreenData: EngineerJobReviewRow[]
) => {
  let hours = 0.0;

  filteredInvoiceScreenData.forEach((data: EngineerJobReviewRow) => {
    hours = hours + parseFloat(data.hours);
  });

  return { hours };
};
