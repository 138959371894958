import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import {
  CustomerFilterComponent,
  customerFilterStatePath
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export const columns: Column[] = [
  {
    id: 'startBooking',
    label: 'Start Date',
    minWidth: 120,
    align: 'center',
    sortingProps: [['startBooking']]
  },
  {
    id: 'endBooking',
    label: 'End Date',
    minWidth: 120,
    align: 'center',
    sortingProps: [['endBooking']]
  },
  {
    id: 'bookingNumber',
    label: 'Number',
    minWidth: 120,
    align: 'center',
    sortingProps: [['bookingNumber']],
    isNumber: true
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 120,
    align: 'center',
    sortingProps: [['name']]
  },
  {
    id: 'customer',
    label: 'Customer',
    minWidth: 120,
    align: 'center',
    sortingProps: [['customer', 'name']],
    filterComponent: CustomerFilterComponent,
    filterStatePath: customerFilterStatePath
  }
];

interface CustomerProps {
  id: number;
  name: string;
}

export interface BookingsRow {
  id: number;
  startBooking: string;
  endBooking: string;
  bookingNumber: number;
  name: string;
  customer: CustomerProps;
}

const isAllCustomerFilters = R.propEq('customerFilters', -1);

const isAllFiltersAppliedEvery = R.allPass([isAllCustomerFilters]);

export const filterBookingsScreenData = (
  bookingsData: BookingsRow[],
  filters: {}
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return bookingsData;
  }

  const customerFilters = R.pathOr(-1, ['customerFilters'], filters);

  let filteredDataArray = bookingsData;

  if (!isAllCustomerFilters({ customerFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['customer', 'id'], obj), customerFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  return filteredDataArray;
};
