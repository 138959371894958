import { put, takeLatest, call, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import { fetchCustomerFilters } from '../utils/sagaHelpers';
import {
  fetchBookingsScreenFiltersSuccess,
  fetchBookingsScreenFiltersFailure
} from '../actions/bookingsScreenFilters';
import { showSpinner, hideSpinner } from '../actions/spinner';

function* fetchBookingsScreenFilters() {
  yield put(showSpinner());

  yield delay(1000);

  try {
    const customerFiltersResponse = yield call(fetchCustomerFilters);

    yield put(
      fetchBookingsScreenFiltersSuccess(
        R.pathOr([], ['data', 'customerFilters'], customerFiltersResponse)
      )
    );
  } catch (e) {
    yield put(fetchBookingsScreenFiltersFailure());
  }

  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_BOOKINGS_SCREEN_FILTERS_REQUEST,
    fetchBookingsScreenFilters
  )
];
