import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { isPresent } from '../../utils/helper';
import {
  // departmentFilterStatePath,
  // bushPayFilterStatePath,
  statusFilterStatePath,
  // DepartmentFilterComponent,
  // BushPayFilterComponent,
  StatusFilterComponent,
  FirstNameFilterComponent,
  firstNameFilterStatePath,
  LastNameFilterComponent,
  lastNameFilterStatePath,
  companyFilterStatePath,
  CompanyFilterComponent
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export const columns: Column[] = [
  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 90,
    align: 'center',
    sortingProps: [['firstName']],
    filterComponent: FirstNameFilterComponent,
    filterStatePath: firstNameFilterStatePath
  },
  {
    id: 'lastName',
    label: 'Last Name',
    minWidth: 90,
    align: 'center',
    sortingProps: [['lastName']],
    filterComponent: LastNameFilterComponent,
    filterStatePath: lastNameFilterStatePath
  },
  {
    id: 'username',
    label: 'Email',
    minWidth: 90,
    align: 'center',
    sortingProps: [['username']]
  },
  {
    id: 'client',
    label: 'Company',
    minWidth: 90,
    align: 'center',
    sortingProps: [['client']],
    filterComponent: CompanyFilterComponent,
    filterStatePath: companyFilterStatePath
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    align: 'center',
    sortingProps: [['status']],
    filterComponent: StatusFilterComponent,
    filterStatePath: statusFilterStatePath
  }
];

interface UsersUserType {
  id: number;
}

interface UsersCompany {
  id: number;
}

export interface ClientUsersRow {
  id: number;
  employeeId: string;
  firstName: string;
  lastName: string;
  username: string;
  userType: UsersUserType;
  company: UsersCompany;
  bushpay: boolean;
  signatureUri: string;
  deletedAt: string | null;
  clientUser: any;
}

export interface FiltersProps {
  department: number;
  bushPay: any;
  status: string;
  firstName: number;
  lastName: number;
  company: number;
}

const isAllDepartmentFilters = R.propEq('department', -1);
const isAllBushPayFilters = R.propEq('bushPay', 'all');
const isAllStatusFilters = R.propEq('status', 'all');
const isAllFirstNameFilters = R.propEq('firstName', -1);
const isAllLastNameFilters = R.propEq('lastName', -1);
const isAllCompanyFilters = R.propEq('company', -1);

const isAllFiltersAppliedEvery = R.allPass([
  isAllDepartmentFilters,
  isAllBushPayFilters,
  isAllStatusFilters,
  isAllFirstNameFilters,
  isAllLastNameFilters,
  isAllCompanyFilters
]);

export const filterUsersData = (
  userArray: ClientUsersRow[],
  filters: FiltersProps
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return userArray;
  }

  const { department, bushPay, status, firstName, lastName, company } = filters;

  let filteredDataArray = userArray;

  if (!isAllDepartmentFilters({ department })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['userType', 'id'], obj), department);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }
  if (!isAllFirstNameFilters({ firstName })) {
    const isFilter = (obj: {}) => R.equals(R.path(['id'], obj), firstName);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllCompanyFilters({ company })) {
    let filteredArray: ClientUsersRow[] = [];
    filteredDataArray.map((user: any) => {
      user.clientUser.map((cl: any) => {
        if (cl.client.id === company) {
          filteredArray.push(user);
        }
      });
    });
    filteredDataArray = filteredArray;
  }

  if (!isAllLastNameFilters({ lastName })) {
    const isFilter = (obj: {}) => R.equals(R.path(['id'], obj), lastName);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllBushPayFilters({ bushPay })) {
    const isFilter = (obj: {}) =>
      R.equals(`${R.pathOr('', ['bushpay'], obj)}`, `${bushPay}`);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllStatusFilters({ status })) {
    const isFilter = (obj: {}) =>
      R.equals(
        isPresent(R.pathOr('', ['deletedAt'], obj)) ? 'archived' : 'active',
        `${status}`
      );

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  return filteredDataArray;
};
