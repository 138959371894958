import { getMonthStartToCurrentDateRange } from '../../utils/helper';

export const filtersNotWorkingOptions = [
  { label: 'All', value: -1 },
  { label: 'Filter not working', value: -2 }
];

export interface RangeProps {
  to: Date;
  from: Date;
}

export const RANGE = 'range';
export const AIRCRAFT_FILTERS = 'aircraftFilters';
export const BOOKING_FILTERS = 'bookingFilters';
export const VENDOR_FILTERS = 'vendorFilters';
export const PILOT_FILTERS = 'pilotFilters';
export const DOCUMENT_HASH_FILTERS = 'documentHashFilters';
export const INVOICE_NUMBER_FILTERS = 'invoiceNumberFilters';
export const FLIGHT_REPORT_FILTERS = 'flightReportFilters';
export const PAYMENT_TYPE_FILTERS = 'paymentFilters';
export const FUEL_TYPE_FILTERS = 'fuelTypeFilters';

export const initialValues = {
  range: getMonthStartToCurrentDateRange(),
  [AIRCRAFT_FILTERS]: -1,
  [BOOKING_FILTERS]: -1,
  [VENDOR_FILTERS]: -1,
  [PILOT_FILTERS]: -1,
  [DOCUMENT_HASH_FILTERS]: -1,
  [INVOICE_NUMBER_FILTERS]: -1,
  [FLIGHT_REPORT_FILTERS]: -1,
  [PAYMENT_TYPE_FILTERS]: -1,
  [FUEL_TYPE_FILTERS]: -1
};
