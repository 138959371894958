import { useState, useEffect, useCallback } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initialize, getFormValues, change } from 'redux-form';
import * as R from 'ramda';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../../redux/utils/interfaces';
import { getMonthStartToCurrentDateRange, isPresent } from '../../utils/helper';
import {
  fetchFlightReportFiltersRequest,
  fetchFlightReportBookingFiltersRequest
} from '../../redux/actions/flightReportScreenFilters';
import {
  fetchFlightReportScreenDataRequest,
  updateFlightReportScreenDataRequest
} from '../../redux/actions/flightReportScreenData';
import { initialValues, RANGE, RangeProps } from './helper';
import { FLIGHT_REPORT_PDF_ROUTE } from '../../utils/routesNavigationConstants';

export const useFlightReportHook = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { reportId }: any = useParams();
  let flightReportPdfMatch = useRouteMatch(FLIGHT_REPORT_PDF_ROUTE);
  const isFlightReportPdfRoute = isPresent(flightReportPdfMatch);

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState({});

  const { formValues, flightReportScreenData } = useSelector(
    (state: StoreState) => {
      return {
        formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {},

        flightReportScreenData: state.flightReportScreenData
      };
    }
  );

  const range = R.pathOr(
    getMonthStartToCurrentDateRange(),
    ['range'],
    formValues
  );

  const { flightReportData } = flightReportScreenData;
  const currentFlightReportData = {
    isLoading: flightReportScreenData.isLoading,
    flightReportData: R.find(R.propEq('id', reportId))(flightReportData)
  };

  useEffect(() => {
    dispatch(initialize(FLIGHT_REPORT_FILTERS_FORM, initialValues));

    dispatch(fetchFlightReportFiltersRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFlightReportBookingFiltersRequest(range.from, range.to));
    dispatch(fetchFlightReportScreenDataRequest(range.from, range.to));
  }, [dispatch, range]);

  const updateFormField = useCallback(
    (key: string, value: any) =>
      dispatch(change(FLIGHT_REPORT_FILTERS_FORM, key, value)),
    [dispatch]
  );

  const goto = useCallback((route: string) => history.push(route), [history]);

  const setRangeHandler = useCallback(
    (range: RangeProps) => {
      updateFormField(RANGE, range);
    },
    [updateFormField]
  );

  const setAllCurrentSelectedStatus = useCallback(
    (value: unknown) => {
      const currentSelectedStatusKays = R.keys(currentSelectedStatus);
      const localCurrentSelectedStatus = { ...currentSelectedStatus };

      currentSelectedStatusKays.forEach((key: string) => {
        localCurrentSelectedStatus[`${key}`] = value;
      });

      setCurrentSelectedStatus(localCurrentSelectedStatus);
    },
    [currentSelectedStatus, setCurrentSelectedStatus]
  );

  const isSaveButtonDisabled = false;

  const onSaveButtonClickHandler = useCallback(() => {
    const currentSelectedStatusKeys = R.keys(currentSelectedStatus);

    const mappedCurrentSelectedStatusArray = currentSelectedStatusKeys.map(
      (currentSelectedStatusKey: string) => {
        return {
          id: currentSelectedStatusKey,
          statusType: {
            id: currentSelectedStatus[`${currentSelectedStatusKey}`]
          }
        };
      }
    );

    dispatch(
      updateFlightReportScreenDataRequest(mappedCurrentSelectedStatusArray)
    );
  }, [currentSelectedStatus, dispatch]);

  return {
    currentSelectedStatus,
    range,
    formValues,
    isSaveButtonDisabled,
    flightReportScreenData,
    currentFlightReportData,
    isFlightReportPdfRoute,
    setAllCurrentSelectedStatus,
    setCurrentSelectedStatus,
    setRange: setRangeHandler,
    onSaveButtonClickHandler,
    goto
  };
};
