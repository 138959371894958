import humps from 'humps';
import * as R from 'ramda';
import { isNilOrEmpty, isPresent } from '../../utils/helper';
import { encryptWithAES, decryptWithAES } from '../../utils/cryptoJsHelpers';

export const MY_WEB_APP_TOKENS = 'airops-web-app-local-storage-tokens';
const TOKENS = ['accessToken', 'refreshToken'];

export interface LocalStorageTokensProps {
  accessToken: string;
  refreshToken: string;
}

export const getEncodedBody = (details: {}) => {
  const decamalizeDetails = humps.decamelizeKeys(details);

  var formBody = [];

  for (var property in decamalizeDetails) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(decamalizeDetails[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }

  const newFormBody = formBody.join('&');

  return newFormBody;
};

export const getStringifyBody = (details: {}, decamelizeKeys: boolean) => {
  const decamalizeDetails = decamelizeKeys
    ? humps.decamelizeKeys(details)
    : details;

  const newFormBody = JSON.stringify(decamalizeDetails);

  return newFormBody;
};

export const setLocalStorageTokens = (tokens: LocalStorageTokensProps) => {
  const currentTokens = getLocalStorageTokens();
  const newTokensValues = { ...currentTokens, ...tokens };
  const tokensToSet = {};

  TOKENS.forEach((token) => {
    tokensToSet[`${token}`] = newTokensValues[`${token}`];
  });

  localStorage.setItem(
    MY_WEB_APP_TOKENS,
    encryptWithAES(JSON.stringify(tokensToSet))
  );
};

export const getLocalStorageTokens = () => {
  const currentTokensString = localStorage.getItem(MY_WEB_APP_TOKENS);

  let currentTokensObject = {};

  if (isPresent(currentTokensString)) {
    const decryptString = decryptWithAES(`${currentTokensString}`);

    if (isPresent(decryptString)) {
      try {
        currentTokensObject = JSON.parse(decryptString);
      } catch (error) {
        currentTokensObject = {};
      }
    }
  }

  const returnTokens: LocalStorageTokensProps = {
    accessToken: '',
    refreshToken: ''
  };

  TOKENS.forEach((token) => {
    returnTokens[`${token}`] = R.pathOr('', [`${token}`], currentTokensObject);
  });

  return returnTokens;
};

export const isTokensPresentLocalStorage = () => {
  const currentTokens = getLocalStorageTokens();
  let isAllTokensPresent = true;

  TOKENS.forEach((tokenName) => {
    const tokenValue = currentTokens[`${tokenName}`];

    if (isNilOrEmpty(tokenValue)) {
      isAllTokensPresent = false;
    }
  });

  return isAllTokensPresent;
};

export const clearLocalStorage = () => {
  localStorage.removeItem(MY_WEB_APP_TOKENS);
  localStorage.removeItem(MY_WEB_APP_TOKENS + '-redux');
};
