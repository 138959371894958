import { getMonthStartToCurrentDateRange } from '../../utils/helper';

export interface RangeProps {
  to: Date | String;
  from: Date | String;
}

export const RANGE = 'range';
export const BOOKING_FILTERS = 'bookingFilters';
export const CUSTOMER_FILTERS = 'customerFilters';
export const AIRCRAFT_FILTERS = 'aircraftFilters';
export const PILOT_FILTERS = 'pilotFilters';
export const FLIGHT_TICKET_FILTERS = 'flightTicketsFilters';
export const CONTRACT_FILTERS = 'contractFilters';
export const STATUS_FILTERS = 'statusFilters';
export const INVOICE_NUMBER_FILTERS = 'invoiceNumberFilters';

export const initialValues = {
  range: getMonthStartToCurrentDateRange(),
  [BOOKING_FILTERS]: -1,
  [CUSTOMER_FILTERS]: -1,
  [AIRCRAFT_FILTERS]: -1,
  [PILOT_FILTERS]: -1,
  [FLIGHT_TICKET_FILTERS]: 'all',
  [CONTRACT_FILTERS]: 'all',
  [STATUS_FILTERS]:'Ready to sign',
  [INVOICE_NUMBER_FILTERS]: 'all',
};

export const filtersNotWorkingOptions = [
  { label: 'All', value: -1 },
  { label: 'Filter not working', value: -2 }
];

export const statusFilters = [
  { id: 2, name: 'Ready To Bill' },
  { id: 3, name: 'In Progress' }
];
