import { LogBookScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: LogBookScreenDataProps = {
  isLoading: true,
  logBookData: [],
  aircrafts:[]
};

const logBookScreenData = (
  state: LogBookScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_LOG_BOOK_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_LOG_BOOK_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        logBookData: action.payload.logBookScreenData,
        isLoading: false
      };
      case types.RESET_LOGBOOK_SCREEN_DATA:
      return initialState;

    case types.FETCH_LOG_BOOK_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case types.FETCH_LOG_BOOK_AIRCRAFT_DATA_SUCCESS:
      return {
        ...state,
        aircrafts: action.payload.aircrafts,
        isLoading: false
      };
    case types.FETCH_LOG_BOOK_AIRCRAFT_DATA_FAILURE:
      return {
        ...state,
        aircrafts: [],
        isLoading: false
      };

    default:
      return state;
  }
};

export default logBookScreenData;
