import React, { useMemo } from 'react';
import AddIcon from '@material-ui/icons/AddOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import Breadcrumbs from '../shared/Breadcrumbs';
import CustomSpeedDial from '../shared/CustomSpeedDial';

import './style.scss';

interface CustomersSecondaryHeaderProps {
  isCustomersDetailsRouteMatch: boolean;
  isAddCustomersRouteMatch: boolean;
  currentCustomerName: string;
  goto: Function;
  onSaveButtonClickHandler: Function;
}

const CustomersSecondaryHeader = ({
  isCustomersDetailsRouteMatch,
  isAddCustomersRouteMatch,
  currentCustomerName,
  goto,
  onSaveButtonClickHandler
}: CustomersSecondaryHeaderProps) => {
  const breadcrumbDataArray = useMemo(() => {
    const breadcrumbDataArrayLocal = [
      {
        id: 'customers_breadcrum',
        label: 'Customers',
        onClick:
          isCustomersDetailsRouteMatch || isAddCustomersRouteMatch
            ? () => goto('/customers')
            : () => {}
      }
    ];

    if (isCustomersDetailsRouteMatch) {
      breadcrumbDataArrayLocal.push({
        id: 'customers_breadcrum_name',
        label: currentCustomerName,
        onClick: () => {}
      });
    } else if (isAddCustomersRouteMatch) {
      breadcrumbDataArrayLocal.push({
        id: 'add_customers_breadcrum_name',
        label: 'Add Customer',
        onClick: () => {}
      });
    }

    return breadcrumbDataArrayLocal;
  }, [
    isCustomersDetailsRouteMatch,
    isAddCustomersRouteMatch,
    goto,
    currentCustomerName
  ]);

  const actions = useMemo(() => {
    return isCustomersDetailsRouteMatch || isAddCustomersRouteMatch
      ? [
          {
            icon: SaveIcon,
            name: 'Save',
            onClick: onSaveButtonClickHandler
          }
        ]
      : [
          {
            icon: AddIcon,
            name: 'Add New',
            onClick: () => goto('/customers/add')
          }
        ];
  }, [
    isCustomersDetailsRouteMatch,
    isAddCustomersRouteMatch,
    onSaveButtonClickHandler,
    goto
  ]);

  return (
    <React.Fragment>
      <div className="breadcrumbs-container-customers">
        <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
      </div>

      <div className="customers-actions-buttons-container">
        <CustomSpeedDial actions={actions} />
      </div>
    </React.Fragment>
  );
};

export default CustomersSecondaryHeader;
