import React from 'react';
import { reduxForm, Field } from 'redux-form';
import ReduxFormInput from '../shared/ReduxFormInput';
import {
  CONTACT_ADD_EDIT_FORM,
  PASSENGER_ADD_EDIT_FORM
} from '../../utils/reduxFormConstants';

const CreateContact = () => {
  return (
    <form>
      <div className="add-user-modal-container">
        <Field
          name="firstName"
          type="text"
          component={ReduxFormInput}
          label="First Name"
        />
        <Field
          name="lastName"
          type="text"
          component={ReduxFormInput}
          label="Last Name"
        />
        <Field
          name="role"
          type="text"
          component={ReduxFormInput}
          label="Role"
        />
        <Field
          name="email"
          type="text"
          component={ReduxFormInput}
          label="Email"
        />
        <Field
          name="phone"
          type="text"
          component={ReduxFormInput}
          label="Phone"
        />
      </div>
    </form>
  );
};

const CreatePassenger = () => {
  return (
    <form>
      <div className="add-user-modal-container">
        <Field
          name="firstName"
          type="text"
          component={ReduxFormInput}
          label="First Name"
        />
        <Field
          name="lastName"
          type="text"
          component={ReduxFormInput}
          label="Last Name"
        />
        <Field
          name="email"
          type="text"
          component={ReduxFormInput}
          label="Email"
        />
      </div>
    </form>
  );
};

const CreateContactForm = reduxForm({
  form: CONTACT_ADD_EDIT_FORM
})(CreateContact);

const CreatePassengerForm = reduxForm({
  form: PASSENGER_ADD_EDIT_FORM
})(CreatePassenger);

export { CreateContactForm, CreatePassengerForm };
