import { BookingsScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: BookingsScreenDataProps = {
  isLoading: true,
  bookingsData: [],
  customers: [],
  requestingPersons: [],
  bases: [],
  equipments: [],
  passengers: [],
  pilot: [],
  ame: [],
  apprentice: [],
  aircraft: [],
  approvingClient: [],
  bookingNumberIsValid: true,
  bookingNumberIsLoading: false
};

const bookingsScreenData = (
  state: BookingsScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_BOOKINGS_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        bookingsData: action.payload.bookingsData,
        customers: action.payload.customers,
        bases: action.payload.bases,
        equipments: action.payload.equipments,
        pilot: action.payload.pilot,
        ame: action.payload.ame,
        approvingClient: action.payload.approvingClient,
        apprentice: action.payload.apprentice,
        aircraft: action.payload.aircraft,
        isLoading: false
      };

    case types.FETCH_BOOKINGS_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case types.FETCH_BOOKINGS_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_SUCCESS:
      return {
        ...state,
        requestingPersons: action.payload.requestingPersons,
        passengers: action.payload.passengers
      };

    case types.VALIDATE_BOOKING_NUMBER_REQUEST:
      return {
        ...state,
        bookingNumberIsLoading: true
      };

    case types.VALIDATE_BOOKING_NUMBER_SUCCESS:
      return {
        ...state,
        bookingNumberIsValid: true,
        bookingNumberIsLoading: false
      };

    case types.VALIDATE_BOOKING_NUMBER_FAILURE:
      return {
        ...state,
        bookingNumberIsValid: false,
        bookingNumberIsLoading: false
      };

    case types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_FAILURE:
    case types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_REQUEST:
    default:
      return state;
  }
};

export default bookingsScreenData;
