import {
  put,
  takeLatest,
  select,
  //  call,
  delay
} from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
// import * as R from 'ramda';
// import { toast } from 'react-toastify';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import { toast } from 'react-toastify';
import {
  fetchClientPortalScreenDataSuccess,
  fetchClientPortalScreenDataFailure,
  fetchClientPortalScreenDataRequest,
  updateClientPortalScreenDataSuccess,
  updateClientPortalScreenDataFailure,
  sendClientPortalFlightReportFailure,
  sendClientPortalFlightReportSuccess,
  postClientPortalCommentSuccess,
  postClientPortalCommentFailure
  //   exportFlightReportScreenDataSuccess,
  //   exportFlightReportScreenDataFailure
} from '../actions/clientPortalScreenData';
import { fetchCustomersScreenDataRequest } from '../actions/customersScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { setFlightReportStatusFilters } from '../actions/flightReportScreenFilters';
import { CLIENT_PORTAL_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../utils/interfaces';
import {
  getDateString
  //  isPresent
} from '../../utils/helper';
// import { filterFlightReportScreenData } from '../../components/FlightReportTable/helper';
// import { fetchAndDownload } from '../utils/sagaHelpers';

interface FetchFlightReportScreenDataActionType {
  type?: String;
  payload: { startDate: Date; endDate: Date };
}

interface UpdateFlightReportScreenDataActionType {
  type: String;
  payload: any;
}

function* updateClientPortalFlightReportStatus(action: any) {
  yield put(showSpinner());

  yield delay(1000);

  const { id } = action.payload;

  try {
    const invoiceScreenDataResponse = yield api.updateData(
      endpoints.CLIENT_PORTAL_FLIGHT_REPORT_LOCK,
      {},
      { id },
      'POST'
    );

    if (invoiceScreenDataResponse.success) {
      yield put(updateClientPortalScreenDataSuccess());

      const {
        formValues: { range }
      } = yield select((state: StoreState) => {
        return {
          formValues: getFormValues(CLIENT_PORTAL_FILTERS_FORM)(state) || {}
        };
      });

      yield fetchClientPortalScreenData({
        payload: { startDate: range.from, endDate: range.to }
      });
    } else {
      yield put(updateClientPortalScreenDataFailure());
    }
  } catch (e) {
    yield put(updateClientPortalScreenDataFailure());
  }

  yield put(hideSpinner());
}

// function* exportFlightReportScreenData(action:any) {
//   yield put(showSpinner());

//   yield delay(1000);

//   const { formValues, flightReportData } = yield select((state: StoreState) => {
//     return {
//       formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {},
//       flightReportData: state.flightReportScreenData.flightReportData
//     };
//   });
//   const { from, to } = formValues.range;

//   const filteredData = filterFlightReportScreenData(
//     flightReportData,
//     formValues
//   );

//   try {
//     if (isPresent(filteredData)) {
//       const body = filteredData.map((data: {}) => {
//         return R.pathOr('', ['id'], data);
//       });

//       const response = yield call(
//         fetchAndDownload,
//         `${endpoints.FLIGHT_OPS_EXPORT}?${action.payload.withData ? 'withFormFields' : ""}`,
//         body,
//         `Flight Reports [${from.toDateString()} - ${to.toDateString()}]`,
//         true,
//         'Flight Report'
//       );

//       if (response.success) {
//         yield put(exportFlightReportScreenDataSuccess());
//       } else {
//         yield put(exportFlightReportScreenDataFailure());
//       }
//     } else {
//       toast.error('No Flight Reports available to download.');
//       yield put(exportFlightReportScreenDataFailure());
//     }
//   } catch (e) {
//     yield put(exportFlightReportScreenDataFailure());
//   }

//   yield put(hideSpinner());
// }

function* updateClientPortalScreenData(
  action: UpdateFlightReportScreenDataActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { updateValues, isCustomerFlightReport } = action.payload;

  try {
    const invoiceScreenDataResponse = yield api.updateData(
      endpoints.FLIGHT_OPS,
      {},
      updateValues
    );

    if (invoiceScreenDataResponse.success) {
      yield put(updateClientPortalScreenDataSuccess());

      if (!isCustomerFlightReport) {
        const {
          formValues: { range }
        } = yield select((state: StoreState) => {
          return {
            formValues: getFormValues(CLIENT_PORTAL_FILTERS_FORM)(state) || {}
          };
        });

        yield put(fetchClientPortalScreenDataRequest(range.from, range.to));
      } else {
        yield put(fetchCustomersScreenDataRequest());
      }
    } else {
      yield put(updateClientPortalScreenDataFailure());
    }
  } catch (e) {
    yield put(updateClientPortalScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchClientPortalScreenData(
  action: FetchFlightReportScreenDataActionType
) {
  yield delay(1000);
  const { startDate, endDate } = action.payload;


  const startDateString = getDateString(new Date(startDate));
  const endDateString = getDateString(new Date(endDate));
  const isDateValid = startDateString !== "Invalid date" || endDateString !=="Invalid date";
  try {
    const response = yield api.fetchResponse(
      isDateValid ? endpoints.CLIENT_PORTAL_RANGE(startDateString, endDateString): endpoints.CLIENT_PORTAL_REPORTS
    );

    if (response.success) {
      yield put(fetchClientPortalScreenDataSuccess(response.data.data));

      const { status } = response.data.metaData;

      yield put(setFlightReportStatusFilters(status));
    } else {
      yield put(fetchClientPortalScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchClientPortalScreenDataFailure());
  }
}
function* sendFlightReportEmail(action: any) {
  yield put(showSpinner());
  yield delay(1000);

  try {
    const response = yield api.updateData(
      endpoints.SEND_FLIGHT_REPORT_EMAIL,
      {},
      action.payload,
      'POST'
    );

    if (response.success) {
      toast.success('Email sent successfully.');
      yield put(sendClientPortalFlightReportSuccess());
    } else {
      toast.error('Email sending failed.');
      yield put(sendClientPortalFlightReportFailure());
    }
  } catch (e) {
    toast.error('Email sending failed.');
    yield put(sendClientPortalFlightReportFailure());
  }
  yield put(hideSpinner());
}
function* addClientPortalComments(action: any) {
  yield put(showSpinner());
  yield delay(1000);

  try {
    const response = yield api.updateData(
      endpoints.ADD_CLIENT_PORTAL_COMMENTS,
      {},
      action.payload,
      'POST'
    );
    if (response.success) {
      const {
        formValues: { range }
      } = yield select((state: StoreState) => {
        return {
          formValues: getFormValues(CLIENT_PORTAL_FILTERS_FORM)(state) || {}
        };
      });

      yield put(fetchClientPortalScreenDataRequest(range.from, range.to));
      yield put(postClientPortalCommentSuccess(response.data));
    } else {
      yield put(postClientPortalCommentFailure());
    }
  } catch (e) {
    yield put(postClientPortalCommentFailure());
  }
  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_CLIENT_PORTAL_SCREEN_DATA_REQUEST,
    fetchClientPortalScreenData
  ),
  takeLatest(
    types.UPDATE_CLIENT_PORTAL_SCREEN_DATA_REQUEST,
    updateClientPortalScreenData
  ),
  takeLatest(types.SEND_FLIGHT_REPORT_EMAIL_REQUEST, sendFlightReportEmail),
  takeLatest(
    types.UPDATE_CLIENT_PORTAL_FLIGHT_REPORT_STATUS_REQUEST,
    updateClientPortalFlightReportStatus
  ),
  takeLatest(types.ADD_CLIENT_PORTAL_COMMENTS_REQUEST, addClientPortalComments)
];
