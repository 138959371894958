import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import FlightReportPDFRender from './FlightReportPDFRender';
import FlightReportSecondaryHeader from '../../components/FlightReportSecondaryHeader';
import FlightReportTable from '../../components/FlightReportTable';
import SetStatus from './SetStatus';
import { FLIGHT_REPORT_NAV_KEY } from '../../utils/routesNavigationConstants';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { useFlightReportHook } from './flightReportHooks';
import SharedSpinner from '../../components/shared/SharedSpinner';

import './style.scss';

const FlightReport = () => {
  const {
    currentSelectedStatus,
    range,
    formValues,
    isSaveButtonDisabled,
    flightReportScreenData,
    currentFlightReportData,
    isFlightReportPdfRoute,
    setAllCurrentSelectedStatus,
    setCurrentSelectedStatus,
    setRange,
    onSaveButtonClickHandler,
    goto
  } = useFlightReportHook();

  return (
    <React.Fragment>
      <Helmet>
        <title>Flight Reports</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={
          <FlightReportSecondaryHeader
            range={range}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isFlightReportPdfRoute={isFlightReportPdfRoute}
            currentFlightReportData={currentFlightReportData}
            setRange={setRange}
            onSaveButtonClickHandler={onSaveButtonClickHandler}
            goto={goto}
          />
        }
        navKey={FLIGHT_REPORT_NAV_KEY}>
        <Box py={2.5} className="flight-report-container">
          {flightReportScreenData.isLoading ? (
            <SharedSpinner />
          ) : isFlightReportPdfRoute ? (
            <FlightReportPDFRender
              id={R.pathOr(
                '',
                ['flightReportData', 'id'],
                currentFlightReportData
              )}
            />
          ) : (
            <Fragment>
              <SetStatus
                setAllCurrentSelectedStatus={setAllCurrentSelectedStatus}
              />

              <Box py={2.5}>
                <FlightReportTable
                  formValues={formValues}
                  flightReportScreenData={flightReportScreenData}
                  currentSelectedStatus={currentSelectedStatus}
                  setCurrentSelectedStatus={setCurrentSelectedStatus}
                  goto={goto}
                />
              </Box>
            </Fragment>
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: FLIGHT_REPORT_FILTERS_FORM
})(FlightReport);
