import React from 'react';
import { Box } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Status from '../Status';
import { RowData } from '../../../redux/utils/interfaces';
import { isPresent } from '../../../utils/helper';

import './style.scss';

interface BreadcrumbDataProps {
  id: string;
  label: string;
  dropdownList?: RowData[];
  currentSelectedUserId?: string | number;
  onClickDropdownOptions?: any;
  onClick?: any;
}

interface CustomBreadcrumbsProps {
  breadcrumbDataArray: BreadcrumbDataProps[];
}

interface BreadcrumbLinkProps {
  id: string;
  label: string;
  dropdownList?: RowData[];
  currentSelectedUserId?: string | number;
  isLastBreadcrumb: boolean;
  onClickDropdownOptions?: any;
  onClick?: any;
}

const BreadcrumbLink = ({
  id,
  label,
  dropdownList,
  isLastBreadcrumb,
  currentSelectedUserId,
  onClickDropdownOptions,
  onClick
}: BreadcrumbLinkProps) => {
  const isDropdownBreadCrum = isPresent(dropdownList);

  return (
    <div className="breadcrumb-link-container">
      <Link
        key={id}
        color={isLastBreadcrumb ? 'textPrimary' : 'inherit'}
        onClick={onClick}
        className={`single-breadcrumb ${
          isLastBreadcrumb && 'active-single-breadcrumb'
        } ${isPresent(dropdownList) ? 'active-breadcrumb-dropdown' : ''}`}>
        {label}
      </Link>
      {isDropdownBreadCrum && (
        <div className="dropdown-content">
          <Paper elevation={3}>
            <MenuList>
              {dropdownList?.map((userData: RowData) => {
                return (
                  <MenuItem
                    key={userData.id}
                    selected={currentSelectedUserId === userData.id}
                    onClick={() => onClickDropdownOptions(userData.id)}>
                    <Box mx={2} className="breadcrumb-dropdown-name-container">
                      {`${userData.firstName} ${userData.lastName}`}
                    </Box>
                    <Box
                      mx={2}
                      className="breadcrumb-dropdown-department-container">
                      {userData.department.name}
                    </Box>
                    <Box
                      mx={2}
                      className="breadcrumb-dropdown-status-container">
                      <Status status={userData.status} />
                    </Box>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Paper>
        </div>
      )}
    </div>
  );
};

const CustomBreadcrumbs = (props: CustomBreadcrumbsProps) => {
  const { breadcrumbDataArray } = props;

  return (
    <div className="custom-breadcrumbs-container">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="large" />}
        aria-label="breadcrumb">
        {breadcrumbDataArray.map(
          (breadcrumbData: BreadcrumbDataProps, index: number) => {
            const {
              id,
              label,
              onClick,
              dropdownList,
              currentSelectedUserId,
              onClickDropdownOptions
            } = breadcrumbData;
            const isLastBreadcrumb = index === breadcrumbDataArray.length - 1;

            return (
              <BreadcrumbLink
                key={id}
                id={id}
                label={label}
                dropdownList={dropdownList}
                currentSelectedUserId={currentSelectedUserId}
                isLastBreadcrumb={isLastBreadcrumb}
                onClickDropdownOptions={onClickDropdownOptions}
                onClick={onClick}
              />
            );
          }
        )}
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadcrumbs;
