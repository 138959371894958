import React, { useEffect, useCallback, useMemo } from 'react';
import Status from '../shared/Status';
import moment from 'moment';
import * as R from 'ramda';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import SharedSpinner from '../shared/SharedSpinner';
// import Dropdown from '../shared/Dropdown';
import { FlightReportScreenDataProps } from '../../redux/utils/interfaces';
import {
  useStyles,
  columns,
  FlightReportRow,
  filterFlightReportScreenData,
  getFlightReportScreenDataTotal
} from './helper';
// import { statusFilters } from '../../routes/ClientPortal/helper';
import {
  //isPresent,
  numberOrEmpty
} from '../../utils/helper';
import SharedTableHeader from '../shared/SharedTableHeader';
import { useSortHook } from '../../hooks/sortHooks';

import './style.scss';

interface FlightReportTableProps {
  formValues: any;
  flightReportScreenData: FlightReportScreenDataProps;
  currentSelectedStatus: any;
  setCurrentSelectedStatus: Function;
  goto: Function;
  currentSelectedPdfIndex: string;
  setCurrentSelectedPdf: Function;
  range: any;
}

const FlightReportTable = ({
  formValues,
  flightReportScreenData,
  currentSelectedStatus,
  setCurrentSelectedStatus,
  goto,
  currentSelectedPdfIndex,
  setCurrentSelectedPdf,
  range
}: FlightReportTableProps) => {
  const classes = useStyles();

  const { isLoading, flightReportData } = flightReportScreenData;

  useEffect(() => {
    let customSelectedStatus = {};

    flightReportData.forEach((row: FlightReportRow) => {
      if (Number(row.status.id) !== 1) {
        customSelectedStatus[`${row.id}`] = row.status.id;
      }
    });

    setCurrentSelectedStatus(customSelectedStatus);
  }, [flightReportData, setCurrentSelectedStatus]);

  // const handleRowStatusValueChange = useCallback(
  //   (value: unknown, id: string) => {
  //     let customSelectedStatus = { ...currentSelectedStatus };
  //     customSelectedStatus[`${id}`] = value;
  //     setCurrentSelectedStatus(customSelectedStatus);
  //   },
  //   [currentSelectedStatus, setCurrentSelectedStatus]
  // );

  const handelOnClickRow = useCallback(
    (row: FlightReportRow, index: number) => {
      // const flightReprotIndex = R.indexOf({id:row.id},flightReportData)
      setCurrentSelectedPdf(row.id);
      goto(`/clientportal/flightreport`);
    },
    [goto]
  );

  const filteredFlightReportData = useMemo(
    () => filterFlightReportScreenData(flightReportData, formValues),
    [flightReportData, formValues]
  );

  // get unique date count from filteredFlightReportData
  const uniqueDateCount = useMemo(() => {
    if (filteredFlightReportData) {
      return filteredFlightReportData.map((row: FlightReportRow) => row.date);
    }
    return [];
  }, [filteredFlightReportData]);

  const totals = useMemo(
    () => getFlightReportScreenDataTotal(filteredFlightReportData),
    [filteredFlightReportData]
  );

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    columnsData: columns,
    dataArray: filteredFlightReportData,
    initialSortById: 'date'
  });
  const filteredColumns = useMemo(() => {
    var columnsData = columns;
    if (range.to === '' || range.from === '') {
      columnsData = columns.map(column => {
        return R.omit(['filterStatePath','filterComponent'], column);
      }
      );
    }
    return columnsData;
  }, [range]); 
  

  if (isLoading) {
    return <SharedSpinner />;
  }
 

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          order={order}
          orderBy={orderBy}
          columns={filteredColumns}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: FlightReportRow, index: number) => {
            return (
              <TableRow
                key={index}
                className="flight-report-custom-body-row"
                onClick={() => handelOnClickRow(row, index)}>
                <TableCell component="th" scope="row" align="center">
                  <Status status={row.flightStatus} />
                </TableCell>

                <TableCell component="th" scope="row">
                  {moment.utc(row.date).format('MMM D YYYY')}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.aircraft.registration}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {numberOrEmpty(row.revenue, 1)}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.flightReport}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {`${row.pilot.firstName || ''} ${row.pilot.lastName || ''}`}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.customer.name}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.booking.bookingNumber}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.contract}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  {row.invoiceNumber}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow className="totals-row-flight-report">
            <TableCell component="th" scope="row" align="left">
              Totals
            </TableCell>
            {numberOrEmpty(totals.revenue / sortedDataArray.length, 1) ? (
              <TableCell component="th" scope="row" colSpan={2}>
                {R.uniq(uniqueDateCount).length} days and{' '}
                {numberOrEmpty(
                  totals.revenue / R.uniq(uniqueDateCount).length,
                  1
                )}{' '}
                flight hours averaged per day
              </TableCell>
            ) : (
              <>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
              </>
            )}
            <TableCell component="th" scope="row">
              {numberOrEmpty(totals.revenue, 1)}
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>

            <TableCell component="th" scope="row"></TableCell>

            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlightReportTable;
