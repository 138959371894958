import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  AIRCRAFT_TYPE,
  TYPE_DESIGNATOR,
  FUEL_MEASURE,
  CUSTOMER_AIRCRAFT,
  STATUS,
  filtersNotWorkingOptions
} from '../../routes/Aircraft/helper';
import { AIRCRAFT_FILTERS_FORM } from '../../utils/reduxFormConstants';
import {
  fuelMeasureFilterOptions,
  booleanOptions,
  statusOptions,
  initialFilterList
} from '../../utils/helper';

const commonFilterPath = ['form', AIRCRAFT_FILTERS_FORM, 'values'];
export const aircraftTypeFilterStatePath = [...commonFilterPath, AIRCRAFT_TYPE];
export const typeDesignatorFilterStatePath = [
  ...commonFilterPath,
  TYPE_DESIGNATOR
];
export const fuelMeasureFilterStatePath = [...commonFilterPath, FUEL_MEASURE];
export const customerAircraftFilterStatePath = [
  ...commonFilterPath,
  CUSTOMER_AIRCRAFT
];
export const statusFilterStatePath = [...commonFilterPath, STATUS];

export const AircraftTypeFilterComponent = () => {
  const { typeFilterArray } = useSelector((state: StoreState) => ({
    typeFilterArray: R.pathOr(
      filtersNotWorkingOptions,
      ['aircraftScreenFilters', 'typeFilterArray'],
      state
    )
  }));

  return (
    <Field
      name={AIRCRAFT_TYPE}
      component={ReduxFormAutocomplete}
      label="Aircraft Type"
      optionsList={typeFilterArray}
      required
    />
  );
};

export const TypeDesignatorFilterComponent = () => {
  const { aircraftOfficialTypes } = useSelector((state: StoreState) => ({
    aircraftOfficialTypes: initialFilterList.concat(
      R.pathOr(
        filtersNotWorkingOptions,
        ['aircraftScreenData', 'aircraftOfficialTypes'],
        state
      )
    )
  }));

  return (
    <Field
      name={TYPE_DESIGNATOR}
      component={ReduxFormAutocomplete}
      label="Type Designator"
      optionsList={aircraftOfficialTypes}
      required
    />
  );
};

export const FuelMeasureFilterComponent = () => {
  return (
    <Field
      name={FUEL_MEASURE}
      component={ReduxFormAutocomplete}
      label="Fuel Measure"
      optionsList={fuelMeasureFilterOptions}
      required
    />
  );
};

export const CustomerAircraftFilterComponent = () => {
  return (
    <Field
      name={CUSTOMER_AIRCRAFT}
      component={ReduxFormAutocomplete}
      label="Customer Aircraft"
      optionsList={booleanOptions}
      required
    />
  );
};

export const StatusFilterComponent = () => {
  return (
    <Field
      name={STATUS}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={statusOptions}
      required
    />
  );
};
