import { useEffect } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import StoreState from '../../redux/utils/interfaces';
import {
  fetchDetailScreenDataRequest,
  fetchExpenseTypesRequest,
  fetchBookingsRequest
} from '../../redux/actions/detailsScreenData';
import { getCurrentUserIndex } from './helper';
import { isPresent } from '../../utils/helper';
import {
  RECEIPT_ROUTE,
  WORK_HOURS_DETAILS
} from '../../utils/routesNavigationConstants';

export const useDetailScreenHook = () => {
  let { userId }: any = useParams();
  let receiptMatch = useRouteMatch(RECEIPT_ROUTE);
  let workHoursDetailsMatch = useRouteMatch(WORK_HOURS_DETAILS);

  const isReceiptPage = isPresent(receiptMatch);
  const isWorkHoursDetailsPage = isPresent(workHoursDetailsMatch);

  let history = useHistory();
  const dispatch = useDispatch();

  const {
    departmentFilter,
    statusFilter,
    firstNameFilter,
    lastNameFilter,
    selectedFirstDate,
    dataArray,
    expenseTypes,
    isLoading
  } = useSelector((state: StoreState) => {
    return {
      dataArray: state.detailsScreenData.dataArray,
      expenseTypes: state.detailsScreenData.expenseTypes,
      isLoading: state.detailsScreenData.isLoading,
      ...state.detailsScreenSelectedFields
    };
  });

  useEffect(() => {
    dispatch(
      fetchDetailScreenDataRequest(
        selectedFirstDate,
        departmentFilter,
        statusFilter,
        firstNameFilter,
        lastNameFilter,
      )
    );
    dispatch(fetchExpenseTypesRequest());
  }, [selectedFirstDate, departmentFilter, statusFilter, firstNameFilter,
    lastNameFilter, dispatch]);

  useEffect(() => {
    dispatch(fetchBookingsRequest(selectedFirstDate));
  }, [dispatch, selectedFirstDate]);

  const currentUserIndex = getCurrentUserIndex(userId, dataArray);
  const currentUserData = dataArray[currentUserIndex];

  const goToUserIdRoute = (index: number) =>
    history.push(`/details/${dataArray[index].id}`);

  const handlePageChange = (changeIndex: number) =>
    goToUserIdRoute(changeIndex);

  const getTitle = () => {
    if (isReceiptPage) return 'Receipt';
    if (isWorkHoursDetailsPage) return 'Description';

    return 'Details';
  };

  const pageTitle = getTitle();

  return {
    isLoading,
    dataArray,
    currentUserIndex,
    isReceiptPage,
    isWorkHoursDetailsPage,
    currentUserData,
    selectedFirstDate,
    expenseTypes,
    pageTitle,
    handlePageChange
  };
};
