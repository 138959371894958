import React, { useEffect, useCallback, useMemo } from 'react';

import moment from 'moment';
import * as R from 'ramda';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import SharedSpinner from '../shared/SharedSpinner';
import Dropdown from '../shared/Dropdown';
import { FlightReportScreenDataProps } from '../../redux/utils/interfaces';
import {
  useStyles,
  columns,
  FlightReportRow,
  filterFlightReportScreenData,
  getFlightReportScreenDataTotal
} from './helper';
import { statusFilters } from '../../routes/FlightReport/helper';
import { isPresent, numberOrEmpty } from '../../utils/helper';
import SharedTableHeader from '../shared/SharedTableHeader';
import { useSortHook } from '../../hooks/sortHooks';

import './style.scss';

interface FlightReportTableProps {
  formValues: {};
  flightReportScreenData: FlightReportScreenDataProps;
  currentSelectedStatus: any;
  setCurrentSelectedStatus: Function;
  goto: Function;
}

const FlightReportTable = ({
  formValues,
  flightReportScreenData,
  currentSelectedStatus,
  setCurrentSelectedStatus,
  goto
}: FlightReportTableProps) => {
  const classes = useStyles();

  const { isLoading, flightReportData } = flightReportScreenData;

  useEffect(() => {
    let customSelectedStatus = {};

    flightReportData.forEach((row: FlightReportRow) => {
      if (Number(row.status.id) !== 1) {
        customSelectedStatus[`${row.id}`] = row.status.id;
      }
    });

    setCurrentSelectedStatus(customSelectedStatus);
  }, [flightReportData, setCurrentSelectedStatus]);

  const handleRowStatusValueChange = useCallback(
    (value: unknown, id: string) => {
      let customSelectedStatus = { ...currentSelectedStatus };
      customSelectedStatus[`${id}`] = value;
      setCurrentSelectedStatus(customSelectedStatus);
    },
    [currentSelectedStatus, setCurrentSelectedStatus]
  );

  const handelOnClickRow = useCallback(
    (row: FlightReportRow) => {
      goto(`/flightReport/${row.id}`);
    },
    [goto]
  );

  const filteredFlightReportData = useMemo(
    () => filterFlightReportScreenData(flightReportData, formValues),
    [flightReportData, formValues]
  );

  const totals = useMemo(
    () => getFlightReportScreenDataTotal(filteredFlightReportData),
    [filteredFlightReportData]
  );

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    columnsData: columns,
    dataArray: filteredFlightReportData,
    initialSortById: 'date'
  });

  if (isLoading) {
    return <SharedSpinner />;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          order={order}
          orderBy={orderBy}
          columns={columns}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: FlightReportRow, index: number) => {
            const currentRowStatusValue = R.pathOr(
              '1',
              [],
              currentSelectedStatus[`${row.id}`]
            );
            const isDropdownDisabled = Number(currentRowStatusValue) === 1;

            return (
              <TableRow
                key={index}
                className="flight-report-custom-body-row"
                onClick={() => handelOnClickRow(row)}>
                <TableCell component="th" scope="row">
                  {moment.utc(row.date).format('MMM D YYYY')}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {`${row.booking.bookingNumber} - ${row.booking.name}`}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.customer.name}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.flightReport}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.aircraft.registration}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {`${row.pilot.firstName || ''} ${row.pilot.lastName || ''}`}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {`${row.ame.firstName || ''} ${row.ame.lastName || ''}`}
                </TableCell>

                <TableCell component="th" scope="row" align="right">
                  {numberOrEmpty(row.revenue, 1)}
                </TableCell>

                <TableCell component="th" scope="row" align="right">
                  {numberOrEmpty(row.nonRevenue, 1)}
                </TableCell>

                <TableCell component="th" scope="row" align="right">
                  {numberOrEmpty(row.airTime, 1)}
                </TableCell>

                <TableCell component="th" scope="row" align="right">
                  {isPresent(numberOrEmpty(row.difference, 1)) &&
                    `${numberOrEmpty(row.difference, 1)}%`}
                </TableCell>

                <TableCell component="th" scope="row" align="center">
                  <Dropdown
                    label="Set Status"
                    variant="outlined"
                    customClassNames="status-input-table"
                    withLabel={false}
                    value={currentRowStatusValue}
                    disabled={isDropdownDisabled}
                    optionsList={
                      isDropdownDisabled
                        ? [{ id: 1, name: 'Closed' }]
                        : statusFilters
                    }
                    handleChange={(
                      event: React.ChangeEvent<{
                        value: unknown;
                      }>
                    ) => {
                      if (!isDropdownDisabled) {
                        const { value } = event.target;
                        handleRowStatusValueChange(value, row.id);
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow className="totals-row-flight-report">
            <TableCell component="th" scope="row" align="left">
              Totals
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>

            <TableCell component="th" scope="row" align="right">
              {numberOrEmpty(totals.revenue, 1)}
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              {numberOrEmpty(totals.nonRevenue, 1)}
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              {numberOrEmpty(totals.airTime, 1)}
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              {isPresent(numberOrEmpty(totals.difference, 1)) &&
                `${numberOrEmpty(totals.difference, 1)}%`}
            </TableCell>

            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlightReportTable;
