import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  AIRCRAFT_FILTERS,
  BOOKING_FILTERS,
  CUSTOMER_FILTERS,
  PILOT_FILTERS,
  AME_FILTERS,
  STATUS_FILTERS,
  filtersNotWorkingOptions
} from '../../routes/FlightReport/helper';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';

const commonFilterPath = ['form', FLIGHT_REPORT_FILTERS_FORM, 'values'];

export const bookingFilterStatePath = [...commonFilterPath, BOOKING_FILTERS];
export const customerFilterStatePath = [...commonFilterPath, CUSTOMER_FILTERS];
export const aircraftFilterStatePath = [...commonFilterPath, AIRCRAFT_FILTERS];
export const pilotFilterStatePath = [...commonFilterPath, PILOT_FILTERS];
export const ameFilterStatePath = [...commonFilterPath, AME_FILTERS];
export const statusFilterStatePath = [...commonFilterPath, STATUS_FILTERS];

export const BookingFilterComponent = () => {
  const { bookingFiltersArray } = useSelector((state: StoreState) => ({
    bookingFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'bookingFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={BOOKING_FILTERS}
      component={ReduxFormAutocomplete}
      label="Booking"
      optionsList={bookingFiltersArray}
      required
    />
  );
};

export const CustomerFilterComponent = () => {
  const { customerFiltersArray } = useSelector((state: StoreState) => ({
    customerFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'customerFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={CUSTOMER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Customer"
      optionsList={customerFiltersArray}
      required
    />
  );
};

export const AircraftFilterComponent = () => {
  const { aircraftFiltersArray } = useSelector((state: StoreState) => ({
    aircraftFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'aircraftFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={AIRCRAFT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Aircraft"
      optionsList={aircraftFiltersArray}
      required
    />
  );
};

export const PilotFilterComponent = () => {
  const { pilotFiltersArray } = useSelector((state: StoreState) => ({
    pilotFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'pilotFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={PILOT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Pilot"
      optionsList={pilotFiltersArray}
      required
    />
  );
};

export const AmeFilterComponent = () => {
  const { ameFiltersArray } = useSelector((state: StoreState) => ({
    ameFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'ameFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={AME_FILTERS}
      component={ReduxFormAutocomplete}
      label="AME"
      optionsList={ameFiltersArray}
      required
    />
  );
};

export const StatusFilterComponent = () => {
  const { statusFiltersArray } = useSelector((state: StoreState) => ({
    statusFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['flightReportScreenFilters', 'statusFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={STATUS_FILTERS}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={statusFiltersArray}
      required
    />
  );
};
