import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps, Field, initialize } from 'redux-form';
import ReduxFormInput from '../../components/shared/ReduxFormInput';
import ReduxFormCheckbox from '../../components/shared/ReduxFormCheckbox';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import { CLIENT_USERS_ADD_EDIT_FORM } from '../../utils/reduxFormConstants';
import {
  isNilOrEmpty,
  isPresent
  //  languageOptions
} from '../../utils/helper';
import { Grid } from '@material-ui/core';
import ReduxFormAutocompleteMultiple from '../../components/shared/ReduxFormAutocompleteMultiple';
interface ClientUserFormInitialValues {
  // employeeId: string;
  firstName: string;
  lastName: string;
  // bushpay: boolean;
  approvingClient: any[];
  isReviewingClient: boolean;
  isApprovingClient: boolean;
  userType: number;
  // license1: string;
  // supervisor: number;
  // isReviewingSupervisor: boolean;

  username: string;
  // phone: string;
  language: string;
  // isApprovingSupervisor: boolean;

  // isContractor: boolean;

  // notes: string;
}
interface ClientUserFormProps {
  clients: {}[];
  supervisors: {}[];
  initialValues?: ClientUserFormInitialValues;
  showLicenseField: boolean;
}

const ClientUserForm = ({
  clients,
  supervisors,
  initialValues,
  showLicenseField
}: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPresent(initialValues)) {
      dispatch(initialize(CLIENT_USERS_ADD_EDIT_FORM, initialValues));
    }
  }, [initialValues, dispatch]);

  return (
    <div className="users-details-input-options">
      <div className="users-details-input-options-box">
        <div className="users-details-input-options-wrapper">
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Field
                name="firstName"
                type="text"
                component={ReduxFormInput}
                label="First Name"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="lastName"
                type="text"
                component={ReduxFormInput}
                label="Last Name"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="username"
                type="text"
                component={ReduxFormInput}
                label="Email"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="approvingClient"
                component={ReduxFormAutocompleteMultiple}
                label="Client"
                optionsList={clients}
                id="approvingClient"
                variant="outlined"
                disabled={isNilOrEmpty(clients)}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="language"
                component={ReduxFormAutocomplete}
                label="Language"
                variant="outlined"
                optionsList={[
                  { id: 'en', name: 'English' },
                  { id: 'fr', name: 'French' }
                ]}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="isReviewingClient"
                component={ReduxFormCheckbox}
                label="Reviewer"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="isApprovingClient"
                component={ReduxFormCheckbox}
                label="Approver"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default reduxForm<InjectedFormProps, ClientUserFormProps>({
  form: CLIENT_USERS_ADD_EDIT_FORM
})(ClientUserForm);
