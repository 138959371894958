import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Column } from './helper';

interface TableHeaderProps {
  columns: Column[];
}

const TableHeader = ({ columns }: TableHeaderProps) => {
  return (
    <TableHead>
      <TableRow className="details-custom_header-row">
        {columns.map((column: Column) => (
          <TableCell
            key={column.id}
            className="details-custom_header-row-column"
            style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}>
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
