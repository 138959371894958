import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  AIRCRAFT_FILTERS,
  BOOKING_FILTERS,
  CLIENT_FILTERS,
  INVOICE_NUMBER_FILTERS,
  PILOT_FILTERS,
  FLIGHT_REPORT_FILTERS,
  filtersNotWorkingOptions
} from '../../routes/Invoice/helper';
import { INVOICE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { InvoiceRow } from './helper';

const commonFilterPath = ['form', INVOICE_FILTERS_FORM, 'values'];
export const aircraftFilterStatePath = [...commonFilterPath, AIRCRAFT_FILTERS];
export const bookingFilterStatePath = [...commonFilterPath, BOOKING_FILTERS];
export const clientFilterStatePath = [...commonFilterPath, CLIENT_FILTERS];
export const pilotFilterStatePath = [...commonFilterPath, PILOT_FILTERS];
export const flightReportFilterStatePath = [...commonFilterPath, FLIGHT_REPORT_FILTERS];
export const invoiceNumberFilterStatePath = [
  ...commonFilterPath,
  INVOICE_NUMBER_FILTERS
];

export const AircraftFilterComponent = () => {
  // const { aircraftFiltersArray } = useSelector((state: StoreState) => ({
  //   aircraftFiltersArray: R.pathOr(
  //     filtersNotWorkingOptions,
  //     ['invoiceScreenFilters', 'aircraftFiltersArray'],
  //     state
  //     )
  //   }));
  const { invoiceScreenData } = useSelector((state: StoreState) => {
    return {
      invoiceScreenData: R.pathOr(
        [],
        ['invoiceScreenData', 'invoiceData'],
        state
      )
    };
  });
  const aircraftFiltersArray = useMemo(() => {

    let invoiceData: InvoiceRow[] = invoiceScreenData;
  
    const aircrafts = new Set<{ id: number; name: string; }>();
  
    invoiceData.forEach((invoice, index) => {
      aircrafts.add({id:invoice.aircraft.id ,name:invoice.aircraft.registration});
    });
  
    let aircraftFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];
  
    aircrafts.forEach((aircraft,key) => {
      aircraftFiltersArray.push({ id: aircraft.id, name: aircraft.name });
    });
    return R.uniq(aircraftFiltersArray);
  },[invoiceScreenData]);

  return (
    <Field
      name={AIRCRAFT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Aircraft"
      optionsList={aircraftFiltersArray}
      required
    />
  );
};
export const FlightReportFilterComponent = () => {
  const { fuelExpenseScreenData } = useSelector((state: StoreState) => {
    return {
      fuelExpenseScreenData: R.pathOr(
        [],
        ['invoiceScreenData', 'invoiceData'],
        state
      )
    };
  });
  const flightReportFiltersArray = useMemo(() => {

    let invoiceData: InvoiceRow[] = fuelExpenseScreenData;
  
    const flightReports = new Set<string>();
  
    invoiceData.forEach((invoice, index) => {
      flightReports.add(invoice.flightReport);
    });
  
    let flightReportFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];
  
    flightReports.forEach((flightReport,key) => {
      flightReportFiltersArray.push({ id: key, name: flightReport });
    });
    return flightReportFiltersArray;
  },[fuelExpenseScreenData]);

  return (
    <Field
      name={FLIGHT_REPORT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Flight-Report"
      optionsList={flightReportFiltersArray}
      required
    />
  );
};

export const PilotFilterComponent = () => {
  const { pilotFiltersArray } = useSelector((state: StoreState) => ({
    pilotFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['invoiceScreenFilters', 'pilotFiltersArray'],
      state
      )
    }));
  
  return (
    <Field
      name={PILOT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Pilot"
      optionsList={pilotFiltersArray}
      required
    />
  );
};


export const BookingFilterComponent = () => {
  const { bookingFiltersArray } = useSelector((state: StoreState) => ({
    bookingFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['invoiceScreenFilters', 'bookingFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={BOOKING_FILTERS}
      component={ReduxFormAutocomplete}
      label="Booking"
      optionsList={bookingFiltersArray}
      required
    />
  );
};

export const ClientFilterComponent = () => {
  const { clientFiltersArray } = useSelector((state: StoreState) => ({
    clientFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['invoiceScreenFilters', 'clientFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={CLIENT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Client"
      optionsList={clientFiltersArray}
      required
    />
  );
};

export const InvoiceNumberFilterComponent = () => {
  const { invoiceNumberArray } = useSelector((state: StoreState) => ({
    invoiceNumberArray: R.pathOr(
      filtersNotWorkingOptions,
      ['invoiceScreenFilters', 'invoiceNumberArray'],
      state
    )
  }));

  return (
    <Field
      name={INVOICE_NUMBER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Invoice Number"
      optionsList={invoiceNumberArray}
      required
    />
  );
};
