import request from '../http/request';
import humps from 'humps';

import {
  fetchLogin,
  fetchNewTokens,
  createNewUser,
  createNewClientUser,
  fetchMe
} from './userApiModules';
import { fetchImagesDataArray } from './imageApiModules';
import {
  fetchExportData,
  fetchReceipt,
  fetchFlightReportReceipt
} from './pdfApiModules';

const updateData = async (
  dataUrl: string,
  header: {},
  body: {},
  method: string = 'PUT',
  decamelizeKeys: boolean = true
) => {
  const response = await request(dataUrl, method, header, body, decamelizeKeys);

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
};

async function fetchResponse(dataUrl: string, header: {} = {}) {
  const method = 'GET';

  const response = await request(dataUrl, method, header);

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
}

export default {
  fetchLogin,
  fetchNewTokens,
  createNewUser,
  createNewClientUser,
  fetchMe,
  fetchResponse,
  updateData,
  fetchImagesDataArray,
  fetchExportData,
  fetchReceipt,
  fetchFlightReportReceipt
};
