import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps, Field, initialize } from 'redux-form';
import ReduxFormInput from '../../components/shared/ReduxFormInput';
import ReduxFormCheckbox from '../../components/shared/ReduxFormCheckbox';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import { AIRCRAFT_ADD_EDIT_FORM } from '../../utils/reduxFormConstants';
import { isPresent, fuelMeasureOptions } from '../../utils/helper';

interface AircraftFormProps {
  initialValues?: any;
  aircraftOfficialTypes: any;
}

const AircraftForm = ({ initialValues, aircraftOfficialTypes }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPresent(initialValues)) {
      dispatch(initialize(AIRCRAFT_ADD_EDIT_FORM, initialValues));
    }
  }, [initialValues, dispatch]);

  return (
    <div className="aircraft-details-input-options">
      <div className="aircraft-details-input-options-wrapper">
        <Field
          name="registration"
          type="text"
          component={ReduxFormInput}
          label="Registration"
          required
        />

        <Field
          name="aircraftType"
          type="text"
          component={ReduxFormInput}
          label="Type"
          required
        />

        <Field
          name="aircraftOfficialType"
          component={ReduxFormAutocomplete}
          label="Type Designator"
          optionsList={aircraftOfficialTypes}
          required
        />

        <Field
          name="isClientAircraft"
          component={ReduxFormCheckbox}
          label="Customer Aircraft"
        />
      </div>

      <div className="aircraft-details-input-options-wrapper">
        <Field
          name="fuelMeasure"
          component={ReduxFormAutocomplete}
          label="Fuel Measure"
          optionsList={fuelMeasureOptions}
          required
        />

        <Field
          name="maxFuel"
          type="number"
          component={ReduxFormInput}
          label="Max Fuel"
          required
        />

        <Field
          name="satPhoneNumber"
          type="text"
          component={ReduxFormInput}
          label="Sat Phone Number"
        />
      </div>
    </div>
  );
};

export default reduxForm<InjectedFormProps, AircraftFormProps>({
  form: AIRCRAFT_ADD_EDIT_FORM
})(AircraftForm);
