import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import { Box } from '@material-ui/core';
import CalendarInputRange from '../CalendarInputRange';
import Breadcrumbs from '../shared/Breadcrumbs';
import { RANGE } from '../../routes/EngineerJobReview/helper';
import { getWeekRange } from '../../utils/helper';
import StoreState from '../../redux/utils/interfaces';
import { ENGINEER_JOB_REVIEW_FILTERS_FORM } from '../../utils/reduxFormConstants';

export interface RangeProps {
  to: Date;
  from: Date;
}

const EngineerJobReviewHeader = () => {
  const dispatch = useDispatch();

  const { formValues } = useSelector((state: StoreState) => {
    return {
      formValues: getFormValues(ENGINEER_JOB_REVIEW_FILTERS_FORM)(state) || {}
    };
  });

  const setRangeHandler = (range: RangeProps) => {
    updateFormField(RANGE, range);
  };

  const rangeValue = R.pathOr(getWeekRange(), ['range'], formValues);

  const updateFormField = (key: string, value: any) =>
    dispatch(change(ENGINEER_JOB_REVIEW_FILTERS_FORM, key, value));

  return (
    <React.Fragment>
      <Box mr={5}>
        <Breadcrumbs
          breadcrumbDataArray={[
            {
              id: 'route_name',
              label: 'Engineer Job Review'
            }
          ]}
        />
      </Box>

      <CalendarInputRange range={rangeValue} setRange={setRangeHandler} showOffFilter={false}/>
    </React.Fragment>
  );
};

export default EngineerJobReviewHeader;
