import types from '../utils/actionTypes';

export const setAircraftScreenFilters = (typeFilterArray: []) => {
  return {
    type: types.SET_AIRCRAFT_SCREEN_FILTERS,
    payload: {
      typeFilterArray
    }
  };
};
