import types from '../utils/actionTypes';

export const fetchBookingsScreenFiltersRequest = () => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_FILTERS_REQUEST,
    payload: {}
  };
};

export const fetchBookingsScreenFiltersSuccess = (customerFiltersArray: []) => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_FILTERS_SUCCESS,
    payload: {
      customerFiltersArray
    }
  };
};

export const fetchBookingsScreenFiltersFailure = () => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_FILTERS_FAILURE,
    payload: {}
  };
};
