import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

interface DropdownProps {
  name?: string;
  label: string;
  value?: number | string | {};
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  optionsList: {}[];
  customClassNames?: string;
  withLabel?: boolean;
  disabled?: boolean;
  required?: boolean;
  handleChange: (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
}

function Dropdown(props: DropdownProps) {
  const classes = useStyles();

  const {
    name,
    label,
    value,
    variant = 'standard',
    optionsList,
    customClassNames = '',
    withLabel = true,
    disabled = false,
    required = false,
    handleChange
  } = props;

  return (
    <FormControl
      variant={variant}
      className={`${classes.formControl} form-control-custom-styles ${customClassNames}`}>
      {withLabel && <InputLabel>{required ? label + ' *' : label}</InputLabel>}

      <Select
        name={name}
        value={value}
        disabled={disabled}
        onClick={(e) => e.stopPropagation()}
        onChange={handleChange}>
        {optionsList.map(({ label, value, id, name }: any, index: number) => (
          <MenuItem key={index} value={value || id}>
            {label || name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
