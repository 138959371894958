/**
 * Moulds the success response
 */

import * as R from 'ramda';
import { Response } from 'node-fetch';

const successResponse = async (res: Response) => {
  const statusObject = {
    status: res.status,
    statusText: res.statusText
  };

  try {
    if (res.status === 204 && res.statusText === 'No Content') {
      return {
        success: true,
        data: { message: 'Success!', noContent: true },
        ...statusObject
      };
    } else {
      const contentType = res.headers.get('content-type');

      let response;

      if (R.includes('text/plain', `${contentType}`)) {
        response = await res.text();
      } else if (
        R.includes(`${contentType}`, [
          'application/csv',
          'application/zip',
          'application/pdf'
        ])
      ) {
        response = await res.blob();
      } else {
        response = await res.json();
      }

      return {
        success: true,
        data: response,
        ...statusObject
      };
    }
  } catch (error) {
    if (error.type === 'invalid-json') {
      return {
        success: true,
        data: { message: 'Success!' },
        ...statusObject
      };
    }

    return {
      success: true,
      data: { message: error.message },
      ...statusObject
    };
  }
};

export default successResponse;
