import { CustomersScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: CustomersScreenDataProps = {
  isLoading: false,
  customersData: []
};

const customersScreenData = (
  state: CustomersScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_CUSTOMERS_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_CUSTOMERS_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        customersData: action.payload.customersData,
        isLoading: false
      };

    case types.FETCH_CUSTOMERS_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

export default customersScreenData;
