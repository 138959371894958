import React from 'react';
import CustomersDetailsInput from './CustomersDetailsInput';

import './style.scss';

const AddCustomer = () => {
  return (
    <React.Fragment>
      <CustomersDetailsInput />
    </React.Fragment>
  );
};

export default AddCustomer;
