import React from 'react';
import { Helmet } from 'react-helmet';
import * as R from 'ramda';
import { reduxForm } from 'redux-form';
import { Box, Paper } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import CustomersSecondaryHeader from '../../components/CustomersSecondaryHeader';
import CustomersTable from '../../components/CustomersTable';
import CustomersDetails from '../../components/CustomersDetails';
import AddCustomer from '../../components/CustomersDetails/AddCustomer';
import CustomerFlightReports from '../../components/CustomerFlightReports';
import CustomersDetailsHeader from './CustomersDetailsHeader';
import { CUSTOMERS_NAV_KEY } from '../../utils/routesNavigationConstants';
import { useCustomersHook } from './hooks';
import SharedSpinner from '../../components/shared/SharedSpinner';
import { CUSTOMERS_FILTERS_FORM } from '../../utils/reduxFormConstants';

import './style.scss';

const Customers = () => {
  const {
    pageTitle,
    customersData,
    isCustomersDataLoading,
    isCustomersDetailsRouteMatch,
    isAddCustomersRouteMatch,
    isFlightReportsRouteMatch,
    currentCustomerData,
    currentSelectedStatus,
    setCurrentSelectedStatus,
    goto,
    onSaveButtonClickHandler,
    setAllCurrentSelectedStatus
  } = useCustomersHook();

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={
          <CustomersSecondaryHeader
            isCustomersDetailsRouteMatch={
              isCustomersDetailsRouteMatch || isFlightReportsRouteMatch
            }
            isAddCustomersRouteMatch={isAddCustomersRouteMatch}
            currentCustomerName={R.pathOr(
              'Loading...',
              ['name'],
              currentCustomerData
            )}
            goto={goto}
            onSaveButtonClickHandler={onSaveButtonClickHandler}
          />
        }
        navKey={CUSTOMERS_NAV_KEY}>
        <Box py={2.5}>
          {isCustomersDataLoading ? (
            <SharedSpinner />
          ) : isCustomersDetailsRouteMatch ||
            isFlightReportsRouteMatch ||
            isAddCustomersRouteMatch ? (
            <Paper>
              <CustomersDetailsHeader
                isCustomersDetailsRouteMatch={isCustomersDetailsRouteMatch}
                isFlightReportsRouteMatch={isFlightReportsRouteMatch}
                isAddCustomersRouteMatch={isAddCustomersRouteMatch}
                currentCustomerId={R.pathOr(-1, ['id'], currentCustomerData)}
                goto={goto}
              />
              <div className="customers-details-table-body">
                {isCustomersDetailsRouteMatch ? (
                  <CustomersDetails currentCustomerData={currentCustomerData} />
                ) : isFlightReportsRouteMatch ? (
                  <CustomerFlightReports
                    currentSelectedStatus={currentSelectedStatus}
                    setAllCurrentSelectedStatus={setAllCurrentSelectedStatus}
                    setCurrentSelectedStatus={setCurrentSelectedStatus}
                  />
                ) : (
                  <AddCustomer />
                )}
              </div>
            </Paper>
          ) : (
            <CustomersTable customersData={customersData} goto={goto} />
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: CUSTOMERS_FILTERS_FORM
})(Customers);
