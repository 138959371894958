import React from 'react';

interface CustomersDetailsHeaderProps {
  isCustomersDetailsRouteMatch: boolean;
  isFlightReportsRouteMatch: boolean;
  isAddCustomersRouteMatch: boolean;
  currentCustomerId: number;
  goto: Function;
}

const CustomersDetailsHeader = ({
  isCustomersDetailsRouteMatch,
  isFlightReportsRouteMatch,
  isAddCustomersRouteMatch,
  currentCustomerId,
  goto
}: CustomersDetailsHeaderProps) => {
  const routeChange = (route: string) => {
    if (!isAddCustomersRouteMatch) {
      if (!isFlightReportsRouteMatch && route === 'flightReports') {
        goto(`/customers/flightReports/${currentCustomerId}`);
      } else if (!isCustomersDetailsRouteMatch && route === 'details') {
        goto(`/customers/details/${currentCustomerId}`);
      }
    }
  };

  return (
    <div className="customers-details-table-header">
      <div
        className={`customers-details-table-header-options ${
          isCustomersDetailsRouteMatch || isAddCustomersRouteMatch
            ? 'customers-details-table-header-options-selected'
            : ''
        }`}
        onClick={() => routeChange('details')}>
        DETAILS
      </div>
      {!isAddCustomersRouteMatch && (
        <div
          className={`customers-details-table-header-options ${
            isFlightReportsRouteMatch
              ? 'customers-details-table-header-options-selected'
              : ''
          }`}
          onClick={() => routeChange('flightReports')}>
          FLIGHT REPORT
        </div>
      )}
    </div>
  );
};

export default CustomersDetailsHeader;
