import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Field,
  reduxForm,
  initialize,
  InjectedFormProps,
  getFormValues,
  change
} from 'redux-form';
import * as R from 'ramda';
import Box from '@material-ui/core/Box';
import { ReceiptsArrayData } from '../../routes/Details/helper';
import DatePicker from '../shared/DatePicker';
import ReduxFormInput from '../shared/ReduxFormInput';
import ReduxFormAutocomplete from '../shared/ReduxFormAutocomplete';
import ColorButton from '../shared/ColorButton';
import StoreState from '../../redux/utils/interfaces';
import {
  getNumberUpto2Decimals,
  paymentMethodsList,
  getDateString
} from '../../utils/helper';
import { RECEIPT_DETAILS_EDIT_FORM } from '../../utils/reduxFormConstants';
import { getFormattedValues } from './helper';
import { updateReceiptDetailScreenDataRequest } from '../../redux/actions/detailsScreenData';

import './detailsFormStyles.scss';

export interface DetailsFormProps {
  currentReceiptData: ReceiptsArrayData;
}

const preDiems = {
  breakfast: [2, 5],
  lunch: [3, 6],
  dinner: [4, 7],
  déjeuner: [30, 33],
  diner: [31, 34],
  souper: [32, 35]
};

const getPerDiemsIdsList = () => {
  let array: number[] = [];

  const keys = R.keys(preDiems);

  keys.forEach((key) => {
    array = array.concat(preDiems[key]);
  });

  return array;
};

const getFilteredExpenseTypes = (
  expenseTypesList: any,
  currentExpenseType: any
) => {
  const idsList = getPerDiemsIdsList();

  if (!idsList.includes(currentExpenseType)) {
    return expenseTypesList.filter((data: any) => {
      return !idsList.includes(data.id);
    });
  }

  const keys = R.keys(preDiems);

  let newExpenseTypesList = expenseTypesList;

  keys.forEach((key) => {
    const currentArray = preDiems[key];

    if (currentArray.includes(currentExpenseType)) {
      newExpenseTypesList = expenseTypesList.filter((data: any) => {
        return currentArray.includes(data.id);
      });
    }
  });

  return newExpenseTypesList;
};

const DetailsForm = (props: any) => {
  const dispatch = useDispatch();
  const { formValues, expenseTypesList, bookings } = useSelector(
    (state: StoreState) => {
      return {
        formValues: getFormValues(RECEIPT_DETAILS_EDIT_FORM)(state) || {},
        expenseTypesList: R.pathOr(
          [],
          ['detailsScreenData', 'expenseTypes'],
          state
        ),
        bookings: R.pathOr([], ['detailsScreenData', 'bookings'], state)
      };
    }
  );

  const {
    id,
    booking,
    paymentMethod,
    expenseType,
    amount,
    correctedAmount,
    correctedDescription,
    managerComment,
    date
  } = props.currentReceiptData;

  useEffect(() => {
    dispatch(
      initialize(RECEIPT_DETAILS_EDIT_FORM, {
        id: id,
        booking: R.pathOr('', ['id'], booking),
        paymentMethod: paymentMethod,
        expenseType: R.pathOr('', ['id'], expenseType),
        amount: getNumberUpto2Decimals(amount),
        correctedAmount: getNumberUpto2Decimals(correctedAmount),
        correctedDescription: correctedDescription,
        managerComment: managerComment,
        date: date
      })
    );
  }, [
    id,
    booking,
    paymentMethod,
    expenseType,
    amount,
    correctedAmount,
    correctedDescription,
    managerComment,
    date,
    dispatch
  ]);

  const { expenseType: currentExpenseType }: any = formValues;

  const updateFormField = (key: string, value: any) =>
    dispatch(change(RECEIPT_DETAILS_EDIT_FORM, key, value));

  const selectedDate = R.pathOr(new Date(), ['date'], formValues);

  const handleSelectDate = (date: Date) => {
    updateFormField('date', getDateString(date));
  };

  return (
    <form
      onSubmit={(event: any) => {
        event.preventDefault();
        props.handleSubmit(event);
      }}
      noValidate
      autoComplete="off">
      <div className="receipt-details-fields-container">
        <div className="details-field-container">
          <DatePicker
            selectedDate={selectedDate}
            handleDateChange={handleSelectDate}
            disabled
            keyboardIcon={<div></div>}
          />
        </div>

        <Field
          name="booking"
          component={ReduxFormAutocomplete}
          label="Booking"
          optionsList={bookings}
          required={
            ![2, 3, 4, 5, 6, 7, 30, 31, 32, 33, 34, 35].includes(
              currentExpenseType
            )
          }
        />

        <Field
          name="paymentMethod"
          component={ReduxFormAutocomplete}
          label="Payment Method"
          optionsList={paymentMethodsList}
          required
        />

        <Field
          name="expenseType"
          component={ReduxFormAutocomplete}
          label="Expense Type"
          optionsList={getFilteredExpenseTypes(
            expenseTypesList,
            currentExpenseType
          )}
          required
        />

        <Field
          name="amount"
          type="number"
          component={ReduxFormInput}
          label="Amount"
          disabled
        />

        <Field
          name="correctedAmount"
          type="number"
          component={ReduxFormInput}
          label="Corrected Amount"
        />

        <Field
          name="correctedDescription"
          type="text"
          component={ReduxFormInput}
          label="Description"
          multiline
        />

        <Box mt={2}>
          <Field
            name="managerComment"
            type="text"
            component={ReduxFormInput}
            label="Manager Comments"
            multiline
            variant="outlined"
            rows={3}
          />
        </Box>

        <div className="submit-button-container">
          <ColorButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={props.pristine || props.invalid}>
            Save
          </ColorButton>
        </div>
      </div>
    </form>
  );
};

export default reduxForm<InjectedFormProps, DetailsFormProps>({
  form: RECEIPT_DETAILS_EDIT_FORM,
  onSubmit: (values: any, dispatch: any) => {
    const formattedValues = getFormattedValues(values);

    dispatch(updateReceiptDetailScreenDataRequest(formattedValues));
  }
})(DetailsForm);
