import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  sortingProps: string[][];
}

export const columns: Column[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 120,
    align: 'center',
    sortingProps: [['firstName'], ['lastName']]
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 120,
    align: 'center',
    sortingProps: [['email']]
  }
];

interface CustomerId {
  id: number;
}

export interface PassengersRow {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  customer: CustomerId;
}
