import * as R from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import {
  bookingFilterStatePath,
  customerFilterStatePath,
  aircraftFilterStatePath,
  pilotFilterStatePath,
  ameFilterStatePath,
  statusFilterStatePath,
  BookingFilterComponent,
  CustomerFilterComponent,
  AircraftFilterComponent,
  PilotFilterComponent,
  AmeFilterComponent,
  StatusFilterComponent
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export const columns: Column[] = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 90,
    align: 'center',
    sortingProps: [['date']]
  },
  {
    id: 'booking',
    label: 'Booking',
    minWidth: 90,
    align: 'center',
    isNumber: true,
    sortingProps: [['booking', 'bookingNumber']],
    filterComponent: BookingFilterComponent,
    filterStatePath: bookingFilterStatePath
  },
  {
    id: 'customer',
    label: 'Customer',
    minWidth: 90,
    align: 'center',
    sortingProps: [['customer', 'name']],
    filterComponent: CustomerFilterComponent,
    filterStatePath: customerFilterStatePath
  },
  {
    id: 'flightReport',
    label: 'Flight report',
    minWidth: 90,
    align: 'center',
    sortingProps: [['flightReport']]
  },
  {
    id: 'aircraft',
    label: 'Aircraft',
    minWidth: 60,
    align: 'center',
    sortingProps: [['aircraft', 'registration']],
    filterComponent: AircraftFilterComponent,
    filterStatePath: aircraftFilterStatePath
  },
  {
    id: 'pilot',
    label: 'Pilot',
    minWidth: 90,
    align: 'center',
    sortingProps: [
      ['pilot', 'firstName'],
      ['pilot', 'lastName']
    ],
    filterComponent: PilotFilterComponent,
    filterStatePath: pilotFilterStatePath
  },
  {
    id: 'ame',
    label: 'AME',
    minWidth: 90,
    align: 'center',
    sortingProps: [
      ['ame', 'firstName'],
      ['ame', 'lastName']
    ],
    filterComponent: AmeFilterComponent,
    filterStatePath: ameFilterStatePath
  },
  {
    id: 'revenue',
    label: 'Revenue Hrs',
    minWidth: 60,
    align: 'center',
    isNumber: true,
    sortingProps: [['revenue']]
  },
  {
    id: 'nonRevenue',
    label: 'Non revenue Hrs',
    minWidth: 60,
    align: 'center',
    isNumber: true,
    sortingProps: [['nonRevenue']]
  },
  {
    id: 'airTime',
    label: 'Air Time',
    minWidth: 60,
    align: 'center',
    isNumber: true,
    sortingProps: [['airTime']]
  },
  {
    id: 'difference',
    label: 'Air Time/Flight Time %',
    minWidth: 60,
    align: 'center',
    isNumber: true,
    sortingProps: [['difference']]
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 60,
    align: 'center',
    sortingProps: [['status', 'name']],
    filterComponent: StatusFilterComponent,
    filterStatePath: statusFilterStatePath
  }
];

interface AircraftProps {
  id: number;
  registration: string;
}

interface BookingProps {
  id: number;
  name: string;
  bookingNumber: number;
}

interface CustomerProps {
  id: number;
  name: string;
}

interface PilotProps {
  id: number;
  firstName: string;
  lastName: string;
}

interface AmeProps {
  id: number;
  firstName: string;
  lastName: string;
}

interface StatusProps {
  id: number;
  name: string;
}

export interface FlightReportRow {
  id: string;
  date: string;
  booking: BookingProps;
  customer: CustomerProps;
  flightReport: string;
  aircraft: AircraftProps;
  pilot: PilotProps;
  ame: AmeProps;
  status: StatusProps;
  revenue: number;
  nonRevenue: number;
  airTime: number;
  difference: number;
}

const isAllBookingFilters = R.propEq('bookingFilters', -1);
const isAllCustomerFilters = R.propEq('customerFilters', -1);
const isAllAircraftFilters = R.propEq('aircraftFilters', -1);
const isAllPilotFilters = R.propEq('pilotFilters', -1);
const isAllAmeFilters = R.propEq('ameFilters', -1);
const isAllStatusFilters = R.propEq('statusFilters', -1);

const isAllFiltersAppliedEvery = R.allPass([
  isAllBookingFilters,
  isAllCustomerFilters,
  isAllAircraftFilters,
  isAllPilotFilters,
  isAllAmeFilters,
  isAllStatusFilters
]);

export const filterFlightReportScreenData = (
  flightReportScreenData: FlightReportRow[],
  filters: {}
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return flightReportScreenData;
  }

  const bookingFilters = R.pathOr(-1, ['bookingFilters'], filters);
  const customerFilters = R.pathOr(-1, ['customerFilters'], filters);
  const aircraftFilters = R.pathOr(-1, ['aircraftFilters'], filters);
  const pilotFilters = R.pathOr(-1, ['pilotFilters'], filters);
  const ameFilters = R.pathOr(-1, ['ameFilters'], filters);
  const statusFilters = R.pathOr(-1, ['statusFilters'], filters);

  let filteredDataArray = flightReportScreenData;

  if (!isAllBookingFilters({ bookingFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['booking', 'id'], obj), bookingFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllCustomerFilters({ customerFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['customer', 'id'], obj), customerFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllAircraftFilters({ aircraftFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['aircraft', 'id'], obj), aircraftFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllPilotFilters({ pilotFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['pilot', 'id'], obj), pilotFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllAmeFilters({ ameFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['ame', 'id'], obj), ameFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllStatusFilters({ statusFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['status', 'id'], obj), statusFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  return filteredDataArray;
};

export const getFlightReportScreenDataTotal = (
  flightReportScreenData: FlightReportRow[]
) => {
  let revenue = 0;
  let nonRevenue = 0;
  let airTime = 0;
  let difference = 0;
  let differenceCount = 0;

  flightReportScreenData.forEach(
    (flightReportScreenRowData: FlightReportRow) => {
      revenue = Number(revenue) + Number(flightReportScreenRowData.revenue);
      nonRevenue =
        Number(nonRevenue) + Number(flightReportScreenRowData.nonRevenue);
      airTime = Number(airTime) + Number(flightReportScreenRowData.airTime);
      difference =
        Number(difference) + Number(flightReportScreenRowData.difference);

      const value = parseFloat(`${flightReportScreenRowData.difference}`);

      if (R.is(Number, value) && `${value}` !== 'NaN') {
        differenceCount = differenceCount + 1;
      }
    }
  );

  if (differenceCount !== 0) {
    difference = parseFloat(`${difference}`) / differenceCount;
  }

  return {
    revenue,
    nonRevenue,
    airTime,
    difference
  };
};
