import React, { useMemo, useCallback } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import ConfirmationDialogRaw from '../../shared/SharedDialog';
import CustomSpeedDial from '../../shared/CustomSpeedDial';
import PdfOptionsSelector from './PdfOptionsSelector';
import { exportWeeklyReportRequest } from '../../../redux/actions/pdfExport';
import StoreState from '../../../redux/utils/interfaces';
import { getWeekRange, isPresent } from '../../../utils/helper';
import { filterUserDataIds } from './helper';

import './style.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    paper: {
      width: '80%',
      maxHeight: 435
    }
  })
);

const CloudDownloadButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedFirstDate, userData, departmentFilter, statusFilter,firstNameFilter,lastNameFilter} =
    useSelector((state: StoreState) => {
      return {
        selectedFirstDate: R.pathOr(
          new Date(),
          ['weeklyScreenFilters', 'selectedFirstDate'],
          state
        ),
        userData: R.pathOr([], ['weeklyScreenData', 'userDataArray'], state),
        departmentFilter: R.pathOr(
          -1,
          ['weeklyScreenFilters', 'departmentFilter'],
          state
        ),
        statusFilter: R.pathOr(
          'all',
          ['weeklyScreenFilters', 'statusFilter'],
          state
        ),
        firstNameFilter: R.pathOr(
          -1,
          ['weeklyScreenFilters', 'firstNameFilter'],
          state
        ),
        lastNameFilter: R.pathOr(
          -1,
          ['weeklyScreenFilters', 'lastNameFilter'],
          state
        )
      };
    });

  const [open, setOpen] = React.useState(false);
  const [pdfOptions, setPdfOptions] = React.useState({
    accpacImport: true,
    weeklySummary: true,
    receipts: true,
    summaryModeImages: true
  });

  const filteredUserDataArray = useMemo(
    () => filterUserDataIds(userData, departmentFilter, statusFilter,firstNameFilter,lastNameFilter),
    [userData, departmentFilter, statusFilter,firstNameFilter,lastNameFilter]
  );
  const { from, to } = useMemo(
    () => getWeekRange(selectedFirstDate),
    [selectedFirstDate]
  );
  const dataIsPresent = useMemo(
    () => isPresent(filteredUserDataArray),
    [filteredUserDataArray]
  );
  const { accpacImport, weeklySummary, receipts, summaryModeImages } =
    pdfOptions;
  const isError = !(
    accpacImport ||
    weeklySummary ||
    receipts ||
    summaryModeImages
  );

  const toggleOpen = useCallback(() => {
    setOpen((openLocal: boolean) => !openLocal);
  }, []);

  const handleSubmit = useCallback(() => {
    toggleOpen();

    if (dataIsPresent) {
      dispatch(
        exportWeeklyReportRequest(from, to, filteredUserDataArray, {
          accpacImport,
          weeklySummary,
          receipts,
          summaryModeImages
        })
      );
    }
  }, [
    dispatch,
    toggleOpen,
    from,
    to,
    filteredUserDataArray,
    accpacImport,
    weeklySummary,
    receipts,
    summaryModeImages,
    dataIsPresent
  ]);

  const actions = useMemo(() => {
    return [
      {
        icon: CloudDownloadIcon,
        name: 'Export',
        disabled: !dataIsPresent,
        disabledMsg: 'Please review the weekly data to export!',
        onClick: toggleOpen
      }
    ];
  }, [dataIsPresent, toggleOpen]);

  return (
    <div className="cloud-download-button-container">
      <CustomSpeedDial actions={actions} />

      <ConfirmationDialogRaw
        classes={{
          paper: classes.paper
        }}
        id="pdf-download-dialog"
        keepMounted
        open={open}
        dialogueTitle="Choose PDFs to export"
        submitButtonText="Export"
        isSubmitDisabled={isError}
        dialogContent={
          <PdfOptionsSelector
            pdfOptions={pdfOptions}
            setPdfOptions={setPdfOptions}
          />
        }
        handleCancel={toggleOpen}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default CloudDownloadButton;
