import React, { useMemo } from 'react';
import AddIcon from '@material-ui/icons/AddOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveIcon from '@material-ui/icons/UnarchiveOutlined';
import { Box } from '@material-ui/core';
import Breadcrumbs from '../shared/Breadcrumbs';
import Status from '../shared/Status';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import {
  ADD_CLIENT_USERS_ROUTE,
  CLIENT_USERS_ROUTE
} from '../../utils/routesNavigationConstants';

import './style.scss';

interface ClientUsersSecondaryHeaderProps {
  currentUserName: string;
  isActive: boolean;
  isAddUserRouteMatch: boolean;
  isUserDetailsRouteMatch: boolean;
  isSubmitDisabled: boolean;
  saveButtonHandler: Function;
  goto: Function;
  toggleUserStatusHandler: Function;
}

const ClientUsersSecondaryHeader = ({
  currentUserName,
  isActive,
  isAddUserRouteMatch,
  isUserDetailsRouteMatch,
  isSubmitDisabled,
  saveButtonHandler,
  goto,
  toggleUserStatusHandler
}: ClientUsersSecondaryHeaderProps) => {
  const breadcrumbDataArray = useMemo(() => {
    const breadcrumbDataArrayLocal = [
      {
        id: 'users_breadcrum',
        label: 'Client Users',
        onClick: () => goto(CLIENT_USERS_ROUTE)
      }
    ];

    if (isAddUserRouteMatch) {
      breadcrumbDataArrayLocal.push({
        id: 'add_users_breadcrum_name',
        label: 'Add Client',
        onClick: () => {}
      });
    } else if (isUserDetailsRouteMatch) {
      breadcrumbDataArrayLocal.push({
        id: 'user_breadcrum_name',
        label: currentUserName,
        onClick: () => {}
      });
    }

    return breadcrumbDataArrayLocal;
  }, [goto, isAddUserRouteMatch, currentUserName, isUserDetailsRouteMatch]);

  const actions = useMemo(() => {
    if (isAddUserRouteMatch || isUserDetailsRouteMatch) {
      const localAction: any = [
        {
          icon: SaveIcon,
          name: 'Save',
          onClick: saveButtonHandler,
          disabled: isSubmitDisabled,
          showToast: false
        }
      ];

      if (isUserDetailsRouteMatch) {
        localAction.push({
          icon: isActive ? ArchiveIcon : UnarchiveIcon,
          name: isActive ? 'Archive' : 'Unarchive',
          onClick: toggleUserStatusHandler
        });
      }

      return localAction;
    }

    return [
      {
        icon: AddIcon,
        name: 'Add New',
        onClick: () => goto(ADD_CLIENT_USERS_ROUTE)
      }
    ];
  }, [
    goto,
    toggleUserStatusHandler,
    saveButtonHandler,
    isActive,
    isAddUserRouteMatch,
    isUserDetailsRouteMatch,
    isSubmitDisabled
  ]);

  return (
    <React.Fragment>
      <div className="breadcrumbs-container-users">
        <Box mr={5}>
          <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
        </Box>

        {isUserDetailsRouteMatch && (
          <Box>
            <Status status={isActive ? 'ACTIVE' : 'ARCHIVED'} />
          </Box>
        )}
      </div>

      <div className="users-actions-buttons-container">
        <CustomSpeedDial actions={actions} />
      </div>
    </React.Fragment>
  );
};

export default ClientUsersSecondaryHeader;
