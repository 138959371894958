import React from 'react';
import SharedTableHeader from '../shared/SharedTableHeader';
import { columns } from './helper';

type Order = 'asc' | 'desc';
interface TableHeaderProps {
  order: Order;
  orderBy: string;
  handleRequestSort: Function;
}

const TableHeader = ({
  order,
  orderBy,
  handleRequestSort
}: TableHeaderProps) => {
  return (
    <SharedTableHeader
      order={order}
      orderBy={orderBy}
      columns={columns}
      handleRequestSort={handleRequestSort}
    />
  );
};

export default TableHeader;
