export const AIRCRAFT_TYPE = 'aircraftType';
export const TYPE_DESIGNATOR = 'typeDesignator';
export const FUEL_MEASURE = 'fuelMeasure';
export const CUSTOMER_AIRCRAFT = 'customerAircraft';
export const STATUS = 'status';

export const initialValues = {
  [AIRCRAFT_TYPE]: -1,
  [TYPE_DESIGNATOR]: -1,
  [FUEL_MEASURE]: 'all',
  [CUSTOMER_AIRCRAFT]: 'all',
  [STATUS]: 'all'
};

export const filtersNotWorkingOptions = [
  { name: 'All', id: -1 },
  { name: 'Filter not working', id: -2 }
];
