import React from 'react';

interface AircraftDetailsHeaderProps {
  isAircraftDetailsRouteMatch: boolean;
  isAddAircraftRouteMatch: boolean;
  currentAircraftId: number | null;
  goto: Function;
}

const AircraftDetailsHeader = ({
  isAircraftDetailsRouteMatch,
  isAddAircraftRouteMatch,
  currentAircraftId,
  goto
}: AircraftDetailsHeaderProps) => {
  const routeChange = (route: string) => {
    if (!isAddAircraftRouteMatch) {
      if (!isAircraftDetailsRouteMatch && route === 'details') {
        goto(`/aircraft/details/${currentAircraftId}`);
      }
    }
  };

  return (
    <div className="aircraft-details-table-header">
      <div
        className={`aircraft-details-table-header-options ${
          isAircraftDetailsRouteMatch || isAddAircraftRouteMatch
            ? 'aircraft-details-table-header-options-selected'
            : ''
        }`}
        onClick={() => routeChange('details')}>
        DETAILS
      </div>
      {
        !isAddAircraftRouteMatch && null
        // <div
        //   className={`aircraft-details-table-header-options ${
        //     isFlightReportsRouteMatch
        //       ? 'aircraft-details-table-header-options-selected'
        //       : ''
        //   }`}
        //   onClick={() => routeChange('flightReports')}>
        //   FLIGHT REPORT
        // </div>
      }
    </div>
  );
};

export default AircraftDetailsHeader;
