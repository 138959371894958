import types from '../utils/actionTypes';

export const showSpinner = () => {
  return {
    type: types.SHOW_SPINNER
  };
};

export const hideSpinner = () => {
  return {
    type: types.HIDE_SPINNER
  };
};
