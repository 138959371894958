import { FuelExpenseScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: FuelExpenseScreenDataProps = {
  isLoading: true,
  fuelExpenseData: []
};

const fuelExpenseScreenData = (
  state: FuelExpenseScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_FUEL_EXPENSE_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_FUEL_EXPENSE_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        fuelExpenseData: action.payload.fuelExpenseScreenData,
        isLoading: false
      };

    case types.FETCH_FUEL_EXPENSE_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

export default fuelExpenseScreenData;
