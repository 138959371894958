import React from 'react';
import TextField from '@material-ui/core/TextField';

import './style.scss';

interface FillAllAccountingDetails {
  changeFillAllValuesHandler: (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => void;
  invoiceNumberText: string;
  batchNumberText: string;
}

const FillAllAccountingDetails = ({
  changeFillAllValuesHandler,
  invoiceNumberText,
  batchNumberText
}: FillAllAccountingDetails) => {
  return (
    <div className="fill-all-fields-input">
      <TextField
        name="invoiceNumbers"
        id="filled-invoice-numbers"
        className="fill-invoice-numbers"
        label="Fill Invoice Numbers"
        variant="outlined"
        size="small"
        onChange={changeFillAllValuesHandler}
        value={invoiceNumberText}
      />

      <TextField
        name="batchNumbers"
        id="filled-batch-numbers"
        className="fill-batch-numbers"
        label="Fill Batch Numbers"
        variant="outlined"
        size="small"
        onChange={changeFillAllValuesHandler}
        value={batchNumberText}
      />
    </div>
  );
};

export default FillAllAccountingDetails;
