import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import { resetLogBookScreenData } from '../../redux/actions/logBookScreenData';
import { useDispatch } from 'react-redux';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import './style.scss';

function LogBookSecondaryHeader({handleSaveDataClick}:any) {
const history = useHistory();
const dispatch = useDispatch();
const actions = useMemo(() => {
  return [
    {
      icon: CloudDownloadIcon,
      name: 'Download',
      disabled: false,
      onClick: ()=> handleSaveDataClick(),
      showToast: false
    }
  ];
}, [handleSaveDataClick]);

  return (
    <React.Fragment>
      <Box mr={5}>
        <Breadcrumbs
          breadcrumbDataArray={[
            {
              id: 'route_name',
              label: 'Log Book',
              onClick: () => {history.push('/logbook');dispatch(resetLogBookScreenData())}
            },
            {
              id: 'route_name1',
              label: 'Details',
            }
          ]}
        />
      </Box>
      <div className="invoice-screen-dropdown-container"></div>
      <CustomSpeedDial actions={actions} />
    </React.Fragment>
  );
}

export default LogBookSecondaryHeader;
