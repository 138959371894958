import React,{useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ImagesViewer from '../ImagesViewer';
import DetailsForm from './DetailsForm';
import { ReceiptsArrayData } from '../../routes/Details/helper';

import './style.scss';

interface ReceiptDetails {
  currentReceiptData: ReceiptsArrayData;
}

const ReceiptDetails = ({ currentReceiptData }: ReceiptDetails) => {
  const [currentActiveImage, setCurrentActiveImage] = useState(0);
  return (
    <Paper>
      <Grid container>
        <Grid item xs={9}>
          <Paper square className="image-slider-container">
            <ImagesViewer imagesData={currentReceiptData.receiptUri} currentActiveImage={currentActiveImage} setCurrentActiveImage={setCurrentActiveImage} />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper square className="receipt-fields-container">
            <DetailsForm currentReceiptData={currentReceiptData} />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReceiptDetails;
