import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  CUSTOMER_FILTERS,
  filtersNotWorkingOptions
} from '../../routes/Bookings/helper';
import { BOOKINGS_FILTERS_FORM } from '../../utils/reduxFormConstants';

export const customerFilterStatePath = [
  'form',
  BOOKINGS_FILTERS_FORM,
  'values',
  CUSTOMER_FILTERS
];

export const CustomerFilterComponent = () => {
  const { customerFiltersArray } = useSelector((state: StoreState) => ({
    customerFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['bookingsScreenFilters', 'customerFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={CUSTOMER_FILTERS}
      component={ReduxFormAutocomplete}
      label={'Customer'}
      optionsList={customerFiltersArray}
      required
    />
  );
};
