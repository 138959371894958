import { put, takeLatest, delay, call } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import {
  refreshTokensSuccess,
  refreshTokensFailure
} from '../actions/refreshTokens';
import { userLogOutRequest } from '../actions/userAuth';
import {
  LocalStorageTokensProps,
  setLocalStorageTokens
} from '../../services/utils/helper';

interface NewTokensResponse {
  data: LocalStorageTokensProps;
  success: boolean;
}

function* refreshTokens() {
  yield delay(1000);

  try {
    const newTokens: NewTokensResponse = yield call(api.fetchNewTokens);

    if (newTokens.success) {
      setLocalStorageTokens({
        accessToken: newTokens.data.accessToken,
        refreshToken: newTokens.data.refreshToken
      });

      yield put(refreshTokensSuccess());

      window.location.reload();
    } else {
      throw new Error('Unable to fetch refresh token');
    }
  } catch (e) {
    yield put(refreshTokensFailure());

    yield put(userLogOutRequest());
  }
}

export default [takeLatest(types.REFRESH_TOKENS_REQUEST, refreshTokens)];
