import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography
} from '@material-ui/core';
import { useStyles, PassengersRow, columns } from './helper';
import { isPresent } from '../../utils/helper';
import addUser from '../../assets/add_user/outline.svg';
import { useSortHook } from '../../hooks/sortHooks';
import SharedTableHeader from '../shared/SharedTableHeader';

import './style.scss';

interface PassengersTableProps {
  passengers: PassengersRow[];
  addPassengerHandler: Function;
  editPassengerHandler: Function;
}

const PassengersTable = ({
  passengers,
  addPassengerHandler,
  editPassengerHandler
}: PassengersTableProps) => {
  const classes = useStyles();

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    columnsData: columns,
    dataArray: passengers
  });

  return (
    <div className="customers-details-passengers-container">
      <div className="customers-details-passengers-title-container">
        <Typography variant="h6">Passengers</Typography>
        <img
          src={addUser}
          alt="add_passengers"
          onClick={() => addPassengerHandler()}
        />
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <SharedTableHeader
            order={order}
            orderBy={orderBy}
            columns={columns}
            secondaryVariant
            handleRequestSort={handleRequestSort}
          />

          <TableBody>
            {isPresent(sortedDataArray) &&
              sortedDataArray.map((row: PassengersRow, index: number) => {
                return (
                  <TableRow
                    key={index}
                    className="customers-details-passengers-custom-body-row"
                    onClick={() => editPassengerHandler(row)}>
                    <TableCell component="th" scope="row">
                      {`${row.firstName} ${row.lastName}`}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PassengersTable;
