import moment from 'moment';
import * as R from 'ramda';
import {
  getMonthStartToCurrentDateRange,
  isNilOrEmpty,
  isPresent
} from '../../utils/helper';

export interface RangeProps {
  to: Date;
  from: Date;
}

export interface EquipmentsTypes {
  equipment: {};
}

export interface PassengersTypes {
  customerPax: {};
}

export const RANGE = 'range';
export const CUSTOMER_FILTERS = 'customerFilters';

export const initialValues = {
  range: getMonthStartToCurrentDateRange(),
  [CUSTOMER_FILTERS]: -1
};

export const filtersNotWorkingOptions = [
  { label: 'All', value: -1 },
  { label: 'Filter not working', value: -2 }
];

export const statusFilters = [
  { id: 2, name: 'Ready To Bill' },
  { id: 3, name: 'In Progress' }
];

export const transformBaseList = (bases: {}[]) => {
  return bases.map((base: any) => {
    return {
      id: base.id,
      name: base.baseName
    };
  });
};

export const transformClientList = (clients: {}[], customer: number) => {
  var filteredUsers: {}[] = [];
  clients.map((client: any) => {
    const isCustomerUser = client.clientIds.includes(customer);
    if (isCustomerUser && customer !== -1) 
      filteredUsers.push({
        id: client.id,
        name: client.name
      });
  });
  return filteredUsers;
};

export const transformRequestingPerson = (requestingPersons: {}[]) => {
  return requestingPersons.map((requestingPerson: any) => {
    return {
      id: requestingPerson.id,
      name: `${requestingPerson.firstName} ${requestingPerson.lastName}`
    };
  });
};

export const transformPassengersList = (passengers: {}[]) => {
  return passengers.map((passenger: any) => {
    return {
      id: passenger.id,
      name: `${passenger.firstName} ${passenger.lastName}`
    };
  });
};

export const getCustomerFormInitialValues = (currentBookingData: {}) => {
  const equipments = R.pathOr(
    [],
    ['bookingEquipments'],
    currentBookingData
  ).map(({ equipment }: EquipmentsTypes) => {
    return {
      id: R.pathOr(-1, ['id'], equipment),
      name: R.pathOr('', ['name'], equipment)
    };
  });
  const passengers = R.pathOr(
    [],
    ['bookingPassengers'],
    currentBookingData
  ).map(({ customerPax }: PassengersTypes) => {
    return {
      id: R.pathOr(-1, ['id'], customerPax),
      name: `${R.pathOr('', ['firstName'], customerPax)} ${R.pathOr(
        '',
        ['lastName'],
        customerPax
      )}`
    };
  });

  const base = R.pathOr(-1, ['base', 'id'], currentBookingData);
  const contract = R.pathOr('', ['contract'], currentBookingData);
  const costCenter = R.pathOr('', ['costCenter'], currentBookingData);
  const customer = R.pathOr(-1, ['customer', 'id'], currentBookingData);
  const department = R.pathOr('', ['department'], currentBookingData);
  const endBooking = R.pathOr('', ['endBooking'], currentBookingData);
  const endLocation = R.pathOr('', ['endLocation'], currentBookingData);
  const isDefault = R.pathOr(false, ['isDefault'], currentBookingData);
  const bookingNumber = R.pathOr(-1, ['bookingNumber'], currentBookingData);
  const meetingDateTime = R.pathOr('', ['meetingDateTime'], currentBookingData);
  const meetingLocation = R.pathOr('', ['meetingLocation'], currentBookingData);
  const name = R.pathOr('', ['name'], currentBookingData);
  const notes = R.pathOr('', ['notes'], currentBookingData);
  const orderNumber = R.pathOr('', ['orderNumber'], currentBookingData);
  const requestingPerson = R.pathOr(
    null,
    ['requestingPerson', 'id'],
    currentBookingData
  );
  const aircraft = R.pathOr(
    -1,
    ['aircraftBooking', 0, 'aircraft', 'id'],
    currentBookingData
  );
  const returnDateTime = R.pathOr('', ['returnDateTime'], currentBookingData);
  const returnLocation = R.pathOr('', ['returnLocation'], currentBookingData);
  const startBooking = R.pathOr('', ['startBooking'], currentBookingData);
  const startLocation = R.pathOr('', ['startLocation'], currentBookingData);

  const pilot = R.pathOr([], ['pilot'], currentBookingData).map(
    ({ user }: any) => {
      return {
        id: R.pathOr(-1, ['id'], user),
        name: `${R.pathOr('', ['firstName'], user)} ${R.pathOr(
          '',
          ['lastName'],
          user
        )}`
      };
    }
  );
  const approvingClient = R.pathOr(
    [],
    ['approvingClient'],
    currentBookingData
  ).map(({ user }: any) => {
    return {
      id: R.pathOr(-1, ['id'], user),
      name: `${R.pathOr('', ['firstName'], user)} ${R.pathOr(
        '',
        ['lastName'],
        user
      )}`
    };
  });

  const coPilot = R.pathOr([], ['coPilot'], currentBookingData).map(
    ({ user }: any) => {
      return {
        id: R.pathOr(-1, ['id'], user),
        name: `${R.pathOr('', ['firstName'], user)} ${R.pathOr(
          '',
          ['lastName'],
          user
        )}`
      };
    }
  );

  const ame = R.pathOr([], ['ame'], currentBookingData).map(({ user }: any) => {
    return {
      id: R.pathOr(-1, ['id'], user),
      name: `${R.pathOr('', ['firstName'], user)} ${R.pathOr(
        '',
        ['lastName'],
        user
      )}`
    };
  });

  const apprentice = R.pathOr([], ['apprentice'], currentBookingData).map(
    ({ user }: any) => {
      return {
        id: R.pathOr(-1, ['id'], user),
        name: `${R.pathOr('', ['firstName'], user)} ${R.pathOr(
          '',
          ['lastName'],
          user
        )}`
      };
    }
  );

  let modeledData = {
    base,
    contract,
    costCenter,
    customer,
    department,
    endBooking,
    endLocation,
    isDefault,
    bookingNumber,
    meetingDateTime,
    meetingLocation,
    name,
    notes,
    orderNumber,
    requestingPerson,
    returnDateTime,
    returnLocation,
    startBooking,
    startLocation,
    equipments,
    passengers,
    pilot,
    approvingClient,
    coPilot,
    ame,
    apprentice,
    aircraft
  };

  return modeledData;
};

export const modelSaveBookingData = (formValuesBookingsDetails: {}) => {
  const bookingPassengers = R.pathOr(
    [],
    ['passengers'],
    formValuesBookingsDetails
  ).map((passenger: {}) => {
    return {
      customerPax: { id: R.pathOr(-1, ['id'], passenger) }
    };
  });

  const bookingEquipments = R.pathOr(
    [],
    ['equipments'],
    formValuesBookingsDetails
  ).map((equipment: {}) => {
    return {
      equipment: { id: R.pathOr(-1, ['id'], equipment) }
    };
  });

  const customer = {
    id: R.pathOr(-1, ['customer'], formValuesBookingsDetails)
  };
  const requestingPerson = {
    id: R.pathOr(-1, ['requestingPerson'], formValuesBookingsDetails)
  };
  const base = { id: R.pathOr(-1, ['base'], formValuesBookingsDetails) };
  const aircraft = {
    id: R.pathOr(-1, ['aircraft'], formValuesBookingsDetails)
  };

  const pilot = R.pathOr([], ['pilot'], formValuesBookingsDetails).map(
    (user: {}) => {
      return {
        user: { id: R.pathOr(-1, ['id'], user) },
        userBookingType: 'pilot'
      };
    }
  );
  const approvingClient = R.pathOr(
    [],
    ['approvingClient'],
    formValuesBookingsDetails
  ).map((user: {}) => {
    return {
      user: { id: R.pathOr(-1, ['id'], user) },
      userBookingType: 'approvingClient'
    };
  });

  const coPilot = R.pathOr([], ['coPilot'], formValuesBookingsDetails).map(
    (user: {}) => {
      return {
        user: { id: R.pathOr(-1, ['id'], user) },
        isCopilot: true,
        userBookingType: 'coPilot'
      };
    }
  );
  const apprentice = R.pathOr(
    [],
    ['apprentice'],
    formValuesBookingsDetails
  ).map((user: {}) => {
    return {
      user: { id: R.pathOr(-1, ['id'], user) },
      userBookingType: 'apprentice'
    };
  });
  const ame = R.pathOr([], ['ame'], formValuesBookingsDetails).map(
    (user: {}) => {
      return {
        user: { id: R.pathOr(-1, ['id'], user) },
        userBookingType: 'ame'
      };
    }
  );

  const userBooking: any = [
    ...pilot,
    ...coPilot,
    ...apprentice,
    ...ame,
    ...approvingClient
  ];
  const aircraftBooking: any = [];

  if (aircraft.id !== -1) {
    aircraftBooking.push({
      aircraft
    });
  }

  let modeledData = {
    ...formValuesBookingsDetails,
    bookingNumber: Number(
      R.pathOr(-1, ['bookingNumber'], formValuesBookingsDetails)
    ),
    customer,
    base,
    bookingEquipments,
    aircraftBooking,
    userBooking
  };

  if (isPresent(bookingPassengers)) {
    modeledData = R.assoc('bookingPassengers', bookingPassengers, modeledData);
  }

  if (!R.equals(requestingPerson.id, -1)) {
    modeledData = R.assoc('requestingPerson', requestingPerson, modeledData);
  }

  modeledData = R.omit(
    [
      'equipments',
      'passengers',
      'aircraft',
      'ame',
      'apprentice',
      'coPilot',
      'pilot',
      'approvingClient'
    ],
    modeledData
  );

  return modeledData;
};

export const modelEditData = (modeledData: {}, currentBookingId: number) => {
  let newModeledData: any = R.assoc('id', currentBookingId, modeledData);

  let bookingEquipments = R.pathOr([], ['bookingEquipments'], modeledData);
  let bookingPassengers = R.pathOr([], ['bookingPassengers'], modeledData);
  newModeledData = R.omit(
    ['bookingEquipments', 'bookingPassengers'],
    newModeledData
  );

  newModeledData = R.assoc(
    'bookingPassengers',
    bookingPassengers.map((currentPass: PassengersTypes) => {
      return {
        ...currentPass,
        booking: { id: currentBookingId }
      };
    }),
    newModeledData
  );

  newModeledData = R.assoc(
    'bookingEquipments',
    bookingEquipments.map((currentEquip: EquipmentsTypes) => {
      return {
        ...currentEquip,
        booking: { id: currentBookingId }
      };
    }),
    newModeledData
  );

  return newModeledData;
};

export const isBookingDateTimeValidHandler = (
  startBooking: string,
  meetingDateTime: string,
  returnDateTime: string,
  endBooking: string
) => {
  if (
    isNilOrEmpty(startBooking) ||
    isNilOrEmpty(meetingDateTime) ||
    isNilOrEmpty(returnDateTime) ||
    isNilOrEmpty(endBooking)
  ) {
    return { isValid: false, message: "Date/Time Fields Can't be empty" };
  }

  if (moment(startBooking).isAfter(endBooking)) {
    return {
      isValid: false,
      message: 'Start Date/Time should be less than End Date/Time'
    };
  }

  if (!moment(meetingDateTime).isBetween(startBooking, endBooking)) {
    return {
      isValid: false,
      message:
        'Meeting Date/Time should lie between Start Date/Time & End Date/Time'
    };
  }

  if (!moment(returnDateTime).isBetween(startBooking, endBooking)) {
    return {
      isValid: false,
      message:
        'Return Date/Time should lie between Start Date/Time & End Date/Time'
    };
  }

  return { isValid: true, message: '' };
};
