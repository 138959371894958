import React, { useEffect, useState } from 'react';
import { reduxForm, initialize } from 'redux-form';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, TextField } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import FuelExpenseScreenSecondaryHeader from '../../components/FuelExpenseScreenSecondaryHeader';
import FuelExpenseScreenTable from '../../components/FuelExpenseScreenTable';
import { FUEL_EXPENSE_NAV_KEY } from '../../utils/routesNavigationConstants';
import { updateFuelExpenseScreenDataRequest } from '../../redux/actions/fuelExpenseScreenData';
import { fetchFuelExpenseScreenAircraftFiltersRequest } from '../../redux/actions/fuelExpenseScreenFilters';
import { FUEL_EXPENSE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { initialValues } from './helper';

import './styles.scss';

const FuelExpense = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(FUEL_EXPENSE_FILTERS_FORM, initialValues));
    dispatch(fetchFuelExpenseScreenAircraftFiltersRequest());
  }, [dispatch]);

  const [invoiceNumberValues, setInvoiceNumberValues] = useState({});

  const getNewNumberValues = (value: string, numberValues: {}) => {
    const numberKeys = R.keys(numberValues);
    let newNumbers = {};

    numberKeys.forEach((key: string) => {
      newNumbers[`${key}`] = value;
    });

    return newNumbers;
  };

  const changeFillAllValuesHandler = (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    const { name, value } = event.target;

    if (name === 'invoiceNumbers') {
      setInvoiceNumberValues(getNewNumberValues(value, invoiceNumberValues));
    }
  };

  const handleSaveDataClick = () => {
    const invoiceNumberKeys = R.keys(invoiceNumberValues);

    const mappedArray = invoiceNumberKeys.map((key: string) => {
      const id = key.slice(key.indexOf('_') + 1, key.length);

      return {
        id: id,
        vendorInvoiceNumber: invoiceNumberValues[`invoiceNumber_${id}`]
      };
    });

    dispatch(updateFuelExpenseScreenDataRequest(mappedArray));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Fuel Expense</title>
      </Helmet>
      <HeaderContainer
        secondaryHeader={
          <FuelExpenseScreenSecondaryHeader
            handleSaveDataClick={handleSaveDataClick}
          />
        }
        navKey={FUEL_EXPENSE_NAV_KEY}>
        <Box py={2.5}>
          <div className="invoice-number-container">
            <TextField
              name="invoiceNumbers"
              id="filled-invoice-numbers"
              className="fill-invoice-numbers"
              label="Fill Vendor Invoice Numbers"
              variant="outlined"
              size="small"
              onChange={changeFillAllValuesHandler}
            />
          </div>

          <Box py={2.5}>
            <FuelExpenseScreenTable
              invoiceNumberValues={invoiceNumberValues}
              setInvoiceNumberValues={setInvoiceNumberValues}
            />
          </Box>
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: FUEL_EXPENSE_FILTERS_FORM
})(FuelExpense);
