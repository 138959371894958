import { useState, useCallback, useMemo } from 'react';
import { sortArrayBasedOnColumn } from '../utils/helper';

type Order = 'asc' | 'desc';

interface SortHookProps {
  dataArray: any[];
  columnsData: any[];
  initialOrder?: Order;
  initialSortById?: string;
}

export const useSortHook = ({
  dataArray = [],
  columnsData = [],
  initialOrder = 'asc',
  initialSortById = 'firstName'
}: SortHookProps) => {
  const [order, setOrder] = useState<Order>(initialOrder);
  const [orderBy, setOrderBy] = useState(initialSortById);

  const sortedDataArray = useMemo(
    () => sortArrayBasedOnColumn(dataArray, order, orderBy, columnsData),
    [dataArray, order, orderBy, columnsData]
  );

  const handleRequestSort = useCallback(
    (order: Order, orderBy: string) => {
      setOrder(order);
      setOrderBy(orderBy);
    },
    [setOrder, setOrderBy]
  );

  return {
    order,
    orderBy,
    sortedDataArray,
    handleRequestSort
  };
};
