import React, { useMemo, useCallback } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import PdfRender from '../../components/PdfRender';
import PdfRenderPage from './PdfRender';
import './styles.scss';
import ForwardBackwardNav from '../../components/shared/ForwardBackwardNav';
import Rotate90DegreesCcwIcon from '@material-ui/icons/RotateLeft';
import Rotate90DegreesCcwIcon2 from '@material-ui/icons/RotateRight';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
// import {useParams} from 'react-router-dom';
interface PdfViewerProps {
  pdfUrls: string[];
  flightReportIds: string[];
  currentSelectedPdfIndex: string;
  setCurrentSelectedPdf: Function;
}

const PdfViewer = ({
  pdfUrls,
  flightReportIds,
  currentSelectedPdfIndex,
  setCurrentSelectedPdf
}: PdfViewerProps) => {
  const onlyWidth = useWindowWidth();
  const [rotationDegree, setRotatioDegree] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  // const {reportId}:any = useParams();
  // const [currentSelectedPdfIndex, setCurrentSelectedPdf] = useState(flightReportIds.indexOf(reportId));
  const currentSelectedPdf = pdfUrls[0];
  const handlePageChange = useCallback(
    (changeIndex: number) =>
      setCurrentSelectedPdf(flightReportIds[changeIndex]),
    [setCurrentSelectedPdf]
  );
  const [activePageIndex, setActivePageIndex] = React.useState(0);
  const mainPdfScale = useMemo(() => {
    const currentWidth = onlyWidth;
    const startingWidth = 1600;
    const buffer = 200;
    const increaseRatio = 0.1;

    let ratio = 1;

    if (currentWidth < startingWidth) {
      return ratio;
    }

    for (let i = 1; i <= 20; i++) {
      if (currentWidth < startingWidth + i * buffer) {
        ratio = 1 + i * increaseRatio;
        break;
      }
    }

    return Number(ratio.toFixed(1));
    // return 1.0;
  }, [onlyWidth]);
  const [pdfScale, setPdfScale] = React.useState(mainPdfScale);

  const getCurrentPdfIndex = useMemo(() => {
    if (flightReportIds.indexOf(currentSelectedPdfIndex) !== -1) {
      return flightReportIds.indexOf(currentSelectedPdfIndex);
    }
    setCurrentSelectedPdf(flightReportIds[0]);
    return 0;
  }, [currentSelectedPdfIndex, flightReportIds, setCurrentSelectedPdf]);
  const handelActivePageIndex = useCallback((index: number) => {
    setActivePageIndex(index);
    setRotatioDegree(0);
  }, []);
  return (
    <>
      <div style={{ marginBottom: '10px', marginTop: '1px' }}>
        <ForwardBackwardNav
          currentIndex={getCurrentPdfIndex}
          total={flightReportIds.length}
          handlePageChange={handlePageChange}
          // customLabel={
          //   workHourDetails &&
          //   moment(workHourDetails.date).format('dddd MMM DD, YYYY')
          // }
        />
      </div>
      <div className="pdf-viewer-container">
        <div className="pdf-options-container">
          {/* {pdfUrls.map((pdfUrl: string, index: number) => {
          return (
            <div
              key={index}
              className={`pdf-option-wrapper ${
                currentSelectedPdfIndex === pdfUrl
                  ? 'pdf-option-wrapper-active'
                  : ''
              }`}
              onClick={() => setCurrentSelectedPdf(pdfUrl)}>
              <PdfRender pdf={pdfUrl} onlyFirstPage scale={0.2} />
            </div>
          );
        })} */}
          <div>
            <PdfRender
              pdf={currentSelectedPdf}
              scale={0.2}
              activePageIndex={activePageIndex}
              setActivePageIndex={handelActivePageIndex}
            />
          </div>
        </div>
        <div className="current-selected-pdf-container">
          <PdfRenderPage
            pdf={currentSelectedPdf}
            scale={pdfScale}
            rotationDegree={rotationDegree}
            activePageIndex={activePageIndex}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        </div>
        <div className="rotate-image-option">
          <h2 className="page-count">
            {activePageIndex + 1}/{totalPages}
          </h2>
          <Rotate90DegreesCcwIcon
            fontSize="large"
            style={{ color: 'gray', fontSize: '50px', marginRight: '10px' }}
            onClick={() => setRotatioDegree(rotationDegree - 90)}
          />
          <Rotate90DegreesCcwIcon2
            fontSize="large"
            style={{ color: 'gray', fontSize: '50px' }}
            onClick={() => setRotatioDegree(rotationDegree + 90)}
          />
          <div style={{ textAlign: 'center' }}>
            <ZoomIn
              fontSize="large"
              style={{ color: 'gray', marginRight: '5px' }}
              onClick={() => setPdfScale(pdfScale + 0.2)}
            />
            <ZoomOut
              fontSize="large"
              style={{ color: 'gray' }}
              onClick={() => setPdfScale(pdfScale - 0.2)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
