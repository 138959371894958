import {
  EngineerJobReviewScreenDataProps,
  ActionType
} from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: EngineerJobReviewScreenDataProps = {
  isLoading: true,
  engineerJobReviewData: []
};

const engineerJobReviewScreenData = (
  state: EngineerJobReviewScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_ENGINEER_JOB_REVIEW_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_ENGINEER_JOB_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        engineerJobReviewData: action.payload.engineerJobReviewData,
        isLoading: false
      };

    case types.FETCH_ENGINEER_JOB_REVIEW_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

export default engineerJobReviewScreenData;
