import { put, takeLatest, delay } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchEngineerJobReviewDataSuccess,
  fetchEngineerJobReviewDataFailure
} from '../actions/engineerJobReviewScreenData';
import {
  setEngineerJobReviewEmployeeFilters,
  setEngineerJobReviewWorkDoneFilters,
  setEngineerJobReviewWorkReportFilters
} from '../../redux/actions/engineerJobReviewScreenFilters';
import { isPresent, getDateString } from '../../utils/helper';

interface FetchEngineerJobReviewScreenDataActionType {
  type: String;
  payload: { startDate: Date; endDate: Date };
}

function* fetchEngineerJobReviewData(
  action: FetchEngineerJobReviewScreenDataActionType
) {
  yield delay(1000);

  const { startDate, endDate } = action.payload;

  const startDateString = getDateString(new Date(startDate));
  const endDateString = getDateString(new Date(endDate));

  try {
    const engineerJobReviewScreenDataResponse = yield api.fetchResponse(
      endpoints.ENGINEER_JOB_REVIEW_RANGE(startDateString, endDateString)
    );

    if (engineerJobReviewScreenDataResponse.success) {
      yield put(
        fetchEngineerJobReviewDataSuccess(
          engineerJobReviewScreenDataResponse.data.data
        )
      );

      const mapInIdName = (filterArray: []) => {
        const filteredArray = filterArray.filter((filter: string) =>
          isPresent(filter)
        );

        return filteredArray.map((filter: string) => {
          return {
            id: filter.toLowerCase(),
            name: filter
          };
        });
      };

      const {
        employees,
        workDone,
        workReport
      } = engineerJobReviewScreenDataResponse.data.metaData;

      yield put(setEngineerJobReviewEmployeeFilters(mapInIdName(employees)));
      yield put(setEngineerJobReviewWorkDoneFilters(mapInIdName(workDone)));
      yield put(setEngineerJobReviewWorkReportFilters(mapInIdName(workReport)));
    } else {
      yield put(fetchEngineerJobReviewDataFailure());
    }
  } catch (e) {
    yield put(fetchEngineerJobReviewDataFailure());
  }
}

export default [
  takeLatest(
    types.FETCH_ENGINEER_JOB_REVIEW_DATA_REQUEST,
    fetchEngineerJobReviewData
  )
];
