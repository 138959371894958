import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormControlLabel, Checkbox } from '@material-ui/core';

interface FieldProps {
  label: string;
  disabled: boolean;
}

const ReduxFormCheckbox = (field: WrappedFieldProps & FieldProps) => {
  return (
    <div className="redux-form-checkbox-container details-field-container">
      <FormControlLabel
        control={
          <Checkbox
            {...field.input}
            checked={field.input.value}
            disabled={field.disabled}
            color="primary"
          />
        }
        label={field.label}
      />
    </div>
  );
};

export default ReduxFormCheckbox;
