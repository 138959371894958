import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLogBookScreenDataRequest } from '../../redux/actions/logBookScreenData';
import * as R from 'ramda';
import { getMonthStartToCurrentDateRange } from '../../utils/helper';
import { getFormValues } from 'redux-form';
import { LOG_BOOK_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../../redux/utils/interfaces';
import SharedSpinner from '../shared/SharedSpinner';
import LogBookDetailsScreen from '../../routes/LogbookDetails';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography
  // TextField
} from '@material-ui/core';
import moment from 'moment';
import SharedTableHeader from '../shared/SharedTableHeader';
import {
  useStyles,
  columns,
  filterLogBookScreenData,
  LogBookRow
} from './helper';
import { useSortHook } from '../../hooks/sortHooks';
import { useHistory, useParams } from 'react-router-dom';

function LogBookScreenTable() {
  const dispatch = useDispatch();
  let history = useHistory();
  let { id }: any = useParams();
  const [currentIndex, setCurrentIndex] = useState(parseInt(id) || 0);

  const classes = useStyles();
  const { filterFormValues, logBookScreenData, isLogBookScreenDataLoading } =
    useSelector((state: StoreState) => {
      return {
        filterFormValues: getFormValues(LOG_BOOK_FILTERS_FORM)(state) || {},
        logBookScreenData: R.pathOr(
          [],
          ['logBookScreenData', 'logBookData'],
          state
        ),
        isLogBookScreenDataLoading: R.pathOr(
          false,
          ['logBookScreenData', 'isLoading'],
          state
        )
      };
    });
  const rangeValue = R.pathOr(
    getMonthStartToCurrentDateRange(),
    ['range'],
    filterFormValues
  );
  const aircraftFilters = R.pathOr(-1, ['aircraftFilters'], filterFormValues);
  const bookNumberFilters = R.pathOr(
    -1,
    ['bookNumberFilters'],
    filterFormValues
  );
  const pageNumberFilters = R.pathOr(
    -1,
    ['pageNumberFilters'],
    filterFormValues
  );
  const uploadedbyFilters = R.pathOr(
    -1,
    ['uploadedbyFilters'],
    filterFormValues
  );
  useEffect(() => {
    dispatch(fetchLogBookScreenDataRequest(rangeValue.from, rangeValue.to));
    // dispatch(fetchLogBookScreenAricraftRequest());
  }, [rangeValue, dispatch, id]);

  const filteredLogBookScreenData = useMemo(
    () =>
      filterLogBookScreenData(logBookScreenData, {
        aircraftFilters,
        bookNumberFilters,
        pageNumberFilters,
        uploadedbyFilters
      }),
    [
      logBookScreenData,
      aircraftFilters,
      bookNumberFilters,
      pageNumberFilters,
      uploadedbyFilters
    ]
  );
  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    dataArray: filteredLogBookScreenData,
    columnsData: columns,
    initialSortById: 'date'
  });
  const handelOnClickRow = (index: number) => {
    setCurrentIndex(index);
    history.push({
      pathname: `/logbook/details/${index}`,
      state: { currentIndex: index }
    });
  };
  if (isLogBookScreenDataLoading) {
    return <SharedSpinner />;
  }
  if (!id) {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <SharedTableHeader
            order={order}
            orderBy={orderBy}
            columns={columns}
            handleRequestSort={handleRequestSort}
          />

          <TableBody>
            {sortedDataArray?.length >0&& sortedDataArray.map((row: LogBookRow, index: number) => {
              // const currentLogbookName = `invoiceNumber_${row.id}`;

              return (
                <TableRow
                  key={index}
                  className="fuel-expense-custom-body-row"
                  onClick={() => handelOnClickRow(index)}>
                  <TableCell component="th" scope="row" align={'center'}>
                    {moment.utc(row.localDate).format('MMM D YYYY')}
                  </TableCell>
                  <TableCell component="th" scope="row" align={'center'}>
                    {row.aircraft.registration}
                  </TableCell>
                  <TableCell component="th" scope="row" align={'center'}>
                    {row.bookNumber}
                  </TableCell>
                  <TableCell component="th" scope="row" align={'center'}>
                    {row.pageNumber}
                  </TableCell>
                  <TableCell component="th" scope="row" align={'center'}>
                  {`${row.daily.user.firstName} ${row.daily.user.lastName}`}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    if (logBookScreenData.length > 0) {
      return (
        <LogBookDetailsScreen
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          logBookScreenData={logBookScreenData}
        />
      );
    } else {
      return (
        <Typography
          variant="h4"
          gutterBottom
          className="no-receipts-available-text">
          Go to logbookscreen or refresh the app.
        </Typography>
      );
    }
  }
}

export default LogBookScreenTable;
