import * as R from 'ramda';
// import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import api from '../../services/ApiModule';
import { getDateString } from '../../utils/helper';
import * as endpoints from '../../services/endpoints';
import * as messages from '../../utils/messagesConstants';

export const fetchAircraftFilters = async () => {
  try {
    const response: any = await api.fetchResponse(endpoints.AIRCRAFT);

    if (response.success) {
      const mappedData = response.data.map((data: {}) => {
        return {
          id: R.pathOr(0, ['id'], data),
          name: R.pathOr('', ['registration'], data)
        };
      });

      return {
        success: true,
        data: { aircraftFilters: mappedData }
      };
    } else {
      return {
        success: false
      };
    }
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};

export const fetchBookingFilters = async (startDate?: Date, endDate?: Date) => {
  try {
    const startDateString = getDateString(new Date(startDate || ''));
    const endDateString = getDateString(new Date(endDate || ''));

    const response: any = await api.fetchResponse(
      endpoints.WEB_BOOKING_RANGE(startDateString, endDateString)
    );

    if (response.success) {
      const mappedData = response.data.map((data: {}) => {
        const name = R.pathOr('', ['name'], data);
        const bookingNumber = R.pathOr('', ['bookingNumber'], data);

        return {
          id: R.pathOr(0, ['id'], data),
          name: `${bookingNumber} - ${name}`
        };
      });

      return {
        success: true,
        data: { bookingFilters: mappedData }
      };
    } else {
      return {
        success: false
      };
    }
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};

export const fetchCustomerFilters = async () => {
  try {
    const response: any = await api.fetchResponse(endpoints.CUSTOMERS);

    if (response.success) {
      const mappedData = response.data.map((data: {}) => {
        return {
          id: R.pathOr(0, ['id'], data),
          name: R.pathOr(0, ['name'], data)
        };
      });

      return {
        success: true,
        data: { customerFilters: mappedData }
      };
    } else {
      return {
        success: false
      };
    }
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};

export const fetchPilotFilters = async () => {
  try {
    const response: any = await api.fetchResponse(endpoints.PILOT);
    if (response.success) {
      const mappedData = response.data.map((data: {}) => {
        const firstName = R.pathOr(0, ['firstName'], data);
        const lastName = R.pathOr(0, ['lastName'], data);

        return {
          id: R.pathOr(0, ['id'], data),
          name: `${firstName} ${lastName}`
        };
      });

      return {
        success: true,
        data: { pilotFilters: mappedData }
      };
    } else {
      return {
        success: false
      };
    }
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};
export const fetchClientUsersFilters = async () => {
  try {
    const response: any = await api.fetchResponse(endpoints.GET_CLIENT_USERS);
    if (response.success) {
      const mappedData = response.data.map((data: {}) => {
        
        const firstName = R.pathOr("", ['firstName'], data);
        const lastName = R.pathOr("", ['lastName'], data);
        const isApprover = R.pathOr("", ['isApprover'], data);
        const isViewer = R.pathOr("", ['isViewer'], data);
        const clientUserId = R.pathOr(-1, ['user','id'], data);
        const clients = R.pathOr([], ['clientUser'], data);
        var clientIds: any[] = [];
        clients.map((client: any) => {
          clientIds.push(client.client.id);
        });
        return {
          id: R.pathOr(0, ['id'], data),
          name: `${firstName} ${lastName}`,
          isApprover:isApprover,
          isViewer: isViewer,
          clientUserId:clientUserId,
          clientIds:clientIds,
          //clientName:client.name,
        };
      });

      return {
        success: true,
        data: { clientFilters: mappedData }
      };
    } else {
      return {
        success: false
      };
    }
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};

export const fetchAmeFilters = async () => {
  try {
    const response: any = await api.fetchResponse(endpoints.AME);

    if (response.success) {
      const mappedData = response.data.map((data: {}) => {
        const firstName = R.pathOr(0, ['firstName'], data);
        const lastName = R.pathOr(0, ['lastName'], data);

        return {
          id: R.pathOr(0, ['id'], data),
          name: `${firstName} ${lastName}`
        };
      });

      return {
        success: true,
        data: { ameFilters: mappedData }
      };
    } else {
      return {
        success: false
      };
    }
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};

export const fetchApprenticeFilters = async () => {
  try {
    const response: any = await api.fetchResponse(endpoints.APPRENTICE);

    if (response.success) {
      const mappedData = response.data.map((data: {}) => {
        const firstName = R.pathOr(0, ['firstName'], data);
        const lastName = R.pathOr(0, ['lastName'], data);

        return {
          id: R.pathOr(0, ['id'], data),
          name: `${firstName} ${lastName}`
        };
      });

      return {
        success: true,
        data: { apprenticeFilters: mappedData }
      };
    } else {
      return {
        success: false
      };
    }
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};

export const fetchAndDownload = async (
  fetchUrl: string,
  body: {} | [],
  saveName: string,
  isSelected: boolean,
  name: string
) => {
  if (isSelected) {
    const response = await api.fetchExportData(fetchUrl, body);

    if (response.data.noContent) {
      toast.error(`No ${name} Available`);

      return response;
    }

    if (response.success) {
      if (response.data != null && response.data.count != null) {
        toast.success(
          `${response.data.count} ${name} ${messages.EXPORT_SUCCESS}`
        );
      } else {
        toast.success(`${name} ${messages.EXPORT_SUCCESS}`);
      }
      // saveAs(response.data, saveName);
      return response;
    }

    toast.error(`${messages.EXPORT_FAILED} ${name}`);

    return response;
  }

  return { isSelected };
};
