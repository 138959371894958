import { put, takeLatest, delay, call } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchEngineerJobReviewAircraftFiltersSuccess,
  fetchEngineerJobReviewAircraftFiltersFailure,
  fetchEngineerJobReviewWorkTypeFiltersSuccess,
  fetchEngineerJobReviewWorkTypeFiltersFailure
} from '../actions/engineerJobReviewScreenFilters';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { fetchAircraftFilters } from '../utils/sagaHelpers';

function* fetchEngineerJobReviewWorkTypeFilters() {
  yield put(showSpinner());

  yield delay(1000);

  try {
    const workTypeFilters = yield api.fetchResponse(endpoints.WORK_TYPES);

    if (workTypeFilters.success) {
      const workTypeFiltersMappedData = workTypeFilters.data.map((data: {}) => {
        return {
          id: R.pathOr(0, ['id'], data),
          name: R.pathOr('', ['name'], data)
        };
      });

      yield put(
        fetchEngineerJobReviewWorkTypeFiltersSuccess(workTypeFiltersMappedData)
      );
    } else {
      yield put(fetchEngineerJobReviewWorkTypeFiltersFailure());
    }
  } catch (e) {
    yield put(fetchEngineerJobReviewWorkTypeFiltersFailure());
  }

  yield put(hideSpinner());
}

function* fetchEngineerJobReviewAircraftFilters() {
  yield put(showSpinner());

  yield delay(1000);

  const aircraftFiltersResponse = yield call(fetchAircraftFilters);

  if (R.pathOr(false, ['success'], aircraftFiltersResponse)) {
    yield put(
      fetchEngineerJobReviewAircraftFiltersSuccess(
        R.pathOr([], ['data', 'aircraftFilters'], aircraftFiltersResponse)
      )
    );
  } else {
    yield put(fetchEngineerJobReviewAircraftFiltersFailure());
  }

  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_ENGINEER_JOB_REVIEW_AIRCRAFT_FILTERS_REQUEST,
    fetchEngineerJobReviewAircraftFilters
  ),
  takeLatest(
    types.FETCH_ENGINEER_JOB_REVIEW_WORK_TYPE_FILTERS_REQUEST,
    fetchEngineerJobReviewWorkTypeFilters
  )
];
