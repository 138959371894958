import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { isNilOrEmpty, isPresent } from '../../utils/helper';
import api from '../../services/ApiModule';

import './style.scss';

interface ImagesViewerProps {
  imagesData: string[];
  currentActiveImage:number;
   setCurrentActiveImage:React.Dispatch<React.SetStateAction<number>>;
}

const ImagesViewer = ({ imagesData,currentActiveImage, setCurrentActiveImage }: ImagesViewerProps) => {
  const [fetchedImages, setFetchedImages] = useState([]);
  const [isImagesLoading, setIsImagesLoading] = useState(false);

  const fetchCurrentImageData = useCallback(async () => {
    setIsImagesLoading(true);
    const fetchedImagesArray = await api.fetchImagesDataArray(imagesData);
    setFetchedImages(fetchedImagesArray);
    setIsImagesLoading(false);
  }, [imagesData]);

  useEffect(() => {
    if (isPresent(imagesData)) {
      fetchCurrentImageData();
    }
  }, [imagesData, fetchCurrentImageData]);

  if (isNilOrEmpty(imagesData)) {
    return (
      <Typography
        variant="h4"
        gutterBottom
        className="no-receipts-available-text">
        No Receipt Available
      </Typography>
    );
  }

  const currentSelectedImage = fetchedImages[currentActiveImage];

  return (
    <div className="image-viewer-container">
      <div className="image-options-container">
        {isImagesLoading ? (
          <React.Fragment>
            {imagesData.map((data, index: number) => {
              return (
                <Skeleton key={index} variant="rect" width={100} height={100} />
              );
            })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {fetchedImages.map((imageSrc: string, index: number) => {
              return (
                <div
                  key={index}
                  className={`image-option-wrapper ${
                    currentActiveImage === index
                      ? 'image-option-wrapper-active'
                      : ''
                  }`}
                  onClick={() => setCurrentActiveImage(index)}>
                  <img src={imageSrc} alt={`${imageSrc}-${index}`} />
                </div>
              );
            })}
          </React.Fragment>
        )}
      </div>
      <div className="current-selected-image-container">
        {isImagesLoading ? (
          <Skeleton variant="rect" width={740} height={740} />
        ) : (
          <img
            src={currentSelectedImage}
            alt="current-selected"
            width="740px"
          />
        )}
      </div>
    </div>
  );
};

export default ImagesViewer;
