import React from 'react';

interface BookingsDetailsHeaderProps {
  isBookingsDetailsRouteMatch: boolean;
  isAddBookingsRouteMatch: boolean;
  currentBookingId: number;
  goto: Function;
}

const BookingsDetailsHeader = ({
  isBookingsDetailsRouteMatch,
  isAddBookingsRouteMatch,
  currentBookingId,
  goto
}: BookingsDetailsHeaderProps) => {
  const routeChange = (route: string) => {
    if (!isAddBookingsRouteMatch) {
      if (!isBookingsDetailsRouteMatch && route === 'details') {
        goto(`/bookings/details/${currentBookingId}`);
      }
    }
  };

  return (
    <div className="bookings-details-table-header">
      <div
        className={`bookings-details-table-header-options ${
          isBookingsDetailsRouteMatch || isAddBookingsRouteMatch
            ? 'bookings-details-table-header-options-selected'
            : ''
        }`}
        onClick={() => routeChange('details')}>
        DETAILS
      </div>
      {
        !isAddBookingsRouteMatch && null
        // <div
        //   className={`bookings-details-table-header-options ${
        //     isFlightReportsRouteMatch
        //       ? 'bookings-details-table-header-options-selected'
        //       : ''
        //   }`}
        //   onClick={() => routeChange('flightReports')}>
        //   FLIGHT REPORT
        // </div>
      }
    </div>
  );
};

export default BookingsDetailsHeader;
