import { InvoiceScreenFiltersProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList } from '../../utils/helper';

const initialState: InvoiceScreenFiltersProps = {
  aircraftFiltersArray: initialFilterList,
  bookingFiltersArray: initialFilterList,
  clientFiltersArray: initialFilterList,
  invoiceNumberArray: initialFilterList
};

const invoiceScreenFilters = (
  state: InvoiceScreenFiltersProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_INVOICE_SCREEN_AIRCRAFT_FILTERS_SUCCESS:
      const aircraftFiltersArray = initialFilterList.concat(
        action.payload.aircraftFiltersArray
      );

      return {
        ...state,
        aircraftFiltersArray: aircraftFiltersArray
      };

    case types.FETCH_INVOICE_SCREEN_BOOKING_FILTERS_SUCCESS:
      const bookingFiltersArray = initialFilterList.concat(
        action.payload.bookingFiltersArray
      );

      return {
        ...state,
        bookingFiltersArray: bookingFiltersArray
      };
    case types.FETCH_INVOICE_SCREEN_CLIENT_FILTERS_SUCCESS:
      const clientFiltersArray = initialFilterList.concat(
        action.payload.clientFiltersArray
      );

      return {
        ...state,
        clientFiltersArray: clientFiltersArray
      };

    case types.FETCH_INVOICE_SCREEN_PILOT_FILTERS_SUCCESS:
      const pilotFiltersArray = initialFilterList.concat(
        action.payload.pilotFiltersArray
      );

      return {
        ...state,
        pilotFiltersArray: pilotFiltersArray
      };

    case types.FETCH_INVOICE_SCREEN_AIRCRAFT_FILTERS_FAILURE:
    case types.FETCH_INVOICE_SCREEN_BOOKING_FILTERS_FAILURE:
    case types.FETCH_INVOICE_SCREEN_CLIENT_FILTERS_FAILURE:
    case types.FETCH_INVOICE_SCREEN_PILOT_FILTERS_FAILURE:
      return {
        ...state
      };

    case types.SET_INVOICE_SCREEN_INVOICE_NUMBER_FILTERS:
      const invoiceNumberArray = initialFilterList.concat(
        action.payload.invoiceNumberArray
      );

      return {
        ...state,
        invoiceNumberArray: invoiceNumberArray
      };

    default:
      return state;
  }
};

export default invoiceScreenFilters;
