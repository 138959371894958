import React, { ReactNode, useMemo } from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import StoreState from '../redux/utils/interfaces';
import Landing from './Landing';
import Login from './Login';
import Home from './Home';
import Details from './Details';
import Invoice from './Invoice';
import FuelExpense from './FuelExpense';
import EngineerJobReview from './EngineerJobReview';
import FlightReport from './FlightReport';
import Customers from './Customers';
import Users from './Users';
import ClientUsers from './ClientUsers';
import ClientPortal from './ClientPortal';
import ClientPortalHelp from './ClientPortalHelp';
import Bookings from './Bookings';
import Aircraft from './Aircraft';
import LogBook from './LogBook';
// import LogBookDetails from './LogbookDetails';
import PageNotFound from './PageNotFound';
import { isTokensPresentLocalStorage } from '../services/utils/helper';
import {
  // routesToRenderForUser,
  // routesToRenderForApprover,
  // routesToRenderForLogBook,
  ROOT_ROUTE,
  LOGIN_ROUTE,
  HOME_ROUTE,
  DETAILS_ROUTE,
  RECEIPT_ROUTE,
  WORK_HOURS_DETAILS,
  INVOICE_ROUTE,
  FUEL_EXPENSE_ROUTE,
  ENGINEER_JOB_REVIEW_ROUTE,
  FLIGHT_REPORT_ROUTE,
  FLIGHT_REPORT_PDF_ROUTE,
  CUSTOMERS_ROUTE,
  CUSTOMERS_DETAILS_ROUTE,
  ADD_CUSTOMER_ROUTE,
  CUSTOMERS_FLIGHT_REPORTS_ROUTE,
  USERS_ROUTE,
  USERS_DETAILS_ROUTE,
  ADD_USERS_ROUTE,
  CLIENT_USERS_ROUTE,
  CLIENT_USERS_DETAILS_ROUTE,
  ADD_CLIENT_USERS_ROUTE,
  BOOKINGS_ROUTE,
  BOOKINGS_DETAILS_ROUTE,
  ADD_BOOKINGS_ROUTE,
  AIRCRAFT_ROUTE,
  AIRCRAFT_DETAILS_ROUTE,
  ADD_AIRCRAFT_ROUTE,
  CLIENT_PORTAL_FLIGHT_REPORT_PDF_ROUTE,
  CLIENT_PORTAL_ROUTE,
  LOGBOOK_ROUTE,
  LOGBOOK_DETAILS_ROUTE,
  HELP_ROUTE
  // routesToRenderForLogBookAndApprover
} from '../utils/routesNavigationConstants';

interface RouteConfigProps {
  path: string | string[];
  component: ReactNode;
  name: string;
  exact: boolean;
  privateRoute: boolean;
}

const routesConfig = [
  {
    path: ROOT_ROUTE,
    component: Landing,
    name: 'Landing',
    exact: true,
    privateRoute: false
  },
  {
    path: LOGIN_ROUTE,
    component: Login,
    name: 'Login',
    exact: true,
    privateRoute: false
  },
  {
    path: HELP_ROUTE,
    component: ClientPortalHelp,
    name: 'Help',
    exact: true,
    privateRoute: true
  },
  {
    path: HOME_ROUTE,
    component: Home,
    name: 'Home',
    exact: true,
    privateRoute: true
  },
  {
    path: [LOGBOOK_ROUTE, LOGBOOK_DETAILS_ROUTE],
    component: LogBook,
    name: 'LogBooks',
    exact: true,
    privateRoute: true
  },
  {
    path: [DETAILS_ROUTE, RECEIPT_ROUTE, WORK_HOURS_DETAILS],
    component: Details,
    name: 'Details',
    exact: true,
    privateRoute: true
  },
  {
    path: INVOICE_ROUTE,
    component: Invoice,
    name: 'Invoice',
    exact: true,
    privateRoute: true
  },
  {
    path: FUEL_EXPENSE_ROUTE,
    component: FuelExpense,
    name: 'FuelExpense',
    exact: true,
    privateRoute: true
  },
  {
    path: ENGINEER_JOB_REVIEW_ROUTE,
    component: EngineerJobReview,
    name: 'EngineerJobReview',
    exact: true,
    privateRoute: true
  },
  {
    path: [FLIGHT_REPORT_ROUTE, FLIGHT_REPORT_PDF_ROUTE],
    component: FlightReport,
    name: 'FlightReport',
    exact: true,
    privateRoute: true
  },
  {
    path: [
      CUSTOMERS_ROUTE,
      CUSTOMERS_DETAILS_ROUTE,
      ADD_CUSTOMER_ROUTE,
      CUSTOMERS_FLIGHT_REPORTS_ROUTE
    ],
    component: Customers,
    name: 'Customers',
    exact: true,
    privateRoute: true
  },
  {
    path: [USERS_ROUTE, USERS_DETAILS_ROUTE, ADD_USERS_ROUTE],
    component: Users,
    name: 'Users',
    exact: true,
    privateRoute: true
  },
  {
    path: [CLIENT_USERS_ROUTE, CLIENT_USERS_DETAILS_ROUTE, ADD_CLIENT_USERS_ROUTE],
    component: ClientUsers,
    name: 'ClientUsers',
    exact: true,
    privateRoute: true
  },
  {
    path: [BOOKINGS_ROUTE, BOOKINGS_DETAILS_ROUTE, ADD_BOOKINGS_ROUTE],
    component: Bookings,
    name: 'Bookings',
    exact: true,
    privateRoute: true
  },
  {
    path: [AIRCRAFT_ROUTE, AIRCRAFT_DETAILS_ROUTE, ADD_AIRCRAFT_ROUTE],
    component: Aircraft,
    name: 'Aircraft',
    exact: true,
    privateRoute: true
  }
];
const clientportalConfig = [
  {
    path: LOGIN_ROUTE,
    component: Login,
    name: 'Login',
    exact: true,
    privateRoute: false
  },
  {
    path: [HELP_ROUTE],
    component: ClientPortalHelp,
    name: 'Help',
    exact: true,
    privateRoute: true
  },
  {
    path: [CLIENT_PORTAL_ROUTE, CLIENT_PORTAL_FLIGHT_REPORT_PDF_ROUTE],
    component: ClientPortal,
    name: 'ClientPortal',
    exact: true,
    privateRoute: true
  },
]

interface ProtectedRoutesProps {
  component: any;
  privateRoute: boolean;
}

const ProtectedRoutes = (props: RouteComponentProps & ProtectedRoutesProps) => {
  const { component: Component, privateRoute, ...rest } = props;
  const isUserPresent = isTokensPresentLocalStorage();

  const isValidRoute =
  (privateRoute && isUserPresent) || !(privateRoute || isUserPresent);

  if (isValidRoute) {
    return <Component {...rest} />;
  }

  return <Redirect to={privateRoute ? LOGIN_ROUTE : HOME_ROUTE} />;
};

const AppRoutes = () => {
  const {
    isApprovingSupervisor,
    isReviewingSupervisor,
    isLogbookWebpageUser,
    isJobsWebpageAccess,
    isAircraftWebpageAccess,
    isBillingWebpageAccess,
    isBookingWebpageAccess,
    isCustomersWebpageAccess,
    isEmployeesWebpageAccess,
    isFlightreportWebpageAccess,
    isFuelWebpageAccess,
    isLoading,
    isClientWebpageAccess,
    isClientUser,
    id
  } = useSelector((state: StoreState) => ({
    id: state.userInformation.id,
    isLoading: state.userInformation.isLoading,
    isApprovingSupervisor: state.userInformation.isApprovingSupervisor,
    isReviewingSupervisor: state.userInformation.isReviewingSupervisor,
    isLogbookMobileUser: state.userInformation.isLogbookMobileUser,
    isLogbookWebpageUser: state.userInformation.isLogbookWebpageUser,
    isJobsWebpageAccess: state.userInformation.isJobsWebpageAccess,
    isCustomersWebpageAccess: state.userInformation.isCustomersWebpageAccess,
    isBillingWebpageAccess: state.userInformation.isBillingWebpageAccess,
    isBookingWebpageAccess: state.userInformation.isBookingWebpageAccess,
    isFlightreportWebpageAccess:
      state.userInformation.isFlightreportWebpageAccess,
    isAircraftWebpageAccess: state.userInformation.isAircraftWebpageAccess,
    isFuelWebpageAccess: state.userInformation.isFuelWebpageAccess,
    isEmployeesWebpageAccess: state.userInformation.isEmployeesWebpageAccess,
    isClientWebpageAccess: state.userInformation.isClientWebpageAccess,
    isClientUser: state.userInformation.isClientUser
  }));

  const routesConfigCalculated = useMemo(() => {
    if (isLoading) return routesConfig;
    let filteredRoutes: any = [
      {
        path: ROOT_ROUTE,
        component: Landing,
        name: 'Landing',
        exact: true,
        privateRoute: false
      },
      {
        path: LOGIN_ROUTE,
        component: Login,
        name: 'Login',
        exact: true,
        privateRoute: false
      },
      {
        path: HELP_ROUTE,
        component: ClientPortalHelp,
        name: 'Help',
        exact: true,
        privateRoute: true
      },
      {
        path: HOME_ROUTE,
        component: Home,
        name: 'Home',
        exact: true,
        privateRoute: true
      },
      {
        path: [DETAILS_ROUTE, RECEIPT_ROUTE, WORK_HOURS_DETAILS],
        component: Details,
        name: 'Details',
        exact: true,
        privateRoute: true
      },
    ];
    if (id !== -1) {
      if(isClientUser) {
        return clientportalConfig;
      }
      if (isReviewingSupervisor || isApprovingSupervisor) {
        return routesConfig;
      }
      if (isLogbookWebpageUser) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'LogBooks';
        })[0]
        );
      }
      if (isJobsWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'EngineerJobReview';
        })[0]
        )
      }
      if (isAircraftWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'Aircraft';
        })[0]
        )
      }
      if (isFuelWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'FuelExpense';
        })[0]
        )
      }
      if (isFlightreportWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'FlightReport';
        })[0]
        )
      }
      if (isBookingWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'Bookings';
        })[0]
        )
      }
      if (isCustomersWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'Customers';
        })[0]
        )
      }
      if (isEmployeesWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'Users';
        })[0]
        )
      }
      if (isBillingWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'Invoice';
        })[0]
        )
      }
      if (isClientWebpageAccess) {
        filteredRoutes.push(routesConfig.filter((routeConfig) => {
          return routeConfig.name === 'ClientUsers';
        })[0]
        )
      }
    }
    return filteredRoutes;
  }, [
    id,
    isLoading,
    isApprovingSupervisor,
    isReviewingSupervisor,
    isLogbookWebpageUser,
    isJobsWebpageAccess,
    isAircraftWebpageAccess,
    isBillingWebpageAccess,
    isBookingWebpageAccess,
    isCustomersWebpageAccess,
    isEmployeesWebpageAccess,
    isFlightreportWebpageAccess,
    isFuelWebpageAccess
  ]);
  return (
    <Switch>
      {routesConfigCalculated.map((config: RouteConfigProps) => {
        return (
          <Route
            exact={config.exact}
            key={`${config.name}`}
            path={config.path}
            render={(props: RouteComponentProps) => {
              return (
                <ProtectedRoutes
                  component={config.component}
                  privateRoute={config.privateRoute}
                  {...props}
                />
              );
            }}
          />
        );
      })}

      <Route path="*" render={() => <PageNotFound />} />
    </Switch>
  );
};

export default withRouter(AppRoutes);
