import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@material-ui/core';
import HeaderContainer from '../containers/HeaderContainer';
import { isTokensPresentLocalStorage } from '../services/utils/helper';

const PageNotFound = () => {
  const isLoggedIn = isTokensPresentLocalStorage();

  return (
    <React.Fragment>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={<Typography>Page Not Found</Typography>}>
        <Box mt={34} display="flex" justifyContent="center">
          {isLoggedIn ? (
            <Typography variant="h5">
              This page is not available for you. If you think this page should
              be available to you, please contact your supervisor.
            </Typography>
          ) : (
            <Typography variant="h3">We couldn't find this page.</Typography>
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default PageNotFound;
