import { getMonthStartToCurrentDateRange } from '../../utils/helper';

export const filtersNotWorkingOptions = [
  { label: 'All', value: -1 },
  { label: 'Filter not working', value: -2 }
];

export interface RangeProps {
  to: Date;
  from: Date;
}

export const RANGE = 'range';
export const AIRCRAFT_FILTERS = 'aircraftFilters';
export const BOOK_NUMBER_FILTERS = 'bookNumberFilters';
export const PAGE_NUMBER_FILTERS = 'pageNumberFilters';
export const UPLOADED_BY_FILTERS = 'uploadedbyFilters';

export const initialValues = {
  range: getMonthStartToCurrentDateRange(),
  [AIRCRAFT_FILTERS]: -1,
  [BOOK_NUMBER_FILTERS]: -1,
  [PAGE_NUMBER_FILTERS]: -1,
  [UPLOADED_BY_FILTERS]:-1
};
