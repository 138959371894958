import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues, initialize } from 'redux-form';
import * as R from 'ramda';
import SharedDialog from '../shared/SharedDialog';
import CustomersDetailsInput from './CustomersDetailsInput';
import ContactsTable from '../ContactsTable';
import PassengersTable from '../PassengersTable';
import { CreatePassengerForm, CreateContactForm } from './CreateUserForms';
import { ContactsRow } from '../ContactsTable/helper';
import { PassengersRow } from '../PassengersTable/helper';
import { isPresent, isNilOrEmpty } from '../../utils/helper';
import {
  addContactPassengerDataRequest,
  editContactPassengerDataRequest
} from '../../redux/actions/customersScreenData';
import StoreState from '../../redux/utils/interfaces';
import {
  CONTACT_ADD_EDIT_FORM,
  PASSENGER_ADD_EDIT_FORM
} from '../../utils/reduxFormConstants';

import './style.scss';

export interface CustomerData {
  name: string;
  customerNumber: string;
  address1: string;
  address2: string;
  province: string;
  city: string;
  postalCode: string;
  phone: string;
  contacts: ContactsRow[];
  passengers: PassengersRow[];
}

interface CustomersDetailsProps {
  currentCustomerData: CustomerData | {};
}

const CustomersDetails = ({ currentCustomerData }: CustomersDetailsProps) => {
  const dispatch = useDispatch();
  const [currentDialogOpen, setCurrentDialogOpen] = useState('');

  const { contactForm, passengerForm } = useSelector((state: StoreState) => ({
    contactForm: getFormValues(CONTACT_ADD_EDIT_FORM)(state) || {},
    passengerForm: getFormValues(PASSENGER_ADD_EDIT_FORM)(state) || {}
  }));

  const { id, initialValues, contacts, passengers } = useMemo(() => {
    return {
      id: R.pathOr('', ['id'], currentCustomerData),
      initialValues: {
        name: R.pathOr('', ['name'], currentCustomerData),
        customerNumber: R.pathOr('', ['customerNumber'], currentCustomerData),
        address1: R.pathOr('', ['address1'], currentCustomerData),
        address2: R.pathOr('', ['address2'], currentCustomerData),
        province: R.pathOr('', ['province'], currentCustomerData),
        city: R.pathOr('', ['city'], currentCustomerData),
        postalCode: R.pathOr('', ['postalCode'], currentCustomerData),
        phone: R.pathOr('', ['phone'], currentCustomerData)
      },
      contacts: R.pathOr([], ['contacts'], currentCustomerData),
      passengers: R.pathOr([], ['passengers'], currentCustomerData)
    };
  }, [currentCustomerData]);

  const addContactHandler = useCallback(() => {
    setCurrentDialogOpen('addContact');
  }, [setCurrentDialogOpen]);

  const addPassengerHandler = useCallback(() => {
    setCurrentDialogOpen('addPassenger');
  }, [setCurrentDialogOpen]);

  const editContactHandler = useCallback(
    (contact: ContactsRow) => {
      setCurrentDialogOpen('editContact');

      const { firstName, lastName, role, email, phone, id, customer } = contact;

      dispatch(
        initialize(CONTACT_ADD_EDIT_FORM, {
          firstName,
          lastName,
          role,
          email,
          phone,
          id,
          customer
        })
      );
    },
    [setCurrentDialogOpen, dispatch]
  );

  const editPassengerHandler = useCallback(
    (passenger: PassengersRow) => {
      setCurrentDialogOpen('editPassenger');

      const { firstName, lastName, email, id, customer } = passenger;

      dispatch(
        initialize(PASSENGER_ADD_EDIT_FORM, {
          firstName,
          lastName,
          email,
          id,
          customer
        })
      );
    },
    [setCurrentDialogOpen, dispatch]
  );

  const handleCancel = useCallback(() => {
    setCurrentDialogOpen('');
  }, [setCurrentDialogOpen]);

  const handleSubmit = useCallback(() => {
    if (currentDialogOpen === 'addContact') {
      dispatch(
        addContactPassengerDataRequest(
          {
            ...contactForm,
            customer: {
              id: id
            }
          },
          true
        )
      );
    } else if (currentDialogOpen === 'addPassenger') {
      dispatch(
        addContactPassengerDataRequest(
          {
            ...passengerForm,
            customer: {
              id: id
            }
          },
          false
        )
      );
    } else if (currentDialogOpen === 'editContact') {
      dispatch(editContactPassengerDataRequest(contactForm, true));
    } else if (currentDialogOpen === 'editPassenger') {
      dispatch(editContactPassengerDataRequest(passengerForm, false));
    }

    setCurrentDialogOpen('');
  }, [
    dispatch,
    setCurrentDialogOpen,
    contactForm,
    passengerForm,
    currentDialogOpen,
    id
  ]);

  const { isSubmitDisabled, dialogueTitle, dialogContent } = useMemo(() => {
    let isSubmitDisabledLocal = true;
    let dialogueTitleLocal = '';
    let dialogContentLocal = null;

    switch (currentDialogOpen) {
      case 'addContact': {
        const firstName = R.pathOr('', ['firstName'], contactForm);
        const lastName = R.pathOr('', ['lastName'], contactForm);

        dialogueTitleLocal = 'CREATE NEW CONTACT';
        isSubmitDisabledLocal =
          isNilOrEmpty(firstName) || isNilOrEmpty(lastName);
        dialogContentLocal = <CreateContactForm />;
        break;
      }

      case 'addPassenger': {
        const firstName = R.pathOr('', ['firstName'], passengerForm);
        const lastName = R.pathOr('', ['lastName'], passengerForm);

        dialogueTitleLocal = 'CREATE NEW PASSENGER';
        isSubmitDisabledLocal =
          isNilOrEmpty(firstName) || isNilOrEmpty(lastName);
        dialogContentLocal = <CreatePassengerForm />;
        break;
      }

      case 'editContact': {
        const firstName = R.pathOr('', ['firstName'], contactForm);
        const lastName = R.pathOr('', ['lastName'], contactForm);

        dialogueTitleLocal = 'EDIT CONTACT';
        isSubmitDisabledLocal =
          isNilOrEmpty(firstName) || isNilOrEmpty(lastName);
        dialogContentLocal = <CreateContactForm />;
        break;
      }

      case 'editPassenger': {
        const firstName = R.pathOr('', ['firstName'], passengerForm);
        const lastName = R.pathOr('', ['lastName'], passengerForm);

        dialogueTitleLocal = 'EDIT PASSENGER';
        isSubmitDisabledLocal =
          isNilOrEmpty(firstName) || isNilOrEmpty(lastName);
        dialogContentLocal = <CreatePassengerForm />;
        break;
      }
    }

    return {
      isSubmitDisabled: isSubmitDisabledLocal,
      dialogueTitle: dialogueTitleLocal,
      dialogContent: dialogContentLocal
    };
  }, [contactForm, passengerForm, currentDialogOpen]);

  return (
    <React.Fragment>
      <CustomersDetailsInput initialValues={initialValues} />

      <ContactsTable
        contacts={contacts}
        addContactHandler={addContactHandler}
        editContactHandler={editContactHandler}
      />

      <PassengersTable
        passengers={passengers}
        addPassengerHandler={addPassengerHandler}
        editPassengerHandler={editPassengerHandler}
      />

      {isPresent(currentDialogOpen) && (
        <SharedDialog
          id="add-user-dialog"
          keepMounted
          maxWidth={false}
          open
          isSubmitDisabled={isSubmitDisabled}
          dialogueTitle={dialogueTitle}
          submitButtonText="SAVE"
          dialogContent={dialogContent}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      )}
    </React.Fragment>
  );
};

export default CustomersDetails;
