import * as R from 'ramda';
import { Row } from '../../WeeklyScreenTable/helper';

export const filterUserDataIds = (
  userDataArray: Row[],
  departmentFilter: number,
  statusFilter: string,
  firstNameFilter: number,
  lastNameFilter: number
) => {
  let filterUserDataArray = userDataArray;

  if (statusFilter === 'approved') {
    filterUserDataArray = R.filter(
      (userData: Row) => userData.status === statusFilter,
      filterUserDataArray
    );
  } else if (statusFilter === 'all') {
    filterUserDataArray = R.filter(
      (userData: Row) => userData.status === 'approved',
      filterUserDataArray
    );
  } else {
    filterUserDataArray = [];
  }

  if (departmentFilter !== -1) {
    filterUserDataArray = R.filter(
      (userData: Row) => userData.department.id === departmentFilter,
      filterUserDataArray
    );
  }
  if (firstNameFilter !== -1) {
    filterUserDataArray = R.filter(
      (userData: Row) => userData.id === firstNameFilter.toString(),
      filterUserDataArray
    );
  }
  if (lastNameFilter !== -1) {
    filterUserDataArray = R.filter(
      (userData: Row) => userData.id === lastNameFilter.toString(),
      filterUserDataArray
    );
  }

  return filterUserDataArray.map((data: Row) => Number(data.id));
};
