import { Box } from '@material-ui/core';
import React from 'react';
// import CustomSpeedDial from '../shared/CustomSpeedDial';
import CalendarInputRange from '../CalendarInputRange';
import Breadcrumbs from '../shared/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from '../../redux/utils/interfaces';
import { LOG_BOOK_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { change, getFormValues } from 'redux-form';
import { getWeekRange } from '../../utils/helper';
import * as R from 'ramda';
import { RANGE, RangeProps } from '../../routes/LogBook/helper';
// import SaveIcon from '@material-ui/icons/SaveOutlined';
// import { fetchLogBookScreenDataRequest } from '../../redux/actions/logBookScreenData';

function LogBookSecondaryHeader() {
  const dispatch = useDispatch();

  const { formValues } = useSelector((state: StoreState) => {
    return {
      formValues: getFormValues(LOG_BOOK_FILTERS_FORM)(state) || {}
    };
  });
  const rangeValue = R.pathOr(getWeekRange(), ['range'], formValues);
  
  const updateFormField = (key: string, value: any) =>
    dispatch(change(LOG_BOOK_FILTERS_FORM, key, value));

  const setRangeHandler = (range: RangeProps) => {
    updateFormField(RANGE, range);
  };

  // const actions = useMemo(() => {
  //   return [
  //     {
  //       icon: SaveIcon,
  //       name: 'Save',
  //       onClick: setRangeHandler
  //     }
  //   ];
  // }, []);
  return (
    <React.Fragment>
      <Box mr={5}>
        <Breadcrumbs
          breadcrumbDataArray={[
            {
              id: 'route_name',
              label: 'Log Book'
            }
          ]}
        />
      </Box>

      <div className="dropdown-container-fuel-expense">
        <CalendarInputRange range={rangeValue} setRange={setRangeHandler} showOffFilter={false}/>
      </div>

      {/* <CustomSpeedDial actions={actions} /> */}
    </React.Fragment>
  );
}

export default LogBookSecondaryHeader;
