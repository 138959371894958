import { WeeklyScreenFilters, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';
import {
  initialFilterList,
  statusFilterList,
  getWeekRange
} from '../../utils/helper';

const initialState: WeeklyScreenFilters = {
  departmentFilter: initialFilterList[0].id,
  statusFilter: statusFilterList[0].value,
  selectedFirstDate: getWeekRange(new Date()).from,
firstNameFilter: -1,
lastNameFilter: -1
};

const weeklyScreenFilters = (
  state: WeeklyScreenFilters = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.SET_DEPARTMENT_FILTER:
      return {
        ...state,
        departmentFilter: action.payload.value
      };

    case types.SET_STATUS_FILTER:
      return {
        ...state,
        statusFilter: action.payload.value
      };
    case types.SET_FIRST_NAME_FILTER:
      return {
        ...state,
        firstNameFilter: action.payload.value
      };
    case types.SET_LAST_NAME_FILTER:
      return {
        ...state,
        lastNameFilter: action.payload.value
      };

    case types.SET_SELECTED_DAYS:
      return {
        ...state,
        selectedFirstDate: action.payload.selectedFirstDate
      };

    default:
      return state;
  }
};

export default weeklyScreenFilters;
