import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    }
  })
);

const CircularIndeterminate: React.FunctionComponent<{
  showSpinner: boolean;
}> = ({ showSpinner }) => {
  const classes = useStyles();

  return (
    <div className={`cover-spin ${showSpinner ? '' : 'hide-spinner'}`}>
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default CircularIndeterminate;
