import types from '../utils/actionTypes';

export const fetchFuelExpenseScreenAircraftFiltersRequest = () => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_AIRCRAFT_FILTERS_REQUEST
  };
};

export const fetchFuelExpenseScreenAircraftFiltersSuccess = (
  aircraftFiltersArray: []
) => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_AIRCRAFT_FILTERS_SUCCESS,
    payload: { aircraftFiltersArray }
  };
};

export const fetchFuelExpenseScreenAircraftFiltersFailure = () => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_AIRCRAFT_FILTERS_FAILURE
  };
};

export const fetchFuelExpenseScreenBookingFiltersRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_BOOKING_FILTERS_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};

export const fetchFuelExpenseScreenBookingFiltersSuccess = (
  bookingFiltersArray: []
) => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_BOOKING_FILTERS_SUCCESS,
    payload: { bookingFiltersArray }
  };
};

export const fetchFuelExpenseScreenBookingFiltersFailure = () => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_BOOKING_FILTERS_FAILURE
  };
};

export const setFuelExpenseScreenVendorFilters = (vendorFiltersArray: {}[]) => {
  return {
    type: types.SET_FUEL_EXPENSE_SCREEN_VENDOR_FILTERS,
    payload: { vendorFiltersArray }
  };
};

export const setFuelExpenseScreenDocumentHashFilters = (
  documentHashFiltersArray: {}[]
) => {
  return {
    type: types.SET_FUEL_EXPENSE_SCREEN_DOCUMENT_HASH_FILTERS,
    payload: { documentHashFiltersArray }
  };
};

export const setFuelExpenseScreenInvoiceNumberFilters = (
  invoiceNumberArray: {}[]
) => {
  return {
    type: types.SET_FUEL_EXPENSE_SCREEN_INVOICE_NUMBER_FILTERS,
    payload: { invoiceNumberArray }
  };
};
