import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
export interface SharedDialogProps {
  classes?: Record<'paper', string>;
  id: string;
  keepMounted: boolean;
  open: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  dialogueTitle: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  imageData?: string;
  isSubmitDisabled?: boolean;
  dialogContent: ReactNode;
  handleCancel?: () => void;
  handleSubmit?: () => void;
  handleDownload?: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadBtn: {
      position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(1),
    boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    }
  })
);
function SharedDialog(props: SharedDialogProps) {
  const classes = useStyles();
  const {
    open,
    maxWidth = 'xs',
    dialogueTitle,
    cancelButtonText,
    submitButtonText,
    isSubmitDisabled,
    dialogContent,
    imageData,
    handleCancel,
    handleSubmit,
    handleDownload,
    ...other
  } = props;

// const [openSpeedDial, setOpenSpeedDial] = useState(true);
  return (
    <Dialog
      maxWidth={maxWidth}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={handleCancel}
      {...other}>
      <DialogTitle id="confirmation-dialog-title">
        {dialogueTitle}
        {handleDownload && (
          <IconButton aria-label="Download" className={classes.downloadBtn} onClick={handleDownload}>
         <CloudDownloadIcon color="primary"/>
        </IconButton>
        )}
        </DialogTitle>
      <DialogContent dividers>{dialogContent}</DialogContent>
      <DialogActions>
        {handleCancel && (
          <Button autoFocus onClick={handleCancel} color="primary">
            {cancelButtonText || 'Cancel'}
          </Button>
        )}
        {handleSubmit && (
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isSubmitDisabled}>
            {submitButtonText || 'Submit'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default SharedDialog;
