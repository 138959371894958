import types from '../utils/actionTypes';

export const fetchInvoiceScreenAircraftFiltersRequest = () => {
  return {
    type: types.FETCH_INVOICE_SCREEN_AIRCRAFT_FILTERS_REQUEST
  };
};

export const fetchInvoiceScreenAircraftFiltersSuccess = (
  aircraftFiltersArray: []
) => {
  return {
    type: types.FETCH_INVOICE_SCREEN_AIRCRAFT_FILTERS_SUCCESS,
    payload: { aircraftFiltersArray }
  };
};

export const fetchInvoiceScreenAircraftFiltersFailure = () => {
  return {
    type: types.FETCH_INVOICE_SCREEN_AIRCRAFT_FILTERS_FAILURE
  };
};
export const fetchInvoiceScreenPilotFiltersRequest = () => {
  return {
    type: types.FETCH_INVOICE_SCREEN_PILOT_FILTERS_REQUEST
  };
};

export const fetchInvoiceScreenPilotFiltersSuccess = (
  pilotFiltersArray: []
) => {
  return {
    type: types.FETCH_INVOICE_SCREEN_PILOT_FILTERS_SUCCESS,
    payload: { pilotFiltersArray }
  };
};

export const fetchInvoiceScreenPilotFiltersFailure = () => {
  return {
    type: types.FETCH_INVOICE_SCREEN_PILOT_FILTERS_FAILURE
  };
};

export const fetchInvoiceScreenBookingFiltersRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_INVOICE_SCREEN_BOOKING_FILTERS_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};

export const fetchInvoiceScreenBookingFiltersSuccess = (
  bookingFiltersArray: []
) => {
  return {
    type: types.FETCH_INVOICE_SCREEN_BOOKING_FILTERS_SUCCESS,
    payload: { bookingFiltersArray }
  };
};

export const fetchInvoiceScreenBookingFiltersFailure = () => {
  return {
    type: types.FETCH_INVOICE_SCREEN_BOOKING_FILTERS_FAILURE
  };
};

export const fetchInvoiceScreenClientFiltersRequest = () => {
  return {
    type: types.FETCH_INVOICE_SCREEN_CLIENT_FILTERS_REQUEST
  };
};

export const fetchInvoiceScreenClientFiltersSuccess = (
  clientFiltersArray: []
) => {
  return {
    type: types.FETCH_INVOICE_SCREEN_CLIENT_FILTERS_SUCCESS,
    payload: { clientFiltersArray }
  };
};

export const fetchInvoiceScreenClientFiltersFailure = () => {
  return {
    type: types.FETCH_INVOICE_SCREEN_CLIENT_FILTERS_FAILURE
  };
};

export const setInvoiceScreenInvoiceNumberFilters = (
  invoiceNumberArray: {}[]
) => {
  return {
    type: types.SET_INVOICE_SCREEN_INVOICE_NUMBER_FILTERS,
    payload: { invoiceNumberArray }
  };
};
