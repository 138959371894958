import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import WeeklyScreenTable from '../../components/WeeklyScreenTable';
import WeeklyScreenSecondaryHeader from '../../components/WeeklyScreenSecondaryHeader';
import { HOME_NAV_KEY } from '../../utils/routesNavigationConstants';

const Home = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <HeaderContainer
        secondaryHeader={<WeeklyScreenSecondaryHeader />}
        navKey={HOME_NAV_KEY}>
        <Box py={3.5}>
          <WeeklyScreenTable />
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default Home;
