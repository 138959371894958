import React, { useCallback, useState, useEffect } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { WrappedFieldProps, getFormValues, change } from 'redux-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { isPresent } from '../../../utils/helper';

import './styles.scss';

interface FieldProps {
  name: string;
  id: string;
  label: string;
  disabled: boolean;
  optionsList: (string | undefined)[];
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  required?: boolean;
}

const ReduxFormAutocomplete = (field: WrappedFieldProps & FieldProps) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');

  const value = useSelector((state: any) =>
    R.pathOr(null, [field.input.name], getFormValues(field.meta.form)(state))
  );

  const updateFormField = useCallback(
    (value: any) => dispatch(change(field.meta.form, field.input.name, value)),
    [dispatch, field.input.name, field.meta.form]
  );

  const setLabelForCurrentSelectedOption = useCallback(() => {
    const selectedOption = field.optionsList.find((option: any) => {
      return option.id === value;
    });

    setInputValue(
      isPresent(value) && isPresent(selectedOption)
        ? `${R.pathOr('', ['name'], selectedOption)}`
        : ''
    );
  }, [value, field.optionsList]);

  useEffect(() => {
    setLabelForCurrentSelectedOption();
  }, [setLabelForCurrentSelectedOption]);

  return (
    <div className="redux-form-autocomplete-container details-field-container">
      <Autocomplete
        id={field.id}
        value={value}
        inputValue={inputValue}
        options={field.optionsList}
        disabled={field.disabled}
        openOnFocus
        disableClearable={field.required}
        getOptionLabel={(option) => {
          return R.pathOr('', ['name'], option);
        }}
        getOptionSelected={(option, value) => {
          return R.pathOr('', ['id'], option).toString() === value?.toString();
        }}
        onChange={(event: any, value: any) => {
          const id = R.pathOr(null, ['id'], value);
          const name = R.pathOr('', ['name'], value);

          updateFormField(id);
          setInputValue(name);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={field.required}
            variant={field.variant}
            label={field.label}
            onFocus={() => {
              if (field.required) {
                setInputValue('');
              }
            }}
            onChange={(event: any) => {
              setInputValue(event.target.value);
            }}
            onBlur={setLabelForCurrentSelectedOption}
          />
        )}
      />
    </div>
  );
};

export default ReduxFormAutocomplete;
