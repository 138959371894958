const asyncActions: String[] = [
  'USER_LOGIN',
  'USER_LOG_OUT',
  'USER_RESET_PASSWORD',
  'REFRESH_TOKENS',
  'FETCH_WEEKLY_SCREEN_DATA',
  'FETCH_USER_INFO',
  'FETCH_DETAILS_SCREEN_DATA',
  'UPDATE_STATUS_DETAILS_SCREEN_DATA',
  'FETCH_EXPENSE_TYPES',
  'FETCH_DEPARTMENT_FILTERS',
  'FETCH_BOOKINGS',
  'UPDATE_RECEIPT_DETAILS_SCREEN_DATA',
  'UPDATE_LOGBOOK_DETAILS_SCREEN_DATA',
  'FETCH_INVOICE_SCREEN_AIRCRAFT_FILTERS',
  'FETCH_INVOICE_SCREEN_PILOT_FILTERS',
  'FETCH_INVOICE_SCREEN_BOOKING_FILTERS',
  'FETCH_INVOICE_SCREEN_CLIENT_FILTERS',
  'FETCH_INVOICE_SCREEN_DATA',
  'UPDATE_INVOICE_SCREEN_DATA',
  'EXPORT_WEEKLY_REPORT',
  'EXPORT_REFUEL_IMAGE',
  'EXPORT_LOGBOOK_IMAGE',
  'EXPORT_BILLING_SCREEN_DATA',
  'FETCH_FUEL_EXPENSE_SCREEN_AIRCRAFT_FILTERS',
  'FETCH_FUEL_EXPENSE_SCREEN_BOOKING_FILTERS',
  'FETCH_FUEL_EXPENSE_SCREEN_DATA',
  'UPDATE_FUEL_EXPENSE_SCREEN_DATA',
  'FETCH_ENGINEER_JOB_REVIEW_AIRCRAFT_FILTERS',
  'FETCH_ENGINEER_JOB_REVIEW_WORK_TYPE_FILTERS',
  'FETCH_ENGINEER_JOB_REVIEW_DATA',
  'FETCH_LOG_BOOK_SCREEN_DATA',
  'FETCH_LOG_BOOK_AIRCRAFT_DATA',
  'UPDATE_LOG_BOOK_SCREEN_DATA',
  'FETCH_FLIGHT_REPORT_FILTERS',
  'FETCH_FLIGHT_REPORT_BOOKING_FILTERS',
  'FETCH_FLIGHT_REPORT_SCREEN_DATA',
  'UPDATE_FLIGHT_REPORT_SCREEN_DATA',
  'EXPORT_FLIGHT_REPORT_SCREEN_DATA',
  'FETCH_CUSTOMERS_SCREEN_DATA',
  'UPDATE_CUSTOMER_DATA',
  'ADD_CONTACT_PASSENGER_DATA',
  'EDIT_CONTACT_PASSENGER_DATA',
  'ADD_CUSTOMER_DATA',
  'FETCH_USERS_SCREEN_DATA',
  'FETCH_CLIENT_USERS_SCREEN_DATA',
  'FETCH_USERS_SCREEN_TYPES',
  'FETCH_CLIENT_USERS_SCREEN_CLIENTS',
  'ADD_NEW_USER',
  'ADD_NEW_CLIENT_USER',
  'FETCH_BOOKINGS_SCREEN_DATA',
  'FETCH_BOOKINGS_SCREEN_DATA_FIELDS',
  'FETCH_BOOKINGS_SCREEN_FILTERS',
  'ADD_BOOKINGS_SCREEN_DATA',
  'ADD_CLIENT_PORTAL_COMMENTS',
  'EDIT_BOOKINGS_SCREEN_DATA',
  'VALIDATE_BOOKING_NUMBER',
  'FETCH_AIRCRAFT_SCREEN_DATA',
  'UPDATE_USER',
  'UPDATE_CLIENT_USER',
  'USER_STATUS_CHANGE',
  'CLIENT_USER_STATUS_CHANGE',
  'FETCH_AIRCRAFT_OFFICIAL_TYPES',
  'ADD_EDIT_AIRCRAFT_DETAILS',
  'UPDATE_AIRCRAFT_STATUS',
  'UPDATE_FLIGHT_REPORT_STATUS',
  'SET_COMMENT_DETAILS_SCREEN',
  'SEND_FLIGHT_REPORT_EMAIL',
  'FETCH_CUSTOMER_FLIGHT_REPORT',
  'CLIENT_PORTAL_FILTERS',
  'FETCH_CLIENT_PORTAL_SCREEN_DATA',
  'UPDATE_CLIENT_PORTAL_SCREEN_DATA',
  'UPDATE_CLIENT_PORTAL_FLIGHT_REPORT_STATUS'
];

export default asyncActions;
