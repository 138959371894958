import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import ForwardBackwardNav from '../../components/shared/ForwardBackwardNav';
import LogbookDetails from '../../components/LogbookDetails';
import { LogBookRow } from '../../components/LogBookScreenTable/helper';
import { LOGBOOK_NAV_KEY } from '../../utils/routesNavigationConstants';
import HeaderContainer from '../../containers/HeaderContainer';
import LogBookScreenSecondaryHeader from '../../components/LogbookDetailsSecondaryHeaders';
import { useDispatch } from 'react-redux';
import { exportLogbookImageRequest } from '../../redux/actions/logBookScreenData';

interface ReceiptScreenProps {
  currentIndex: number;
  setCurrentIndex: Function;
  logBookScreenData: never[];
}

const LogbookDetailsScreen = ({
  currentIndex,
  setCurrentIndex,
  logBookScreenData
}: ReceiptScreenProps) => {
  const currentReceiptData: LogBookRow =
    logBookScreenData[currentIndex] || logBookScreenData[0];
  const dispatch = useDispatch();
  const [currentActiveImage, setCurrentActiveImage] = useState(0);

  useEffect(() => {
    if (logBookScreenData.length < currentIndex) {
      setCurrentIndex(0);
    }
  }, [logBookScreenData.length, currentIndex, setCurrentIndex]);

  const handlePageChange = (changeIndex: number) => {
    setCurrentIndex(changeIndex);
  };
  const handleSaveDataClick = () => {
    dispatch(
      exportLogbookImageRequest(currentReceiptData.receiptUri[currentActiveImage])
    );
  };
  if (logBookScreenData.length <= 0) {
    return (
      <Typography
        variant="h4"
        gutterBottom
        className="no-receipts-available-text">
        No Receipt Data Available
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <HeaderContainer
        secondaryHeader={
          <LogBookScreenSecondaryHeader
            handleSaveDataClick={handleSaveDataClick}
          />
        }
        navKey={LOGBOOK_NAV_KEY}>
        <div style={{ marginTop: '-100px' }}>
          <ForwardBackwardNav
            currentIndex={currentIndex}
            total={logBookScreenData.length}
            handlePageChange={handlePageChange}
          />
          <LogbookDetails
            imageViewerData={currentReceiptData.receiptUri}
            formData={currentReceiptData}
            currentActiveImage={currentActiveImage}
            setCurrentActiveImage={setCurrentActiveImage}
          />
        </div>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default LogbookDetailsScreen;
