import types from '../utils/actionTypes';

export const fetchEngineerJobReviewDataRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_DATA_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};

export const fetchEngineerJobReviewDataSuccess = (
  engineerJobReviewData: []
) => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_DATA_SUCCESS,
    payload: { engineerJobReviewData }
  };
};

export const fetchEngineerJobReviewDataFailure = () => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_DATA_FAILURE
  };
};
