import { useState, useEffect, useCallback, useMemo } from 'react';
import {  useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initialize, getFormValues, change } from 'redux-form';
import * as R from 'ramda';
import { CLIENT_PORTAL_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../../redux/utils/interfaces';
import { getMonthStartToCurrentDateRange, isPresent } from '../../utils/helper';
import { toast } from 'react-toastify';
// import {
//   //fetchFlightReportFiltersRequest,
//   fetchFlightReportBookingFiltersRequest
// } from '../../redux/actions/flightReportScreenFilters';

import { initialValues, RANGE, RangeProps } from './helper';
import { CLIENT_PORTAL_FLIGHT_REPORT_PDF_ROUTE } from '../../utils/routesNavigationConstants';
import { fetchClientPortalScreenDataRequest, postClientPortalCommentRequest, updateClientPortalScreenDataRequest } from '../../redux/actions/clientPortalScreenData';
import { filterFlightReportScreenData } from '../../components/ClientPortalTable/helper';
// import {reset} from 'redux-form';

export const useFlightReportHook = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let clientPortalFlightReportPdfMatch = useRouteMatch(CLIENT_PORTAL_FLIGHT_REPORT_PDF_ROUTE);
  const isFlightReportPdfRoute = isPresent(clientPortalFlightReportPdfMatch);

  const [currentSelectedStatus, setCurrentSelectedStatus] = useState({});

  const { formValues, clientPortalScreenData,loggedInUser } = useSelector(
    (state: StoreState) => {
      return {
        formValues: getFormValues(CLIENT_PORTAL_FILTERS_FORM)(state) || {},
        loggedInUser: state.userInformation,
        clientPortalScreenData: state.clientPortalScreenData
      };
    }
    );

  const range = R.pathOr(
    getMonthStartToCurrentDateRange(),
    ['range'],
    formValues
  );

  useEffect(() => {
    dispatch(initialize(CLIENT_PORTAL_FILTERS_FORM, initialValues));

    // dispatch(fetchFlightReportFiltersRequest());
  }, [dispatch]);

  useEffect(() => {
    // dispatch(fetchFlightReportBookingFiltersRequest(range.from, range.to));
    dispatch(fetchClientPortalScreenDataRequest(range.from, range.to));
  }, [dispatch,range]);
  

  const updateFormField = useCallback(
    (key: string, value: any) =>
      dispatch(change(CLIENT_PORTAL_FILTERS_FORM, key, value)),
    [dispatch]
  );

  const goto = useCallback((route: string) => history.push(route), [history]);

  const setRangeHandler = useCallback(
    (range: RangeProps) => {
      updateFormField(RANGE, range);
    },
    [updateFormField]
  );

  const setAllCurrentSelectedStatus = useCallback(
    (value: unknown) => {
      const currentSelectedStatusKays = R.keys(currentSelectedStatus);
      const localCurrentSelectedStatus = { ...currentSelectedStatus };

      currentSelectedStatusKays.forEach((key: string) => {
        localCurrentSelectedStatus[`${key}`] = value;
      });

      setCurrentSelectedStatus(localCurrentSelectedStatus);
    },
    [currentSelectedStatus, setCurrentSelectedStatus]
  );
  const handleonPostComment = useCallback((comment,flightReportId) => {
    if(comment !== ''){

      const commentData = {
        comment: comment,
        user:{id:loggedInUser.id },
        flightReport: {id:flightReportId}
      }
        dispatch(postClientPortalCommentRequest(commentData));
        dispatch(change(CLIENT_PORTAL_FILTERS_FORM,'comment',null))
    } else {
      toast.error('Please enter comment message');
    }
      // dispatch(reset(CLIENT_PORTAL_FILTERS_FORM));
  },[dispatch]);

  const isSaveButtonDisabled = false;

  const onSaveButtonClickHandler = useCallback(() => {
    const currentSelectedStatusKeys = R.keys(currentSelectedStatus);

    const mappedCurrentSelectedStatusArray = currentSelectedStatusKeys.map(
      (currentSelectedStatusKey: string) => {
        return {
          id: currentSelectedStatusKey,
          statusType: {
            id: currentSelectedStatus[`${currentSelectedStatusKey}`]
          }
        };
      }
    );

    dispatch(
      updateClientPortalScreenDataRequest(mappedCurrentSelectedStatusArray)
    );
  }, [currentSelectedStatus, dispatch]);
// get array of ids of clientPortalScreenData.flightReportData
const filteredFlightReportData = useMemo(
  () => filterFlightReportScreenData(clientPortalScreenData.flightReportData, formValues),
  [clientPortalScreenData.flightReportData, formValues]
  );
  const flightReportIds:string[] = R.map(R.prop('id'))(filteredFlightReportData)
  return {
    currentSelectedStatus,
    range,
    formValues,
    isSaveButtonDisabled,
    clientPortalScreenData,
    // currentFlightReportData,
    filteredFlightReportData,
    isFlightReportPdfRoute,
    flightReportIds,
    setAllCurrentSelectedStatus,
    setCurrentSelectedStatus,
    setRange: setRangeHandler,
    onSaveButtonClickHandler,
    goto,
    handleonPostComment
  };
};
