import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { Box } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAltOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import UnlockIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import CalendarInputRange from '../CalendarInputRange';
import Status from '../shared/Status';
import {
  exportFlightReportScreenDataRequest,
  updateFlightReportStatusRequest
} from '../../redux/actions/flightReportScreenData';
import CustomSpeedDial from '../shared/CustomSpeedDial';

import './style.scss';

interface RangeProps {
  to: Date;
  from: Date;
}

interface FlightReportSecondaryHeaderProps {
  range: RangeProps;
  isSaveButtonDisabled: boolean;
  isFlightReportPdfRoute: boolean;
  currentFlightReportData: any;
  setRange: Function;
  onSaveButtonClickHandler: Function;
  goto: Function;
}

const FlightReportSecondaryHeader = ({
  range,
  isSaveButtonDisabled,
  isFlightReportPdfRoute,
  currentFlightReportData,
  setRange,
  onSaveButtonClickHandler,
  goto
}: FlightReportSecondaryHeaderProps) => {
  const dispatch = useDispatch();

  const isLocked = R.pathOr(
    true,
    ['flightReportData', 'isLocked'],
    currentFlightReportData
  );
  const isLockedOnClientPortal = R.pathOr(
    false,
    ['flightReportData', 'isForClientPortal'],
    currentFlightReportData
  );
  const id = R.pathOr('', ['flightReportData', 'id'], currentFlightReportData);

  const breadcrumbDataArray = useMemo(() => {
    const breadcrumbDataArrayLocal = [
      {
        id: 'flight_report_breadcrum',
        label: 'Flight Reports',
        onClick: isFlightReportPdfRoute ? () => goto('/flightReport') : () => {}
      }
    ];

    if (isFlightReportPdfRoute) {
      breadcrumbDataArrayLocal.push({
        id: 'flight_report_pdf_breadcrum',
        label: currentFlightReportData.isLoading
          ? 'Loading...'
          : currentFlightReportData.flightReportData.flightReport,
        onClick: () => {}
      });
    }

    return breadcrumbDataArrayLocal;
  }, [goto, isFlightReportPdfRoute, currentFlightReportData]);

  const handleCloudDownloadButtonClick = useCallback(
    (withData) => {
      dispatch(exportFlightReportScreenDataRequest(withData));
    },
    [dispatch]
  );

  const actions = useMemo(() => {
    if (isFlightReportPdfRoute) {
      return [
        {
          icon: isLocked ? UnlockIcon : LockIcon,
          name: isLocked ? 'Unlock' : 'Lock',
          onClick: () => {
            dispatch(updateFlightReportStatusRequest(isLocked, id));
          },
          showToast: false
        },
        {
          icon: isLockedOnClientPortal ? UnlockIcon : LockIcon,
          name: isLockedOnClientPortal
            ? 'Unregister from Client Portal'
            : 'Not Registered on Client Portal',
          onClick: () => {
            isLockedOnClientPortal && dispatch(updateFlightReportStatusRequest(isLockedOnClientPortal, id));
          },
          showToast: false,
          disabled:!isLockedOnClientPortal
        }
      ];
    }

    return [
      {
        icon: SaveIcon,
        name: 'Save',
        disabled: isSaveButtonDisabled,
        onClick: onSaveButtonClickHandler,
        showToast: false
      },
      {
        icon: SaveAltIcon,
        name: 'Download',
        onClick: () => handleCloudDownloadButtonClick(false)
      },
      {
        icon: CloudDownloadOutlinedIcon,
        name: 'Download with Data',
        onClick: () => handleCloudDownloadButtonClick(true)
      }
    ];
  }, [
    dispatch,
    id,
    isLocked,
    isSaveButtonDisabled,
    isFlightReportPdfRoute,
    onSaveButtonClickHandler,
    handleCloudDownloadButtonClick
  ]);

  return (
    <React.Fragment>
      <div className="dropdown-container-flight-report">
        <Box mr={5}>
          <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
        </Box>

        {isFlightReportPdfRoute ? (
          <Box mx={2}>
            <Status
              status={R.pathOr(
                '',
                ['flightReportData', 'status', 'name'],
                currentFlightReportData
              )}
            />
          </Box>
        ) : (
          <CalendarInputRange
            range={range}
            setRange={setRange}
            showOffFilter={false}
          />
        )}
      </div>

      <CustomSpeedDial actions={actions} />
    </React.Fragment>
  );
};

export default FlightReportSecondaryHeader;
