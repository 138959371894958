import React, { useMemo } from 'react';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import SharedSpinner from '../shared/SharedSpinner';
import { useStyles, columns, EngineerJobReviewRow, getTotals } from './helper';
import { useGetSetDataHook } from './hook';
import SharedTableHeader from '../shared/SharedTableHeader';
import { useSortHook } from '../../hooks/sortHooks';

import './style.scss';

const EngineerJobReviewTable = () => {
  const classes = useStyles();
  const {
    engineerJobReviewData,
    isEngineerJobReviewDataLoading
  } = useGetSetDataHook();

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    initialSortById: 'date',
    columnsData: columns,
    dataArray: engineerJobReviewData
  });

  const total = useMemo(() => getTotals(engineerJobReviewData), [
    engineerJobReviewData
  ]);

  if (isEngineerJobReviewDataLoading) {
    return <SharedSpinner />;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: EngineerJobReviewRow) => {
            return (
              <TableRow
                key={row.id}
                className="engineer-job-review-custom-body-row">
                <TableCell component="th" scope="row">
                  {moment.utc(row.date).format('MMM D YYYY')}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.aircraft.registration}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.workReport}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.workType.name}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.workDone}
                </TableCell>
                <TableCell component="th" scope="row" align={'left'}>
                  {row.comments}
                </TableCell>
                <TableCell component="th" scope="row" align={'right'}>
                  {row.hours}
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow
            key="totals-row-engineer-job-review"
            className="totals-row-engineer-job-review">
            <TableCell component="th" scope="row">
              Totals
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row" align="right">
              {total.hours.toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EngineerJobReviewTable;
