import { put, takeLatest, delay } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import { userInfoSuccess, userInfoFailure } from '../actions/userInfo';
import { navigateTo } from '../../utils/history';
import { HOME_ROUTE, CLIENT_PORTAL_ROUTE } from '../../utils/routesNavigationConstants';

function* fetchUserInformation() {
  yield delay(1000);

  try {
    const userInfoStatus = yield api.fetchMe();

    const {
      id,
      firstName,
      company: { id: companyId, name: companyName },
      lastName,
      username: email,
      phone,
      language,
      license1,
      license2,
      isApprovingSupervisor,
      isReviewingSupervisor,
      isLogbookMobileUser,
      isLogbookWebpageUser,
      isJobsWebpageAccess,
      isCustomersWebpageAccess,
      isBillingWebpageAccess,
      isFlightreportWebpageAccess,
      isFuelWebpageAccess,
      isEmployeesWebpageAccess,
      isAircraftWebpageAccess,
      isBookingWebpageAccess,
      isClientUser,
      isClientWebpageAccess,
      canReviewUserIds = []
    } = userInfoStatus.data;

    const meInfo = {
      id,
      firstName,
      lastName,
      email,
      companyId,
      companyName,
      phone,
      language,
      license1,
      license2,
      isApprovingSupervisor,
      isReviewingSupervisor,
      isLogbookMobileUser,
      isLogbookWebpageUser,
      isJobsWebpageAccess,
      isCustomersWebpageAccess,
      isBillingWebpageAccess,
      isFlightreportWebpageAccess,
      isFuelWebpageAccess,
      isEmployeesWebpageAccess,
      isAircraftWebpageAccess,
      isBookingWebpageAccess,
      canReviewUserIds,
      isClientUser,
      isClientWebpageAccess,
    };

    if (userInfoStatus.success) {
      yield put(userInfoSuccess(meInfo));
      if(userInfoStatus.data.isClientUser) {
        navigateTo(CLIENT_PORTAL_ROUTE);
      } else {
        navigateTo(HOME_ROUTE);
      }
    } else {
      yield put(userInfoFailure());
    }
  } catch (e) {
    yield put(userInfoFailure());
  }
}

export default [
  takeLatest(types.FETCH_USER_INFO_REQUEST, fetchUserInformation)
];
