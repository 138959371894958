import React from 'react';

import './styls.scss';
import { isNilOrEmpty } from '../../../utils/helper';

interface StatusProps {
  status: string;
}

const Status = ({ status }: StatusProps) => {
  if (isNilOrEmpty(status)) {
    return null;
  }
  // remove space from status
  const statusWithoutSpace = status.toLowerCase().replace(/\s/g, '');
  return (
    <span className={`${statusWithoutSpace}-status status-wrapper`}>
      {status.toUpperCase()}
    </span>
  );
};

export default Status;
