import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { TextField } from '@material-ui/core';

interface FieldProps {
  label: string;
  type: string;
  placeHolder: string;
  disabled: boolean;
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
}

const ReduxFormInput = (field: WrappedFieldProps & FieldProps) => {
  return (
    <div className="details-field-container">
      <TextField
        {...field.input}
        className="standard-basic"
        type={field.type}
        placeholder={field.placeHolder}
        disabled={field.disabled}
        label={field.label}
        variant={field.variant}
        multiline={field.multiline}
        rows={field.rows}
        required={field.required}
      />
    </div>
  );
};

export default ReduxFormInput;
