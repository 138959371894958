import { Button } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import * as colors from '../../../utils/colorConstants';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: colors.WHITE_COLOR,
    backgroundColor: colors.LIGHT_GREEN_COLOR,
    minWidth: '120px',
    '&:hover': {
      backgroundColor: colors.HOVER_LIGHT_GREEN_COLOR
    }
  }
}))(Button);

export default ColorButton;
