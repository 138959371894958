import fetch from 'node-fetch';
import { getLocalStorageTokens } from '../utils/helper';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || '';

export const fetchImageData = (imageUrl: any) => {
  var myHeaders = new Headers();
  let { accessToken } = getLocalStorageTokens();
  myHeaders.append('Authorization', `Bearer ${accessToken}`);

  var requestOptions: any = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return new Promise((resolve, reject) => {
    fetch(`${BASE_URL}image?image_url=${imageUrl}`, requestOptions)
      .then((response: any) => response.blob())
      .then((result: any) => {
        resolve(URL.createObjectURL(result));
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchImagesDataArray = async (imageUrlArray: string[] = []) => {
  const promisesArray = imageUrlArray.map((urlOfImage: string) => {
    return fetchImageData(urlOfImage);
  });

  return Promise.all(promisesArray).then((values: any) => {
    return values;
  });
};
