import React, { useCallback } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { WrappedFieldProps, getFormValues, change } from 'redux-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

interface FieldProps {
  name: string;
  id: string;
  label: string;
  disabled: boolean;
  optionsList: (string | undefined)[];
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
}

const ReduxFormAutocomplete = (field: WrappedFieldProps & FieldProps) => {
  const dispatch = useDispatch();

  const value:any = useSelector((state: any) =>
    R.pathOr([], [field.input.name], getFormValues(field.meta.form)(state))
  );

  const updateFormField = useCallback(
    (value: any) => dispatch(change(field.meta.form, field.input.name, value)),
    [dispatch, field.input.name, field.meta.form]
  );

  return (
    <div className="redux-form-autocomplete-multiple-container details-field-container">
      <Autocomplete
        multiple
        id={field.id}
        value={value}
        disabled={field.disabled}
        options={field.optionsList}
        getOptionLabel={(option) => R.pathOr('', ['name'], option)}
        getOptionSelected={(option, value) => {
          return (
            R.pathOr('', ['id'], option).toString() ===
            R.pathOr('', ['id'], value).toString()
          );
        }}
        renderInput={(params) => (
          <TextField {...params} variant={field.variant} label={field.label} />
        )}
        onChange={(event: any, values: any) => {
          updateFormField(values);
        }}
      />
    </div>
  );
};

export default ReduxFormAutocomplete;
