import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import * as R from 'ramda';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField
} from '@material-ui/core';
import SharedSpinner from '../shared/SharedSpinner';
import StoreState from '../../redux/utils/interfaces';
import { getMonthStartToCurrentDateRange } from '../../utils/helper';
import { INVOICE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import {
  useStyles,
  columns,
  InvoiceRow,
  filterInvoiceScreenData,
  getTotals
} from './helper';
import { fetchInvoiceScreenDataRequest } from '../../redux/actions/invoiceScreenData';

import './style.scss';
import { useSortHook } from '../../hooks/sortHooks';
import SharedTableHeader from '../shared/SharedTableHeader';

interface InvoiceScreenTableProps {
  invoiceNumberValues: {};
  batchNumberValues: {};
  setInvoiceNumberValues: Function;
  setBatchNumberValues: Function;
}

const InvoiceScreenTable = ({
  invoiceNumberValues,
  batchNumberValues,
  setInvoiceNumberValues,
  setBatchNumberValues
}: InvoiceScreenTableProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { filterFormValues, invoiceScreenData, isInvoiceScreenDataLoading } =
    useSelector((state: StoreState) => {
      return {
        filterFormValues: getFormValues(INVOICE_FILTERS_FORM)(state) || {},
        invoiceScreenData: R.pathOr(
          [],
          ['invoiceScreenData', 'invoiceData'],
          state
        ),
        isInvoiceScreenDataLoading: R.pathOr(
          false,
          ['invoiceScreenData', 'isLoading'],
          state
        )
      };
    });

  const rangeValue = R.pathOr(
    getMonthStartToCurrentDateRange(),
    ['range'],
    filterFormValues
  );
  const aircraftFilters = R.pathOr(-1, ['aircraftFilters'], filterFormValues);
  const bookingFilters = R.pathOr(-1, ['bookingFilters'], filterFormValues);
  const clientFilters = R.pathOr(-1, ['clientFilters'], filterFormValues);
  const pilotFilters = R.pathOr(-1, ['pilotFilters'], filterFormValues);
  const flightReportFilters = R.pathOr(
    -1,
    ['flightReportFilters'],
    filterFormValues
  );

  const invoiceNumberFilters = R.pathOr(
    -1,
    ['invoiceNumberFilters'],
    filterFormValues
  );

  useEffect(() => {
    dispatch(fetchInvoiceScreenDataRequest(rangeValue.from, rangeValue.to));
  }, [rangeValue, dispatch]);

  const handleInvoiceNumberChange = (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    const { name, value } = event.target;

    setInvoiceNumberValues({ ...invoiceNumberValues, [name]: value });
  };

  const handleBatchNumberChange = (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    const { name, value } = event.target;

    setBatchNumberValues({ ...batchNumberValues, [name]: value });
  };

  const filteredInvoiceScreenData = useMemo(
    () =>
      filterInvoiceScreenData(invoiceScreenData, {
        aircraftFilters,
        bookingFilters,
        clientFilters,
        invoiceNumberFilters,
        pilotFilters,
        flightReportFilters
      }),
    [
      invoiceScreenData,
      aircraftFilters,
      bookingFilters,
      clientFilters,
      invoiceNumberFilters,
      pilotFilters,
      flightReportFilters
    ]
  );

  useEffect(() => {
    const invoiceNumberValuesLocal = {};
    const batchNumberValuesLocal = {};

    filteredInvoiceScreenData.forEach((row: InvoiceRow, index: number) => {
      invoiceNumberValuesLocal[`invoiceNumber_${row.id}`] = row.invoiceNumber;
      batchNumberValuesLocal[`batchNumber_${row.id}`] = row.batchNumber;
    });
    setInvoiceNumberValues(invoiceNumberValuesLocal);
    setBatchNumberValues(batchNumberValuesLocal);
  }, [
    invoiceScreenData,
    filteredInvoiceScreenData,
    setInvoiceNumberValues,
    setBatchNumberValues
  ]);

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    dataArray: filteredInvoiceScreenData,
    columnsData: columns,
    initialSortById: 'date'
  });

  const totals = useMemo(() => getTotals(sortedDataArray), [sortedDataArray]);

  if (isInvoiceScreenDataLoading) {
    return <SharedSpinner />;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: InvoiceRow, index: number) => {
            const currentInvoiceNumberName = `invoiceNumber_${row.id}`;
            const currentBatchNumberName = `batchNumber_${row.id}`;

            return (
              <TableRow key={index} className="invoice-custom-body-row">
                <TableCell component="th" scope="row">
                  {moment.utc(row.date).format('MMM D YYYY')}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row.aircraft.registration}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {`${row.booking.bookingNumber} - ${row.booking.name}`}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row.flightReport}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row.pilot.firstName} {row.pilot.lastName}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row.client.name}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {Number(row.revHours).toFixed(1)}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {Number(row.nonRevHours).toFixed(1)}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <TextField
                    name={currentInvoiceNumberName}
                    value={invoiceNumberValues[currentInvoiceNumberName] || ''}
                    onChange={handleInvoiceNumberChange}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <TextField
                    name={currentBatchNumberName}
                    value={batchNumberValues[currentBatchNumberName] || ''}
                    onChange={handleBatchNumberChange}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow key="totals-row-invoice" className="totals-row-invoice">
            <TableCell component="th" scope="row">
              Totals
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row" align="right">
              {totals.revHours.toFixed(1)}
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              {totals.nonRevHours.toFixed(1)}
            </TableCell>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceScreenTable;
