import { put, takeLatest, all, delay } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchUsersScreenDataRequest,
  fetchUsersScreenDataSuccess,
  fetchUsersScreenDataFailure,
  fetchUsersScreenTypesSuccess,
  fetchUsersScreenTypesFailure,
  addNewUserSuccess,
  addNewUserFailure,
  updateUserSuccess,
  updateUserFailure,
  userStatusChangeSuccess,
  userStatusChangeFailure
} from '../actions/usersScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { isPresent } from '../../utils/helper';
import { navigateTo } from '../../utils/history';

interface UserPayload {
  newUserData: {};
}
interface ActionType {
  type: String;
  payload: UserPayload;
}

function* updateUserStatus(action: any) {
  yield put(showSpinner());

  const { userId, isActive } = action.payload;

  try {
    const response = yield api.updateData(
      isActive ? endpoints.BLOCK_USER : endpoints.UNBLOCK_USER,
      {},
      {
        userId
      },
      'POST'
    );

    if (response.success) {
      yield put(userStatusChangeSuccess());

      yield put(fetchUsersScreenDataRequest());
    } else {
      yield put(userStatusChangeFailure());
    }
  } catch (e) {
    yield put(userStatusChangeFailure());
  }

  yield put(hideSpinner());
}

function* updateUser(action: any) {
  yield put(showSpinner());

  const { userId, updatedData } = action.payload;

  try {
    const response = yield api.updateData(
      endpoints.UPDATE_USER(userId),
      {},
      updatedData
    );

    if (response.success) {
      yield put(updateUserSuccess());

      yield put(fetchUsersScreenDataRequest());
    } else {
      yield put(updateUserFailure());
    }
  } catch (e) {
    yield put(updateUserFailure());
  }

  yield put(hideSpinner());
}

function* addNewUser(action: ActionType) {
  yield put(showSpinner());

  const body = action.payload.newUserData;

  try {
    const response = yield api.createNewUser([body]);

    if (response.success) {
      yield put(addNewUserSuccess());

      yield put(fetchUsersScreenDataRequest());

      const userId = response.data[0].id;

      if (isPresent(userId)) {
        navigateTo(`/users/details/${userId}`);
      } else {
        navigateTo(`/users`);
      }
    } else {
      yield put(addNewUserFailure());
    }
  } catch (e) {
    yield put(addNewUserFailure());
  }

  yield put(hideSpinner());
}

function* fetchUsersScreenTypes() {
  yield delay(1000);

  try {
    const [userTypeResponse, companiesResponse]: any = yield all([
      api.fetchResponse(endpoints.WEB_USER_TYPES),
      api.fetchResponse(endpoints.WEB_COMPANY)
    ]);

    if (userTypeResponse.success && companiesResponse.success) {
      yield put(
        fetchUsersScreenTypesSuccess(
          userTypeResponse.data,
          companiesResponse.data
        )
      );
    } else {
      yield put(fetchUsersScreenTypesFailure());
    }
  } catch (e) {
    yield put(fetchUsersScreenTypesFailure());
  }
}

function* fetchUsersScreenData() {
  yield delay(1000);

  try {
    const response = yield api.fetchResponse(endpoints.USERS);

    if (response.success) {
      yield put(fetchUsersScreenDataSuccess(response.data));
    } else {
      yield put(fetchUsersScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchUsersScreenDataFailure());
  }
}

export default [
  takeLatest(types.FETCH_USERS_SCREEN_DATA_REQUEST, fetchUsersScreenData),
  takeLatest(types.FETCH_USERS_SCREEN_TYPES_REQUEST, fetchUsersScreenTypes),
  takeLatest(types.ADD_NEW_USER_REQUEST, addNewUser),
  takeLatest(types.UPDATE_USER_REQUEST, updateUser),
  takeLatest(types.USER_STATUS_CHANGE_REQUEST, updateUserStatus)
];
