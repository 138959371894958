import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import {
  aircraftTypeFilterStatePath,
  typeDesignatorFilterStatePath,
  fuelMeasureFilterStatePath,
  customerAircraftFilterStatePath,
  statusFilterStatePath,
  AircraftTypeFilterComponent,
  TypeDesignatorFilterComponent,
  FuelMeasureFilterComponent,
  CustomerAircraftFilterComponent,
  StatusFilterComponent
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export const columns: Column[] = [
  {
    id: 'registration',
    label: 'Registration',
    minWidth: 120,
    align: 'center',
    sortingProps: [['registration']]
  },
  {
    id: 'aircraftType',
    label: 'Type',
    minWidth: 90,
    align: 'center',
    sortingProps: [['aircraftType']],
    filterStatePath: aircraftTypeFilterStatePath,
    filterComponent: AircraftTypeFilterComponent
  },
  {
    id: 'typeDesignator',
    label: 'Type Designator',
    minWidth: 90,
    align: 'center',
    sortingProps: [['typeDesignator']],
    filterStatePath: typeDesignatorFilterStatePath,
    filterComponent: TypeDesignatorFilterComponent
  },
  {
    id: 'fuelMeasure',
    label: 'Fuel Measure',
    minWidth: 90,
    align: 'center',
    sortingProps: [['fuelMeasure']],
    filterStatePath: fuelMeasureFilterStatePath,
    filterComponent: FuelMeasureFilterComponent
  },
  {
    id: 'maxFuel',
    label: 'Max Fuel',
    minWidth: 90,
    align: 'center',
    sortingProps: [['maxFuel']],
    isNumber: true
  },
  {
    id: 'satPhoneNumber',
    label: 'Sat Phone',
    minWidth: 120,
    align: 'center',
    sortingProps: [['satPhoneNumber']]
  },
  {
    id: 'customerAircraft',
    label: 'Customer Aircraft',
    minWidth: 120,
    align: 'center',
    sortingProps: [['customerAircraft']],
    filterStatePath: customerAircraftFilterStatePath,
    filterComponent: CustomerAircraftFilterComponent
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    align: 'center',
    sortingProps: [['status']],
    filterStatePath: statusFilterStatePath,
    filterComponent: StatusFilterComponent
  }
];

interface AircraftOfficialType {
  id: number;
  aircraftOfficialType: {
    id: number;
    typeName: string;
  };
}
export interface AircraftRow {
  aircraftOfficialType: AircraftOfficialType;
  aircraftType: string;
  callSign: string;
  clientAircraft: boolean;
  fuelMeasure: string;
  id: number;
  maxFuel: number;
  registration: string;
  satPhoneNumber: number;
  status: string;
}

export interface FiltersProps {
  aircraftType: number;
  typeDesignator: number;
  fuelMeasure: string;
  status: string;
  customerAircraft: string;
}

const isAllAircraftTypeFilters = R.propEq('aircraftType', -1);
const isAllTypeDesignatorFilters = R.propEq('typeDesignator', -1);
const isAllFuelMeasureFilters = R.propEq('fuelMeasure', 'all');
const isAllStatusFilters = R.propEq('status', 'all');
const isAllCustomerAircraftFilters = R.propEq('customerAircraft', 'all');

const isAllFiltersAppliedEvery = R.allPass([
  isAllAircraftTypeFilters,
  isAllTypeDesignatorFilters,
  isAllFuelMeasureFilters,
  isAllStatusFilters,
  isAllCustomerAircraftFilters
]);

export const filterAircraftData = (
  aircraftArray: AircraftRow[],
  filters: FiltersProps
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return aircraftArray;
  }

  const {
    aircraftType,
    typeDesignator,
    fuelMeasure,
    status,
    customerAircraft
  } = filters;

  let filteredDataArray = aircraftArray;

  if (!isAllAircraftTypeFilters({ aircraftType })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['aircraftType'], obj), aircraftType);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllTypeDesignatorFilters({ typeDesignator })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['aircraftOfficialType', 'id'], obj), typeDesignator);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllFuelMeasureFilters({ fuelMeasure })) {
    const isFilter = (obj: {}) =>
      R.equals(R.pathOr('', ['fuelMeasure'], obj), `${fuelMeasure}`);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllStatusFilters({ status })) {
    const isFilter = (obj: {}) =>
      R.equals(R.pathOr('', ['status'], obj), `${status}`);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllCustomerAircraftFilters({ customerAircraft })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['clientAircraft'], obj), customerAircraft);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  return filteredDataArray;
};
