import * as R from 'ramda';
import { isNilOrEmpty, isPresent } from '../../utils/helper';

export interface ReceiptDetailsProps {
  id: string;
  booking: number;
  paymentMethod: string;
  expenseType: number;
  amount: string;
  correctedAmount: string;
  description: string;
  date: string;
}

export const getFormattedValues = (values: ReceiptDetailsProps) => {
  const { paymentMethod, expenseType, booking, correctedAmount } = values;

  const formattedExpenseType = { id: expenseType };
  const formattedBooking = { id: booking };

  let formattedValues: any = {
    ...values,
    ...(isPresent(paymentMethod) && { paymentType: paymentMethod }),
    expenseType: formattedExpenseType,
    booking: formattedBooking,
    correctedAmount: parseFloat(correctedAmount)
  };

  if (isNilOrEmpty(booking)) {
    formattedValues = R.omit(['booking'], formattedValues);
  }
  if (isNilOrEmpty(expenseType)) {
    formattedValues = R.omit(['expenseType'], formattedValues);
  }

  return R.omit(['date', 'amount', 'paymentMethod'], formattedValues);
};
