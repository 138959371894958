import request from '../http/request';
import { getDateString } from '../../utils/helper';

export const fetchExportData = async (dataUrl: string, body: {} | [] = {}) => {
  const method = 'POST';

  const response = await request(dataUrl, method, {}, body);

  return response;
};

export const fetchReceipt = async (date: Date, userId: string | number) => {
  const dateString = getDateString(new Date(date));

  const method = 'GET';
  const url = `weekly/flight_report?date=${dateString}&user_id=${userId}`;

  const response = await request(url, method);

  return response;
};

export const fetchFlightReportReceipt = async (reportId: string) => {
  const method = 'GET';
  const url = `flight_report_pdf?id=${reportId}`;

  const response = await request(url, method);

  return response;
};
