import types from '../utils/actionTypes';

export const fetchCustomerFlightReportRequest = (id: String) => {
  return {
    type: types.FETCH_CUSTOMER_FLIGHT_REPORT_REQUEST,
    payload: {
      id
    }
  };
};

export const fetchCustomerFlightReportSuccess = (flightReportData: []) => {
  return {
    type: types.FETCH_CUSTOMER_FLIGHT_REPORT_SUCCESS,
    payload: { flightReportData }
  };
};

export const fetchCustomerFlightReportFailure = () => {
  return {
    type: types.FETCH_CUSTOMER_FLIGHT_REPORT_FAILURE
  };
};
