import { useMemo, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, initialize } from 'redux-form';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  fetchClientUsersScreenDataRequest,
  fetchClientUsersScreenClientsRequest,
  addNewClientUserRequest,
  updateClientUserRequest,
  clientUserStatusChangeRequest
} from '../../redux/actions/clientUsersScreenData';
import StoreState from '../../redux/utils/interfaces';
import {
  CLIENT_USERS_ADD_EDIT_FORM,
  CLIENT_USERS_FILTERS_FORM
} from '../../utils/reduxFormConstants';
import { isNilOrEmpty, isPresent } from '../../utils/helper';
import {
  CLIENT_USERS_DETAILS_ROUTE,
  ADD_CLIENT_USERS_ROUTE
} from '../../utils/routesNavigationConstants';
import { initialValues } from './helper';

// interface CompanyProps {
//   id: number;
//   name: string;
// }
// interface UserTypeProps {
//   company: CompanyProps;
//   id: number;
//   name: string;
// }

export const useUsersHook = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { userId }: any = useParams();

  const isUserDetailsRouteMatch = isPresent(
    useRouteMatch(CLIENT_USERS_DETAILS_ROUTE)
  );
  const isAddUserRouteMatch = isPresent(useRouteMatch(ADD_CLIENT_USERS_ROUTE));

  const goto = useCallback((route: string) => history.push(route), [history]);

  const {
    usersData,
    clients,
    isUsersDataLoading,
    formValues,
    userInformation,
    initialFormValues,
    loggedInUserCompanyId
  } = useSelector((state: StoreState) => {
    return {
      usersData: state.clientUsersScreenData.usersData,
      clients: state.clientUsersScreenData.clients,
      isUsersDataLoading:
        state.clientUsersScreenData.isLoading ||
        state.clientUsersScreenData.isLoadingTypes,
      formValues: getFormValues(CLIENT_USERS_ADD_EDIT_FORM)(state),
      initialFormValues: R.pathOr(
        [],
        ['form', CLIENT_USERS_ADD_EDIT_FORM, 'initial'],
        state
      ),
      userInformation: state.userInformation,
      loggedInUserCompanyId: state.userInformation.companyId
    };
  });

  const showLicenseField = [1, 2, 5, 6, 7, 28, 29].includes(
    R.pathOr(-1, ['userType'], formValues)
    );

  const currentUserData = useMemo(
    () => R.find(R.propEq('id', Number(userId)))(usersData) || {},
    [usersData, userId]
  );

  useEffect(() => {
    dispatch(fetchClientUsersScreenDataRequest());
    dispatch(fetchClientUsersScreenClientsRequest());
    dispatch(initialize(CLIENT_USERS_FILTERS_FORM, initialValues));
  }, [dispatch]);

  const getUserFormValues = useCallback(() => {
    const employeeId = R.pathOr('', ['employeeId'], formValues);
    const first_name = R.pathOr('', ['firstName'], formValues);
    const last_name = R.pathOr('', ['lastName'], formValues);
    const bushpay = R.pathOr(false, ['bushpay'], formValues);
    const approvingClient = R.pathOr([], ['approvingClient'], formValues);
    const isApprovingClient = R.pathOr('', ['isApprovingClient'], formValues);
    const isReviewingClient = R.pathOr('', ['isReviewingClient'], formValues);
    const userTypeId = R.pathOr(-1, ['userType'], formValues);
    const license_1 = R.pathOr('', ['license1'], formValues);
    const supervisorId = R.pathOr(-1, ['supervisor'], formValues);
    const is_reviewing_supervisor = R.pathOr(
      false,
      ['isReviewingSupervisor'],
      formValues
    );

    const username = R.pathOr('', ['username'], formValues);
    const phone = R.pathOr('', ['phone'], formValues);
    const language = R.pathOr('', ['language'], formValues);
    const is_approving_supervisor = R.pathOr(
      false,
      ['isApprovingSupervisor'],
      formValues
    );
    const is_contractor = R.pathOr(false, ['isContractor'], formValues);

    const notes = R.pathOr('', ['notes'], formValues);

    const user_type = {};
    if (userTypeId === -1)
      user_type['id'] = loggedInUserCompanyId === 1 ? 31 : 32;
    const supervisor = {};
    if (supervisorId !== -1) supervisor['id'] = supervisorId;
    const employee_id = R.isEmpty(employeeId)
      ? `${first_name.substring(0, 4)}${Math.floor(
          Math.random() * (999 - 100 + 1) + 100
        )}`
      : employeeId;

      let approvingClients: {
        is_viewer: string;
        is_approver: string;
        client: { id: never[] };
        id:number;
      }[] = [];
      approvingClient.map((client: any) => {
        approvingClients.push({
          is_viewer: isReviewingClient,
          is_approver: isApprovingClient,
          client: { id: client.id },
          id:client.clientUserId
        });
      });
    return {
      employee_id,
      first_name,
      last_name,
      bushpay,
      clientUser: approvingClients,
      user_type,
      ...(showLicenseField && { license_1 }),
      supervisor,
      is_reviewing_supervisor,

      username,
      phone,
      language,
      is_approving_supervisor,

      is_contractor,

      company: {
        id: userInformation.companyId
      },
      is_client_user: true,
      notes
    };
  }, [formValues, userInformation.companyId, showLicenseField]);

  const saveButtonHandler = useCallback(() => {
    const userValues = getUserFormValues();

    if (isAddUserRouteMatch) {
      dispatch(addNewClientUserRequest(userValues));
    } else {
      dispatch(
        updateClientUserRequest(
          R.pathOr(-1, ['id'], currentUserData),
          userValues
        )
      );
    }
  }, [dispatch, isAddUserRouteMatch, getUserFormValues, currentUserData]);

  const toggleUserStatusHandler = useCallback(() => {
    const isActive = isNilOrEmpty(
      R.pathOr(null, ['deletedAt'], currentUserData)
    );
    const userId = R.pathOr(-1, ['id'], currentUserData);

    dispatch(clientUserStatusChangeRequest(userId, isActive));
  }, [dispatch, currentUserData]);

  const isSubmitDisabled = useMemo(() => {
    return R.equals(formValues, initialFormValues);
  }, [formValues, initialFormValues]);

  // const filteredUserTypes = useMemo(() => {
  //   return userTypes.filter((userType: UserTypeProps) => {
  //     return (
  //       R.pathOr(-1, ['company', 'id'], userType) === userInformation.companyId
  //     );
  //   });
  // }, [userTypes, userInformation.companyId]);

  const filteredUsers = useMemo(() => {
    return usersData.filter((user: any) => {
      return (
        R.pathOr(-1, ['company', 'id'], user) === userInformation.companyId
      );
    });
  }, [usersData, userInformation.companyId]);

  const supervisors = useMemo(() => {
    const supervisorsLocal = filteredUsers.filter((user: any) => {
      return user.isApprovingSupervisor || user.isReviewingSupervisor;
    });

    return supervisorsLocal.map((user: any) => {
      return {
        ...user,
        name: `${R.pathOr('', ['firstName'], user)} ${R.pathOr(
          '',
          ['lastName'],
          user
        )}`
      };
    });
  }, [filteredUsers]);

  return {
    isUsersDataLoading,
    usersData: filteredUsers,
    supervisors,
    currentUserData,
    clients,
    isSubmitDisabled,
    isUserDetailsRouteMatch,
    isAddUserRouteMatch,
    showLicenseField,
    toggleUserStatusHandler,
    saveButtonHandler,
    goto
  };
};