import types from '../utils/actionTypes';

export const fetchClientPortalScreenDataRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_CLIENT_PORTAL_SCREEN_DATA_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};

export const fetchClientPortalScreenDataSuccess = (flightReportData: []) => {
  return {
    type: types.FETCH_CLIENT_PORTAL_SCREEN_DATA_SUCCESS,
    payload: { flightReportData }
  };
};

export const fetchClientPortalScreenDataFailure = () => {
  return {
    type: types.FETCH_CLIENT_PORTAL_SCREEN_DATA_FAILURE
  };
};

export const updateClientPortalScreenDataRequest = (
  updateValues: {}[],
  isCustomerFlightReport = false
) => {
  return {
    type: types.UPDATE_CLIENT_PORTAL_SCREEN_DATA_REQUEST,
    payload: {
      updateValues,
      isCustomerFlightReport
    }
  };
};

export const updateClientPortalScreenDataSuccess = () => {
  return {
    type: types.UPDATE_CLIENT_PORTAL_SCREEN_DATA_SUCCESS
  };
};

export const updateClientPortalScreenDataFailure = () => {
  return {
    type: types.UPDATE_CLIENT_PORTAL_SCREEN_DATA_FAILURE
  };
};

export const sendClientPortalFlightReportRequest = (id:String) => {
  return {
    type: types.SEND_FLIGHT_REPORT_EMAIL_REQUEST,
    payload: {id}
  };
};

export const sendClientPortalFlightReportSuccess = () => {
  return {
    type: types.SEND_FLIGHT_REPORT_EMAIL_SUCCESS
  };
};

export const sendClientPortalFlightReportFailure = () => {
  return {
    type: types.SEND_FLIGHT_REPORT_EMAIL_FAILURE
  };
};

export const updateClientPortalFlightReportStatusRequest = (
  isLocked: boolean,
  id: string
) => {
  return {
    type: types.UPDATE_CLIENT_PORTAL_FLIGHT_REPORT_STATUS_REQUEST,
    payload: {
      isLocked,
      id
    }
  };
};

export const updateClientPortalFlightReportStatusSuccess = () => {
  return {
    type: types.UPDATE_CLIENT_PORTAL_FLIGHT_REPORT_STATUS_SUCCESS
  };
};

export const updateClientPortalFlightReportStatusFailure = () => {
  return {
    type: types.UPDATE_CLIENT_PORTAL_FLIGHT_REPORT_STATUS_FAILURE
  };
};

export const postClientPortalCommentRequest = (data:any) => {
  return {
    type: types.ADD_CLIENT_PORTAL_COMMENTS_REQUEST,
    payload: data
  };
};
export const postClientPortalCommentSuccess = (commentData:any) => {
  return {
    type: types.ADD_CLIENT_PORTAL_COMMENTS_SUCCESS,
    payload: {commentData}
  };
};
export const postClientPortalCommentFailure = () => {
  return {
    type: types.ADD_CLIENT_PORTAL_COMMENTS_FAILURE
  };
};
