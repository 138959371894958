import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import FlightReportsTable from './FlightReportsTable';
import SetStatus from '../../routes/FlightReport/SetStatus';
import { useCustomerFlightReportHook } from './hooks';
import CalendarInputRange from '../CalendarInputRange';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';

import './style.scss';

interface CustomerFlightReportsProps {
  currentSelectedStatus: any;
  setAllCurrentSelectedStatus: Function;
  setCurrentSelectedStatus: Function;
}

const CustomerFlightReports = ({
  currentSelectedStatus,
  setAllCurrentSelectedStatus,
  setCurrentSelectedStatus
}: any) => {
  const { filteredFlightReports, range, setRange } =
    useCustomerFlightReportHook();

  return (
    <div className="customer-flight-reports">
      <div className="customer-flight-reports-filters-header">
        <CalendarInputRange
          range={range}
          setRange={setRange}
          showOffFilter={false}
        />

        <SetStatus setAllCurrentSelectedStatus={setAllCurrentSelectedStatus} />
      </div>

      <FlightReportsTable
        flightReports={filteredFlightReports}
        currentSelectedStatus={currentSelectedStatus}
        setCurrentSelectedStatus={setCurrentSelectedStatus}
      />
    </div>
  );
};

export default reduxForm<InjectedFormProps, CustomerFlightReportsProps>({
  form: FLIGHT_REPORT_FILTERS_FORM
})(CustomerFlightReports);
