import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import PdfRender from '../../components/PdfRender';
import api from '../../services/ApiModule';
import { isNilOrEmpty, isPresent } from '../../utils/helper';

interface FlightReportPDFRenderProps {
  id: string;
}

const FlightReportPDFRender = ({ id }: FlightReportPDFRenderProps) => {
  const [pdf, setPdf] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchPdf = useCallback(async () => {
    if (isPresent(id)) {
      setIsLoading(true);
      const response = await api.fetchFlightReportReceipt(id);

      if (response.success) {
        if (response.data.noContent) {
          setPdf('');
        } else {
          const url = URL.createObjectURL(response.data);
          setPdf(url);
        }
      }
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchPdf();
  }, [fetchPdf]);

  if (isNilOrEmpty(id)) {
    return (
      <Typography
        variant="h4"
        gutterBottom
        className="no-receipts-available-text">
        No Flight Report Available
      </Typography>
    );
  }

  return <PdfRender pdf={pdf} isLoading={isLoading} />;
};

export default FlightReportPDFRender;
