import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import * as R from 'ramda';
import StoreState from '../../redux/utils/interfaces';
import { getMonthStartToCurrentDateRange } from '../../utils/helper';
import { ENGINEER_JOB_REVIEW_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { fetchEngineerJobReviewDataRequest } from '../../redux/actions/engineerJobReviewScreenData';
import { filterEngineerJobReviewData } from './helper';
import {
  RANGE,
  AIRCRAFT_FILTERS,
  EMPLOYEE_FILTERS,
  WORK_REPORT_FILTERS,
  WORK_TYPE_FILTERS,
  WORK_DONE_FILTERS
} from '../../routes/EngineerJobReview/helper';

export const useGetSetDataHook = () => {
  const dispatch = useDispatch();
  const {
    filterFormValues,
    engineerJobReviewData,
    isEngineerJobReviewDataLoading
  } = useSelector((state: StoreState) => {
    return {
      filterFormValues:
        getFormValues(ENGINEER_JOB_REVIEW_FILTERS_FORM)(state) || {},
      engineerJobReviewData: R.pathOr(
        [],
        ['engineerJobReviewScreenData', 'engineerJobReviewData'],
        state
      ),
      isEngineerJobReviewDataLoading: R.pathOr(
        false,
        ['engineerJobReviewScreenData', 'isLoading'],
        state
      )
    };
  });

  const rangeValue = R.pathOr(
    getMonthStartToCurrentDateRange(),
    [RANGE],
    filterFormValues
  );
  const aircraftFilters = R.pathOr(-1, [AIRCRAFT_FILTERS], filterFormValues);
  const employeeFilters = R.pathOr(-1, [EMPLOYEE_FILTERS], filterFormValues);
  const workReportFilters = R.pathOr(
    -1,
    [WORK_REPORT_FILTERS],
    filterFormValues
  );
  const workTypeFilters = R.pathOr(-1, [WORK_TYPE_FILTERS], filterFormValues);
  const workDoneFilters = R.pathOr(-1, [WORK_DONE_FILTERS], filterFormValues);

  useEffect(() => {
    dispatch(fetchEngineerJobReviewDataRequest(rangeValue.from, rangeValue.to));
  }, [rangeValue, dispatch]);

  const filteredEngineerJobReviewData = useMemo(
    () =>
      filterEngineerJobReviewData(engineerJobReviewData, {
        aircraftFilters,
        employeeFilters,
        workReportFilters,
        workTypeFilters,
        workDoneFilters
      }),
    [
      engineerJobReviewData,
      aircraftFilters,
      employeeFilters,
      workReportFilters,
      workTypeFilters,
      workDoneFilters
    ]
  );

  return {
    engineerJobReviewData: filteredEngineerJobReviewData,
    isEngineerJobReviewDataLoading
  };
};
