import types from '../utils/actionTypes';

export const fetchLogBookScreenDataRequest = (from: Date, to: Date) => {
  return {
    type: types.FETCH_LOG_BOOK_SCREEN_DATA_REQUEST,
    payload: {
      from,
      to
    }
  };
};

export const fetchLogBookScreenDataSuccess = (logBookScreenData: []) => {
  return {
    type: types.FETCH_LOG_BOOK_SCREEN_DATA_SUCCESS,
    payload: { logBookScreenData }
  };
};

export const fetchLogBookScreenDataFailure = () => {
  return {
    type: types.FETCH_LOG_BOOK_SCREEN_DATA_FAILURE
  };
};

export const resetLogBookScreenData = () => {
  return {
    type: types.RESET_LOGBOOK_SCREEN_DATA
  };
};
export const fetchLogBookScreenAricraftRequest = () => {
  return {
    type: types.FETCH_LOG_BOOK_AIRCRAFT_DATA_REQUEST
  };
};
export const fetchLogBookScreenAricraftSuccess = (aircrafts: []) => {
  return {
    type: types.FETCH_LOG_BOOK_AIRCRAFT_DATA_SUCCESS,
    payload: {
      aircrafts: aircrafts
    }
  };
};
export const fetchLogBookScreenAricraftFailure = () => {
  return {
    type: types.FETCH_LOG_BOOK_AIRCRAFT_DATA_REQUEST_FAILURE
  };
};

export const updateLogBookScreenDataRequest = (updateValues: {}[]) => {
  return {
    type: types.UPDATE_LOG_BOOK_SCREEN_DATA_REQUEST,
    payload: {
      updateValues
    }
  };
};

export const updateLogBookScreenDataSuccess = () => {
  return {
    type: types.UPDATE_LOG_BOOK_SCREEN_DATA_SUCCESS
  };
};

export const updateLogBookScreenDataFailure = () => {
  return {
    type: types.UPDATE_LOG_BOOK_SCREEN_DATA_FAILURE
  };
};

export const updateLogBookDetailScreenDataRequest = (updatedValues: {}) => {
  return {
    type: types.UPDATE_LOGBOOK_DETAILS_SCREEN_DATA_REQUEST,
    payload: {
      updatedValues
    }
  };
};

export const updateLogBookDetailScreenDataSuccess = () => {
  return {
    type: types.UPDATE_LOGBOOK_DETAILS_SCREEN_DATA_SUCCESS
  };
};

export const updateLogBookDetailScreenDataFailure = () => {
  return {
    type: types.UPDATE_LOGBOOK_DETAILS_SCREEN_DATA_FAILURE
  };
};

export const exportLogbookImageRequest = (
  image_url:string,
) => {
  return {
    type: types.EXPORT_LOGBOOK_IMAGE_REQUEST,
    payload: {
      image_url,
    }
  };
};

export const exportLogbookImageSuccess = () => {
  return {
    type: types.EXPORT_LOGBOOK_IMAGE_SUCCESS
  };
};
export const exportLogbookImageFailure = () => {
  return {
    type: types.EXPORT_LOGBOOK_IMAGE_FAILURE
  };
};
