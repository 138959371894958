import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { reduxForm } from 'redux-form';
//import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { Box, Paper, Typography } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
// import FlightReportPDFRender from './FlightReportPDFRender';
import FlightReportSecondaryHeader from '../../components/ClientPortalSecondaryHeader';
import FlightReportTable from '../../components/ClientPortalTable';
import { CLIENT_PORTAL_NAV_KEY } from '../../utils/routesNavigationConstants';
import { CLIENT_PORTAL_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { useFlightReportHook } from './flightReportHooks';
import SharedSpinner from '../../components/shared/SharedSpinner';
import CommentsSection from './CommentsSection';
import './styles.scss';
import PilotFlightReportPDFRender from './FlightReportPDFRender';

const ClientPortal = () => {
  const {
    currentSelectedStatus,
    range,
    formValues,
    isSaveButtonDisabled,
    clientPortalScreenData,
    isFlightReportPdfRoute,
    flightReportIds,
    // filteredFlightReportData,
    // setAllCurrentSelectedStatus,
    setCurrentSelectedStatus,
    setRange,
    onSaveButtonClickHandler,
    goto,
    handleonPostComment
  } = useFlightReportHook();
  const [currentSelectedPdfIndex, setCurrentSelectedPdf] = useState(
    flightReportIds[0]
  );

  // const currentFlightReportData = {
  //   isLoading: clientPortalScreenData.isLoading,
  //   flightReportData: filteredFlightReportData[currentSelectedPdfIndex],
  // };

  const currentFlightReportData = useMemo(() => {
    return {
      isLoading: clientPortalScreenData.isLoading,
      flightReportData: clientPortalScreenData.flightReportData.find(
        (flightReport: any) => flightReport.id === currentSelectedPdfIndex
      )
    };
  }, [clientPortalScreenData, currentSelectedPdfIndex]);
  useEffect(() => {
    setRange({ to: '', from: '' });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Client Portal</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={
          <FlightReportSecondaryHeader
            range={range}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isFlightReportPdfRoute={isFlightReportPdfRoute}
            currentFlightReportData={currentFlightReportData}
            setRange={setRange}
            onSaveButtonClickHandler={onSaveButtonClickHandler}
            goto={goto}
            flightReportIds={flightReportIds}
          />
        }
        navKey={CLIENT_PORTAL_NAV_KEY}>
        <Box py={2.5} className="flight-report-container">
          {clientPortalScreenData.isLoading ? (
            <SharedSpinner />
          ) : isFlightReportPdfRoute ? (
            <>
              {flightReportIds.length !== 0 ? (
                <Box>
                  <PilotFlightReportPDFRender
                    flightReportIds={flightReportIds}
                    currentSelectedPdfIndex={currentSelectedPdfIndex}
                    setCurrentSelectedPdf={setCurrentSelectedPdf}
                  />
                  <Paper elevation={2} className="comments-container">
                    <Typography variant="h6">Comments</Typography>
                    <CommentsSection
                      data={currentFlightReportData}
                      handleonPostComment={handleonPostComment}
                    />
                  </Paper>
                </Box>
              ) : (
                <Typography
                  variant="h4"
                  gutterBottom
                  className="no-receipts-available-text">
                  No Flight Report Available to Sign
                </Typography>
              )}
            </>
          ) : (
            <Fragment>
              <Box py={2.5}>
                <FlightReportTable
                  formValues={formValues}
                  flightReportScreenData={clientPortalScreenData}
                  currentSelectedStatus={currentSelectedStatus}
                  setCurrentSelectedStatus={setCurrentSelectedStatus}
                  goto={goto}
                  currentSelectedPdfIndex={currentSelectedPdfIndex}
                  setCurrentSelectedPdf={setCurrentSelectedPdf}
                  range={range}
                />
              </Box>
            </Fragment>
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: CLIENT_PORTAL_FILTERS_FORM
})(ClientPortal);
