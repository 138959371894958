import types from '../utils/actionTypes';

export const fetchFuelExpenseScreenDataRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_DATA_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};

export const fetchFuelExpenseScreenDataSuccess = (
  fuelExpenseScreenData: []
) => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_DATA_SUCCESS,
    payload: { fuelExpenseScreenData }
  };
};

export const fetchFuelExpenseScreenDataFailure = () => {
  return {
    type: types.FETCH_FUEL_EXPENSE_SCREEN_DATA_FAILURE
  };
};

export const exportRefuelImageRequest = (
  image_url:string,
) => {
  return {
    type: types.EXPORT_REFUEL_IMAGE_REQUEST,
    payload: {
      image_url
    }
  };
};

export const exportRefuelImageSuccess = () => {
  return {
    type: types.EXPORT_REFUEL_IMAGE_SUCCESS
  };
};
export const exportRefuelImageFailure = () => {
  return {
    type: types.EXPORT_REFUEL_IMAGE_FAILURE
  };
};

export const updateFuelExpenseScreenDataRequest = (updateValues: {}[]) => {
  return {
    type: types.UPDATE_FUEL_EXPENSE_SCREEN_DATA_REQUEST,
    payload: {
      updateValues
    }
  };
};

export const updateFuelExpenseScreenDataSuccess = () => {
  return {
    type: types.UPDATE_FUEL_EXPENSE_SCREEN_DATA_SUCCESS
  };
};

export const updateFuelExpenseScreenDataFailure = () => {
  return {
    type: types.UPDATE_FUEL_EXPENSE_SCREEN_DATA_FAILURE
  };
};
