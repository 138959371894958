import React, { useState } from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getWeekDays, getWeekRange } from '../../../utils/helper';
import StoreState from '../../../redux/utils/interfaces';
import { setSelectedDays } from '../../../redux/actions/weeklyScreenFilters';
import 'react-day-picker/lib/style.css';
import './style.scss';

const CalendarSelectorWeekly = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const dispatch = useDispatch();

  const { selectedFirstDate } = useSelector((state: StoreState) => ({
    selectedFirstDate: state.weeklyScreenFilters.selectedFirstDate
  }));

  const toggleShowDatePicker = () => setShowDatePicker(!showDatePicker);

  const setCurrentSelectedDays = (selectedFirstDateLocal: Date) => {
    if (showDatePicker) {
      toggleShowDatePicker();
    }
    dispatch(setSelectedDays(selectedFirstDateLocal));
  };

  const handleDayChange = (date: Date) => {
    setCurrentSelectedDays(getWeekRange(date).from);
  };

  const handleWeekClick = (weekNumber: number, days: Date[], e: any) => {
    setCurrentSelectedDays(days[0]);
  };

  const selectedDays = getWeekDays(selectedFirstDate);

  const handleWeekJump = (forwardJump: boolean) => {
    var currentWeekFirstDate = new Date(selectedDays[0]);
    if (forwardJump) {
      currentWeekFirstDate.setDate(currentWeekFirstDate.getDate() + 7);
    } else {
      currentWeekFirstDate.setDate(currentWeekFirstDate.getDate() - 7);
    }
    handleDayChange(currentWeekFirstDate);
  };

  const modifiers: any = {
    selectedRange: {
      from: selectedDays[0],
      to: selectedDays[6]
    },
    selectedRangeStart: selectedDays[0],
    selectedRangeEnd: selectedDays[6]
  };

  return (
    <div className="selected-week-calendar">
      <OutsideClickHandler
        onOutsideClick={() => {
          if (showDatePicker) {
            setShowDatePicker(false);
          }
        }}>
        <div className="calendar-actions-wrapper">
          <ArrowBackIosIcon
            className="show-cursor-pointer"
            onClick={() => handleWeekJump(false)}
          />
          <Typography
            variant="h6"
            gutterBottom
            onClick={toggleShowDatePicker}
            className="show-cursor-pointer">
            {`${moment(selectedDays[0]).format('DD MMM YYYY')} - 
            ${moment(selectedDays[6]).format('DD MMM YYYY')}`}
          </Typography>
          <ArrowForwardIosIcon
            className="show-cursor-pointer"
            onClick={() => handleWeekJump(true)}
          />
        </div>

        {showDatePicker && (
          <div className="date-picker-container">
            <DayPicker
              selectedDays={selectedDays}
              showWeekNumbers
              showOutsideDays
              modifiers={modifiers}
              onDayClick={handleDayChange}
              onWeekClick={handleWeekClick}
              month={selectedDays[0]}
            />
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default CalendarSelectorWeekly;
