export const USER_LOGIN_INVALID_CREDENTIALS_ERROR =
  'Invalid Credentials, Please try again!';

export const USER_LOGIN_UNEXPECTED_ERROR = 'Oops Something Went Wrong!';

export const USER_LOGIN_SUCCESS = 'Login Successfully!';

export const EXPORT_FAILED = 'Unable to export';

export const EXPORT_SUCCESS = 'Successfully exported';

export const BOOKING_EDIT_ERROR = 'Booking overlaps with one of user bookings';

export const CLEAR_LOCAL_STORAGE =
  'An error occurred please clear you local storage!';
