import types from '../utils/actionTypes';
export const exportBillingScreenDataRequest = (withData: false) => {
  return {
    type: types.EXPORT_BILLING_SCREEN_DATA_REQUEST,
    payload: { withData: withData }
  };
};

export const exportBillingScreenDataSuccess = () => {
  return {
    type: types.EXPORT_BILLING_SCREEN_DATA_SUCCESS
  };
};

export const exportBillingScreenDataFailure = () => {
  return {
    type: types.EXPORT_BILLING_SCREEN_DATA_FAILURE
  };
};
export const fetchInvoiceScreenDataRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_INVOICE_SCREEN_DATA_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};
export const fetchInvoiceScreenDataSuccess = (invoiceScreenData: []) => {
  return {
    type: types.FETCH_INVOICE_SCREEN_DATA_SUCCESS,
    payload: { invoiceScreenData }
  };
};

export const fetchInvoiceScreenDataFailure = () => {
  return {
    type: types.FETCH_INVOICE_SCREEN_DATA_FAILURE
  };
};

export const updateInvoiceScreenDataRequest = (updateValues: {}[]) => {
  return {
    type: types.UPDATE_INVOICE_SCREEN_DATA_REQUEST,
    payload: {
      updateValues
    }
  };
};

export const updateInvoiceScreenDataSuccess = () => {
  return {
    type: types.UPDATE_INVOICE_SCREEN_DATA_SUCCESS
  };
};

export const updateInvoiceScreenDataFailure = () => {
  return {
    type: types.UPDATE_INVOICE_SCREEN_DATA_FAILURE
  };
};
