import { Spinner, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: Spinner = { showSpinner: false };

const spinner = (state: Spinner = initialState, action: ActionType) => {
  switch (action.type) {
    case types.SHOW_SPINNER:
      return { showSpinner: true };
    case types.HIDE_SPINNER:
      return { showSpinner: false };
    default:
      return state;
  }
};

export default spinner;
