import * as R from 'ramda';
import StoreState from './interfaces';
import { getFormValues } from 'redux-form';
import { BOOKINGS_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { getMonthStartToCurrentDateRange } from '../../utils/helper';

export const getUserTokensToken = (state: StoreState) => ({
  accessToken: R.pathOr('', ['user', 'userStatus', 'accessToken'], state),
  refreshToken: R.pathOr('', ['user', 'userStatus', 'refreshToken'], state)
});

export const getDetailsScreenSelectedFields = (state: StoreState) => ({
  date: R.pathOr(
    '',
    ['detailsScreenSelectedFields', 'selectedFirstDate'],
    state
  ),
  departmentFilter: R.pathOr(
    '',
    ['detailsScreenSelectedFields', 'departmentFilter'],
    state
  ),
  statusFilter: R.pathOr(
    '',
    ['detailsScreenSelectedFields', 'statusFilter'],
    state
  ),
  firstNameFilter: R.pathOr(
    '',
    ['detailsScreenSelectedFields', 'firstNameFilter'],
    state
  ),
  lastNameFilter: R.pathOr(
    '',
    ['detailsScreenSelectedFields', 'lastNameFilter'],
    state
  ),
});

export const getBookingsFiltersValues = (state: StoreState) => {
  const { to, from } = R.pathOr(
    getMonthStartToCurrentDateRange(),
    ['range'],
    getFormValues(BOOKINGS_FILTERS_FORM)(state)
  );

  return {
    to,
    from
  };
};
