import * as R from 'ramda';
import { RowData } from '../../redux/utils/interfaces';

export const getCurrentUserIndex = (
  userId: string | undefined,
  dataArray: RowData[]
) => {
  const index = R.findIndex(R.propEq('id', userId))(dataArray);
  return index;
};

export interface KeyValueType {
  id: number;
  name: string;
}

export interface ReceiptsArrayData {
  id: string;
  booking: KeyValueType;
  paymentMethod: string;
  expenseType: KeyValueType;
  amount: number;
  correctedAmount: number;
  correctedDescription: string;
  managerComment: string;
  receiptUri: string[];
  date: Date | string;
}

export const getReceiptsData = (currentUserData: RowData) => {
  const dailies = R.pathOr([], ['dailies'], currentUserData);
  const sortedExpenseIds = R.pathOr([], ['sortedExpenseIds'], currentUserData);

  let receiptsArray: ReceiptsArrayData[] = [];

  dailies.forEach((daily: ReceiptsArrayData) => {
    const currentDailyDate = daily.date;
    let currentDailyArray: ReceiptsArrayData[] = R.pathOr(
      [],
      ['expenses', 'details'],
      daily
    );

    currentDailyArray = currentDailyArray.map(
      (currentDaily: ReceiptsArrayData) => ({
        ...currentDaily,
        date: currentDailyDate
      })
    );

    receiptsArray = receiptsArray.concat(currentDailyArray);
  });

  const sortedExpense: any = sortedExpenseIds.map((currentDailyId: string) => {
    return R.find(R.propEq('id', currentDailyId))(receiptsArray);
  });

  return sortedExpense;
};
