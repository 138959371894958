import React, { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import StoreState from '../../redux/utils/interfaces';
import TableHeader from './TableHeader';
import {
  useStyles,
  BookingsRow,
  columns,
  filterBookingsScreenData
} from './helper';
import { useSortHook } from '../../hooks/sortHooks';

import './style.scss';

interface BookingsTableProps {
  bookingsData: BookingsRow[];
  formValues: {};
  goto: Function;
}

const BookingsTable = ({
  bookingsData,
  formValues,
  goto
}: BookingsTableProps) => {
  const classes = useStyles();

  const filteredBookingsData = useMemo(
    () => filterBookingsScreenData(bookingsData, formValues),
    [bookingsData, formValues]
  );

  const { isApprovingSupervisor } = useSelector((state: StoreState) => ({
    isApprovingSupervisor: state.userInformation.isApprovingSupervisor
  }));

  const {
    order,
    orderBy,
    sortedDataArray: sortedUserDataArray,
    handleRequestSort
  } = useSortHook({
    initialSortById: 'startBooking',
    dataArray: filteredBookingsData,
    columnsData: columns
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHeader
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedUserDataArray.map((row: BookingsRow, index: number) => {
            return (
              <TableRow
                key={index}
                className="bookings-custom-body-row"
                onClick={() =>
                  isApprovingSupervisor && goto(`/bookings/details/${row.id}`)
                }>
                <TableCell component="th" scope="row">
                  {moment.utc(row.startBooking).format('MMM D YYYY')}
                </TableCell>

                <TableCell component="th" scope="row">
                  {moment.utc(row.endBooking).format('MMM D YYYY')}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.bookingNumber}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.name}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.customer.name}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BookingsTable;
