export const DEPARTMENT = 'department';
export const BUSH_PAY = 'bushPay';
export const STATUS = 'status';

export const initialValues = {
  [DEPARTMENT]: -1,
  [BUSH_PAY]: 'all',
  [STATUS]: 'all'
};

export const filtersNotWorkingOptions = [
  { name: 'Filter not working', id: -2 }
];
