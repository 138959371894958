import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import {
  aircraftFilterStatePath,
  bookingFilterStatePath,
  clientFilterStatePath,
  invoiceNumberFilterStatePath,
  AircraftFilterComponent,
  BookingFilterComponent,
  ClientFilterComponent,
  InvoiceNumberFilterComponent,
  pilotFilterStatePath,
  PilotFilterComponent,
  flightReportFilterStatePath,
  FlightReportFilterComponent
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

interface AircraftProps {
  id: number;
  registration: string;
}
interface BookingProps {
  id: number;
  name: string;
  bookingNumber: number;
}
interface ClientProps {
  id: number;
  name: string;
}
interface PilotProps {
  id: number;
  firstName: string;
  lastName: string;
}

export interface InvoiceRow {
  id: string;
  date: string;
  aircraft: AircraftProps;
  booking: BookingProps;
  flightReport: string;
  client: ClientProps;
  revHours: string;
  nonRevHours: string;
  invoiceNumber: string;
  batchNumber: string;
  pilot: PilotProps;
}

export interface FiltersProps {
  aircraftFilters: number;
  bookingFilters: number;
  clientFilters: number;
  invoiceNumberFilters: number;
  pilotFilters: number;
  flightReportFilters: number;
}

export const columns: Column[] = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 120,
    align: 'center',
    sortingProps: [['date']]
  },
  {
    id: 'aircraft',
    label: 'Aircraft',
    minWidth: 90,
    align: 'center',
    sortingProps: [['aircraft', 'registration']],
    filterComponent: AircraftFilterComponent,
    filterStatePath: aircraftFilterStatePath
  },
  {
    id: 'booking',
    label: 'Booking',
    minWidth: 200,
    align: 'center',
    sortingProps: [['booking', 'bookingNumber']],
    isNumber: true,
    filterComponent: BookingFilterComponent,
    filterStatePath: bookingFilterStatePath
  },
  {
    id: 'flightReport',
    label: 'Flight report',
    minWidth: 110,
    align: 'center',
    sortingProps: [['flightReport']],
    filterComponent: FlightReportFilterComponent,
    filterStatePath: flightReportFilterStatePath
  },
  {
    id: 'pilotName',
    label: 'Pilot',
    minWidth: 110,
    align: 'center',
    sortingProps: [['pilotName']],
    filterComponent: PilotFilterComponent,
    filterStatePath: pilotFilterStatePath
  },
  {
    id: 'client',
    label: 'Client',
    minWidth: 250,
    align: 'center',
    sortingProps: [['client', 'name']],
    filterComponent: ClientFilterComponent,
    filterStatePath: clientFilterStatePath
  },
  {
    id: 'revHours',
    label: 'Rev hours',
    minWidth: 90,
    align: 'center',
    sortingProps: [['revHours']],
    isNumber: true
  },
  {
    id: 'nonRevHours',
    label: 'Non-rev hours',
    minWidth: 90,
    align: 'center',
    sortingProps: [['nonRevHours']],
    isNumber: true
  },
  {
    id: 'invoiceNumber',
    label: 'Invoice Number',
    minWidth: 90,
    align: 'center',
    sortingProps: [['invoiceNumber']],
    filterComponent: InvoiceNumberFilterComponent,
    filterStatePath: invoiceNumberFilterStatePath
  },
  {
    id: 'batchNumber',
    label: 'Batch Number',
    minWidth: 90,
    align: 'center',
    sortingProps: [['batchNumber']]
  }
];

const isAllAircraftFilters = R.propEq('aircraftFilters', -1);
const isAllBookingFilters = R.propEq('bookingFilters', -1);
const isAllClientFilters = R.propEq('clientFilters', -1);
const isAllPilotFilters = R.propEq('pilotFilters', -1);
const isAllFlightReportFilters = R.propEq('flightReportFilters', -1);
const isAllInvoiceNumberFilters = R.propEq('invoiceNumberFilters', -1);

const isAllFiltersAppliedEvery = R.allPass([
  isAllAircraftFilters,
  isAllBookingFilters,
  isAllClientFilters,
  isAllInvoiceNumberFilters,
  isAllPilotFilters,
  isAllFlightReportFilters
]);

export const filterInvoiceScreenData = (
  invoiceScreenData: InvoiceRow[],
  filters: FiltersProps
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return invoiceScreenData;
  }

  const {
    aircraftFilters,
    bookingFilters,
    clientFilters,
    invoiceNumberFilters,
    pilotFilters,
    flightReportFilters
  } = filters;

  let filteredDataArray = invoiceScreenData;

  if (!isAllAircraftFilters({ aircraftFilters })) {
    const isFilterWithAircraft = (obj: {}) =>
      R.equals(R.path(['aircraft', 'id'], obj), aircraftFilters);

    filteredDataArray = R.filter(isFilterWithAircraft, filteredDataArray);
  }
  if (!isAllPilotFilters({ pilotFilters })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['pilot', 'id'], obj), pilotFilters);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllBookingFilters({ bookingFilters })) {
    const isFilterWithBooking = (obj: {}) =>
      R.equals(R.path(['booking', 'id'], obj), bookingFilters);

    filteredDataArray = R.filter(isFilterWithBooking, filteredDataArray);
  }

  if (!isAllFlightReportFilters({ flightReportFilters })) {
    const isFilterWithFlightReport = (obj: {}) =>
      R.equals(R.path(['flightReport'], obj), flightReportFilters);

    filteredDataArray = R.filter(isFilterWithFlightReport, filteredDataArray);
  }

  if (!isAllClientFilters({ clientFilters })) {
    const isFilterWithClient = (obj: {}) =>
      R.equals(R.path(['client', 'id'], obj), clientFilters);

    filteredDataArray = R.filter(isFilterWithClient, filteredDataArray);
  }

  if (!isAllInvoiceNumberFilters({ invoiceNumberFilters })) {
    const isFilterWithClient = (obj: {}) =>
      R.equals(
        R.toLower(R.pathOr('', ['invoiceNumber'], obj)),
        R.toLower(`${invoiceNumberFilters}`)
      );

    filteredDataArray = R.filter(isFilterWithClient, filteredDataArray);
  }

  return filteredDataArray;
};

export const getTotals = (filteredInvoiceScreenData: InvoiceRow[]) => {
  let totalRevHours = 0.0;
  let totalNonRevHours = 0.0;

  filteredInvoiceScreenData.forEach((data: InvoiceRow) => {
    totalRevHours += Number(data.revHours);
    totalNonRevHours += Number(data.nonRevHours);
  });

  return { revHours: totalRevHours, nonRevHours: totalNonRevHours };
};
