import React, { useEffect } from 'react';
import { reduxForm, initialize } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import LogBookScreenTable from '../../components/LogBookScreenTable';
import { LOGBOOK_NAV_KEY } from '../../utils/routesNavigationConstants';
import { LOG_BOOK_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { initialValues } from './helper';

import './styles.scss';
import Box from '@material-ui/core/Box';
import HeaderContainer from '../../containers/HeaderContainer';
import LogBookScreenSecondaryHeader from '../../components/LogBookSecondaryHeader';
// import { fetchLogBookScreenAricraftRequest } from '../../redux/actions/logBookScreenData';

const LogBook = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(LOG_BOOK_FILTERS_FORM, initialValues));
    // dispatch(fetchLogBookScreenAricraftRequest());
  }, [dispatch]);


  return (
    <React.Fragment>
      <Helmet>
        <title>Log Book</title>
      </Helmet>
      <HeaderContainer
        secondaryHeader={
          <LogBookScreenSecondaryHeader
            // handleSaveDataClick={handleSaveDataClick}
          />
        }
        navKey={LOGBOOK_NAV_KEY}>
          <Box py={2.5}>
            <LogBookScreenTable
            />
          </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: LOG_BOOK_FILTERS_FORM
})(LogBook);
