import { ClientUsersScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: ClientUsersScreenDataProps = {
  isLoading: false,
  isLoadingTypes: false,
  usersData: [],
  clients:[]
};

const clientUsersScreenData = (
  state: ClientUsersScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_CLIENT_USERS_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_CLIENT_USERS_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        usersData: action.payload.usersData,
        isLoading: false
      };

    case types.FETCH_CLIENT_USERS_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case types.FETCH_CLIENT_USERS_SCREEN_CLIENTS_REQUEST:
      return {
        ...state,
        isLoadingTypes: true
      };

    case types.FETCH_CLIENT_USERS_SCREEN_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload.clients,
        isLoadingTypes: false
      };

    case types.FETCH_CLIENT_USERS_SCREEN_CLIENTS_FAILURE:
      return {
        ...state,
        isLoadingTypes: false
      };

    default:
      return state;
  }
};

export default clientUsersScreenData;
