import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { reduxForm, initialize } from 'redux-form';
import { Box } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import EngineerJobReviewSecondaryHeader from '../../components/EngineerJobReviewSecondaryHeader';
import EngineerJobReviewTable from '../../components/EngineerJobReviewTable';
import { ENGINEER_JOB_REVIEW_NAV_KEY } from '../../utils/routesNavigationConstants';
import { ENGINEER_JOB_REVIEW_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { initialValues } from './helper';
import {
  fetchEngineerJobReviewAircraftFiltersRequest,
  fetchEngineerJobReviewWorkTypeFiltersRequest
} from '../../redux/actions/engineerJobReviewScreenFilters';

const EngineerJobReview = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(ENGINEER_JOB_REVIEW_FILTERS_FORM, initialValues));
    dispatch(fetchEngineerJobReviewAircraftFiltersRequest());
    dispatch(fetchEngineerJobReviewWorkTypeFiltersRequest());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Engineer Job Review</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={<EngineerJobReviewSecondaryHeader />}
        navKey={ENGINEER_JOB_REVIEW_NAV_KEY}>
        <Box py={2.5}>
          <EngineerJobReviewTable />
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: ENGINEER_JOB_REVIEW_FILTERS_FORM
})(EngineerJobReview);
