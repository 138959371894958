import { put, takeLatest, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import ApiModule from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchAircraftScreenDataSuccess,
  fetchAircraftScreenDataFailure,
  fetchAircraftOfficialTypesSuccess,
  fetchAircraftOfficialTypesFailure,
  addEditAircraftDetailsSuccess,
  addEditAircraftDetailsFailure,
  aircraftStatusChangeSuccess,
  aircraftStatusChangeFailure
} from '../actions/aircraftScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { setAircraftScreenFilters } from '../actions/aircraftScreenFilters';
import { isNilOrEmpty, isPresent } from '../../utils/helper';
import { navigateTo } from '../../utils/history';

function* fetchAircraftScreenData() {
  yield delay(1000);

  try {
    const responseAircraft = yield ApiModule.fetchResponse(
      endpoints.WEB_AIRCRAFT
    );

    if (responseAircraft.success) {
      const aircraftData: [] = R.pathOr([], ['data'], responseAircraft);

      yield put(fetchAircraftScreenDataSuccess(aircraftData));

      let aircraftTypeArray: any = R.uniq(
        aircraftData.map((aircraft: any) => aircraft.aircraftType)
      ).sort();

      aircraftTypeArray = aircraftTypeArray.filter((filter: string) =>
        isPresent(filter)
      );

      aircraftTypeArray = aircraftTypeArray.map((filter: string) => ({
        id: filter,
        name: filter
      }));

      yield put(setAircraftScreenFilters(aircraftTypeArray));
    } else {
      yield put(fetchAircraftScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchAircraftScreenDataFailure());
  }
}

function* fetchAircraftOfficialTypes() {
  yield delay(1000);

  try {
    const responseAircraftOfficialTypes = yield ApiModule.fetchResponse(
      endpoints.AIRCRAFT_OFFICIAL_TYPES
    );

    if (responseAircraftOfficialTypes.success) {
      const aircraftOfficialTypes: any = R.pathOr(
        [],
        ['data'],
        responseAircraftOfficialTypes
      );

      yield put(
        fetchAircraftOfficialTypesSuccess(
          aircraftOfficialTypes.map((aircraftOfficialType: any) => {
            return {
              id: aircraftOfficialType.id,
              name: aircraftOfficialType.typeName
            };
          })
        )
      );
    } else {
      yield put(fetchAircraftOfficialTypesFailure());
    }
  } catch (e) {
    yield put(fetchAircraftOfficialTypesFailure());
  }
}

function* setAircraftDetails(action: any) {
  yield put(showSpinner());

  const { aircraftId, values } = action.payload;

  try {
    const response = yield ApiModule.updateData(
      endpoints.WEB_AIRCRAFT,
      {},
      {
        ...values,
        ...(isPresent(aircraftId) && {
          id: aircraftId
        })
      },
      isNilOrEmpty(aircraftId) ? 'POST' : 'PUT'
    );

    yield fetchAircraftScreenData();

    if (response.success) {
      yield put(addEditAircraftDetailsSuccess());

      const aircraftId = response.data.id;

      if (isPresent(aircraftId)) {
        navigateTo(`/aircraft/details/${aircraftId}`);
      } else {
        navigateTo(`/aircraft`);
      }
    } else {
      yield put(addEditAircraftDetailsFailure());
    }
  } catch (e) {
    yield put(addEditAircraftDetailsFailure());
  }

  yield put(hideSpinner());
}

function* updateAircraftStatus(action: any) {
  yield put(showSpinner());

  const { aircraftId, isArchived } = action.payload;

  try {
    const response = yield ApiModule.updateData(
      isArchived ? endpoints.UNARCHIVE_AIRCRAFT : endpoints.ARCHIVE_AIRCRAFT,
      {},
      {
        aircraftId
      },
      'POST'
    );

    yield fetchAircraftScreenData();

    if (response.success) {
      yield put(aircraftStatusChangeSuccess());
    } else {
      yield put(aircraftStatusChangeFailure());
    }
  } catch (e) {
    yield put(aircraftStatusChangeFailure());
  }

  yield put(hideSpinner());
}

export default [
  takeLatest(types.FETCH_AIRCRAFT_SCREEN_DATA_REQUEST, fetchAircraftScreenData),
  takeLatest(
    types.FETCH_AIRCRAFT_OFFICIAL_TYPES_REQUEST,
    fetchAircraftOfficialTypes
  ),
  takeLatest(types.ADD_EDIT_AIRCRAFT_DETAILS_REQUEST, setAircraftDetails),
  takeLatest(types.UPDATE_AIRCRAFT_STATUS_REQUEST, updateAircraftStatus)
];
