import types from '../utils/actionTypes';

export const fetchFlightReportFiltersRequest = () => {
  return {
    type: types.FETCH_FLIGHT_REPORT_FILTERS_REQUEST
  };
};

export const fetchFlightReportFiltersSuccess = (
  customerFiltersArray: {}[] | {},
  aircraftFiltersArray: {}[] | {},
  pilotFiltersArray: {}[] | {},
  ameFiltersArray: {}[] | {}
) => {
  return {
    type: types.FETCH_FLIGHT_REPORT_FILTERS_SUCCESS,
    payload: {
      customerFiltersArray,
      aircraftFiltersArray,
      pilotFiltersArray,
      ameFiltersArray
    }
  };
};

export const fetchFlightReportFiltersFailure = () => {
  return {
    type: types.FETCH_FLIGHT_REPORT_FILTERS_FAILURE
  };
};

export const fetchFlightReportBookingFiltersRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_FLIGHT_REPORT_BOOKING_FILTERS_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};

export const fetchFlightReportBookingFiltersSuccess = (
  bookingFiltersArray: {}[] | {}
) => {
  return {
    type: types.FETCH_FLIGHT_REPORT_BOOKING_FILTERS_SUCCESS,
    payload: {
      bookingFiltersArray
    }
  };
};

export const fetchFlightReportBookingFiltersFailure = () => {
  return {
    type: types.FETCH_FLIGHT_REPORT_BOOKING_FILTERS_FAILURE
  };
};

export const setFlightReportStatusFilters = (statusFiltersArray: {}[]) => {
  return {
    type: types.SET_FLIGHT_REPORT_STATUS_FILTERS,
    payload: { statusFiltersArray }
  };
};
