import React, { ReactNode } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Container from '@material-ui/core/Container';
import Header from '../../components/Header';
import './style.scss';

interface HeaderContainerProps {
  secondaryHeader: ReactNode;
  navKey?: string;
  children: any;
}

function HeaderContainer(props: HeaderContainerProps) {
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar className="custom-app-bar">
        {
          <Header
            secondaryHeader={props.secondaryHeader}
            navKey={props.navKey || ''}
          />
        }
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Toolbar id="back-to-top-anchor" />
      <div className="childContainer">{props.children}</div>
    </React.Fragment>
  );
}

export default HeaderContainer;
