import { put, takeLatest, all, call, delay, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  exportWeeklyReportSuccess,
  exportWeeklyReportFailure
} from '../actions/pdfExport';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { getDateString } from '../../utils/helper';
import StoreState from '../utils/interfaces';

export interface ActionType {
  type: String;
  payload: {
    startDate: Date;
    endDate: Date;
    userIds: number[];
    reportsToDownload: {
      accpacImport: boolean;
      weeklySummary: boolean;
      receipts: boolean;
      summaryModeImages: boolean;
    };
  };
}

function* exportWeeklyReport(action: ActionType) {
  try {
    yield put(showSpinner());

    yield delay(1000);

    const { startDate, endDate, userIds, reportsToDownload } = action.payload;

    const { accpacImport, weeklySummary, receipts, summaryModeImages } =
      reportsToDownload;

    const startDateString = getDateString(new Date(startDate));
    const endDateString = getDateString(new Date(endDate));

    const body = {
      startDate: startDateString,
      endDate: endDateString,
      userIds: userIds
    };

    const requests: any = [];
    let receiptPosition = 0;
    let summaryModePosition = 0;

    if (weeklySummary) {
      requests.push(
        call(api.fetchExportData, endpoints.EXPORT_WEEKLY_REPORT, body)
      );
    }

    if (accpacImport) {
      requests.push(
        call(
          api.fetchExportData,
          endpoints.EXPORT_ACCPAC_REPORT(startDateString, endDateString),
          body
        )
      );
    }

    if (receipts) {
      const position = requests.push(
        call(api.fetchExportData, endpoints.EXPORT_WEEKLY_RECEIPT, body)
      );

      receiptPosition = position - 1;
    }

    if (summaryModeImages) {
      const position = requests.push(
        call(api.fetchExportData, endpoints.EXPORT_SUMMARY_REPORT_IMAGES, body)
      );
      summaryModePosition = position - 1;
    }

    const responseArray: any = yield all(requests);

    responseArray.forEach((response: any) => {
      if (!(response.status === 202 || response.status === 200)) {
        throw new Error('Something went wrong');
      }
    });

    yield put(exportWeeklyReportSuccess());

    const { userDataArray }: any = yield select((state: StoreState) => {
      return {
        userDataArray: state.weeklyScreenData.userDataArray
      };
    });

    const filteredArray = userDataArray.filter((data: any) => {
      return userIds.includes(Number(data.id));
    });

    if (weeklySummary) {
      toast.success(
        `${filteredArray.length} Weekly Reports exported to Google Drive`
      );
    }

    if (receipts) {
      const count = responseArray[receiptPosition].data.count;

      toast.success(`${count} Receipt PDFs exported to Google Drive`);
    }

    if (accpacImport) {
      toast.success(`Accpac Report exported to Google Drive`);
    }

    if (summaryModeImages) {
      const count = responseArray[summaryModePosition].data.count;
      toast.success(`${count} SummaryMode Report exported to Google Drive`);
    }
  } catch (error) {
    yield put(exportWeeklyReportFailure());

    toast.error(`Unable to export.`);
  } finally {
    yield put(hideSpinner());
  }
}

export default [
  takeLatest(types.EXPORT_WEEKLY_REPORT_REQUEST, exportWeeklyReport)
];
