import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Intercom from 'react-intercom';
// import humps from 'humps';
import Spinner from '../../components/shared/Spinner';
import StoreState from '../../redux/utils/interfaces';
// import { removeNullOrEmptyKeys } from '../../utils/helper';
import { fetchUserInfo } from '../../redux/actions/userInfo';
import { isTokensPresentLocalStorage } from '../../services/utils/helper';

import './style.scss';

const AppContainer: React.FunctionComponent<{
  children: any;
}> = ({ children }) => {
  const dispatch = useDispatch();

  const {
    showSpinner,
    isRefreshTokenLoading,
    isUserInfoLoading,
    userInformation
  } = useSelector((state: StoreState) => ({
    showSpinner: state.spinner.showSpinner,
    isRefreshTokenLoading: state.refreshTokens.isLoading,
    isUserInfoLoading: state.userInformation.isLoading,
    userInformation: state.userInformation
  }));

  const companyId = userInformation.companyId;

  const isUserPresent = isTokensPresentLocalStorage();

  useEffect(() => {
    if (isUserPresent && companyId === -1) {
      dispatch(fetchUserInfo());
    }
  }, [dispatch, companyId, isUserPresent]);

  // const intercomInfo = removeNullOrEmptyKeys(
  //   humps.decamelizeKeys({
  //     userId: userInformation.id,
  //     email: userInformation.email,
  //     name: `${userInformation.firstName} ${userInformation.lastName}`.trim(),
  //     company: {
  //       ...(userInformation.companyName && {
  //         name: userInformation.companyName
  //       }),
  //       ...(userInformation.companyId &&
  //         userInformation.companyId !== -1 && {
  //           companyId: userInformation.companyId
  //         })
  //     },
  //     phone: userInformation.phone,
  //     language: userInformation.language,
  //     license1: userInformation.license1,
  //     license2: userInformation.license2
  //   })
  // );

  if (isUserInfoLoading && isUserPresent) {
    return (
      <div className="app-container">
        <Spinner showSpinner={isUserInfoLoading} />
      </div>
    );
  }

  return (
    <div className="app-container">
      <Spinner showSpinner={showSpinner || isRefreshTokenLoading} />
      <div className="children-container">{children}</div>

      {/* <Intercom
        appID={process.env.REACT_APP_INTERCOM_APP_ID || ''}
        {...intercomInfo}
      /> */}
    </div>
  );
};

export default AppContainer;
