import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import StoreState from '../../redux/utils/interfaces';
import { AircraftRow, useStyles, columns, filterAircraftData } from './helper';
import { useSortHook } from '../../hooks/sortHooks';
import SharedTableHeader from '../shared/SharedTableHeader';
import {
  fuelMeasureFilterOptions,
  booleanOptions,
  statusOptions
} from '../../utils/helper';
import { AIRCRAFT_FILTERS_FORM } from '../../utils/reduxFormConstants';

import './style.scss';

interface AircraftTableProps {
  aircraftArray: AircraftRow[];
  aircraftOfficialTypes: any;
  goto: Function;
}

const AircraftTable = ({
  aircraftArray,
  aircraftOfficialTypes,
  goto
}: AircraftTableProps) => {
  const classes = useStyles();

  const { filterFormValues } = useSelector((state: StoreState) => ({
    filterFormValues: getFormValues(AIRCRAFT_FILTERS_FORM)(state) || {}
  }));

  const aircraftType = R.pathOr(-1, ['aircraftType'], filterFormValues);
  const typeDesignator = R.pathOr(-1, ['typeDesignator'], filterFormValues);
  const fuelMeasure = R.pathOr('all', ['fuelMeasure'], filterFormValues);
  const status = R.pathOr('all', ['status'], filterFormValues);
  const customerAircraft = R.pathOr(
    'all',
    ['customerAircraft'],
    filterFormValues
  );

  const filteredAircraftData = useMemo(() => {
    return filterAircraftData(aircraftArray, {
      aircraftType,
      typeDesignator,
      fuelMeasure,
      status,
      customerAircraft
    });
  }, [
    aircraftArray,
    aircraftType,
    typeDesignator,
    fuelMeasure,
    status,
    customerAircraft
  ]);

  const formattedData = useMemo(() => {
    return filteredAircraftData.map((row: AircraftRow) => {
      const fuelMeasure: any = R.find(R.propEq('id', row.fuelMeasure))(
        fuelMeasureFilterOptions
      );

      const customerAircraft: any = R.find(R.propEq('id', row.clientAircraft))(
        booleanOptions
      );

      const status: any = R.find(R.propEq('id', row.status))(statusOptions);

      const typeDesignator: any = R.find(
        R.propEq('id', R.path(['aircraftOfficialType', 'id'], row))
      )(aircraftOfficialTypes);

      return {
        id: row.id,
        registration: row.registration,
        aircraftType: row.aircraftType,
        fuelMeasure: R.pathOr('', ['name'], fuelMeasure),
        maxFuel: row.maxFuel,
        satPhoneNumber: row.satPhoneNumber,
        status: R.pathOr('', ['name'], status),
        customerAircraft: R.pathOr('', ['name'], customerAircraft),
        typeDesignator: R.pathOr('', ['name'], typeDesignator)
      };
    });
  }, [filteredAircraftData, aircraftOfficialTypes]);

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    dataArray: formattedData,
    columnsData: columns,
    initialSortById: 'registration'
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: any, index: number) => {
            return (
              <TableRow
                key={index}
                className="users-custom-body-row"
                onClick={() => goto(`/aircraft/details/${row.id}`)}>
                <TableCell component="th" scope="row">
                  {row.registration}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.aircraftType}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.typeDesignator}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.fuelMeasure}
                </TableCell>

                <TableCell component="th" scope="row" align="right">
                  {row.maxFuel}
                </TableCell>

                <TableCell component="th" scope="row" align="right">
                  {row.satPhoneNumber}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.customerAircraft}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.status}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AircraftTable;
