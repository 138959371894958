import { put, takeLatest, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchCustomersScreenDataSuccess,
  fetchCustomersScreenDataFailure,
  fetchCustomersScreenDataRequest,
  updateCustomerDataSuccess,
  updateCustomerDataFailure,
  addContactPassengerDataSuccess,
  addContactPassengerDataFailure,
  editContactPassengerDataSuccess,
  editContactPassengerDataFailure
} from '../actions/customersScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { isPresent } from '../../utils/helper';
import { navigateTo } from '../../utils/history';

export interface AddUpdateCustomerDataActionType {
  type: String;
  payload: { data: {} };
}

export interface AddUpdateContactPassengerDataActionType {
  type: String;
  payload: {
    data: {};
    isContact: boolean;
  };
}

function* editContactPassengerData(
  action: AddUpdateContactPassengerDataActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { data, isContact } = action.payload;

  try {
    const response = yield api.updateData(
      isContact ? endpoints.CUSTOMER_CONTACT : endpoints.PASSENGERS,
      {},
      data
    );

    if (response.success) {
      yield put(editContactPassengerDataSuccess());
      yield put(fetchCustomersScreenDataRequest());
    } else {
      yield put(editContactPassengerDataFailure());
    }
  } catch (e) {
    yield put(editContactPassengerDataFailure());
  }

  yield put(hideSpinner());
}

function* addContactPassengerData(
  action: AddUpdateContactPassengerDataActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { data, isContact } = action.payload;

  try {
    const response = yield api.updateData(
      isContact ? endpoints.CUSTOMER_CONTACT : endpoints.PASSENGERS,
      {},
      data,
      'POST'
    );

    if (response.success) {
      yield put(addContactPassengerDataSuccess());
      yield put(fetchCustomersScreenDataRequest());
    } else {
      yield put(addContactPassengerDataFailure());
    }
  } catch (e) {
    yield put(addContactPassengerDataFailure());
  }

  yield put(hideSpinner());
}

function* addCustomerData(action: AddUpdateCustomerDataActionType) {
  yield put(showSpinner());

  yield delay(1000);

  const { data } = action.payload;

  try {
    const response = yield api.updateData(
      endpoints.CUSTOMERS,
      {},
      data,
      'POST'
    );

    if (response.success) {
      yield put(updateCustomerDataSuccess());
      yield put(fetchCustomersScreenDataRequest());

      const id = R.path(['data', 'id'], response);

      if (isPresent(id)) {
        navigateTo(`/customers/details/${id}`);
      } else {
        navigateTo(`/customers`);
      }
    } else {
      yield put(updateCustomerDataFailure());
    }
  } catch (e) {
    yield put(updateCustomerDataFailure());
  }

  yield put(hideSpinner());
}

function* updateCustomerData(action: AddUpdateCustomerDataActionType) {
  yield put(showSpinner());

  yield delay(1000);

  const { data } = action.payload;

  try {
    const response = yield api.updateData(endpoints.CUSTOMERS, {}, data);

    if (response.success) {
      yield put(updateCustomerDataSuccess());
      yield put(fetchCustomersScreenDataRequest());
    } else {
      yield put(updateCustomerDataFailure());
    }
  } catch (e) {
    yield put(updateCustomerDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchCustomersScreenData() {
  yield delay(1000);

  try {
    const response = yield api.fetchResponse(endpoints.CUSTOMER_DETAILS);

    if (response.success) {
      yield put(fetchCustomersScreenDataSuccess(response.data));
    } else {
      yield put(fetchCustomersScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchCustomersScreenDataFailure());
  }
}

export default [
  takeLatest(
    types.FETCH_CUSTOMERS_SCREEN_DATA_REQUEST,
    fetchCustomersScreenData
  ),
  takeLatest(types.UPDATE_CUSTOMER_DATA_REQUEST, updateCustomerData),
  takeLatest(types.ADD_CUSTOMER_DATA_REQUEST, addCustomerData),
  takeLatest(types.ADD_CONTACT_PASSENGER_DATA_REQUEST, addContactPassengerData),
  takeLatest(
    types.EDIT_CONTACT_PASSENGER_DATA_REQUEST,
    editContactPassengerData
  )
];
