import React from 'react';

interface UsersDetailsHeaderProps {
  isUserDetailsRouteMatch: boolean;
  isAddUserRouteMatch: boolean;
  currentUserId: number;
  goto: Function;
}

const UsersDetailsHeader = ({
  isUserDetailsRouteMatch,
  isAddUserRouteMatch,
  currentUserId,
  goto
}: UsersDetailsHeaderProps) => {
  const routeChange = (route: string) => {
    if (!isAddUserRouteMatch) {
      if (!isUserDetailsRouteMatch && route === 'details') {
        goto(`/users/details/${currentUserId}`);
      }
    }
  };

  return (
    <div className="users-details-table-header">
      <div
        className={`users-details-table-header-options ${
          isUserDetailsRouteMatch || isAddUserRouteMatch
            ? 'users-details-table-header-options-selected'
            : ''
        }`}
        onClick={() => routeChange('details')}>
        DETAILS
      </div>
      {
        !isAddUserRouteMatch && null
        // <div
        //   className={`users-details-table-header-options ${
        //     isFlightReportsRouteMatch
        //       ? 'users-details-table-header-options-selected'
        //       : ''
        //   }`}
        //   onClick={() => routeChange('flightReports')}>
        //   FLIGHT REPORT
        // </div>
      }
    </div>
  );
};

export default UsersDetailsHeader;
