import { useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { change, initialize, getFormValues } from 'redux-form';
import { filterFlightReportScreenData } from './helper';
import {
  fetchFlightReportFiltersRequest,
  fetchFlightReportBookingFiltersRequest
} from '../../redux/actions/flightReportScreenFilters';
import StoreState from '../../redux/utils/interfaces';
import {
  initialValues,
  RANGE,
  RangeProps
} from '../../routes/FlightReport/helper';
import { FLIGHT_REPORT_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { getMonthStartToCurrentDateRange } from '../../utils/helper';
import { useParams } from 'react-router-dom';
import { fetchCustomerFlightReportRequest } from '../../redux/actions/customerFlightReport';

export const useCustomerFlightReportHook = () => {
  const dispatch = useDispatch();
  const { customerId }: any = useParams();

  const { formValues } = useSelector((state: StoreState) => {
    return {
      formValues: getFormValues(FLIGHT_REPORT_FILTERS_FORM)(state) || {}
    };
  });

  const { flightReports } = useSelector((state: StoreState) => {
    return {
      flightReports: state.customerFlightReport.flightReports
    };
  });

  const range = R.pathOr(
    getMonthStartToCurrentDateRange(),
    ['range'],
    formValues
  );

  const updateFormField = useCallback(
    (key: string, value: any) =>
      dispatch(change(FLIGHT_REPORT_FILTERS_FORM, key, value)),
    [dispatch]
  );

  const setRangeHandler = useCallback(
    (range: RangeProps) => {
      updateFormField(RANGE, range);
    },
    [updateFormField]
  );

  useEffect(() => {
    dispatch(initialize(FLIGHT_REPORT_FILTERS_FORM, initialValues));
    dispatch(fetchCustomerFlightReportRequest(customerId.toString()));
    dispatch(fetchFlightReportFiltersRequest());
  }, [dispatch, customerId]);

  useEffect(() => {
    dispatch(fetchFlightReportBookingFiltersRequest(range.from, range.to));
  }, [dispatch, range]);

  const filteredFlightReports = useMemo(() => {
    return filterFlightReportScreenData(flightReports, formValues);
  }, [flightReports, formValues]);

  return {
    filteredFlightReports,
    range,
    setRange: setRangeHandler
  };
};
