import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  AIRCRAFT_FILTERS,
  BOOKING_FILTERS,
  CUSTOMER_FILTERS,
  PILOT_FILTERS,
  STATUS_FILTERS,
  CONTRACT_FILTERS,
  FLIGHT_TICKET_FILTERS,
  INVOICE_NUMBER_FILTERS,
  // filtersNotWorkingOptions
} from '../../routes/ClientPortal/helper';
import { CLIENT_PORTAL_FILTERS_FORM } from '../../utils/reduxFormConstants';

const commonFilterPath = ['form', CLIENT_PORTAL_FILTERS_FORM, 'values'];

export const bookingFilterStatePath = [...commonFilterPath, BOOKING_FILTERS];
export const customerFilterStatePath = [...commonFilterPath, CUSTOMER_FILTERS];
export const aircraftFilterStatePath = [...commonFilterPath, AIRCRAFT_FILTERS];
export const pilotFilterStatePath = [...commonFilterPath, PILOT_FILTERS];
export const contractFilterStatePath = [...commonFilterPath, CONTRACT_FILTERS];
// export const ameFilterStatePath = [...commonFilterPath, AME_FILTERS];
export const statusFilterStatePath = [...commonFilterPath, STATUS_FILTERS];
export const flightTicketsFilterStatePath = [...commonFilterPath, FLIGHT_TICKET_FILTERS];
export const invoiceNumberFilterStatePath = [...commonFilterPath, INVOICE_NUMBER_FILTERS];

export const BookingFilterComponent = () => {
  // const { bookingFiltersArray } = useSelector((state: StoreState) => ({
  //   bookingFiltersArray: R.pathOr(
  //     filtersNotWorkingOptions,
  //     ['flightReportScreenFilters', 'bookingFiltersArray'],
  //     state
  //   )
  // }));
  const { clientPortalScreenData } = useSelector((state: StoreState) => ({
    clientPortalScreenData: state.clientPortalScreenData.flightReportData
  }));
  const bookingFiltersArray = useMemo(() => {
    let bookingData: any[] = clientPortalScreenData;

    const bookings = new Set<{ id: number | string; name: string }>();

    bookingData.forEach((data, index) => {
      bookings.add({ id: data.booking.id, name: `${data.booking.bookingNumber} - ${data.booking.name}` });
    });

    let bookingFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    bookings.forEach((data, key) => {
      bookingFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(bookingFiltersArray);
  }, [clientPortalScreenData]);

  return (
    <Field
      name={BOOKING_FILTERS}
      component={ReduxFormAutocomplete}
      label="Booking"
      optionsList={bookingFiltersArray}
      required
    />
  );
};

export const CustomerFilterComponent = () => {
  const { clientPortalScreenData } = useSelector((state: StoreState) => ({
    clientPortalScreenData: state.clientPortalScreenData.flightReportData
  }));
  const customersFiltersArray = useMemo(() => {
    let customerData: any[] = clientPortalScreenData;

    const customers = new Set<{ id: number | string; name: string }>();

    customerData.forEach((data, index) => {
      customers.add({ id: data.customer.id, name: data.customer.name });
    });

    let customersFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    customers.forEach((data, key) => {
      customersFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(customersFiltersArray);
  }, [clientPortalScreenData]);

  return (
    <Field
      name={CUSTOMER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Customer"
      optionsList={customersFiltersArray}
      required
    />
  );
};
export const ContractFilterComponent = () => {
  const { clientPortalScreenData } = useSelector((state: StoreState) => ({
    clientPortalScreenData: state.clientPortalScreenData.flightReportData
  }));
  const contractFiltersArray = useMemo(() => {
    let contractsData: any[] = clientPortalScreenData;

    const contracts = new Set<{ id: number | string; name: string }>();

    contractsData.forEach((data, index) => {
      if(data.contract) {
      contracts.add({ id: data.contract, name: data.contract });
      }
    });

    let contractFiltersArray: { id: string | number; name: string }[] = [
      { id: "all", name: 'All' }
    ];

    contracts.forEach((data, key) => {
      contractFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(contractFiltersArray);
  }, [clientPortalScreenData]);

  return (
    <Field
      name={CONTRACT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Contracts"
      optionsList={contractFiltersArray}
      required
    />
  );
};

export const AircraftFilterComponent = () => {
  const { clientPortalScreenData } = useSelector((state: StoreState) => ({
    clientPortalScreenData: state.clientPortalScreenData.flightReportData
  }));

  const aircraftFiltersArray = useMemo(() => {
    let aircraftsData: any[] = clientPortalScreenData;

    const aircrafts = new Set<{ id: number | string; name: string }>();

    aircraftsData.forEach((data, index) => {
      aircrafts.add({ id: data.aircraft.id, name: data.aircraft.registration });
    });

    let aircraftFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    aircrafts.forEach((data, key) => {
      aircraftFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(aircraftFiltersArray);
  }, [clientPortalScreenData]);

  return (
    <Field
      name={AIRCRAFT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Aircraft"
      optionsList={aircraftFiltersArray}
      required
    />
  );
};
export const FlightTicketsFilterComponent = () => {
  const { clientPortalScreenData } = useSelector((state: StoreState) => ({
    clientPortalScreenData: state.clientPortalScreenData.flightReportData
  }));

  const flightReportTicketsFiltersArray = useMemo(() => {
    let flightReportTicketsData: any[] = clientPortalScreenData;

    const flightReportTickets = new Set<{ id: number | string; name: string }>();

    flightReportTicketsData.forEach((data, index) => {
      flightReportTickets.add({ id: data.flightReport, name: data.flightReport });
    });

    let flightReportTicketsFiltersArray: { id: string | number; name: string }[] = [
      { id: 'all', name: 'All' }
    ];

    flightReportTickets.forEach((data, key) => {
      flightReportTicketsFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(flightReportTicketsFiltersArray);
  }, [clientPortalScreenData]);

  return (
    <Field
      name={FLIGHT_TICKET_FILTERS}
      component={ReduxFormAutocomplete}
      label="Flight Tickets"
      optionsList={flightReportTicketsFiltersArray}
      required
    />
  );
};

export const InvoiceNumberFilterComponent = () => {
  const { clientPortalScreenData } = useSelector((state: StoreState) => ({
    clientPortalScreenData: state.clientPortalScreenData.flightReportData
  }));

  const invoiceNumberFiltersArray = useMemo(() => {
    let invoiceNumberData: any[] = clientPortalScreenData;

    const invoiceNumbers = new Set<{ id: number | string; name: string }>();

    invoiceNumberData.forEach((data, index) => {
      if(data.invoiceNumber)
      invoiceNumbers.add({ id: data.invoiceNumber, name: data.invoiceNumber });
    });

    let invoiceNumberFiltersArray: { id: string | number; name: string }[] = [
      { id: 'all', name: 'All' }
    ];

    invoiceNumbers.forEach((data, key) => {
      invoiceNumberFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(invoiceNumberFiltersArray);
  }, [clientPortalScreenData]);

  return (
    <Field
      name={INVOICE_NUMBER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Invoice Numbers"
      optionsList={invoiceNumberFiltersArray}
      required
    />
  );
};

export const PilotFilterComponent = () => {
  const { clientPortalScreenData } = useSelector((state: StoreState) => ({
    clientPortalScreenData: state.clientPortalScreenData.flightReportData
  }));
  const pilotFiltersArray = useMemo(() => {
    let pilotssData: any[] = clientPortalScreenData;

    const pilots = new Set<{ id: number | string; name: string }>();

    pilotssData.forEach((data, index) => {
      pilots.add({
        id: data.pilot.id,
        name: `${data.pilot.firstName} ${data.pilot.lastName}`
      });
    });

    let pilotFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    pilots.forEach((data, key) => {
      pilotFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(pilotFiltersArray);
  }, [clientPortalScreenData]);
  // const { pilotFiltersArray } = useSelector((state: StoreState) => ({
  //   pilotFiltersArray: R.pathOr(
  //     filtersNotWorkingOptions,
  //     ['flightReportScreenFilters', 'pilotFiltersArray'],
  //     state
  //   )
  // }));

  return (
    <Field
      name={PILOT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Pilot"
      optionsList={pilotFiltersArray}
      required
    />
  );
};

export const StatusFilterComponent = () => {
  // const { statusFiltersArray } = useSelector((state: StoreState) => ({
  //   statusFiltersArray: R.pathOr(
  //     filtersNotWorkingOptions,
  //     ['flightReportScreenFilters', 'statusFiltersArray'],
  //     state
  //   )
  // }));
  const statusFiltersArray = [
    {id:'all', name:'All'},
    {id:'Ready to sign', name:'Ready to sign'},
    {id:'Signed On Mobile', name:'Signed On Mobile'},
    {id:'Signed', name:'Signed'},
    {id:'No Signature', name:'No Signature'},
    {id:'Not Locked', name:'Not Locked'},
  ]

  return (
    <Field
      name={STATUS_FILTERS}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={statusFiltersArray}
      required
    />
  );
};
