import {
  put,
  takeLatest,
  select,
  delay,
  call
} from 'redux-saga/effects';
// import { getFormValues } from 'redux-form';
import * as R from 'ramda';

import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchLogBookScreenDataRequest,
  fetchLogBookScreenDataFailure,
  fetchLogBookScreenDataSuccess,
  fetchLogBookScreenAricraftSuccess,
  fetchLogBookScreenAricraftFailure,
  updateLogBookScreenDataSuccess,
  updateLogBookScreenDataFailure,
  exportLogbookImageSuccess,
  exportLogbookImageFailure
} from '../actions/logBookScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { LOG_BOOK_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../utils/interfaces';
import {
  //isPresent,
  getDateString
} from '../../utils/helper';
import { getFormValues } from 'redux-form';
import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';

interface FetchLogBookScreenDataActionType {
  type: String;
  payload: { from: Date; to: Date };
}
export interface UpdateLogBookDataActionType {
  type: String;
  payload: { updatedValues: {} };
}
interface exportLogbookImageActionType {
  type: String;
  payload: {image_url: String}
  }

// interface UpdateLogBookScreenDataActionType {
//   type: String;
//   payload: { updateValues: {}[] };
// }
// interface exportRefuelImageActionType {
// type: String;
// payload: {image_url: String}
// }

function* updateLogBookScreenData(
  action: UpdateLogBookDataActionType
) {
  yield put(showSpinner());

  yield delay(1000);

  const { updatedValues } = action.payload;

  try {
    const status = yield api.updateData(
      endpoints.LOGBOOK,
      {},
      updatedValues
    );

    if (status.success) {
      yield put(updateLogBookScreenDataSuccess());

      const {
        formValues: { range }
      } = yield select((state: StoreState) => {
        return {
          formValues: getFormValues(LOG_BOOK_FILTERS_FORM)(state) || {}
        };
      });

      yield put(fetchLogBookScreenDataRequest(range.from, range.to));
    } else {
      yield put(updateLogBookScreenDataFailure());
    }
  } catch (e) {
    yield put(updateLogBookScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchLogBookScreenData(action: FetchLogBookScreenDataActionType) {
  yield delay(1000);

  const { from, to } = action.payload;

  const startDateString = getDateString(new Date(from));
  const endDateString = getDateString(new Date(to));

  try {
    const logBookScreenDataResponse = yield api.fetchResponse(
      endpoints.LOGBOOK_RANGE(startDateString, endDateString)
    );

    if (logBookScreenDataResponse.success) {

      yield put(
        fetchLogBookScreenDataSuccess(logBookScreenDataResponse.data)
      );

      //   const mapInIdName = (filterArray: []) => {
      //     const filteredArray = filterArray.filter((filter: string) =>
      //       isPresent(filter)
      //     );

      //     return filteredArray.map((filter: string) => {
      //       return {
      //         id: filter.toLowerCase(),
      //         name: filter
      //       };
      //     });
      //   };

      //   const { vendors, documentNumbers, vendorInvoiceNumbers } =
      //     fuelExpenseScreenDataResponse.data.metaData;

      //   yield put(setFuelExpenseScreenVendorFilters(mapInIdName(vendors)));
      //   yield put(
      //     setFuelExpenseScreenDocumentHashFilters(mapInIdName(documentNumbers))
      //   );
      //   yield put(
      //     setFuelExpenseScreenInvoiceNumberFilters(
      //       mapInIdName(vendorInvoiceNumbers)
      //     )
      //   );
    } else {
      yield put(fetchLogBookScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchLogBookScreenDataFailure());
  }
}

function* exportLogbookImage(
  action:exportLogbookImageActionType
) {
  try{
    yield put(showSpinner());
    // yield delay(1000);
    const { image_url} = action.payload;
    const body = {
      image_url: image_url,
      isLogbook:true
    }
    const response: any= yield call(api.fetchExportData, endpoints.EXPORT_REFUEL_IMAGE, body);
    if (!(response.status === 202 || response.status === 200)) {
      throw new Error('Something went wrong');
    }
    yield put(exportLogbookImageSuccess());
    toast.success(
      `Image exported to Google Drive`
    );
  }catch(error){
    console.log(error)
    yield put(exportLogbookImageFailure());
    toast.error(`Unable to export.`);
  } finally {
    yield put(hideSpinner());
  }
}

function* fetchAircrafts() {
  yield delay(1000);

  try {
    const responseAircraft = yield api.fetchResponse(
      endpoints.WEB_AIRCRAFT
    );

    if (responseAircraft.success) {
      const mappedData = responseAircraft.data.map((data: {}) => {
        const name = R.pathOr('', ['registration'], data);

        return {
          id: R.pathOr(0, ['id'], data),
          name: `${name}`
        };
      });
      yield put(fetchLogBookScreenAricraftSuccess(mappedData));
    } else {
      yield put(fetchLogBookScreenAricraftFailure());
    }
  } catch (e) {
    yield put(fetchLogBookScreenAricraftFailure());
  }
}
export default [
  takeLatest(types.FETCH_LOG_BOOK_SCREEN_DATA_REQUEST, fetchLogBookScreenData),
  takeLatest(types.UPDATE_LOGBOOK_DETAILS_SCREEN_DATA_REQUEST, updateLogBookScreenData),
  takeLatest(types.FETCH_LOG_BOOK_AIRCRAFT_DATA_REQUEST, fetchAircrafts),
    takeLatest(
      types.EXPORT_LOGBOOK_IMAGE_REQUEST,
      exportLogbookImage
    )
];
