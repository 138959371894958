import React from 'react';
import Dropdown from '../../components/shared/Dropdown';
import { statusFilters } from './helper';

interface SetStatusProps {
  setAllCurrentSelectedStatus: Function;
}

const SetStatus = ({ setAllCurrentSelectedStatus }: SetStatusProps) => {
  return (
    <div className="set-status-all">
      <Dropdown
        label="Set Status"
        variant="filled"
        optionsList={statusFilters}
        handleChange={(
          event: React.ChangeEvent<{ name?: string; value: unknown }>
        ) => {
          const { value } = event.target;
          setAllCurrentSelectedStatus(value);
        }}
      />
    </div>
  );
};

export default SetStatus;
