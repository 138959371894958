import React, { useMemo, useCallback, useState, useEffect } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterAltIcon from '../../../assets/FilterAltIcon';
import StoreState from '../../../redux/utils/interfaces';
import { isNilOrEmpty, isPresent } from '../../../utils/helper';

import './style.scss';

type Order = 'asc' | 'desc';

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  filterComponent?: any;
  filterStatePath?: string[];
}

interface SharedTableHeaderProps {
  columns: Column[];
  handleRequestSort: Function;
  order: Order;
  orderBy: string;
  secondaryVariant?: boolean;
}

interface TableCellMenuProps {
  order: Order;
  orderBy: string;
  column: Column;
  handleRequestSort: Function;
}

const TableCellMenu = ({
  order,
  orderBy,
  column,
  handleRequestSort
}: TableCellMenuProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const filterValue: any = useSelector((state: StoreState) => {
    if (isNilOrEmpty(column.filterStatePath)) {
      return null;
    }

    return R.pathOr(null, column.filterStatePath || [], state);
  });

  const { filterComponent: FilterComponent } = column;
  const isSelectedOrder = orderBy === column.id;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { isPresentFilterComponent, isSelected } = useMemo(() => {
    return {
      isPresentFilterComponent:
        isPresent(FilterComponent) && isPresent(filterValue),
      isSelected:
        isPresent(filterValue) && filterValue !== 'all' && filterValue !== -1
    };
  }, [FilterComponent, filterValue]);

  useEffect(() => {
    if (isPresent(filterValue)) {
      handleClose();
    }
  }, [filterValue, handleClose]);

  return (
    <TableCell
      ref={anchorRef}
      className={`custom_header-row-column ${
        open ? 'custom_header-row-column-selected' : ''
      }`}
      style={{ minWidth: column.minWidth }}
      align={column.align}
      onClick={() => {
        setOpen((openLocal) => !openLocal);
      }}>
      <div className="custom_header-row-column-title-container">
        <TableSortLabel
          active={orderBy === column.id}
          direction={orderBy === column.id ? order : 'asc'}>
          {column.label}
        </TableSortLabel>

        {isPresentFilterComponent &&
          (!isSelected ? <FilterListIcon /> : <FilterAltIcon />)}
      </div>

      <Popper
        className="popper-custom-class"
        open={open}
        anchorEl={anchorRef.current}
        disablePortal>
        <Box
          className={`menu-container ${
            isPresentFilterComponent ? 'menu-container-big' : ''
          }`}>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper
              className="menu-wrapper"
              onClick={(e: React.MouseEvent<EventTarget>) => {
                e.stopPropagation();
              }}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                <MenuItem
                  selected={isSelectedOrder && order === 'asc'}
                  onClick={() => {
                    handleClose();
                    handleRequestSort('asc', column.id);
                  }}>
                  Sort A → Z
                </MenuItem>
                <MenuItem
                  selected={isSelectedOrder && order === 'desc'}
                  onClick={() => {
                    handleClose();
                    handleRequestSort('desc', column.id);
                  }}>
                  Sort Z → A
                </MenuItem>

                {isPresentFilterComponent && <Divider component="li" />}
              </MenuList>

              {isPresentFilterComponent && (
                <div className="filter-container">
                  <Typography variant="button" align="left" gutterBottom>
                    Filter
                  </Typography>

                  <FilterComponent />
                </div>
              )}
            </Paper>
          </ClickAwayListener>
        </Box>
      </Popper>
    </TableCell>
  );
};

const SharedTableHeader = ({
  columns,
  order,
  orderBy,
  handleRequestSort,
  secondaryVariant = false
}: SharedTableHeaderProps) => {
  return (
    <TableHead className="shared-table-header">
      <TableRow
        className={
          secondaryVariant
            ? 'custom_header-row-second-variant'
            : 'custom_header-row'
        }>
        {columns.map((column: Column) => (
          <TableCellMenu
            order={order}
            orderBy={orderBy}
            key={column.id}
            column={column}
            handleRequestSort={handleRequestSort}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SharedTableHeader;
