import { ActionType, RefreshTokens } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: RefreshTokens = {
  isLoading: false
};

const refreshTokens = (
  state: RefreshTokens = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.REFRESH_TOKENS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.REFRESH_TOKENS_SUCCESS:
    case types.REFRESH_TOKENS_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

export default refreshTokens;
