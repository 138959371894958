import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export interface EngineerJobReviewRow {
  date: string;
  aircraft: string;
  booking: string;
  client: string;
  flightReport: string;
  workReport: string;
  workType: string;
  comments: string;
  description: string;
  hours: string;
  flightPay: string;
}

export const columnsEngineer: Column[] = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 120,
    align: 'center',
    sortingProps: [['date']]
  },
  {
    id: 'aircraft',
    label: 'Aircraft',
    minWidth: 90,
    align: 'center',
    sortingProps: [['aircraft']]
  },
  {
    id: 'booking',
    label: 'Booking',
    minWidth: 90,
    align: 'center',
    sortingProps: [['booking']],
    isNumber: true
  },
  {
    id: 'client',
    label: 'Client',
    minWidth: 90,
    align: 'center',
    sortingProps: [['client']]
  },
  {
    id: 'flightReport',
    label: 'Flight Report',
    minWidth: 90,
    align: 'center',
    sortingProps: [['flightReport']]
  },
  {
    id: 'workReport',
    label: 'Work Report',
    minWidth: 90,
    align: 'center',
    sortingProps: [['workReport']]
  },
  {
    id: 'workType',
    label: 'Work Type',
    minWidth: 90,
    align: 'center',
    sortingProps: [['workType']]
  },
  {
    id: 'description',
    label: 'Work Description',
    minWidth: 200,
    align: 'center',
    sortingProps: [['description']]
  },
  {
    id: 'comments',
    label: 'Comments',
    minWidth: 200,
    align: 'center',
    sortingProps: [['comments']]
  },
  {
    id: 'hours',
    label: 'Hours',
    minWidth: 80,
    align: 'center',
    sortingProps: [['hours']]
  },
  {
    id: 'flightPay',
    label: 'Flight Pay',
    minWidth: 80,
    align: 'center',
    sortingProps: [['flightPay']],
    isNumber: true
  }
];

export const columnsAdmin: Column[] = [
  {
    id: 'date',
    label: 'Date',
    minWidth: 120,
    align: 'center',
    sortingProps: [['date']]
  },
  {
    id: 'workType',
    label: 'Work Type',
    minWidth: 170,
    align: 'center',
    sortingProps: [['workType']]
  },
  {
    id: 'comments',
    label: 'Comments',
    minWidth: 830,
    align: 'center',
    sortingProps: [['comments']]
  },
  {
    id: 'hours',
    label: 'Hours',
    minWidth: 50,
    align: 'center',
    sortingProps: [['hours']]
  }
];
