import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import * as R from 'ramda';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { Box } from '@material-ui/core';
import Breadcrumbs from '../shared/Breadcrumbs';
import CalendarInputRange from '../CalendarInputRange';
import { INVOICE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import { RANGE, RangeProps } from '../../routes/Invoice/helper';
import StoreState from '../../redux/utils/interfaces';
import { getWeekRange } from '../../utils/helper';
import { fetchInvoiceScreenBookingFiltersRequest } from '../../redux/actions/invoiceScreenFilters';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

import './style.scss';
import { exportBillingScreenDataRequest } from '../../redux/actions/invoiceScreenData';

interface InvoiceSecondaryHeadersProps {
  isSaveButtonDisabled: boolean;
  handleSaveDataClick: Function;
}

const InvoiceSecondaryHeaders = ({
  isSaveButtonDisabled,
  handleSaveDataClick
}: InvoiceSecondaryHeadersProps) => {
  const dispatch = useDispatch();
  const handleCloudDownloadButtonClick = useCallback(
    (withData) => {
      dispatch(exportBillingScreenDataRequest(withData));
    },
    [dispatch]
  );
  const { formValues } = useSelector((state: StoreState) => {
    return {
      formValues: getFormValues(INVOICE_FILTERS_FORM)(state) || {}
    };
  });

  const rangeValue = R.pathOr(getWeekRange(), ['range'], formValues);

  useEffect(() => {
    dispatch(
      fetchInvoiceScreenBookingFiltersRequest(rangeValue.from, rangeValue.to)
    );
  }, [rangeValue, dispatch]);

  const updateFormField = (key: string, value: any) =>
    dispatch(change(INVOICE_FILTERS_FORM, key, value));

  const setRangeHandler = (range: RangeProps) => {
    updateFormField(RANGE, range);
  };

  const actions = useMemo(() => {
    return [
      {
        icon: SaveIcon,
        name: 'Save',
        disabled: isSaveButtonDisabled,
        onClick: handleSaveDataClick,
        showToast: false
      },
      {
        icon: CloudDownloadOutlinedIcon,
        name: 'Download with Data',
        onClick: () => handleCloudDownloadButtonClick(true)
      }
    ];
  }, [isSaveButtonDisabled, handleSaveDataClick]);

  return (
    <React.Fragment>
      <Box mr={5}>
        <Breadcrumbs
          breadcrumbDataArray={[
            {
              id: 'route_name',
              label: 'Billings'
            }
          ]}
        />
      </Box>

      <div className="invoice-screen-dropdown-container">
        <CalendarInputRange
          range={rangeValue}
          setRange={setRangeHandler}
          showOffFilter={false}
        />
      </div>

      <CustomSpeedDial actions={actions} />
    </React.Fragment>
  );
};

export default InvoiceSecondaryHeaders;
