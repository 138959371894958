import { makeStyles } from '@material-ui/core/styles';
import { isNilOrEmpty } from '../../utils/helper';
import * as R from 'ramda';
import { AircraftFilterComponent, aircraftFilterStatePath, BookFilterComponent, bookNumberFilterStatePath, PageFilterComponent, pageNumberFilterStatePath, uploadedByFilterStatePath, UsersFilterComponent } from './FiltersComponents';

export interface FiltersProps {
  aircraftFilters: number;
  bookNumberFilters: number;
  pageNumberFilters: number;
  uploadedbyFilters: number;
}
const isAllAircraftFilters = R.propEq('aircraftFilters', -1);
const isAllBookNumberFilters = R.propEq('bookNumberFilters', -1);
const isAllPageNumberFilters = R.propEq('pageNumberFilters', -1);
const isAllUploadedByFilters = R.propEq('uploadedbyFilters', -1);

const isAllFiltersAppliedEvery = R.allPass([
  isAllAircraftFilters,
  isAllBookNumberFilters,
  isAllPageNumberFilters,
  isAllUploadedByFilters
]);
export const useStyles = makeStyles({
    table: {
      minWidth: 500
    }
  });
  interface AircraftProps {
    id: number;
    registration: string;
  }
  interface UserProps {
    id: number;
    firstName: string;
    lastName: string;
  }
export interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
    isNumber?: boolean;
    sortingProps: string[][];
    filterComponent?: any;
    filterStatePath?: string[];
  }
export interface LogBookRow {
    id: string;
    bookNumber: string;
    pageNumber: string;
    correctedBookNumber: string;
    correctedPageNumber: string;
    receiptUri: string[];
    user:UserProps,
    aircraft:AircraftProps;
    correctedAircraft:AircraftProps;
    localDate:string;
    daily:any
}  
export const columns: Column[] = [
    {
      id: 'localDate',
      label: 'Date',
      minWidth: 120,
      align: 'center',
      sortingProps: [['localDate']]
    },
    {
      id: 'aircraft',
      label: 'Aircraft',
      minWidth: 90,
      align: 'center',
      sortingProps: [['aircraft', 'registration']],
      filterComponent: AircraftFilterComponent,
      filterStatePath: aircraftFilterStatePath
    },
    {
      id: 'bookNumber',
      label: 'Book #',
      minWidth: 90,
      align: 'center',
      sortingProps: [['bookNumber']],
      filterComponent: BookFilterComponent,
      filterStatePath: bookNumberFilterStatePath,
    },
    {
      id: 'pageNumber',
      label: 'Page #',
      minWidth: 120,
      align: 'center',
      sortingProps: [['pageNumber']],
      filterComponent: PageFilterComponent,
      filterStatePath: pageNumberFilterStatePath,
    },
    {
      id: 'userName',
      label: 'Uploaded By',
      minWidth: 110,
      align: 'center',
      sortingProps: [['userName']],
      filterComponent: UsersFilterComponent,
      filterStatePath: uploadedByFilterStatePath,
    },
  ];

  export const filterLogBookScreenData = (
    logBookScreenData: LogBookRow[],
    filters: FiltersProps
  ) => {
    if (isAllFiltersAppliedEvery(filters)) {
      return logBookScreenData;
    }
  
    const {
      aircraftFilters,
      bookNumberFilters,
      pageNumberFilters,
      uploadedbyFilters
    } = filters;
  
    let filteredDataArray = logBookScreenData;
  
    if (aircraftFilters !== -1) {
      filteredDataArray = R.filter(
        (data: LogBookRow) => data.aircraft.id === aircraftFilters,
        filteredDataArray
      );
    }
    if (bookNumberFilters !== -1) {
      filteredDataArray = R.filter(
        (data: LogBookRow) => data.bookNumber === bookNumberFilters.toString(),
        filteredDataArray
      );
    }
    if (pageNumberFilters !== -1) {
      filteredDataArray = R.filter(
        (data: LogBookRow) => data.pageNumber === pageNumberFilters.toString(),
        filteredDataArray
      );
    }
    if (uploadedbyFilters !== -1) {
      filteredDataArray = R.filter(
        (data: LogBookRow) => data.daily.user.id === uploadedbyFilters,
        filteredDataArray
      );
    }

  
    return filteredDataArray;
  };
  export const getFormattedValues = (values: any) => {
    const { aircraft ,correctedAircraft,correctedBookNumber,correctedPageNumber } = values;
  
    const formattedAircraft = { id: aircraft };
    // const formattedUser = { id: user};
    const formattedCorrectedAircraft = { id: correctedAircraft};
  
    let formattedValues: any = {
      ...values,
      aircraft:formattedAircraft,
      correctedAircraft:formattedCorrectedAircraft
    };
    formattedValues = R.omit(['userName','date'], formattedValues);
    if(isNilOrEmpty(correctedAircraft)) {
      formattedValues = R.omit(['correctedAircraft'], formattedValues);
    } 
    if(isNilOrEmpty(correctedBookNumber)) {
      formattedValues = R.omit(['correctedBookNumber'], formattedValues);
    }
    if(isNilOrEmpty(correctedPageNumber)) {
      formattedValues = R.omit(['correctedPageNumber'], formattedValues);
    }
    return formattedValues;
  };

  