import React, { useCallback } from 'react';
import * as R from 'ramda';
import { WrappedFieldProps, getFormValues, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import MaterialUIPickers from './MaterialUIPicker';
import StoreState from '../../../redux/utils/interfaces';
import { isPresent } from '../../../utils/helper';

import './style.scss';

interface FieldProps {
  name: string;
  id: string;
  label: string;
  disabled: boolean;
  required?: boolean;
  optionsList: (string | undefined)[];
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
}

const ReduxFormDateTimePicker = (field: WrappedFieldProps & FieldProps) => {
  const dispatch = useDispatch();

  const value = useSelector((state: StoreState) =>
    R.pathOr('', [field.input.name], getFormValues(field.meta.form)(state))
  );

  const updateFormField = useCallback(
    (value: any) => dispatch(change(field.meta.form, field.input.name, value)),
    [dispatch, field.input.name, field.meta.form]
  );

  const handleDateChange = useCallback(
    (date: any) => {
      updateFormField(date);
    },
    [updateFormField]
  );

  const selectedDate = isPresent(value) ? new Date(value) : null;

  return (
    <div className="redux-form-date-time-picker-container">
      <MaterialUIPickers
        label={field.label}
        name={field.input.name}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};

export default ReduxFormDateTimePicker;
