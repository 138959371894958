import { BookingsScreenFiltersProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList } from '../../utils/helper';

const initialState: BookingsScreenFiltersProps = {
  customerFiltersArray: initialFilterList
};

const bookingsScreenFilters = (
  state: BookingsScreenFiltersProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_BOOKINGS_SCREEN_FILTERS_SUCCESS:
      const customerFiltersArray = initialFilterList.concat(
        action.payload.customerFiltersArray
      );

      return {
        ...state,
        customerFiltersArray
      };

    case types.FETCH_BOOKINGS_SCREEN_FILTERS_FAILURE:
      return state;

    default:
      return state;
  }
};

export default bookingsScreenFilters;
