/**
 * Kernel to differentiate success / error / exception
 * in responses.
 */

import { failureResponse, successResponse } from './index';
import { Response } from 'node-fetch';
import ReduxStore from '../../../redux';
// import { refreshTokensRequest } from '../../../redux/actions/refreshTokens';
import {userLogOutRequest} from '../../../redux/actions/userAuth';

export const kernel = async (value: Response) => {
  const { status, statusText } = value;

  if (status === 401 && statusText === 'Unauthorized') {
    ReduxStore.dispatch(userLogOutRequest());
  }

  if (!value.ok) {
    return await failureResponse(value);
  }
  return await successResponse(value);
};

export default kernel;
