import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialize, getFormValues, change } from 'redux-form';
import * as R from 'ramda';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  fetchBookingsScreenDataRequest,
  fetchBookingsScreenDataFieldsRequest,
  addBookingsScreenDataRequest,
  editBookingsScreenDataRequest
} from '../../redux/actions/bookingsScreenData';
import {
  getMonthStartToCurrentDateRange,
  isNilOrEmpty,
  isPresent,
  removeNullOrEmptyKeys
} from '../../utils/helper';
import StoreState from '../../redux/utils/interfaces';
import {
  initialValues,
  RangeProps,
  RANGE,
  transformBaseList,
  transformRequestingPerson,
  transformPassengersList,
  modelSaveBookingData,
  getCustomerFormInitialValues,
  modelEditData,
  transformClientList
} from './helper';
import {
  BOOKINGS_FILTERS_FORM,
  BOOKINGS_ADD_EDIT_FORM
} from '../../utils/reduxFormConstants';
import { fetchBookingsScreenFiltersRequest } from '../../redux/actions/bookingsScreenFilters';
import {
  BOOKINGS_ROUTE,
  ADD_BOOKINGS_ROUTE,
  BOOKINGS_DETAILS_ROUTE
} from '../../utils/routesNavigationConstants';

export const useBookingsHook = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { bookingsId }: any = useParams();

  const isBookingsDetailsRouteMatch = isPresent(
    useRouteMatch(BOOKINGS_DETAILS_ROUTE)
  );
  const isAddBookingsRouteMatch = isPresent(useRouteMatch(ADD_BOOKINGS_ROUTE));

  const goto = (route: string) => history.push(route);

  const {
    formValues,
    formValuesBookingsDetails,
    bookingsData,
    customers,
    requestingPersons,
    bases,
    passengers,
    equipments,
    pilot,
    ame,
    apprentice,
    aircraft,
    isLoading,
    bookingNumberIsLoading,
    bookingNumberIsValid,
    approvingClient
  } = useSelector((state: StoreState) => ({
    formValues: getFormValues(BOOKINGS_FILTERS_FORM)(state) || {},
    formValuesBookingsDetails:
      getFormValues(BOOKINGS_ADD_EDIT_FORM)(state) || {},
    bookingsData: state.bookingsScreenData.bookingsData,
    customers: state.bookingsScreenData.customers,
    requestingPersons: state.bookingsScreenData.requestingPersons,
    bases: state.bookingsScreenData.bases,
    passengers: state.bookingsScreenData.passengers,
    equipments: state.bookingsScreenData.equipments,
    pilot: state.bookingsScreenData.pilot,
    approvingClient: state.bookingsScreenData.approvingClient,
    ame: state.bookingsScreenData.ame,
    apprentice: state.bookingsScreenData.apprentice,
    aircraft: state.bookingsScreenData.aircraft,
    isLoading: state.bookingsScreenData.isLoading,
    bookingNumberIsLoading: state.bookingsScreenData.bookingNumberIsLoading,
    bookingNumberIsValid: state.bookingsScreenData.bookingNumberIsValid
  }));

  const { isApprovingSupervisor } = useSelector((state: StoreState) => ({
    isApprovingSupervisor: state.userInformation.isApprovingSupervisor
  }));

  useEffect(() => {
    if (
      !isApprovingSupervisor &&
      (isBookingsDetailsRouteMatch || isAddBookingsRouteMatch)
    ) {
      history.push(BOOKINGS_ROUTE);
    }
  }, [
    history,
    isApprovingSupervisor,
    isBookingsDetailsRouteMatch,
    isAddBookingsRouteMatch
  ]);

  const currentBookingData = useMemo(
    () => R.find(R.propEq('id', Number(bookingsId)))(bookingsData) || {},
    [bookingsData, bookingsId]
  );

  const range = R.pathOr(
    getMonthStartToCurrentDateRange(),
    ['range'],
    formValues
  );

  useEffect(() => {
    dispatch(initialize(BOOKINGS_FILTERS_FORM, initialValues));

    dispatch(fetchBookingsScreenFiltersRequest());
  }, [dispatch]);

  useEffect(() => {
    const { from, to } = range;

    dispatch(fetchBookingsScreenDataRequest(from, to));
  }, [dispatch, range]);

  const isDefault = R.pathOr(false, ['isDefault'], formValuesBookingsDetails);
  const customer = R.pathOr(-1, ['customer'], formValuesBookingsDetails);
  const requestingPersonValue = R.pathOr(
    -1,
    ['requestingPerson'],
    formValuesBookingsDetails
  );
  const passengersValue = R.pathOr(
    [],
    ['passengers'],
    formValuesBookingsDetails
  );

  useEffect(() => {
    if (customer !== -1) {
      dispatch(fetchBookingsScreenDataFieldsRequest(customer));
    }
  }, [dispatch, customer]);

  const updateFormField = useCallback(
    (key: string, value: any) =>
      dispatch(change(BOOKINGS_FILTERS_FORM, key, value)),
    [dispatch]
  );

  const updateEditFormField = useCallback(
    (key: string, value: any) =>
      dispatch(change(BOOKINGS_ADD_EDIT_FORM, key, value)),
    [dispatch]
  );

  useEffect(() => {
    if (isPresent(requestingPersons) && requestingPersonValue !== -1) {
      let isPersonPresent = false;

      requestingPersons.forEach((requestingPerson: {}) => {
        if (R.pathOr('', ['id'], requestingPerson) === requestingPersonValue) {
          isPersonPresent = true;
        }
      });

      if (!isPersonPresent) updateEditFormField('requestingPerson', null);
    } else {
      updateEditFormField('requestingPerson', null);
    }
    // eslint-disable-next-line
  }, [dispatch, updateEditFormField, requestingPersons]);

  useEffect(() => {
    if (isPresent(passengers) && isPresent(passengersValue)) {
      let isAllPassengersPresent = true;

      passengersValue.forEach((passengerValue: {}) => {
        const currentPassengerValueId = R.pathOr('', ['id'], passengerValue);
        let isValuePresent = false;

        passengers.forEach((passenger: {}) => {
          const currentPassengerId = R.pathOr('', ['id'], passenger);

          if (currentPassengerValueId === currentPassengerId) {
            isValuePresent = true;
          }
        });

        if (!isValuePresent) isAllPassengersPresent = false;
      });

      if (!isAllPassengersPresent) updateEditFormField('passengers', null);
    } else {
      updateEditFormField('passengers', null);
    }
    // eslint-disable-next-line
  }, [dispatch, updateEditFormField, passengers]);

  const setRangeHandler = useCallback(
    (range: RangeProps) => {
      updateFormField(RANGE, range);
    },
    [updateFormField]
  );

  const onSaveButtonClickHandler = () => {
    const modeledData = modelSaveBookingData(formValuesBookingsDetails);
    if (isBookingsDetailsRouteMatch) {
      const modeledEditData = modelEditData(
        modeledData,
        R.pathOr(-1, ['id'], currentBookingData)
      );

      dispatch(
        editBookingsScreenDataRequest(removeNullOrEmptyKeys(modeledEditData))
      );
    }

    if (isAddBookingsRouteMatch) {
      dispatch(
        addBookingsScreenDataRequest(removeNullOrEmptyKeys(modeledData))
      );
    }
  };

  const initialValuesBookingsForm = useMemo(
    () => getCustomerFormInitialValues(currentBookingData),
    [currentBookingData]
  );

  const { saveButtonDisabled } = useMemo(() => {
    const name = R.pathOr('', ['name'], formValuesBookingsDetails);
    const customer = R.pathOr('', ['customer'], formValuesBookingsDetails);
    const base = R.pathOr('', ['base'], formValuesBookingsDetails);
    const startLocation = R.pathOr(
      '',
      ['startLocation'],
      formValuesBookingsDetails
    );
    const endLocation = R.pathOr(
      '',
      ['endLocation'],
      formValuesBookingsDetails
    );
    const meetingLocation = R.pathOr(
      '',
      ['meetingLocation'],
      formValuesBookingsDetails
    );
    const returnLocation = R.pathOr(
      '',
      ['returnLocation'],
      formValuesBookingsDetails
    );

    const isDisabled =
      bookingNumberIsLoading ||
      !bookingNumberIsValid ||
      isNilOrEmpty(name) ||
      isNilOrEmpty(customer) ||
      isNilOrEmpty(base) ||
      isNilOrEmpty(startLocation) ||
      isNilOrEmpty(endLocation) ||
      isNilOrEmpty(meetingLocation) ||
      isNilOrEmpty(returnLocation);

    return {
      saveButtonDisabled: isDisabled
    };
  }, [bookingNumberIsLoading, bookingNumberIsValid, formValuesBookingsDetails]);
  return {
    range,
    formValues,
    dataLoading: isLoading,
    bookingsData,
    isBookingsDetailsRouteMatch,
    isAddBookingsRouteMatch,
    currentBookingData,
    customersList: customers,
    requestingPersonsList: transformRequestingPerson(requestingPersons),
    basesList: transformBaseList(bases),
    passengers: transformPassengersList(passengers),
    equipments,
    pilot,
    approvingClient:transformClientList(approvingClient,customer),
    ame,
    apprentice,
    aircraft,
    initialValues: initialValuesBookingsForm,
    saveButtonDisabled: saveButtonDisabled,

    isDefault,
    setRange: setRangeHandler,
    goto,
    onSaveButtonClickHandler
  };
};
