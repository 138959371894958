import types from '../utils/actionTypes';

export const fetchBookingsScreenDataRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_DATA_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};

export const fetchBookingsScreenDataSuccess = (
  bookingsData: [],
  customers: [],
  bases: [],
  equipments: [],
  pilot: [],
  ame: [],
  apprentice: [],
  aircraft: [],
  approvingClient:[]
) => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_DATA_SUCCESS,
    payload: {
      bookingsData,
      customers,
      bases,
      equipments,
      pilot,
      ame,
      apprentice,
      aircraft,
      approvingClient
    }
  };
};

export const fetchBookingsScreenDataFailure = () => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_DATA_FAILURE,
    payload: {}
  };
};

export const fetchBookingsScreenDataFieldsRequest = (customerId: number) => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_REQUEST,
    payload: { customerId }
  };
};

export const fetchBookingsScreenDataFieldsSuccess = (
  requestingPersons: [],
  passengers: []
) => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_SUCCESS,
    payload: {
      requestingPersons,
      passengers
    }
  };
};

export const fetchBookingsScreenDataFieldsFailure = () => {
  return {
    type: types.FETCH_BOOKINGS_SCREEN_DATA_FIELDS_FAILURE,
    payload: {}
  };
};

export const addBookingsScreenDataRequest = (addBookingData: {}) => {
  return {
    type: types.ADD_BOOKINGS_SCREEN_DATA_REQUEST,
    payload: { addBookingData }
  };
};

export const addBookingsScreenDataSuccess = () => {
  return {
    type: types.ADD_BOOKINGS_SCREEN_DATA_SUCCESS,
    payload: {}
  };
};

export const addBookingsScreenDataFailure = () => {
  return {
    type: types.ADD_BOOKINGS_SCREEN_DATA_FAILURE,
    payload: {}
  };
};

export const editBookingsScreenDataRequest = (editBookingData: {}) => {
  return {
    type: types.EDIT_BOOKINGS_SCREEN_DATA_REQUEST,
    payload: {
      editBookingData
    }
  };
};

export const editBookingsScreenDataSuccess = () => {
  return {
    type: types.EDIT_BOOKINGS_SCREEN_DATA_SUCCESS,
    payload: {}
  };
};

export const editBookingsScreenDataFailure = () => {
  return {
    type: types.EDIT_BOOKINGS_SCREEN_DATA_FAILURE,
    payload: {}
  };
};

export const validateBookingNumberRequest = (bookingNumber: string) => {
  return {
    type: types.VALIDATE_BOOKING_NUMBER_REQUEST,
    payload: {
      bookingNumber
    }
  };
};

export const validateBookingNumberSuccess = () => {
  return {
    type: types.VALIDATE_BOOKING_NUMBER_SUCCESS,
    payload: {}
  };
};

export const validateBookingNumberFailure = () => {
  return {
    type: types.VALIDATE_BOOKING_NUMBER_FAILURE,
    payload: {}
  };
};
