import { createMuiTheme } from '@material-ui/core/styles';

let appTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    },
    secondary: {
      main: '#90c548'
    }
  }
});

export default appTheme;
