import React,{ useState} from 'react';
import SharedDialog from '../shared/SharedDialog';
import ImagesViewer from '../ImagesViewer';
import { isNilOrEmpty } from '../../utils/helper';
import { Typography } from '@material-ui/core';
import {exportRefuelImageRequest} from '../../redux/actions/fuelExpenseScreenData';
import './imageViewerStyles.scss';
import { useDispatch } from 'react-redux';

interface ImageViewerProps {
  imageViewerData: string[];
  currentActiveImage:number;
   setCurrentActiveImage:React.Dispatch<React.SetStateAction<number>>;
}

const ImageViewer = ({ imageViewerData,currentActiveImage, setCurrentActiveImage }: ImageViewerProps) => {
  const noReceipts = isNilOrEmpty(imageViewerData);
  if (noReceipts) {
    return <Typography>No receipts available</Typography>;
  }

  return (
    <div>
      <ImagesViewer imagesData={imageViewerData} currentActiveImage={currentActiveImage} setCurrentActiveImage={setCurrentActiveImage}/>
    </div>
  );
};

interface ImageViewerModalProps {
  imageViewerData: string[];
  isImageViewerOpen: boolean;
  setIsImageViewerOpen: Function;
}

const ImageViewerModal = ({
  imageViewerData,
  isImageViewerOpen,
  setIsImageViewerOpen
}: ImageViewerModalProps) => {
  const dispatch = useDispatch();
  const [currentActiveImage, setCurrentActiveImage] = useState(0);

  const handleCancel = () => {
    setIsImageViewerOpen(false);
  };
  const handleDownload = () => {
    dispatch(exportRefuelImageRequest(imageViewerData[currentActiveImage]));
  };
  return (
    <SharedDialog
      id="fuel-receipt-dialog"
      keepMounted
      maxWidth={false}
      open={isImageViewerOpen}
      dialogueTitle="Refuel/Fuel Receipt"
      cancelButtonText="Close"
      dialogContent={
        <div className="receipt-viewer">
          <ImageViewer currentActiveImage={currentActiveImage} setCurrentActiveImage={setCurrentActiveImage} imageViewerData={imageViewerData} />
        </div>
      }
      handleCancel={handleCancel}
      handleDownload={handleDownload}
    />
  );
};

export default ImageViewerModal;
