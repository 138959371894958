import {
  FlightReportScreenFiltersProps,
  ActionType
} from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList } from '../../utils/helper';

const statusFiltersArray = [
  ...initialFilterList,
  { id: 1, name: 'Closed' },
  { id: 2, name: 'Ready To Bill' },
  { id: 3, name: 'In Progress' }
];

const initialState: FlightReportScreenFiltersProps = {
  bookingFiltersArray: initialFilterList,
  customerFiltersArray: initialFilterList,
  aircraftFiltersArray: initialFilterList,
  pilotFiltersArray: initialFilterList,
  ameFiltersArray: initialFilterList,
  statusFiltersArray: statusFiltersArray
};

const flightReportScreenFilters = (
  state: FlightReportScreenFiltersProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_FLIGHT_REPORT_FILTERS_SUCCESS:
      const customerFiltersArray = initialFilterList.concat(
        action.payload.customerFiltersArray
      );
      const aircraftFiltersArray = initialFilterList.concat(
        action.payload.aircraftFiltersArray
      );
      const pilotFiltersArray = initialFilterList.concat(
        action.payload.pilotFiltersArray
      );
      const ameFiltersArray = initialFilterList.concat(
        action.payload.ameFiltersArray
      );

      return {
        ...state,
        customerFiltersArray,
        aircraftFiltersArray,
        pilotFiltersArray,
        ameFiltersArray
      };

    case types.FETCH_FLIGHT_REPORT_FILTERS_FAILURE:
      return state;

    case types.FETCH_FLIGHT_REPORT_BOOKING_FILTERS_SUCCESS:
      return {
        ...state,
        bookingFiltersArray: initialFilterList.concat(
          action.payload.bookingFiltersArray
        )
      };

    case types.FETCH_FLIGHT_REPORT_BOOKING_FILTERS_FAILURE:
      return state;

    case types.SET_FLIGHT_REPORT_STATUS_FILTERS:
      const statusFiltersArray = initialFilterList.concat(
        action.payload.statusFiltersArray
      );

      return {
        ...state,
        statusFiltersArray
      };

    default:
      return state;
  }
};

export default flightReportScreenFilters;
