import types from '../utils/actionTypes';

export const userLoginRequest = (values: any) => {
  const { email, password } = values;
  return {
    type: types.USER_LOGIN_REQUEST,
    payload: { email: email.trim(), password: password.trim() }
  };
};

export const userLoginSuccess = () => {
  return {
    type: types.USER_LOGIN_SUCCESS
  };
};

export const userLoginFailure = () => {
  return {
    type: types.USER_LOGIN_FAILURE
  };
};
export const userResetPassRequest = (values: {}) => {
  return {
    type: types.USER_RESET_PASSWORD_REQUEST,
    payload: values
  };
};

export const userResetPassSuccess = () => {
  return {
    type: types.USER_RESET_PASSWORD_SUCCESS
  };
};

export const userResetPassFailure = () => {
  return {
    type: types.USER_RESET_PASSWORD_FAILURE
  };
};

export const userLogOutRequest = () => {
  return {
    type: types.USER_LOG_OUT_REQUEST
  };
};

export const userLogOutSuccess = () => {
  return {
    type: types.USER_LOG_OUT_SUCCESS
  };
};

export const userLogOutFailure = () => {
  return {
    type: types.USER_LOG_OUT_FAILURE
  };
};
