import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  AIRCRAFT_FILTERS,
  BOOKING_FILTERS,
  VENDOR_FILTERS,
  PILOT_FILTERS,
  DOCUMENT_HASH_FILTERS,
  INVOICE_NUMBER_FILTERS,
  filtersNotWorkingOptions,
  FLIGHT_REPORT_FILTERS,
  PAYMENT_TYPE_FILTERS,
  FUEL_TYPE_FILTERS
} from '../../routes/FuelExpense/helper';
import { FUEL_EXPENSE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { FuelExpenseRow } from './helper';
import { fuelTypeList, paymentMethodsList } from '../../utils/helper';

const commonFilterPath = ['form', FUEL_EXPENSE_FILTERS_FORM, 'values'];
export const aircraftFilterStatePath = [...commonFilterPath, AIRCRAFT_FILTERS];
export const bookingFilterStatePath = [...commonFilterPath, BOOKING_FILTERS];
export const vendorFilterStatePath = [...commonFilterPath, VENDOR_FILTERS];
export const pilotFilterStatePath = [...commonFilterPath, PILOT_FILTERS];
export const fuelFilterStatePath = [...commonFilterPath, FUEL_TYPE_FILTERS];
export const paymentFilterStatePath = [
  ...commonFilterPath,
  PAYMENT_TYPE_FILTERS
];
export const flightReportFilterStatePath = [
  ...commonFilterPath,
  FLIGHT_REPORT_FILTERS
];
export const documentHashStatePath = [
  ...commonFilterPath,
  DOCUMENT_HASH_FILTERS
];
export const invoiceNumberFilterStatePath = [
  ...commonFilterPath,
  INVOICE_NUMBER_FILTERS
];

export const AircraftFilterComponent = () => {
  const { aircraftFiltersArray } = useSelector((state: StoreState) => ({
    aircraftFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['fuelExpenseScreenFilters', 'aircraftFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={AIRCRAFT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Aircraft"
      optionsList={aircraftFiltersArray}
      required
    />
  );
};

export const BookingFilterComponent = () => {
  const { bookingFiltersArray } = useSelector((state: StoreState) => ({
    bookingFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['fuelExpenseScreenFilters', 'bookingFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={BOOKING_FILTERS}
      component={ReduxFormAutocomplete}
      label="Booking"
      optionsList={bookingFiltersArray}
      required
    />
  );
};

export const PaymentFilterComponent = () => {
  let paymentMethodsFilteredArray: { id: string | number; name: string }[] = [
    { id: -1, name: 'All' }
  ];
  paymentMethodsFilteredArray.push(...paymentMethodsList);
  return (
    <Field
      name={PAYMENT_TYPE_FILTERS}
      component={ReduxFormAutocomplete}
      label="Payment"
      optionsList={paymentMethodsFilteredArray}
      required
    />
  );
};

export const FuelTypeFilterComponent = () => {
  let fuelTypeFilteredArray: { id: string | number; name: string }[] = [
    { id: -1, name: 'All' }
  ];
  fuelTypeFilteredArray.push(...fuelTypeList);
  return (
    <Field
      name={FUEL_TYPE_FILTERS}
      component={ReduxFormAutocomplete}
      label="Fuel"
      optionsList={fuelTypeFilteredArray}
      required
    />
  );
};

export const FlightReportFilterComponent = () => {
  const { fuelExpenseScreenData } = useSelector((state: StoreState) => {
    return {
      fuelExpenseScreenData: R.pathOr(
        [],
        ['fuelExpenseScreenData', 'fuelExpenseData'],
        state
      )
    };
  });
  const flightReportFiltersArray = useMemo(() => {
    let invoiceData: FuelExpenseRow[] = fuelExpenseScreenData;

    const flightReports = new Set<string>();

    invoiceData.forEach((invoice, index) => {
      flightReports.add(invoice.flightReport);
    });

    let flightReportFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    flightReports.forEach((flightReport, key) => {
      flightReportFiltersArray.push({ id: key, name: flightReport });
    });
    return flightReportFiltersArray;
  }, [fuelExpenseScreenData]);

  return (
    <Field
      name={FLIGHT_REPORT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Flight-Report"
      optionsList={flightReportFiltersArray}
      required
    />
  );
};

export const VendorFilterComponent = () => {
  const { vendorFiltersArray } = useSelector((state: StoreState) => ({
    vendorFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['fuelExpenseScreenFilters', 'vendorFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={VENDOR_FILTERS}
      component={ReduxFormAutocomplete}
      label="Vendor"
      optionsList={vendorFiltersArray}
      required
    />
  );
};

export const PilotFilterComponent = () => {
  const { fuelExpenseScreenData } = useSelector((state: StoreState) => {
    return {
      fuelExpenseScreenData: R.pathOr(
        [],
        ['fuelExpenseScreenData', 'fuelExpenseData'],
        state
      )
    };
  });
  const pilotFiltersArray = useMemo(() => {
    let fuelExpenseData: FuelExpenseRow[] = fuelExpenseScreenData;

    const pilotNames = new Set<string>();

    fuelExpenseData.forEach((fuelExpense, index) => {
      pilotNames.add(fuelExpense.userName);
    });

    let pilotFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    pilotNames.forEach((pilotName) => {
      pilotFiltersArray.push({ id: pilotName.toLowerCase(), name: pilotName });
    });
    return pilotFiltersArray;
  }, [fuelExpenseScreenData]);

  return (
    <Field
      name={PILOT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Pilot"
      optionsList={pilotFiltersArray}
      required
    />
  );
};

export const DocumentHashFilterComponent = () => {
  const { documentHashFiltersArray } = useSelector((state: StoreState) => ({
    documentHashFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['fuelExpenseScreenFilters', 'documentHashFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={DOCUMENT_HASH_FILTERS}
      component={ReduxFormAutocomplete}
      label="Document #"
      optionsList={documentHashFiltersArray}
      required
    />
  );
};

export const InvoiceNumberFilterComponent = () => {
  const { invoiceNumberArray } = useSelector((state: StoreState) => ({
    invoiceNumberArray: R.pathOr(
      filtersNotWorkingOptions,
      ['fuelExpenseScreenFilters', 'invoiceNumberArray'],
      state
    )
  }));

  return (
    <Field
      name={INVOICE_NUMBER_FILTERS}
      component={ReduxFormAutocomplete}
      label="Invoice Number"
      optionsList={invoiceNumberArray}
      required
    />
  );
};
