import React, { Fragment, useState, useRef } from 'react';
// import * as R from 'ramda';
import { Typography } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {
  NavigationArrayProps,
  OptionsProps
} from '../../utils/routesNavigationConstants';

const isCurrentNavActive = (navKey: string, options: OptionsProps[]) => {
  let currentActiveNav = false;

  options.forEach((currentNavObject: OptionsProps) => {
    if (currentNavObject.key === navKey) {
      currentActiveNav = true;
    }
  });

  return currentActiveNav;
};

interface DropdownButtonProps {
  navigation: NavigationArrayProps;
  navKey: string;
  goto: Function;
}

const DropdownButton = ({ navigation, navKey, goto }: DropdownButtonProps) => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: React.MouseEvent<EventTarget>) => {
    setOpen(false);
  };

  const handleNavClick = (gotoRoute: string) => {
    handleClose();
    goto(gotoRoute);
  };

  const currentActiveNav = isCurrentNavActive(navKey, navigation.options);

  return (
    <Fragment>
      <button
        ref={anchorRef}
        className={`nav-button nav-button-link ${
          currentActiveNav ? 'nav-button-active' : ''
        }`}
        onClick={handleToggle}>
        <Typography variant="subtitle2">{navigation.name}</Typography>
      </button>

      {navigation.options.length > 0 && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    {navigation.options.map(
                      (currentNavObject: OptionsProps, index: number) => {
                        const currentActiveNav =
                          currentNavObject.key === navKey;

                        return (
                          <MenuItem
                            key={index}
                            selected={currentActiveNav}
                            onClick={() =>
                              handleNavClick(currentNavObject.gotoRoute)
                            }>
                            {currentNavObject.name}
                          </MenuItem>
                        );
                      }
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Fragment>
  );
};

export default DropdownButton;
