import { AircraftScreenFiltersProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList } from '../../utils/helper';

const initialState: AircraftScreenFiltersProps = {
  typeFilterArray: initialFilterList
};

const aircraftScreenFilters = (
  state: AircraftScreenFiltersProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.SET_AIRCRAFT_SCREEN_FILTERS:
      return {
        ...state,
        typeFilterArray: initialFilterList.concat(
          action.payload.typeFilterArray
        )
      };

    default:
      return state;
  }
};

export default aircraftScreenFilters;
