import types from '../utils/actionTypes';

export const fetchClientUsersScreenDataRequest = () => {
  return {
    type: types.FETCH_CLIENT_USERS_SCREEN_DATA_REQUEST
  };
};

export const fetchClientUsersScreenDataSuccess = (usersData: []) => {
  return {
    type: types.FETCH_CLIENT_USERS_SCREEN_DATA_SUCCESS,
    payload: { usersData }
  };
};

export const fetchClientUsersScreenDataFailure = () => {
  return {
    type: types.FETCH_CLIENT_USERS_SCREEN_DATA_FAILURE
  };
};

export const fetchClientUsersScreenClientsRequest = () => {
  return {
    type: types.FETCH_CLIENT_USERS_SCREEN_CLIENTS_REQUEST
  };
};

export const fetchClientUsersScreenClientsSuccess = (
  clients: [],
) => {
  return {
    type: types.FETCH_CLIENT_USERS_SCREEN_CLIENTS_SUCCESS,
    payload: { clients }
  };
};

export const fetchClientUsersScreenClientsFailure = () => {
  return {
    type: types.FETCH_CLIENT_USERS_SCREEN_CLIENTS_FAILURE
  };
};

export const addNewClientUserRequest = (newUserData: {}) => {
  return {
    type: types.ADD_NEW_CLIENT_USER_REQUEST,
    payload: {
      newUserData
    }
  };
};

export const addNewClientUserSuccess = () => {
  return {
    type: types.ADD_NEW_CLIENT_USER_SUCCESS
  };
};

export const addNewClientUserFailure = () => {
  return {
    type: types.ADD_NEW_CLIENT_USER_FAILURE
  };
};

export const updateClientUserRequest = (userId: number, updatedData: {}) => {
  return {
    type: types.UPDATE_CLIENT_USER_REQUEST,
    payload: {
      userId,
      updatedData
    }
  };
};

export const updateClientUserSuccess = () => {
  return {
    type: types.UPDATE_CLIENT_USER_SUCCESS
  };
};

export const updateClientUserFailure = () => {
  return {
    type: types.UPDATE_CLIENT_USER_FAILURE
  };
};

export const clientUserStatusChangeRequest = (
  userId: number,
  isActive: boolean
) => {
  return {
    type: types.CLIENT_USER_STATUS_CHANGE_REQUEST,
    payload: {
      userId,
      isActive
    }
  };
};

export const clientUserStatusChangeSuccess = () => {
  return {
    type: types.CLIENT_USER_STATUS_CHANGE_SUCCESS
  };
};

export const clientUserStatusChangeFailure = () => {
  return {
    type: types.CLIENT_USER_STATUS_CHANGE_FAILURE
  };
};
