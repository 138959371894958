import types from '../utils/actionTypes';

export const fetchEngineerJobReviewAircraftFiltersRequest = () => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_AIRCRAFT_FILTERS_REQUEST
  };
};

export const fetchEngineerJobReviewAircraftFiltersSuccess = (
  aircraftFiltersArray: []
) => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_AIRCRAFT_FILTERS_SUCCESS,
    payload: { aircraftFiltersArray }
  };
};

export const fetchEngineerJobReviewAircraftFiltersFailure = () => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_AIRCRAFT_FILTERS_FAILURE
  };
};

export const fetchEngineerJobReviewWorkTypeFiltersRequest = () => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_WORK_TYPE_FILTERS_REQUEST
  };
};

export const fetchEngineerJobReviewWorkTypeFiltersSuccess = (
  workTypeFiltersArray: []
) => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_WORK_TYPE_FILTERS_SUCCESS,
    payload: { workTypeFiltersArray }
  };
};

export const fetchEngineerJobReviewWorkTypeFiltersFailure = () => {
  return {
    type: types.FETCH_ENGINEER_JOB_REVIEW_WORK_TYPE_FILTERS_FAILURE
  };
};

export const setEngineerJobReviewEmployeeFilters = (
  employeeFiltersArray: {}[]
) => {
  return {
    type: types.SET_ENGINEER_JOB_REVIEW_EMPLOYEE_FILTERS,
    payload: { employeeFiltersArray }
  };
};

export const setEngineerJobReviewWorkReportFilters = (
  workReportFiltersArray: {}[]
) => {
  return {
    type: types.SET_ENGINEER_JOB_REVIEW_WORK_REPORT_FILTERS,
    payload: { workReportFiltersArray }
  };
};

export const setEngineerJobReviewWorkDoneFilters = (
  workDoneFiltersArray: {}[]
) => {
  return {
    type: types.SET_ENGINEER_JOB_REVIEW_WORK_DONE_FILTERS,
    payload: { workDoneFiltersArray }
  };
};
