import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  AIRCRAFT_FILTERS,
  EMPLOYEE_FILTERS,
  WORK_REPORT_FILTERS,
  WORK_TYPE_FILTERS,
  WORK_DONE_FILTERS,
  filtersNotWorkingOptions
} from '../../routes/EngineerJobReview/helper';
import { ENGINEER_JOB_REVIEW_FILTERS_FORM } from '../../utils/reduxFormConstants';

const commonFilterPath = ['form', ENGINEER_JOB_REVIEW_FILTERS_FORM, 'values'];
export const aircraftFilterStatePath = [...commonFilterPath, AIRCRAFT_FILTERS];
export const employeeFilterStatePath = [...commonFilterPath, EMPLOYEE_FILTERS];
export const workReportFilterStatePath = [
  ...commonFilterPath,
  WORK_REPORT_FILTERS
];
export const workTypeFilterStatePath = [...commonFilterPath, WORK_TYPE_FILTERS];
export const workDoneFilterStatePath = [...commonFilterPath, WORK_DONE_FILTERS];

export const AircraftFilterComponent = () => {
  const { aircraftFiltersArray } = useSelector((state: StoreState) => ({
    aircraftFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['engineerJobReviewScreenFilters', 'aircraftFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={AIRCRAFT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Aircraft"
      optionsList={aircraftFiltersArray}
      required
    />
  );
};

export const EmployeeFilterComponent = () => {
  const { employeeFiltersArray } = useSelector((state: StoreState) => ({
    employeeFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['engineerJobReviewScreenFilters', 'employeeFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={EMPLOYEE_FILTERS}
      component={ReduxFormAutocomplete}
      label="Employee"
      optionsList={employeeFiltersArray}
      required
    />
  );
};

export const WorkReportFilterComponent = () => {
  const { workReportFiltersArray } = useSelector((state: StoreState) => ({
    workReportFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['engineerJobReviewScreenFilters', 'workReportFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={WORK_REPORT_FILTERS}
      component={ReduxFormAutocomplete}
      label="Work Report"
      optionsList={workReportFiltersArray}
      required
    />
  );
};

export const WorkTypeFilterComponent = () => {
  const { workTypeFiltersArray } = useSelector((state: StoreState) => ({
    workTypeFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['engineerJobReviewScreenFilters', 'workTypeFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={WORK_TYPE_FILTERS}
      component={ReduxFormAutocomplete}
      label="Work Type"
      optionsList={workTypeFiltersArray}
      required
    />
  );
};

export const WorkDoneFilterComponent = () => {
  const { workDoneFiltersArray } = useSelector((state: StoreState) => ({
    workDoneFiltersArray: R.pathOr(
      filtersNotWorkingOptions,
      ['engineerJobReviewScreenFilters', 'workDoneFiltersArray'],
      state
    )
  }));

  return (
    <Field
      name={WORK_DONE_FILTERS}
      component={ReduxFormAutocomplete}
      label="Work Done"
      optionsList={workDoneFiltersArray}
      required
    />
  );
};
