import { useEffect, useCallback, useMemo, useState } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { getFormValues, initialize } from 'redux-form';
import {
  fetchCustomersScreenDataRequest,
  updateCustomerDataRequest,
  addCustomerDataRequest
} from '../../redux/actions/customersScreenData';
import StoreState from '../../redux/utils/interfaces';
import {
  CUSTOMERS_DETAILS_ROUTE,
  ADD_CUSTOMER_ROUTE,
  CUSTOMERS_FLIGHT_REPORTS_ROUTE
} from '../../utils/routesNavigationConstants';
import { isPresent, sortArrayByProp } from '../../utils/helper';
import {
  CUSTOMERS_ADD_EDIT_FORM,
  CUSTOMERS_FILTERS_FORM
} from '../../utils/reduxFormConstants';
import { updateFlightReportScreenDataRequest } from '../../redux/actions/flightReportScreenData';
import { initialValues } from './helper';

export const useCustomersHook = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { customerId }: any = useParams();
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState({});

  const isCustomersDetailsRouteMatch = isPresent(
    useRouteMatch(CUSTOMERS_DETAILS_ROUTE)
  );
  const isAddCustomersRouteMatch = isPresent(useRouteMatch(ADD_CUSTOMER_ROUTE));
  const isFlightReportsRouteMatch = isPresent(
    useRouteMatch(CUSTOMERS_FLIGHT_REPORTS_ROUTE)
  );

  const {
    customersData,
    isCustomersDataLoading,
    customerFormData
  } = useSelector((state: StoreState) => {
    return {
      customersData: state.customersScreenData.customersData,
      isCustomersDataLoading: state.customersScreenData.isLoading,
      customerFormData: getFormValues(CUSTOMERS_ADD_EDIT_FORM)(state) || {}
    };
  });

  useEffect(() => {
    dispatch(fetchCustomersScreenDataRequest());
    dispatch(initialize(CUSTOMERS_FILTERS_FORM, initialValues));
  }, [dispatch]);

  const currentCustomerData = useMemo(
    () => R.find(R.propEq('id', Number(customerId)))(customersData) || {},
    [customersData, customerId]
  );

  const goto = useCallback((route: string) => history.push(route), [history]);

  const onSaveButtonClickHandler = useCallback(() => {
    if (isFlightReportsRouteMatch) {
      const currentSelectedStatusKeys = R.keys(currentSelectedStatus);

      const mappedCurrentSelectedStatusArray = currentSelectedStatusKeys.map(
        (currentSelectedStatusKey: string) => {
          return {
            id: currentSelectedStatusKey,
            statusType: {
              id: currentSelectedStatus[`${currentSelectedStatusKey}`]
            }
          };
        }
      );

      dispatch(
        updateFlightReportScreenDataRequest(
          mappedCurrentSelectedStatusArray,
          true
        )
      );
    } else {
      let data = {
        ...customerFormData,
        address_1: R.path(['address1'], customerFormData),
        address_2: R.path(['address2'], customerFormData),
        id: R.path(['id'], currentCustomerData)
      };

      data = R.omit(['address1', 'address2'], data);

      if (isCustomersDetailsRouteMatch) {
        dispatch(updateCustomerDataRequest(data));
      } else if (isAddCustomersRouteMatch) {
        dispatch(addCustomerDataRequest(R.omit(['id'], data)));
      }
    }
  }, [
    currentSelectedStatus,
    isCustomersDetailsRouteMatch,
    isAddCustomersRouteMatch,
    isFlightReportsRouteMatch,
    customerFormData,
    currentCustomerData,
    dispatch
  ]);

  const setAllCurrentSelectedStatus = useCallback(
    (value: unknown) => {
      const currentSelectedStatusKays = R.keys(currentSelectedStatus);
      const localCurrentSelectedStatus = { ...currentSelectedStatus };

      currentSelectedStatusKays.forEach((key: string) => {
        localCurrentSelectedStatus[`${key}`] = value;
      });

      setCurrentSelectedStatus(localCurrentSelectedStatus);
    },
    [currentSelectedStatus, setCurrentSelectedStatus]
  );

  const sortedCustomerData: any = useMemo(
    () => sortArrayByProp(customersData, [['name']]),
    [customersData]
  );

  const pageTitle = useMemo(
    () =>
      isCustomersDetailsRouteMatch
        ? 'Customer Details'
        : isFlightReportsRouteMatch
        ? 'Customer Flight Reports'
        : 'Customers',
    [isCustomersDetailsRouteMatch, isFlightReportsRouteMatch]
  );

  return {
    pageTitle,
    customersData: sortedCustomerData,
    isCustomersDataLoading,
    isCustomersDetailsRouteMatch,
    isAddCustomersRouteMatch,
    isFlightReportsRouteMatch,
    currentCustomerData,
    currentSelectedStatus,
    setCurrentSelectedStatus,
    goto,
    onSaveButtonClickHandler,
    setAllCurrentSelectedStatus
  };
};
