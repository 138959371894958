import { getMonthStartToCurrentDateRange } from '../../utils/helper';

export const filtersNotWorkingOptions = [
  { label: 'All', value: -1 },
  { label: 'Filter not working', value: -2 }
];

export interface RangeProps {
  to: Date;
  from: Date;
}

export const RANGE = 'range';
export const AIRCRAFT_FILTERS = 'aircraftFilters';
export const BOOKING_FILTERS = 'bookingFilters';
export const CLIENT_FILTERS = 'clientFilters';
export const INVOICE_NUMBER_FILTERS = 'invoiceNumberFilters';
export const PILOT_FILTERS = 'pilotFilters';
export const FLIGHT_REPORT_FILTERS = 'flightReportFilters';

export const initialValues = {
  range: getMonthStartToCurrentDateRange(),
  [AIRCRAFT_FILTERS]: -1,
  [BOOKING_FILTERS]: -1,
  [CLIENT_FILTERS]: -1,
  [INVOICE_NUMBER_FILTERS]: -1,
  [PILOT_FILTERS]: -1,
  [FLIGHT_REPORT_FILTERS]: -1
};
