import types from '../utils/actionTypes';

export const refreshTokensRequest = () => {
  return {
    type: types.REFRESH_TOKENS_REQUEST
  };
};

export const refreshTokensSuccess = () => {
  return {
    type: types.REFRESH_TOKENS_SUCCESS
  };
};

export const refreshTokensFailure = () => {
  return {
    type: types.REFRESH_TOKENS_FAILURE
  };
};
