import request from '../http/request';
import humps from 'humps';
import { getLocalStorageTokens } from '../utils/helper';
import * as endpoint from '../../services/endpoints';

const AUTHORIZATION_TOKEN = process.env.REACT_APP_AUTHORIZATION_TOKEN || '';
const authorizationTokenBase64Encoded = window.btoa(AUTHORIZATION_TOKEN);

export const fetchLogin = async (body: {}) => {
  const method = 'POST';
  const url = endpoint.AUTH_TOKEN;

  const header = {
    Authorization: `Basic ${authorizationTokenBase64Encoded}`,
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  };

  const response = await request(url, method, header, body);

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
};

export const fetchNewTokens = async () => {
  const method = 'POST';
  const url = endpoint.AUTH_TOKEN;

  const header = {
    Authorization: `Basic ${authorizationTokenBase64Encoded}`,
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  };

  const { refreshToken } = getLocalStorageTokens();

  const body = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  };

  const response = await request(url, method, header, body);

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
};

export const createNewUser = async (body: {}) => {
  const method = 'POST';
  const url = endpoint.REGISTER;

  const header = {
    Authorization: `Basic ${authorizationTokenBase64Encoded}`
  };

  const response = await request(url, method, header, body);

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
};
export const createNewClientUser = async (body: {}) => {
  const method = 'POST';
  const url = endpoint.CLIENT_REGISTER;

  const header = {
    Authorization: `Basic ${authorizationTokenBase64Encoded}`
  };

  const response = await request(url, method, header, body,false);

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
};

export const fetchMe = async () => {
  const method = 'GET';
  const url = endpoint.ME;

  const response = await request(url, method);

  const camelizeResponse = humps.camelizeKeys(response);

  return camelizeResponse;
};
