import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core';
import * as R from 'ramda';
import {
  useStyles,
  CustomersRow,
  columns,
  filterCustomersData
} from './helper';
import { useSortHook } from '../../hooks/sortHooks';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import SharedTableHeader from '../shared/SharedTableHeader';
import { isNilOrEmpty } from '../../utils/helper';
import { CUSTOMERS_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../../redux/utils/interfaces';
import { STATUS } from '../../routes/Customers/helper';

import './style.scss';

interface CustomersTableProps {
  customersData: CustomersRow[];
  goto: Function;
}

const CustomersTable = ({ customersData, goto }: CustomersTableProps) => {
  const classes = useStyles();

  const { filterFormValues } = useSelector((state: StoreState) => ({
    filterFormValues: getFormValues(CUSTOMERS_FILTERS_FORM)(state) || {}
  }));

  const status = R.pathOr('all', [STATUS], filterFormValues);

  const filteredUsersData = useMemo(() => {
    return filterCustomersData(customersData, {
      status
    });
  }, [status, customersData]);

  const formatData = useMemo(() => {
    return filteredUsersData.map((row: any) => {
      return {
        ...row,
        status: isNilOrEmpty(row.deletedAt) ? 'Active' : 'Archived'
      };
    });
  }, [filteredUsersData]);

  const { order, orderBy, sortedDataArray, handleRequestSort } = useSortHook({
    dataArray: formatData,
    columnsData: columns
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <SharedTableHeader
          columns={columns}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedDataArray.map((row: any, index: number) => {
            return (
              <TableRow
                key={index}
                className="customers-custom-body-row"
                onClick={() => goto(`/customers/details/${row.id}`)}>
                <TableCell component="th" scope="row">
                  {row.customerNumber}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.name}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.addressSummary}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.phone}
                </TableCell>

                <TableCell component="th" scope="row" align="left">
                  {row.status}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomersTable;
