import React from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { Box, Paper } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { BOOKINGS_FILTERS_FORM } from '../../utils/reduxFormConstants';
import HeaderContainer from '../../containers/HeaderContainer';
import SharedSpinner from '../../components/shared/SharedSpinner';
import BookingsSecondaryHeader from '../../components/BookingsSecondaryHeader';
import BookingsTable from '../../components/BookingsTable';
import BookingsDetailsHeader from './BookingsDetailsHeader';
import BookingsDetailsForm from './BookingsDetailsForm';
import { BOOKINGS_NAV_KEY } from '../../utils/routesNavigationConstants';
import { useBookingsHook } from './bookingsScreenHooks';
import EditBookingExtraFields from './EditBookingExtraFields';
import { isNilOrEmpty } from '../../utils/helper';
import { BOOKINGS_ROUTE } from '../../utils/routesNavigationConstants';

import './style.scss';

const Bookings = () => {
  const {
    range,
    formValues,
    dataLoading,
    bookingsData,
    isBookingsDetailsRouteMatch,
    isAddBookingsRouteMatch,
    currentBookingData,
    customersList,
    requestingPersonsList,
    basesList,
    passengers,
    equipments,
    pilot,
    approvingClient,
    ame,
    apprentice,
    aircraft,
    initialValues,
    saveButtonDisabled,
    isDefault,
    setRange,
    goto,
    onSaveButtonClickHandler
  } = useBookingsHook();

  return (
    <React.Fragment>
      <Helmet>
        <title>Bookings</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={
          <BookingsSecondaryHeader
            range={range}
            isBookingsDetailsRouteMatch={isBookingsDetailsRouteMatch}
            isAddBookingsRouteMatch={isAddBookingsRouteMatch}
            saveButtonDisabled={saveButtonDisabled}
            currentBookingName={R.pathOr('', ['name'], currentBookingData)}
            setRange={setRange}
            goto={goto}
            onSaveButtonClickHandler={onSaveButtonClickHandler}
          />
        }
        navKey={BOOKINGS_NAV_KEY}>
        <Box py={2.5}>
          {dataLoading ? (
            <SharedSpinner />
          ) : isBookingsDetailsRouteMatch || isAddBookingsRouteMatch ? (
            <React.Fragment>
              {isBookingsDetailsRouteMatch &&
                isNilOrEmpty(currentBookingData) && (
                  <Redirect to={BOOKINGS_ROUTE} />
                )}

              <Box py={2.5}>
                <EditBookingExtraFields
                  isAddBookingsRouteMatch={isAddBookingsRouteMatch}
                  enteredByName={`${R.pathOr(
                    '',
                    ['enteredBy', 'firstName'],
                    currentBookingData
                  )} ${R.pathOr(
                    '',
                    ['enteredBy', 'lastName'],
                    currentBookingData
                  )}`.trim()}
                  dateEntered={R.pathOr(
                    '',
                    ['dateEntered'],
                    currentBookingData
                  )}
                />
              </Box>

              <Paper>
                <BookingsDetailsHeader
                  isBookingsDetailsRouteMatch={isBookingsDetailsRouteMatch}
                  isAddBookingsRouteMatch={isAddBookingsRouteMatch}
                  currentBookingId={R.pathOr(-1, ['id'], currentBookingData)}
                  goto={goto}
                />

                <div className="bookings-details-table-body">
                  {(isAddBookingsRouteMatch || isBookingsDetailsRouteMatch) && (
                    <BookingsDetailsForm
                      initialValues={
                        isAddBookingsRouteMatch ? null : initialValues
                      }
                      isDefault={isDefault}
                      customers={customersList}
                      requestingPersons={requestingPersonsList}
                      bases={basesList}
                      passengers={passengers}
                      equipments={equipments}
                      pilot={pilot}
                      ame={ame}
                      apprentice={apprentice}
                      aircraft={aircraft}
                      approvingClient={approvingClient}
                    />
                  )}
                </div>
              </Paper>
            </React.Fragment>
          ) : (
            <BookingsTable
              formValues={formValues}
              bookingsData={bookingsData}
              goto={goto}
            />
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: BOOKINGS_FILTERS_FORM
})(Bookings);
