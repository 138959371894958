import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import InvoiceSecondaryHeaders from '../../components/InvoiceSecondaryHeaders';
import FillAllAccountingDetails from '../../components/shared/FillAllAccountingDetails';
import InvoiceScreenTable from '../../components/InvoiceScreenTable';
import { INVOICE_NAV_KEY } from '../../utils/routesNavigationConstants';
import { updateInvoiceScreenDataRequest } from '../../redux/actions/invoiceScreenData';
import {
  fetchInvoiceScreenAircraftFiltersRequest,
  fetchInvoiceScreenClientFiltersRequest,
  fetchInvoiceScreenPilotFiltersRequest
} from '../../redux/actions/invoiceScreenFilters';
import { isPresent } from '../../utils/helper';
import { INVOICE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import { initialValues } from './helper';

const Invoice = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(INVOICE_FILTERS_FORM, initialValues));
    dispatch(fetchInvoiceScreenAircraftFiltersRequest());
    dispatch(fetchInvoiceScreenClientFiltersRequest());
    dispatch(fetchInvoiceScreenPilotFiltersRequest());
  }, [dispatch]);

  const [invoiceNumberValues, setInvoiceNumberValues] = useState({});
  const [batchNumberValues, setBatchNumberValues] = useState({});

  const [filteredInvoiceNumber, setInvoiceNumber] = useState('');
  const [filteredBatchNumber, setBatchNumber] = useState('');

  const getNewNumberValues = (value: string, numberValues: {}) => {
    const numberKeys = R.keys(numberValues);
    let newNumbers = {};

    numberKeys.forEach((key: string) => {
      newNumbers[`${key}`] = value;
    });

    return newNumbers;
  };

  const changeFillAllValuesHandler = (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    const { name, value } = event.target;
    if (name === 'invoiceNumbers') {
      setInvoiceNumber(value);
      setInvoiceNumberValues(getNewNumberValues(value, invoiceNumberValues));
    } else {
      setBatchNumber(value);
      setBatchNumberValues(getNewNumberValues(value, batchNumberValues));
    }
  };

  const mappedArray = useMemo(() => {
    const invoiceNumberKeys = R.keys(invoiceNumberValues);

    return invoiceNumberKeys.map((key: string) => {
      const id = key.slice(key.indexOf('_') + 1, key.length);

      return {
        id: id,
        invoiceNumber: invoiceNumberValues[`invoiceNumber_${id}`],
        batchNumber: batchNumberValues[`batchNumber_${id}`]
      };
    });
  }, [invoiceNumberValues, batchNumberValues]);

  const handleSaveDataClick = () => {
    dispatch(updateInvoiceScreenDataRequest(mappedArray));
    setInvoiceNumber('');
    setBatchNumber('');
  };

  const isSaveButtonDisabled = useMemo(() => {
    let isDisabled = false;

    mappedArray.forEach((filledValues: any) => {
      const invoiceNumberIsPresent = isPresent(filledValues.invoiceNumber);
      const batchNumberIsPresent = isPresent(filledValues.batchNumber);

      if (
        !(
          (invoiceNumberIsPresent && batchNumberIsPresent) ||
          (!invoiceNumberIsPresent && !batchNumberIsPresent)
        )
      ) {
        isDisabled = true;
      }
    });

    return isDisabled;
  }, [mappedArray]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Invoice</title>
      </Helmet>
      <HeaderContainer
        navKey={INVOICE_NAV_KEY}
        secondaryHeader={
          <InvoiceSecondaryHeaders
            isSaveButtonDisabled={isSaveButtonDisabled}
            handleSaveDataClick={handleSaveDataClick}
          />
        }>
        <Box py={2.5}>
          <FillAllAccountingDetails
            changeFillAllValuesHandler={changeFillAllValuesHandler}
            invoiceNumberText={filteredInvoiceNumber}
            batchNumberText={filteredBatchNumber}
          />

          <Box py={2.5}>
            <InvoiceScreenTable
              invoiceNumberValues={invoiceNumberValues}
              batchNumberValues={batchNumberValues}
              setInvoiceNumberValues={setInvoiceNumberValues}
              setBatchNumberValues={setBatchNumberValues}
            />
          </Box>
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: INVOICE_FILTERS_FORM
})(Invoice);
