import { put, takeLatest, select, delay, call } from 'redux-saga/effects';
import * as R from 'ramda';
import { getFormValues } from 'redux-form';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchInvoiceScreenDataRequest,
  fetchInvoiceScreenDataSuccess,
  fetchInvoiceScreenDataFailure,
  updateInvoiceScreenDataSuccess,
  updateInvoiceScreenDataFailure,
  exportBillingScreenDataSuccess,
  exportBillingScreenDataFailure
} from '../actions/invoiceScreenData';
import { setInvoiceScreenInvoiceNumberFilters } from '../actions/invoiceScreenFilters';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { isPresent, getDateString } from '../../utils/helper';
import { INVOICE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import StoreState from '../utils/interfaces';
import { filterInvoiceScreenData } from '../../components/InvoiceScreenTable/helper';
import { toast } from 'react-toastify';
import { fetchAndDownload } from '../utils/sagaHelpers';

interface FetchInvoiceScreenDataActionType {
  type: String;
  payload: {
    startDate: Date;
    endDate: Date;
  };
}

interface UpdateInvoiceScreenDataActionType {
  type: String;
  payload: { updateValues: {}[] };
}

function* updateInvoiceScreenData(action: UpdateInvoiceScreenDataActionType) {
  yield put(showSpinner());

  yield delay(1000);

  const { updateValues } = action.payload;

  try {
    const invoiceScreenDataResponse = yield api.updateData(
      endpoints.BILLING,
      {},
      updateValues
    );

    if (invoiceScreenDataResponse.success) {
      yield put(updateInvoiceScreenDataSuccess());

      const {
        formValues: { range }
      } = yield select((state: StoreState) => {
        return {
          formValues: getFormValues(INVOICE_FILTERS_FORM)(state) || {}
        };
      });

      yield put(fetchInvoiceScreenDataRequest(range.from, range.to));
    } else {
      yield put(updateInvoiceScreenDataFailure());
    }
  } catch (e) {
    yield put(updateInvoiceScreenDataFailure());
  }

  yield put(hideSpinner());
}

function* fetchInvoiceScreenData(action: FetchInvoiceScreenDataActionType) {
  yield delay(1000);

  const { startDate, endDate } = action.payload;

  const startDateString = getDateString(new Date(startDate));
  const endDateString = getDateString(new Date(endDate));

  try {
    const invoiceScreenDataResponse = yield api.fetchResponse(
      endpoints.BILLING_RANGE(startDateString, endDateString)
    );

    if (invoiceScreenDataResponse.success) {
      yield put(fetchInvoiceScreenDataSuccess(invoiceScreenDataResponse.data));

      const filters: {}[] = invoiceScreenDataResponse.data.map((data: {}) => {
        const invoiceNumber = R.pathOr('', ['invoiceNumber'], data);

        return {
          id: invoiceNumber.toLowerCase(),
          name: invoiceNumber
        };
      });

      const uniqueFilters = R.uniq(filters).filter((data: {}) =>
        isPresent(R.pathOr('', ['id'], data))
      );

      yield put(setInvoiceScreenInvoiceNumberFilters(uniqueFilters));
    } else {
      yield put(fetchInvoiceScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchInvoiceScreenDataFailure());
  }
}

function* exportBillingScreenData(action:any) {
  yield put(showSpinner());

  yield delay(1000);

  const { formValues, flightReportData } = yield select((state: StoreState) => {
    return {
      formValues: getFormValues(INVOICE_FILTERS_FORM)(state) || {},
      flightReportData: state.invoiceScreenData.invoiceData
    };
  });
  const { from, to } = formValues.range;

  const filteredData = filterInvoiceScreenData(
    flightReportData,
    formValues
  );

  try {
    if (isPresent(filteredData)) {
      const body = filteredData.map((data: {}) => {
        return R.pathOr('', ['id'], data);
      });

      const response = yield call(
        fetchAndDownload,
        `${endpoints.FLIGHT_OPS_EXPORT}?${action.payload.withData ? 'withFormFields' : ""}`,
        body,
        `Flight Reports [${from.toDateString()} - ${to.toDateString()}]`,
        true,
        'Flight Report'
      );

      if (response.success) {
        yield put(exportBillingScreenDataSuccess());
      } else {
        yield put(exportBillingScreenDataFailure());
      }
    } else {
      toast.error('No Flight Reports available to download.');
      yield put(exportBillingScreenDataFailure());
    }
  } catch (e) {
    yield put(exportBillingScreenDataFailure());
  }

  yield put(hideSpinner());
}

export default [
  takeLatest(types.FETCH_INVOICE_SCREEN_DATA_REQUEST, fetchInvoiceScreenData),
  takeLatest(types.UPDATE_INVOICE_SCREEN_DATA_REQUEST, updateInvoiceScreenData),
  takeLatest(types.EXPORT_BILLING_SCREEN_DATA_REQUEST, exportBillingScreenData)
];
