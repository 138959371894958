import {
  EngineerJobReviewScreenFiltersProps,
  ActionType
} from '../utils/interfaces';
import types from '../utils/actionTypes';
import { initialFilterList } from '../../utils/helper';

const initialState: EngineerJobReviewScreenFiltersProps = {
  aircraftFiltersArray: initialFilterList,
  employeeFiltersArray: initialFilterList,
  workReportFiltersArray: initialFilterList,
  workTypeFiltersArray: initialFilterList,
  workDoneFiltersArray: initialFilterList
};

const engineerJobReviewScreenFilters = (
  state: EngineerJobReviewScreenFiltersProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_ENGINEER_JOB_REVIEW_AIRCRAFT_FILTERS_SUCCESS:
      const aircraftFiltersArray = initialFilterList.concat(
        action.payload.aircraftFiltersArray
      );

      return {
        ...state,
        aircraftFiltersArray: aircraftFiltersArray
      };

    case types.FETCH_ENGINEER_JOB_REVIEW_WORK_TYPE_FILTERS_SUCCESS:
      const workTypeFiltersArray = initialFilterList.concat(
        action.payload.workTypeFiltersArray
      );

      return {
        ...state,
        workTypeFiltersArray: workTypeFiltersArray
      };

    case types.FETCH_ENGINEER_JOB_REVIEW_AIRCRAFT_FILTERS_FAILURE:
    case types.FETCH_ENGINEER_JOB_REVIEW_WORK_TYPE_FILTERS_FAILURE:
      return {
        ...state
      };

    case types.SET_ENGINEER_JOB_REVIEW_EMPLOYEE_FILTERS:
      const employeeFiltersArray = initialFilterList.concat(
        action.payload.employeeFiltersArray
      );

      return {
        ...state,
        employeeFiltersArray: employeeFiltersArray
      };

    case types.SET_ENGINEER_JOB_REVIEW_WORK_REPORT_FILTERS:
      const workReportFiltersArray = initialFilterList.concat(
        action.payload.workReportFiltersArray
      );

      return {
        ...state,
        workReportFiltersArray: workReportFiltersArray
      };

    case types.SET_ENGINEER_JOB_REVIEW_WORK_DONE_FILTERS:
      const workDoneFiltersArray = initialFilterList.concat(
        action.payload.workDoneFiltersArray
      );

      return {
        ...state,
        workDoneFiltersArray: workDoneFiltersArray
      };

    default:
      return state;
  }
};

export default engineerJobReviewScreenFilters;
