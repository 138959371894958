import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/styles';
import AppRoutes from '../../routes';
import AppContainer from '../AppContainer';
import ReduxStore, { persistor } from '../../redux/index';
import history from '../../utils/history';
import appTheme from '../../utils/appTheme';

import 'react-toastify/dist/ReactToastify.css';

function AppBootUp() {
  return (
    <Provider store={ReduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={appTheme}>
          <ConnectedRouter history={history}>
            <AppContainer>
              <AppRoutes />
            </AppContainer>
          </ConnectedRouter>

          <ToastContainer
            autoClose={8000}
            position="bottom-left"
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            draggable
            pauseOnHover={false}
          />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppBootUp;
