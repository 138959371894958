import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { isPresent } from '../../utils/helper';
import {
  statusFilterStatePath,
  StatusFilterComponent
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export const columns: Column[] = [
  {
    id: 'customerNumber',
    label: 'Code',
    minWidth: 120,
    align: 'center',
    sortingProps: [['customerNumber']]
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 90,
    align: 'center',
    sortingProps: [['name']]
  },
  {
    id: 'address',
    label: 'Address',
    minWidth: 120,
    align: 'center',
    sortingProps: [['addressSummary']]
  },
  {
    id: 'phone',
    label: 'Phone',
    minWidth: 120,
    align: 'center',
    sortingProps: [['phone']]
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    align: 'center',
    sortingProps: [['status']],
    filterComponent: StatusFilterComponent,
    filterStatePath: statusFilterStatePath
  }
];

export interface CustomersRow {
  id: string;
  customerNumber: string;
  name: string;
  addressSummary: string;
  phone: string;
}

export interface FiltersProps {
  status: string;
}

const isAllStatusFilters = R.propEq('status', 'all');

const isAllFiltersAppliedEvery = R.allPass([isAllStatusFilters]);

export const filterCustomersData = (
  userArray: CustomersRow[],
  filters: FiltersProps
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return userArray;
  }

  const { status } = filters;

  let filteredDataArray = userArray;

  if (!isAllStatusFilters({ status })) {
    const isFilter = (obj: {}) =>
      R.equals(
        isPresent(R.pathOr('', ['deletedAt'], obj)) ? 'archived' : 'active',
        `${status}`
      );

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  return filteredDataArray;
};
