import { getMonthStartToCurrentDateRange } from '../../utils/helper';

export const RANGE = 'range';
export const AIRCRAFT_FILTERS = 'aircraftFilters';
export const EMPLOYEE_FILTERS = 'employeeFilters';
export const WORK_REPORT_FILTERS = 'workReportFilters';
export const WORK_TYPE_FILTERS = 'workTypeFilters';
export const WORK_DONE_FILTERS = 'workDoneFilters';

export const initialValues = {
  range: getMonthStartToCurrentDateRange(),
  [AIRCRAFT_FILTERS]: -1,
  [EMPLOYEE_FILTERS]: -1,
  [WORK_REPORT_FILTERS]: -1,
  [WORK_TYPE_FILTERS]: -1,
  [WORK_DONE_FILTERS]: -1
};

export const filtersNotWorkingOptions = [
  { label: 'All', value: -1 },
  { label: 'Filter not working', value: -2 }
];
