import types from '../utils/actionTypes';

export const fetchCustomersScreenDataRequest = () => {
  return {
    type: types.FETCH_CUSTOMERS_SCREEN_DATA_REQUEST
  };
};

export const fetchCustomersScreenDataSuccess = (customersData: []) => {
  return {
    type: types.FETCH_CUSTOMERS_SCREEN_DATA_SUCCESS,
    payload: { customersData }
  };
};

export const fetchCustomersScreenDataFailure = () => {
  return {
    type: types.FETCH_CUSTOMERS_SCREEN_DATA_FAILURE
  };
};

export const updateCustomerDataRequest = (data: {}) => {
  return {
    type: types.UPDATE_CUSTOMER_DATA_REQUEST,
    payload: {
      data
    }
  };
};

export const updateCustomerDataSuccess = () => {
  return {
    type: types.UPDATE_CUSTOMER_DATA_SUCCESS
  };
};

export const updateCustomerDataFailure = () => {
  return {
    type: types.UPDATE_CUSTOMER_DATA_FAILURE
  };
};

export const addCustomerDataRequest = (data: {}) => {
  return {
    type: types.ADD_CUSTOMER_DATA_REQUEST,
    payload: {
      data
    }
  };
};

export const addCustomerDataSuccess = () => {
  return {
    type: types.ADD_CUSTOMER_DATA_SUCCESS
  };
};

export const addCustomerDataFailure = () => {
  return {
    type: types.ADD_CUSTOMER_DATA_FAILURE
  };
};

export const addContactPassengerDataRequest = (
  data: {},
  isContact: boolean
) => {
  return {
    type: types.ADD_CONTACT_PASSENGER_DATA_REQUEST,
    payload: {
      data,
      isContact
    }
  };
};

export const addContactPassengerDataSuccess = () => {
  return {
    type: types.ADD_CONTACT_PASSENGER_DATA_SUCCESS
  };
};

export const addContactPassengerDataFailure = () => {
  return {
    type: types.ADD_CONTACT_PASSENGER_DATA_FAILURE
  };
};

export const editContactPassengerDataRequest = (
  data: {},
  isContact: boolean
) => {
  return {
    type: types.EDIT_CONTACT_PASSENGER_DATA_REQUEST,
    payload: {
      data,
      isContact
    }
  };
};

export const editContactPassengerDataSuccess = () => {
  return {
    type: types.EDIT_CONTACT_PASSENGER_DATA_SUCCESS
  };
};

export const editContactPassengerDataFailure = () => {
  return {
    type: types.EDIT_CONTACT_PASSENGER_DATA_FAILURE
  };
};
