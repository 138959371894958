import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderContainer from '../../containers/HeaderContainer';
import { HELP_NAV_KEY } from '../../utils/routesNavigationConstants';
import Breadcrumbs from '../../components/shared/Breadcrumbs';

const HelpPage = () => {
  const breadcrumbDataArray = [
    {
      id: 'help_breadcrum',
      label: 'Help',
      onClick: () => {}
    }
  ];
  return (
    <React.Fragment>
      <Helmet>
        <title>Help</title>
      </Helmet>
      <HeaderContainer
        secondaryHeader={
          <div className="breadcrumbs-container-bookings">
            <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
          </div>
        }
        navKey={HELP_NAV_KEY}>
        <Box style={{ marginTop: '30px' }}>
          <Typography variant="subtitle1">
            You can contact Pierre Marcoux at 514-706-7070 or by email at{' '}
            <a href="mailto:pierre.marcoux@pballard.com">
              pierre.marcoux@pballard.com
            </a>
          </Typography>
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default HelpPage;
