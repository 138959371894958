import React from 'react';
import DatePicker from '../shared/DatePicker';
// import FilterListIcon from '@material-ui/icons/FilterList';
import FilterAltIcon from '../../assets/filter.png';
import NoFilterAltIcon from '../../assets/nofilter.png';
import { useSelector,useDispatch } from 'react-redux';
import './styles.scss';
import { change } from 'redux-form';
import { CLIENT_PORTAL_FILTERS_FORM } from '../../utils/reduxFormConstants';
// import StoreState from '../../redux/utils/interfaces';

interface RangeProps {
  to: Date;
  from: Date;
}
interface CalendarInputRangeProps {
  range: RangeProps | any;
  setRange: Function;
  showOffFilter: boolean;
}

const CalendarInputRange = ({
  range,
  setRange,
  showOffFilter
}: CalendarInputRangeProps) => {
  const { to, from } = range;
  const dispatch = useDispatch();
  const [isValidRange, toggleValidRange] = React.useState(
    to !== '' && from !== ''
  );
  const { initialRange } = useSelector((state: any) => {
    return {
      initialRange: state.form?.clientPortalFiltersForm?.initial?.range
    };
  });
  React.useMemo(() => {
    if (to !== '' && from !== '') {
      toggleValidRange(true);

    } else toggleValidRange(false);
  }, [to, from]);
  const handleSelectDateFrom = (date: Date) => {
    setRange({
      ...range,
      from: new Date(date)
    });
  };

  const handleSelectDateTo = (date: Date) => {
    setRange({
      ...range,
      to: new Date(date)
    });
  };

  const handelOnFilterToggel = () => {
    if (isValidRange) {
      toggleValidRange(false);
      setRange({
        to: '',
        from: ''
      });
      dispatch(change(CLIENT_PORTAL_FILTERS_FORM, 'statusFilters', "Ready to sign"))
    } else {
      setRange({
        to:initialRange.to,
        from:initialRange.from
      });
    }
  };

  return (
    <div className="calendar-input-range">
      <DatePicker
        id="calendar-input-range-from"
        variant="inline"
        disableToolbar
        label="From"
        format="MMM d yyyy"
        selectedDate={from !== '' ? from : new Date()}
        maxDate={new Date(to !== '' ? to : new Date())}
        handleDateChange={handleSelectDateFrom}
        disabled={!isValidRange}
      />
      <DatePicker
        id="calendar-input-range-to"
        variant="inline"
        disableToolbar
        label="To"
        format="MMM d yyyy"
        minDate={new Date(from !== '' ? from : new Date())}
        selectedDate={to !== '' ? to : new Date()}
        handleDateChange={handleSelectDateTo}
        disabled={!isValidRange}
      />
      {showOffFilter ? (
        <div className="date-filter" onClick={handelOnFilterToggel}>
          {isValidRange ? (
            <img
              src={NoFilterAltIcon}
              alt="nofilter"
              className="data-filter-icon"
            />
          ) : (
            <img
              src={FilterAltIcon}
              alt="filter"
              className="data-filter-icon"
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CalendarInputRange;
