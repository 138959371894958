import React, { useEffect, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, initialize, getFormValues } from 'redux-form';
import { Box, Paper } from '@material-ui/core';
import HeaderContainer from '../../containers/HeaderContainer';
import AircraftSecondaryHeader from '../../components/AircraftSecondaryHeader';
import {
  ADD_AIRCRAFT_ROUTE,
  AIRCRAFT_DETAILS_ROUTE,
  AIRCRAFT_NAV_KEY
} from '../../utils/routesNavigationConstants';
import SharedSpinner from '../../components/shared/SharedSpinner';
import {
  fetchAircraftScreenDataRequest,
  fetchAircraftOfficialTypesRequest,
  addEditAircraftDetailsRequest,
  aircraftStatusChangeRequest
} from '../../redux/actions/aircraftScreenData';
import StoreState from '../../redux/utils/interfaces';
import AircraftTable from '../../components/AircraftTable';
import {
  AIRCRAFT_ADD_EDIT_FORM,
  AIRCRAFT_FILTERS_FORM
} from '../../utils/reduxFormConstants';
import { initialValues } from './helper';
import {
  isNilOrEmpty,
  isPresent,
  removeNullOrEmptyKeys
} from '../../utils/helper';
import AircraftDetailsHeader from './AircraftDetailsHeader';
import AircraftForm from './AircraftForm';

import './styles.scss';

const Aircraft = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { aircraftId: aircraftIdParam }: any = useParams();
  const aircraftId = isPresent(aircraftIdParam)
    ? Number(aircraftIdParam)
    : null;

  const isAircraftDetailsRouteMatch = isPresent(
    useRouteMatch(AIRCRAFT_DETAILS_ROUTE)
  );
  const isAddAircraftRouteMatch = isPresent(useRouteMatch(ADD_AIRCRAFT_ROUTE));

  const goto = useCallback((route: string) => history.push(route), [history]);

  const {
    aircraft,
    isLoading,
    aircraftOfficialTypes,
    addEditFormValues
  }: any = useSelector((state: StoreState) => ({
    aircraft: state.aircraftScreenData.aircraft,
    isLoading: state.aircraftScreenData.isLoading,
    aircraftOfficialTypes: state.aircraftScreenData.aircraftOfficialTypes,
    addEditFormValues: getFormValues(AIRCRAFT_ADD_EDIT_FORM)(state) || {}
  }));

  useEffect(() => {
    dispatch(initialize(AIRCRAFT_FILTERS_FORM, initialValues));
    dispatch(fetchAircraftScreenDataRequest());
    dispatch(fetchAircraftOfficialTypesRequest());
  }, [dispatch]);

  const saveButtonHandler = useCallback(() => {
    const values: any = removeNullOrEmptyKeys({
      ...addEditFormValues,
      maxFuel: Number(addEditFormValues.maxFuel)
    });

    const addEditFormValuesToSave = {
      ...values,
      ...(values.aircraftOfficialType && {
        aircraftOfficialType: {
          id: values.aircraftOfficialType
        }
      })
    };

    dispatch(
      addEditAircraftDetailsRequest(aircraftId, addEditFormValuesToSave)
    );
  }, [dispatch, aircraftId, addEditFormValues]);

  const isSubmitDisabled = useMemo(() => {
    return (
      isNilOrEmpty(addEditFormValues.registration) ||
      isNilOrEmpty(addEditFormValues.aircraftType) ||
      isNilOrEmpty(addEditFormValues.aircraftOfficialType) ||
      isNilOrEmpty(addEditFormValues.fuelMeasure) ||
      isNilOrEmpty(addEditFormValues.maxFuel)
    );
  }, [addEditFormValues]);

  const currentAircraftData = R.find(R.propEq('id', aircraftId))(aircraft);

  const addEditFormInitialValues = useMemo(() => {
    return {
      registration: R.pathOr('', ['registration'], currentAircraftData),
      aircraftType: R.pathOr('', ['aircraftType'], currentAircraftData),
      aircraftOfficialType: R.pathOr(
        '',
        ['aircraftOfficialType', 'id'],
        currentAircraftData
      ),
      isClientAircraft: R.pathOr('', ['isClientAircraft'], currentAircraftData),
      fuelMeasure: R.pathOr('', ['fuelMeasure'], currentAircraftData),
      maxFuel: R.pathOr('', ['maxFuel'], currentAircraftData),
      satPhoneNumber: R.pathOr('', ['satPhoneNumber'], currentAircraftData)
    };
  }, [currentAircraftData]);

  const isArchived = R.path(['status'], currentAircraftData) === 'archived';

  const toggleAircraftStatusHandler = useCallback(() => {
    if (isPresent(aircraftId)) {
      dispatch(aircraftStatusChangeRequest(aircraftId, isArchived));
    }
  }, [dispatch, aircraftId, isArchived]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Aircraft</title>
      </Helmet>

      <HeaderContainer
        secondaryHeader={
          <AircraftSecondaryHeader
            currentAircraftRegistration={R.pathOr(
              '',
              ['registration'],
              currentAircraftData
            )}
            isActive={!isArchived}
            isAircraftDetailsRouteMatch={isAircraftDetailsRouteMatch}
            isAddAircraftRouteMatch={isAddAircraftRouteMatch}
            isSubmitDisabled={isSubmitDisabled}
            saveButtonHandler={saveButtonHandler}
            toggleAircraftStatusHandler={toggleAircraftStatusHandler}
            goto={goto}
          />
        }
        navKey={AIRCRAFT_NAV_KEY}>
        <Box py={2.5} className="aircraft-route-container">
          {isLoading ? (
            <SharedSpinner />
          ) : isAircraftDetailsRouteMatch || isAddAircraftRouteMatch ? (
            <Paper>
              <AircraftDetailsHeader
                isAircraftDetailsRouteMatch={isAircraftDetailsRouteMatch}
                isAddAircraftRouteMatch={isAddAircraftRouteMatch}
                currentAircraftId={aircraftId}
                goto={goto}
              />

              <div className="aircraft-details-table-body">
                <AircraftForm
                  initialValues={addEditFormInitialValues}
                  aircraftOfficialTypes={aircraftOfficialTypes}
                />
              </div>
            </Paper>
          ) : (
            <AircraftTable
              aircraftArray={aircraft}
              aircraftOfficialTypes={aircraftOfficialTypes}
              goto={goto}
            />
          )}
        </Box>
      </HeaderContainer>
    </React.Fragment>
  );
};

export default reduxForm({
  form: AIRCRAFT_FILTERS_FORM
})(Aircraft);
