import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export interface PdfOptionsProps {
  accpacImport: boolean;
  weeklySummary: boolean;
  receipts: boolean;
  summaryModeImages: boolean;
}
export interface PdfOptionsSelectorProps {
  pdfOptions: PdfOptionsProps;
  setPdfOptions: Function;
}

const PdfOptionsSelector = ({
  pdfOptions,
  setPdfOptions
}: PdfOptionsSelectorProps) => {
  const { accpacImport, weeklySummary, receipts, summaryModeImages } =
    pdfOptions;

  const handleChange = (
    event: React.ChangeEvent<{ name: string; checked: boolean }>
  ) => {
    const { name, checked } = event.target;

    setPdfOptions({
      ...pdfOptions,
      [name]: checked
    });
  };

  return (
    <div className="pdf-options-selector">
      <FormControl required component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={accpacImport}
                onChange={handleChange}
                name="accpacImport"
              />
            }
            label="Accpac Import"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={weeklySummary}
                onChange={handleChange}
                name="weeklySummary"
              />
            }
            label="Weekly Summary"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={receipts}
                onChange={handleChange}
                name="receipts"
              />
            }
            label="Receipts"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={summaryModeImages}
                onChange={handleChange}
                name="summaryModeImages"
              />
            }
            label="Summary Mode Images"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default PdfOptionsSelector;
