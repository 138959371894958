import { put, takeLatest, all, call, delay } from 'redux-saga/effects';
import * as R from 'ramda';
import types from '../utils/actionTypes';
import {
  fetchBookingFilters,
  fetchCustomerFilters,
  fetchAircraftFilters,
  fetchPilotFilters,
  fetchAmeFilters
} from '../utils/sagaHelpers';
import {
  fetchFlightReportFiltersSuccess,
  fetchFlightReportFiltersFailure,
  fetchFlightReportBookingFiltersSuccess,
  fetchFlightReportBookingFiltersFailure
} from '../actions/flightReportScreenFilters';
import { showSpinner, hideSpinner } from '../actions/spinner';

interface FetchFlightReportBookingFiltersActionType {
  type: String;
  payload: { startDate: Date; endDate: Date };
}

function* fetchFlightReportBookingFilters(
  action: FetchFlightReportBookingFiltersActionType
) {
  yield delay(1000);

  const { startDate, endDate } = action.payload;

  const bookingFiltersResponse = yield call(
    fetchBookingFilters,
    startDate,
    endDate
  );

  if (R.pathOr(false, ['success'], bookingFiltersResponse)) {
    yield put(
      fetchFlightReportBookingFiltersSuccess(
        R.pathOr([], ['data', 'bookingFilters'], bookingFiltersResponse)
      )
    );
  } else {
    yield put(fetchFlightReportBookingFiltersFailure());
  }
}

function* fetchFlightReportFilters() {
  yield put(showSpinner());

  yield delay(1000);

  try {
    const [
      customerFiltersResponse,
      aircraftFiltersResponse,
      pilotFiltersResponse,
      ameFiltersResponse
    ]: any = yield all([
      call(fetchCustomerFilters),
      call(fetchAircraftFilters),
      call(fetchPilotFilters),
      call(fetchAmeFilters)
    ]);

    yield put(
      fetchFlightReportFiltersSuccess(
        R.pathOr([], ['data', 'customerFilters'], customerFiltersResponse),
        R.pathOr([], ['data', 'aircraftFilters'], aircraftFiltersResponse),
        R.pathOr([], ['data', 'pilotFilters'], pilotFiltersResponse),
        R.pathOr([], ['data', 'ameFilters'], ameFiltersResponse)
      )
    );
  } catch (e) {
    yield put(fetchFlightReportFiltersFailure());
  }

  yield put(hideSpinner());
}

export default [
  takeLatest(
    types.FETCH_FLIGHT_REPORT_FILTERS_REQUEST,
    fetchFlightReportFilters
  ),
  takeLatest(
    types.FETCH_FLIGHT_REPORT_BOOKING_FILTERS_REQUEST,
    fetchFlightReportBookingFilters
  )
];
