import React,{useMemo} from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import StoreState from '../../redux/utils/interfaces';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import {
  DEPARTMENT,
  BUSH_PAY,
  STATUS,
  FIRST_NAME,
  LAST_NAME,
  filtersNotWorkingOptions,
  COMPANY
} from '../../routes/ClientUsers/helper';
import { CLIENT_USERS_FILTERS_FORM } from '../../utils/reduxFormConstants';
import {
  initialFilterList,
  booleanOptions,
  statusOptions
} from '../../utils/helper';

const commonFilterPath = ['form', CLIENT_USERS_FILTERS_FORM, 'values'];
export const departmentFilterStatePath = commonFilterPath.concat(DEPARTMENT);
export const bushPayFilterStatePath = commonFilterPath.concat(BUSH_PAY);
export const statusFilterStatePath = commonFilterPath.concat(STATUS);
export const firstNameFilterStatePath = commonFilterPath.concat(FIRST_NAME);
export const lastNameFilterStatePath = commonFilterPath.concat(LAST_NAME);
export const companyFilterStatePath = commonFilterPath.concat(COMPANY);

export const DepartmentFilterComponent = () => {
  const { userTypes } = useSelector((state: StoreState) => ({
    userTypes: initialFilterList.concat(
      R.pathOr(
        filtersNotWorkingOptions,
        ['usersScreenData', 'userTypes'],
        state
      )
    )
  }));

  return (
    <Field
      name={DEPARTMENT}
      component={ReduxFormAutocomplete}
      label="Department"
      optionsList={userTypes}
      required
    />
  );
};

export const BushPayFilterComponent = () => {
  return (
    <Field
      name={BUSH_PAY}
      component={ReduxFormAutocomplete}
      label="Bush Pay"
      optionsList={booleanOptions}
      required
    />
  );
};

export const FirstNameFilterComponent = () => {
  const { clientUsersScreenData } = useSelector((state: StoreState) => ({
    clientUsersScreenData: state.clientUsersScreenData.usersData
  }));

  const firstNameFiltersArray = useMemo(() => {
    let namesData: any[] = clientUsersScreenData;

    const names = new Set<{ id: number | string; name: string }>();

    namesData.forEach((data, index) => {
      names.add({ id: data.id, name: data.firstName });
    });

    let firstNameFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    names.forEach((data, key) => {
      firstNameFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(firstNameFiltersArray);
  }, [clientUsersScreenData]);

  return (
    <Field
      name={FIRST_NAME}
      component={ReduxFormAutocomplete}
      label="First Name"
      optionsList={firstNameFiltersArray}
      required
    />
  );
};
export const LastNameFilterComponent = () => {
  const { clientUsersScreenData } = useSelector((state: StoreState) => ({
    clientUsersScreenData: state.clientUsersScreenData.usersData
  }));

  const lastNameFiltersArray = useMemo(() => {
    let namesData: any[] = clientUsersScreenData;

    const names = new Set<{ id: number | string; name: string }>();

    namesData.forEach((data, index) => {
      names.add({ id: data.id, name: data.lastName });
    });

    let lastNameFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    names.forEach((data, key) => {
      lastNameFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(lastNameFiltersArray);
  }, [clientUsersScreenData]);

  return (
    <Field
      name={LAST_NAME}
      component={ReduxFormAutocomplete}
      label="Last Name"
      optionsList={lastNameFiltersArray}
      required
    />
  );
};
export const CompanyFilterComponent = () => {
  const { clientsDataArray } = useSelector((state: StoreState) => ({
    clientsDataArray: state.clientUsersScreenData.clients
  }));

  const companyFiltersArray = useMemo(() => {
    let companiesData: any[] = clientsDataArray;

    const companies = new Set<{ id: number | string; name: string }>();

    companiesData.forEach((data, index) => {
      companies.add({ id: data.id, name: data.name });
    });

    let companyFiltersArray: { id: string | number; name: string }[] = [
      { id: -1, name: 'All' }
    ];

    companies.forEach((data, key) => {
      companyFiltersArray.push({ id: data.id, name: data.name });
    });
    return R.uniq(companyFiltersArray);
  }, [clientsDataArray]);

  return (
    <Field
      name={COMPANY}
      component={ReduxFormAutocomplete}
      label="Name"
      optionsList={companyFiltersArray}
      required
    />
  );
};

export const StatusFilterComponent = () => {
  return (
    <Field
      name={STATUS}
      component={ReduxFormAutocomplete}
      label="Status"
      optionsList={statusOptions}
      required
    />
  );
};