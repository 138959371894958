import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/AddOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import Breadcrumbs from '../shared/Breadcrumbs';
import CalendarInputRange from '../CalendarInputRange';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import {
  BOOKINGS_ROUTE,
  ADD_BOOKINGS_ROUTE
} from '../../utils/routesNavigationConstants';
import StoreState from '../../redux/utils/interfaces';

import './style.scss';

interface RangeProps {
  to: Date;
  from: Date;
}

interface BookingsSecondaryHeaderProps {
  range: RangeProps;
  isBookingsDetailsRouteMatch: boolean;
  isAddBookingsRouteMatch: boolean;
  saveButtonDisabled: boolean;
  currentBookingName: string;
  setRange: Function;
  goto: Function;
  onSaveButtonClickHandler: Function;
}

const BookingsSecondaryHeader = ({
  range,
  isBookingsDetailsRouteMatch,
  isAddBookingsRouteMatch,
  saveButtonDisabled,
  currentBookingName,
  setRange,
  goto,
  onSaveButtonClickHandler
}: BookingsSecondaryHeaderProps) => {
  const { isApprovingSupervisor } = useSelector((state: StoreState) => ({
    isApprovingSupervisor: state.userInformation.isApprovingSupervisor
  }));

  const breadcrumbDataArray = useMemo(() => {
    const breadcrumbDataArrayLocal = [
      {
        id: 'bookings_breadcrum',
        label: 'Bookings',
        onClick:
          isBookingsDetailsRouteMatch || isAddBookingsRouteMatch
            ? () => goto(BOOKINGS_ROUTE)
            : () => {}
      }
    ];

    if (isBookingsDetailsRouteMatch) {
      breadcrumbDataArrayLocal.push({
        id: 'bookings_breadcrum_name',
        label: currentBookingName,
        onClick: () => {}
      });
    } else if (isAddBookingsRouteMatch) {
      breadcrumbDataArrayLocal.push({
        id: 'add_bookings_breadcrum_name',
        label: 'Add Booking',
        onClick: () => {}
      });
    }

    return breadcrumbDataArrayLocal;
  }, [
    isBookingsDetailsRouteMatch,
    isAddBookingsRouteMatch,
    goto,
    currentBookingName
  ]);

  const addBookingButtonHandler = useCallback(() => {
    goto(ADD_BOOKINGS_ROUTE);
  }, [goto]);

  const actions = useMemo(() => {
    if (!isApprovingSupervisor) return [];

    return isBookingsDetailsRouteMatch || isAddBookingsRouteMatch
      ? [
          {
            icon: SaveIcon,
            name: 'Save',
            disabled: saveButtonDisabled,
            onClick: onSaveButtonClickHandler,
            showToast: false
          }
        ]
      : [
          {
            icon: AddIcon,
            name: 'Add New',
            onClick: addBookingButtonHandler
          }
        ];
  }, [
    isApprovingSupervisor,
    isBookingsDetailsRouteMatch,
    isAddBookingsRouteMatch,
    saveButtonDisabled,
    onSaveButtonClickHandler,
    addBookingButtonHandler
  ]);

  return (
    <React.Fragment>
      <div className="breadcrumbs-container-bookings">
        <Breadcrumbs breadcrumbDataArray={breadcrumbDataArray} />
      </div>

      <div className="filters-container-bookings">
        {!(isBookingsDetailsRouteMatch || isAddBookingsRouteMatch) && (
          <CalendarInputRange range={range} setRange={setRange} showOffFilter={false}/>
        )}
      </div>

      <CustomSpeedDial actions={actions} />
    </React.Fragment>
  );
};

export default BookingsSecondaryHeader;
