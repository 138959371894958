import React from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FirstPage,
  LastPage
} from '@material-ui/icons';
import { isPresent } from '../../../utils/helper';

import './style.scss';

interface ForwardBackwardNavProps {
  currentIndex: number;
  total: number;
  customLabel?: string;
  handlePageChange: (changeIndex: number) => void;
}

class ForwardBackwardNav extends React.Component<ForwardBackwardNavProps> {
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false);
  }

  handleBackButtonClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const { currentIndex, handlePageChange } = this.props;
    if (currentIndex > 0) {
      handlePageChange(currentIndex - 1);
    }
  };

  handleNextButtonClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const { currentIndex, handlePageChange, total } = this.props;
    if (total - 1 > currentIndex) {
      handlePageChange(currentIndex + 1);
    }
  };

  handleFirstPageButtonClick = (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { currentIndex, handlePageChange } = this.props;
    if (currentIndex > 0) {
      handlePageChange(0);
    }
  };

  handleLastPageButtonClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const { currentIndex, handlePageChange, total } = this.props;
    if (total - 1 > currentIndex) {
      handlePageChange(total - 1);
    }
  };

  handleKeyPress = (event: any) => {
    const bodyElement = document.querySelector('body');

    if (event.target === bodyElement) {
      if (event.keyCode === 37 && event.shiftKey) {
        this.handleFirstPageButtonClick();
      } else if (event.keyCode === 39 && event.shiftKey) {
        this.handleLastPageButtonClick();
      } else if (event.keyCode === 37) {
        this.handleBackButtonClick();
      } else if (event.keyCode === 39) {
        this.handleNextButtonClick();
      }
    }
  };

  render() {
    const { currentIndex, total, customLabel } = this.props;

    return (
      <Box className="forward-backward-nav-container">
        <Box mx={0}>
          <Tooltip title="Use Shift + Back Arrow Keys" placement="top" arrow>
            <IconButton
              onClick={this.handleFirstPageButtonClick}
              disabled={currentIndex <= 0}
              aria-label="first page">
              <FirstPage fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box mx={2.5}>
          <Tooltip title="Use Back Arrow Key" placement="top" arrow>
            <IconButton
              onClick={this.handleBackButtonClick}
              disabled={currentIndex <= 0}
              aria-label="previous page">
              <KeyboardArrowLeft fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box mx={2.5}>
          {isPresent(customLabel)
            ? customLabel
            : `${currentIndex + 1} / ${total}`}
        </Box>

        <Box mx={2.5}>
          <Tooltip title="Use Next Arrow Key" placement="top" arrow>
            <IconButton
              onClick={this.handleNextButtonClick}
              disabled={currentIndex >= total - 1}
              aria-label="next page">
              <KeyboardArrowRight fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box mx={0}>
          <Tooltip title="Use Shift + Next Arrow Keys" placement="top" arrow>
            <IconButton
              onClick={this.handleLastPageButtonClick}
              disabled={currentIndex >= total - 1}
              aria-label="last page">
              <LastPage fontSize="large" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
}

export default ForwardBackwardNav;
