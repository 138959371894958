import { CustomerFlightReportProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: CustomerFlightReportProps = {
  flightReports: []
};

const customerFlightReport = (
  state: CustomerFlightReportProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_CUSTOMER_FLIGHT_REPORT_SUCCESS:
      return {
        ...state,
        flightReports: action.payload.flightReportData
      };

    default:
      return state;
  }
};

export default customerFlightReport;
