import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import * as R from 'ramda';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { Box } from '@material-ui/core';
import Breadcrumbs from '../shared/Breadcrumbs';
import CalendarInputRange from '../CalendarInputRange';
import { FUEL_EXPENSE_FILTERS_FORM } from '../../utils/reduxFormConstants';
import CustomSpeedDial from '../shared/CustomSpeedDial';
import { RANGE, RangeProps } from '../../routes/FuelExpense/helper';
import StoreState from '../../redux/utils/interfaces';
import { getWeekRange } from '../../utils/helper';
import { fetchFuelExpenseScreenBookingFiltersRequest } from '../../redux/actions/fuelExpenseScreenFilters';

import './style.scss';

interface FuelExpenseScreenHeaderProps {
  handleSaveDataClick: Function;
}

const FuelExpenseScreenHeader = ({
  handleSaveDataClick
}: FuelExpenseScreenHeaderProps) => {
  const dispatch = useDispatch();

  const { formValues } = useSelector((state: StoreState) => {
    return {
      formValues: getFormValues(FUEL_EXPENSE_FILTERS_FORM)(state) || {}
    };
  });

  const rangeValue = R.pathOr(getWeekRange(), ['range'], formValues);

  useEffect(() => {
    dispatch(
      fetchFuelExpenseScreenBookingFiltersRequest(
        rangeValue.from,
        rangeValue.to
      )
    );
  }, [rangeValue, dispatch]);

  const updateFormField = (key: string, value: any) =>
    dispatch(change(FUEL_EXPENSE_FILTERS_FORM, key, value));

  const setRangeHandler = (range: RangeProps) => {
    updateFormField(RANGE, range);
  };

  const actions = useMemo(() => {
    return [
      {
        icon: SaveIcon,
        name: 'Save',
        onClick: handleSaveDataClick
      }
    ];
  }, [handleSaveDataClick]);

  return (
    <React.Fragment>
      <Box mr={5}>
        <Breadcrumbs
          breadcrumbDataArray={[
            {
              id: 'route_name',
              label: 'Fuel Expenses'
            }
          ]}
        />
      </Box>

      <div className="dropdown-container-fuel-expense">
        <CalendarInputRange range={rangeValue} setRange={setRangeHandler} showOffFilter={false}/>
      </div>

      <CustomSpeedDial actions={actions} />
    </React.Fragment>
  );
};

export default FuelExpenseScreenHeader;
