import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { isPresent } from '../../utils/helper';
import {
  departmentFilterStatePath,
  bushPayFilterStatePath,
  statusFilterStatePath,
  DepartmentFilterComponent,
  BushPayFilterComponent,
  StatusFilterComponent
} from './FiltersComponents';

export const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isNumber?: boolean;
  sortingProps: string[][];
  filterComponent?: any;
  filterStatePath?: string[];
}

export const columns: Column[] = [
  {
    id: 'employeeId',
    label: 'Employee ID',
    minWidth: 120,
    align: 'center',
    sortingProps: [['employeeId']]
  },
  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 90,
    align: 'center',
    sortingProps: [['firstName']]
  },
  {
    id: 'lastName',
    label: 'Last Name',
    minWidth: 90,
    align: 'center',
    sortingProps: [['lastName']]
  },
  {
    id: 'username',
    label: 'Email',
    minWidth: 90,
    align: 'center',
    sortingProps: [['username']]
  },
  {
    id: 'phone',
    label: 'Phone',
    minWidth: 90,
    align: 'center',
    sortingProps: [['phone']]
  },
  {
    id: 'userType',
    label: 'Department',
    minWidth: 90,
    align: 'center',
    sortingProps: [['userTypeLabel']],
    filterComponent: DepartmentFilterComponent,
    filterStatePath: departmentFilterStatePath
  },
  {
    id: 'license1',
    label: 'License',
    minWidth: 90,
    align: 'center',
    sortingProps: [['license1']]
  },
  {
    id: 'bushpay',
    label: 'Bush Pay',
    minWidth: 120,
    align: 'center',
    sortingProps: [['bushpayLabel']],
    filterComponent: BushPayFilterComponent,
    filterStatePath: bushPayFilterStatePath
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    align: 'center',
    sortingProps: [['status']],
    filterComponent: StatusFilterComponent,
    filterStatePath: statusFilterStatePath
  }
];

interface UsersUserType {
  id: number;
}

interface UsersCompany {
  id: number;
}

export interface UsersRow {
  id: number;
  employeeId: string;
  firstName: string;
  lastName: string;
  username: string;
  userType: UsersUserType;
  company: UsersCompany;
  bushpay: boolean;
  signatureUri: string;
  deletedAt: string | null;
}

export interface FiltersProps {
  department: number;
  bushPay: any;
  status: string;
}

const isAllDepartmentFilters = R.propEq('department', -1);
const isAllBushPayFilters = R.propEq('bushPay', 'all');
const isAllStatusFilters = R.propEq('status', 'all');

const isAllFiltersAppliedEvery = R.allPass([
  isAllDepartmentFilters,
  isAllBushPayFilters,
  isAllStatusFilters
]);

export const filterUsersData = (
  userArray: UsersRow[],
  filters: FiltersProps
) => {
  if (isAllFiltersAppliedEvery(filters)) {
    return userArray;
  }

  const { department, bushPay, status } = filters;

  let filteredDataArray = userArray;

  if (!isAllDepartmentFilters({ department })) {
    const isFilter = (obj: {}) =>
      R.equals(R.path(['userType', 'id'], obj), department);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllBushPayFilters({ bushPay })) {
    const isFilter = (obj: {}) =>
      R.equals(`${R.pathOr('', ['bushpay'], obj)}`, `${bushPay}`);

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  if (!isAllStatusFilters({ status })) {
    const isFilter = (obj: {}) =>
      R.equals(
        isPresent(R.pathOr('', ['deletedAt'], obj)) ? 'archived' : 'active',
        `${status}`
      );

    filteredDataArray = R.filter(isFilter, filteredDataArray);
  }

  return filteredDataArray;
};
