import types from '../utils/actionTypes';

export const fetchFlightReportScreenDataRequest = (
  startDate: Date,
  endDate: Date
) => {
  return {
    type: types.FETCH_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    payload: {
      startDate,
      endDate
    }
  };
};

export const fetchFlightReportScreenDataSuccess = (flightReportData: []) => {
  return {
    type: types.FETCH_FLIGHT_REPORT_SCREEN_DATA_SUCCESS,
    payload: { flightReportData }
  };
};

export const fetchFlightReportScreenDataFailure = () => {
  return {
    type: types.FETCH_FLIGHT_REPORT_SCREEN_DATA_FAILURE
  };
};

export const updateFlightReportScreenDataRequest = (
  updateValues: {}[],
  isCustomerFlightReport = false
) => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    payload: {
      updateValues,
      isCustomerFlightReport
    }
  };
};

export const updateFlightReportScreenDataSuccess = () => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_SCREEN_DATA_SUCCESS
  };
};

export const updateFlightReportScreenDataFailure = () => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_SCREEN_DATA_FAILURE
  };
};

export const exportFlightReportScreenDataRequest = (withData: false) => {
  return {
    type: types.EXPORT_FLIGHT_REPORT_SCREEN_DATA_REQUEST,
    payload: { withData: withData }
  };
};

export const exportFlightReportScreenDataSuccess = () => {
  return {
    type: types.EXPORT_FLIGHT_REPORT_SCREEN_DATA_SUCCESS
  };
};

export const exportFlightReportScreenDataFailure = () => {
  return {
    type: types.EXPORT_FLIGHT_REPORT_SCREEN_DATA_FAILURE
  };
};

export const updateFlightReportStatusRequest = (
  isLocked: boolean,
  id: string
) => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_STATUS_REQUEST,
    payload: {
      isLocked,
      id
    }
  };
};

export const updateFlightReportStatusSuccess = () => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_STATUS_SUCCESS
  };
};

export const updateFlightReportStatusFailure = () => {
  return {
    type: types.UPDATE_FLIGHT_REPORT_STATUS_FAILURE
  };
};
