import { AircraftScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: AircraftScreenDataProps = {
  isLoading: false,
  aircraft: [],
  aircraftOfficialTypes: []
};

const aircraftScreenData = (
  state: AircraftScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_AIRCRAFT_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_AIRCRAFT_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        aircraft: action.payload.aircraft,
        isLoading: false
      };

    case types.FETCH_AIRCRAFT_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case types.FETCH_AIRCRAFT_OFFICIAL_TYPES_SUCCESS:
      return {
        ...state,
        aircraftOfficialTypes: action.payload.aircraftOfficialTypes
      };

    case types.FETCH_AIRCRAFT_OFFICIAL_TYPES_FAILURE:
      return {
        ...state,
        aircraftOfficialTypes: []
      };

    default:
      return state;
  }
};

export default aircraftScreenData;
