import { FlightReportScreenDataProps, ActionType } from '../utils/interfaces';
import types from '../utils/actionTypes';

const initialState: FlightReportScreenDataProps = {
  isLoading: true,
  flightReportData: []
};

const flightReportScreenData = (
  state: FlightReportScreenDataProps = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case types.FETCH_CLIENT_PORTAL_SCREEN_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case types.FETCH_CLIENT_PORTAL_SCREEN_DATA_SUCCESS:
      return {
        ...state,
        flightReportData: action.payload.flightReportData,
        isLoading: false
      };

    case types.FETCH_CLIENT_PORTAL_SCREEN_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};

export default flightReportScreenData;
