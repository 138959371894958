import types from '../utils/actionTypes';

export const setDepartmentFilter = (departmentFilter: string | unknown) => {
  return {
    type: types.SET_DEPARTMENT_FILTER,
    payload: { value: departmentFilter }
  };
};

export const setStatusFilter = (statusFilter: string | unknown) => {
  return {
    type: types.SET_STATUS_FILTER,
    payload: { value: statusFilter }
  };
};
export const setFirstNameFilter = (firstNameFilter: string | unknown) => {
  return {
    type: types.SET_FIRST_NAME_FILTER,
    payload: { value: firstNameFilter },
  };
};
export const setLastNameFilter = (lastNameFilter: string | unknown) => {
  return {
    type: types.SET_LAST_NAME_FILTER,
    payload: { value: lastNameFilter },
  };
};

export const setSelectedDays = (selectedFirstDate: Date) => {
  return {
    type: types.SET_SELECTED_DAYS,
    payload: {
      selectedFirstDate
    }
  };
};
