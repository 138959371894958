import { put, takeLatest, all, delay } from 'redux-saga/effects';
import types from '../utils/actionTypes';
import api from '../../services/ApiModule';
import * as endpoints from '../../services/endpoints';
import {
  fetchClientUsersScreenDataRequest,
  fetchClientUsersScreenDataSuccess,
  fetchClientUsersScreenDataFailure,
  fetchClientUsersScreenClientsSuccess,
  fetchClientUsersScreenClientsFailure,
  addNewClientUserSuccess,
  addNewClientUserFailure,
  updateClientUserSuccess,
  updateClientUserFailure,
  clientUserStatusChangeSuccess,
  clientUserStatusChangeFailure
} from '../actions/clientUsersScreenData';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { isPresent } from '../../utils/helper';
import { navigateTo } from '../../utils/history';

interface ClientUserPayload {
  newUserData: {};
}
interface ActionType {
  type: String;
  payload: ClientUserPayload;
}

function* updateClientUserStatus(action: any) {
  yield put(showSpinner());

  const { userId, isActive } = action.payload;

  try {
    const response = yield api.updateData(
      isActive ? endpoints.BLOCK_USER : endpoints.UNBLOCK_USER,
      {},
      {
        userId
      },
      'POST'
    );

    if (response.success) {
      yield put(clientUserStatusChangeSuccess());

      yield put(fetchClientUsersScreenDataRequest());
    } else {
      yield put(clientUserStatusChangeFailure());
    }
  } catch (e) {
    yield put(clientUserStatusChangeFailure());
  }

  yield put(hideSpinner());
}

function* updateClientUser(action: any) {
  yield put(showSpinner());

  const { userId, updatedData } = action.payload;

  try {
    const response = yield api.updateData(
      endpoints.UPDATE_CLIENT_USER(userId),
      {},
      updatedData,
      'PUT',
      false
    );

    if (response.success) {
      yield put(updateClientUserSuccess());

      yield put(fetchClientUsersScreenDataRequest());
    } else {
      yield put(updateClientUserFailure());
    }
  } catch (e) {
    yield put(updateClientUserFailure());
  }

  yield put(hideSpinner());
}

function* addNewClientUser(action: ActionType) {
  yield put(showSpinner());

  const body = action.payload.newUserData;

  try {
    const response = yield api.createNewClientUser([body]);

    if (response.success) {
      yield put(addNewClientUserSuccess());

      yield put(fetchClientUsersScreenDataRequest());

      const userId = response.data[0].id;

      if (isPresent(userId)) {
        navigateTo(`/clientusers/details/${userId}`);
      } else {
        navigateTo(`/clientusers`);
      }
    } else {
      yield put(addNewClientUserFailure());
    }
  } catch (e) {
    yield put(addNewClientUserFailure());
  }

  yield put(hideSpinner());
}

function* fetchClientUsersScreenClients() {
  yield delay(1000);

  try {
    const [customersResponse]: any = yield all([
      api.fetchResponse(endpoints.WEB_CUSTOMERS),
    ]);

    if (customersResponse.success) {
      yield put(
        fetchClientUsersScreenClientsSuccess(
          customersResponse.data,
        )
      );
    } else {
      yield put(fetchClientUsersScreenClientsFailure());
    }
  } catch (e) {
    yield put(fetchClientUsersScreenClientsFailure());
  }
}

function* fetchClientUsersScreenData() {
  yield delay(1000);

  try {
    const response = yield api.fetchResponse(endpoints.CLIENT_USERS);

    if (response.success) {
      yield put(fetchClientUsersScreenDataSuccess(response.data));
    } else {
      yield put(fetchClientUsersScreenDataFailure());
    }
  } catch (e) {
    yield put(fetchClientUsersScreenDataFailure());
  }
}

export default [
  takeLatest(types.FETCH_CLIENT_USERS_SCREEN_DATA_REQUEST, fetchClientUsersScreenData),
  takeLatest(types.FETCH_CLIENT_USERS_SCREEN_CLIENTS_REQUEST, fetchClientUsersScreenClients),
  takeLatest(types.ADD_NEW_CLIENT_USER_REQUEST, addNewClientUser),
  takeLatest(types.UPDATE_CLIENT_USER_REQUEST, updateClientUser),
  takeLatest(types.CLIENT_USER_STATUS_CHANGE_REQUEST, updateClientUserStatus)
];
