import types from '../utils/actionTypes';

interface ReportsToDownloadProps {
  accpacImport: boolean;
  weeklySummary: boolean;
  receipts: boolean;
  summaryModeImages: boolean;
}

export const exportWeeklyReportRequest = (
  startDate: Date,
  endDate: Date,
  userIds: number[],
  reportsToDownload: ReportsToDownloadProps
) => {
  return {
    type: types.EXPORT_WEEKLY_REPORT_REQUEST,
    payload: {
      startDate,
      endDate,
      userIds,
      reportsToDownload
    }
  };
};

export const exportWeeklyReportSuccess = () => {
  return {
    type: types.EXPORT_WEEKLY_REPORT_SUCCESS
  };
};

export const exportWeeklyReportFailure = () => {
  return {
    type: types.EXPORT_WEEKLY_REPORT_FAILURE
  };
};
