import React from 'react';
import * as R from 'ramda';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TableBodyRow } from './helper';

interface MyTableBody {
  customerDetailsArray: TableBodyRow[];
}

const getRedColorText = (id: any, data: any) => {
  let className = '';

  if (id === 'difference') {
    const value = parseFloat(data);

    if (R.is(Number, value) && `${value}` !== 'NaN') {
      if (value < 10) {
        className = `${className} 'red-color-text'`;
      }
    }
  }

  return className;
};

const MyTableBody = ({ customerDetailsArray }: MyTableBody) => {
  return (
    <TableBody>
      {customerDetailsArray.map((row: TableBodyRow, index: number) => {
        return (
          <TableRow
            key={`${row.name}_${index}`}
            className={`details-custom-body-row ${row.className}`}>
            <TableCell
              component="th"
              scope="row"
              align="left"
              className="white-space-nowrap">
              {row.name}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="right"
              className={getRedColorText(row.id, row.day1)}>
              {row.day1}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="right"
              className={getRedColorText(row.id, row.day2)}>
              {row.day2}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="right"
              className={getRedColorText(row.id, row.day3)}>
              {row.day3}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="right"
              className={getRedColorText(row.id, row.day4)}>
              {row.day4}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="right"
              className={getRedColorText(row.id, row.day5)}>
              {row.day5}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="right"
              className={getRedColorText(row.id, row.day6)}>
              {row.day6}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="right"
              className={getRedColorText(row.id, row.day7)}>
              {row.day7}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="right"
              className={getRedColorText(row.id, row.total)}>
              {row.total}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default MyTableBody;
