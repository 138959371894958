import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { Box, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ForwardBackwardNav from '../../components/shared/ForwardBackwardNav';
import DetailsScreenTable from '../../components/DetailsScreenTable';
import StoreState, {
  RowData,
  ExpenseTypes
} from '../../redux/utils/interfaces';
import { setComment } from '../../redux/actions/detailsScreenData';

interface DetailsScreenProps {
  currentUserIndex: number;
  length: number;
  handlePageChange: (changeIndex: number) => void;
  currentUserData: RowData;
  selectedFirstDate: Date;
  expenseTypes: ExpenseTypes[];
}

const DetailsScreen = ({
  currentUserIndex,
  length,
  handlePageChange,
  currentUserData,
  selectedFirstDate,
  expenseTypes
}: DetailsScreenProps) => {
  const dispatch = useDispatch();

  const {
    comment,
    userInformation: { canReviewUserIds, isApprovingSupervisor }
  } = useSelector((state: StoreState) => {
    return {
      comment: state.detailsScreenData.comment,
      userInformation: state.userInformation
    };
  });

  const isCommentFieldEditable =
    isApprovingSupervisor ||
    canReviewUserIds.includes(Number(currentUserData.id));

  const managerComment = R.pathOr('', ['managerComment'], currentUserData);

  const handleCommentChange = useCallback(
    (event: any) => {
      dispatch(setComment(event.target.value));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setComment(managerComment));
  }, [dispatch, managerComment]);

  return (
    <React.Fragment>
      <ForwardBackwardNav
        currentIndex={currentUserIndex}
        total={length}
        handlePageChange={handlePageChange}
      />

      <Box py={2.5}>
        <TextField
          fullWidth
          label="Comments"
          variant="outlined"
          multiline
          rows={3}
          rowsMax={3}
          value={comment}
          onChange={handleCommentChange}
          disabled={!isCommentFieldEditable}
        />
      </Box>

      <Box py={2.5}>
        <DetailsScreenTable
          currentUserData={currentUserData}
          selectedFirstDate={selectedFirstDate}
          expenseTypes={expenseTypes}
        />
      </Box>
    </React.Fragment>
  );
};

export default DetailsScreen;
